import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputPhone from '../../components/InputPhone/InputPhone';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import FormItem from '../../components/FormItem/FormItem';
import Checkbox from '../../components/Checkbox/Checkbox';
import SocialButtons from '../../components/SocialButtons/SocialButtons';
import Title from '../../components/Title/Title';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';

import styles from './SignUpSocial.module.scss';

@inject('user', 'navigate')
@observer
class SignUpSocial extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        console.log('ok', values);
        this.props.user.updateUser({ user: values });
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, i18n, user } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = this.props.form;
    const roles = [
      { value: 'talent', label: t('roles.talent'), isDefault: true },
      { value: 'staff', label: t('roles.staff') },
      { value: 'company', label: t('roles.company') },
    ];
    console.log('user', user);

    return (
      <div className={styles.wrapper}>
        <Title>{t('pages.signup.title-social')}</Title>

        <form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('email', {
              initialValue: get(user, 'user.email'),
              rules: [
                { type: 'email', message: t('validation.email.notvalid') },
                { required: true, message: t('validation.email.required') },
              ]
            })(
              <Input
                required
                name="email"
                type="email"
                autocomplete="email"
                placeholder={t('input.email')}
                errors={getFieldError('email')}
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('phone', {
              initialValue: '',
              rules: [
                { required: true, message: t('validation.phone.required') },
              ]
            })(
              <InputPhone
                required
                locale={this.props.user.language}
                placeholder={t('input.phone')}
                errors={getFieldError('phone')}
              />
            )}
          </FormItem>
          {getFieldDecorator('role', {
            initialValue: 'talent',
          })(
            <RadioGroup data={roles} />
          )}
          <div style={{ marginTop: 20 }} className={styles.buttons}>
            <Button
              kind="primary"
              type="submit"
              className={styles.button}
            >{t("button.finish-signup")}</Button>
          </div>
        </form>

      </div>
    )
  }
}

export default withTranslation()(createForm()(SignUpSocial));
