import React, { Component } from "react";
import Checkbox from "rc-checkbox";
import cn from "classnames";
import styles from "./RadioGroup.module.scss";

export default class RadioGroup extends Component {
  handleChange = e => {
    this.props.onChange(e.target.id);
  };

  render() {
    const {
      data,
      value,
      labelTextStyle = {},
      isColumn,
      isRow,
      invertedColors
    } = this.props;

    return (
      <div
        className={cn(
          styles.wrapper,
          { [`${styles.wrapperColumn}`]: isColumn },
          { [`${styles.wrapperRow}`]: isRow }
        )}
      >
        {data.map(item => {
          const isChecked = value === item.value;

          return (
            <label
              key={item.value}
              className={styles.label}
              htmlFor={item.value}
            >
              <Checkbox
                prefixCls={
                  invertedColors ? "rc-checkbox-radio-inverted-colors" : "rc-checkbox-radio"
                }
                onChange={this.handleChange}
                checked={isChecked}
                id={item.value}
              />
              <span style={{ ...labelTextStyle }} className={styles.labelText}>
                {item.label}
              </span>
            </label>
          );
        })}
      </div>
    );
  }
}
