import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import cn from 'classnames';
import { withTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import styles from "./TalentCard.module.scss";
import get from "lodash/get";
import compact from "lodash/compact";
import loaderImage from '../../../images/loader.gif';


@inject("dataStore", "navigate")
@observer
class TalentCard extends Component {
  state = {
    activeImageIndex: 0
  };


  loadImageAsync = (imgIndex) => {
    const user = this.props.user

    const imagesUrl = compact([
      user.avatar_url,
      user.photo_in_three_quarter,
      user.vertical_photo_1,
      user.vertical_photo_2,
      user.vertical_photo_3,
    ]);

    if (imagesUrl.length <= 1) {
      return
    }

    const imgBlock = document.getElementById(`image_block_${imgIndex}`)

    imgBlock.innerHTML = ''

    if (imagesUrl[imgIndex]) {
      const spinner = new Image();
      spinner.src = loaderImage
      imgBlock.append(spinner)
      imgBlock.firstChild.setAttribute('style', 'display: flex; width: 30px; height: 30px; margin: 0 auto;')
    }

    return new Promise((resolve) => {
      const img = new Image();
      img.src = imagesUrl[imgIndex]

      img.addEventListener('load', () => {
        imgBlock.innerHTML = ''
        imgBlock.append(img)
        resolve();
      });
    });
  }

  asyncTalentImagesLoading = () => {
    if (this.props.role === 'talent') {
      this.loadImageAsync(0)
      this.loadImageAsync(1)
      this.loadImageAsync(2)
    }
  }

  componentDidMount() {
    this.asyncTalentImagesLoading()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      this.setState({activeImageIndex: 0})
      this.asyncTalentImagesLoading()
    }
  }

  setImageActiveIndex = index => () => {
    this.setState({ activeImageIndex: index });
  };

  handleFavoriteClick = () => {
    this.props.onFavorite(this.props.user.id);
  }

  renderName = () => {
    const { user, role, lang } = this.props;

    if (role === 'talent' || role === 'staff') {
      return get(user, `first_name_${lang}`) + ' ' + get(user, `last_name_${lang}`);
    } else if (role === 'company') {
      return user.name;
    }
  }

  render() {
    const {
      t, user, dataStore, role, isFavoriteActive, onLeftClick, isLeftClickActive, userId,
      onRightClick, isRightClickActive, lang
    } = this.props;
    const isFavorite = dataStore.favoritesByRole(role).find(t => t[`${role}_id`] == user.id)

    const imagesUrl = compact([
      user.avatar_url,
      user.photo_in_three_quarter,
      user.vertical_photo_1,
      user.vertical_photo_2,
      user.vertical_photo_3,
    ]);

    const allProfessions = toJS(dataStore.allProfessions);
    const mainProfession = allProfessions
      .find(p => p.value === get(user, 'main_profession'));

    return (
      <div className={styles.wrapper}>
        <div className={styles.photos}>
          {(role === 'talent') && (imagesUrl.length > 1) && (
              <div id="images_block" className={styles.photoPreviews}>
                <div id="image_block_0" className={styles.photoPreview} onClick={this.setImageActiveIndex(0)}>
                </div>
                <div id="image_block_1" className={styles.photoPreview} onClick={this.setImageActiveIndex(1)}>
                </div>
                <div id="image_block_2" className={styles.photoPreview} onClick={this.setImageActiveIndex(2)}>
                </div>
              </div>
          )}

          <div className={cn(styles.mainPhoto, role === 'company' && styles.mainPhotoCompany)}>
            <img
              key={user.avatar_url}
              src={
                imagesUrl[this.state.activeImageIndex]
                  ? imagesUrl[this.state.activeImageIndex]
                  : user.avatar_url
              }
              alt=""
            />
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.name}>{this.renderName()}</div>

          {(role === 'company' && user.professions.length > 0) && (
            <div className={styles.infoBlock}>
              <div className={styles.infoTitle}>{t("input.company-activity")}:</div>
              <div className={styles.infoValue}>
                {user.professions.map(id => {
                  const prof = dataStore.companyServicesOptions.find(o => o.value === id);

                  return prof ? prof.label : '';
                }).join(', ')}
              </div>
            </div>
          )}

          {role === 'talent' && (<div className={styles.infoBlock}>
            <div className={styles.infoTitle}>{t("label.status")}:</div>
            <div className={styles.infoValue}>
              {t(`label.actor-${user.actor_status}`)}
            </div>
          </div>)}
          {role === 'staff' && (<div className={styles.infoBlock}>
            <div className={styles.infoTitle}>{t("label.main-profession")}:</div>
            <div className={styles.infoValue}>
              {get(mainProfession, 'label', '-')}
            </div>
          </div>)}
          {(role === 'talent' || role === 'staff') && (<div className={styles.infoBlock}>
            <div className={styles.infoTitle}>{t("label.location")}:</div>
            <div className={styles.infoValue}>{get(user, 'city', '–')}</div>
          </div>)}
          {user.age > 0 && (
            <div className={styles.infoBlock}>
              <div className={styles.infoTitle}>{t("label.age")}:</div>
              <div className={styles.infoValue}>{user.age}</div>
            </div>
          )}
          {role === 'talent' && (<div className={styles.infoBlock}>
            <div className={styles.infoTitle}>{t("label.height")}:</div>
              <div className={styles.infoValue}>
                {user.height ? `${user.height} ${t("label.cm")}` : '–'}
              </div>
            </div>
          )}
          {role === 'talent' && (<div className={styles.infoBlock}>
            <div className={styles.infoTitle}>{t("label.weight")}:</div>
              <div className={styles.infoValue}>
                {user.weight ? `${user.weight} ${t("label.kg")}` : '–'}
              </div>
            </div>
          )}
          {role === 'staff' && (<div className={styles.infoBlock}>
            <div className={styles.infoTitle}>{t("input.shift-rate")}:</div>
              <div className={styles.infoValue}>
                {user.rate_start ? `${user.rate_start}$` : '–'}
              </div>
            </div>
          )}
          {(role === 'company') && (
            <div className={styles.infoBlock}>
              <div className={styles.infoTitle}>{t("label.geolocation")}:</div>
              <div className={styles.infoValue}>{get(user, 'address', '–')}</div>
            </div>
          )}
          <div className={styles.buttons}>
            {user.slug && (
              <a
                href={`/${lang}/users/${user.slug}/information`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ flex: '1 1 auto'}}
              >
                <Button className={styles.profileButton} onClick={this.handleResetForm} kind="primary">
                  {t("button.go-to-profile")}
                </Button>
              </a>
            )}
            {isFavoriteActive && <div
              onClick={this.handleFavoriteClick}
              className={cn(styles.addFavorite, isFavorite && styles.addFavoriteActive)}
            />}
          </div>
          {(isLeftClickActive || isRightClickActive) && (
              <div className={styles.mobileButtons}>
                {isLeftClickActive && (
                  <div onClick={() => onLeftClick()} className={cn(styles.moveButton, styles.prevButton)}>
                    {t("button.prev-user")}
                  </div>
                )}
                {isRightClickActive && (
                  <div onClick={() => onRightClick()} className={cn(styles.moveButton, styles.nextButton)}>
                    {t("button.next-user")}
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(TalentCard);
