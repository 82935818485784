import React from 'react';
import cn from 'classnames';
import styles from './BlockHeader.module.scss';

export default function BlockHeader(props) {
  return (
    <div className={cn(styles.header, props.className)} style={{ ...props.styles }}>
      {props.children}
    </div>
  )
}
