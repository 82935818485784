import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import Button from '../../components/Button/Button';

import PortfolioMovieEdit from './PortfolioMovieEdit';
import PortfolioTheaterEdit from './PortfolioTheaterEdit';
import PortfolioAwardEdit from '../Forms/PortfolioAwardEdit';
import PortfolioPhotoEdit from './PortfolioPhotoEdit';
import PortfolioVideoEdit from './PortfolioVideoEdit';
import PortfolioAudioEdit from '../Forms/PortfolioAudioEdit';

import formStyles from "../Forms/EditForms.module.scss";

@inject('user', 'dataStore', 'navigate')
@observer
class PortfolioEdit extends Component {
  componentDidMount() {
    this.props.dataStore.fetchMovieTypesList();
  }

  handleNextStep = () => {
    this.props.navigate({
      path: `/app/${this.props.user.user.role}/edit`,
      query: { tab: 'contacts' }
    }, true);
  }

  handlePayClick = () => {
    this.props.navigate({ path: `/app/talent/payment` }, true);
  }

  render() {
    const { user, t } = this.props;
    const role_object = get(user, 'user.role_object', {});

    const movieRoles = get(role_object, 'movie_roles');
    const theaterRoles = get(role_object, 'theater_roles');
    const awards = get(role_object, 'awards');
    const audios = get(role_object, 'media.audios');

    const payStatus = get(role_object, 'subscription.status');

    return (
      <div className={formStyles.form}>
        <div className={formStyles.formTitle}>{t("title.role")}</div>
        <div className={formStyles.formSeparator}/>

        {movieRoles.map(data => (
          <PortfolioMovieEdit key={data.id} data={data}/>
        ))}

        <PortfolioMovieEdit/>

        <div className={formStyles.formTitle}>{t("title.theater-role")}</div>
        <div className={formStyles.formSeparator}/>

        {theaterRoles.map(data => (
          <PortfolioTheaterEdit key={data.id} data={data}/>
        ))}

        <PortfolioTheaterEdit/>

        <div className={formStyles.formTitle}>{t("title.awards")}</div>
        <div className={formStyles.formSeparator}/>

        {awards.map(data => (
          <PortfolioAwardEdit key={data.id} data={data}/>
        ))}

        <PortfolioAwardEdit/>

        <div>
          <div className={formStyles.formTitle}>{t("title.photogallery")}</div>
          <div className={formStyles.formSeparator}/>
          <p className={formStyles.formDesc}>{t("text.photogallery-desc")}</p>

          <PortfolioPhotoEdit/>

          <div className={formStyles.formTitle}>{t("title.video")}</div>
          <div className={formStyles.formSeparator}/>
          <p className={formStyles.formDesc}>{t("text.video-desc")}</p>

          <PortfolioVideoEdit/>

          <div className={formStyles.formTitle}>{t("title.audio")}</div>
          <div className={formStyles.formSeparator}/>
          <p className={formStyles.formDesc}>{t("text.audio-desc")}</p>

          {audios.map(data => (
            <PortfolioAudioEdit key={data.id} data={data}/>
          ))}

          <PortfolioAudioEdit audios={audios}/>
        </div>

        {/* {payStatus === 'not_paid' && (
          <div className={formStyles.paidMaterials}>
            <p>{t("text.talent-paid-materials")}</p>
            <Button onClick={this.handlePayClick} kind="primary" type="button">{t("button.pay")}</Button>
          </div>
        )} */}

        <Button onClick={this.handleNextStep} kind="bordered" type="button">{t("button.next-step")}</Button>

      </div>
    )
  }
}

export default withTranslation()(PortfolioEdit);
