import React from 'react';
import cn from 'classnames';
import styles from './Title.module.scss';

export default function Title(props) {
  return (
    <div className={cn(styles.title, props.className)}>
      {props.children}
    </div>
  )
}
