import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Modal from "react-modal";
import cn from 'classnames';
import Button from '../Button/Button';
import FormHeader from "../FormHeader/FormHeader";
import styles from './BoardMoveModal.module.scss';
import closeImage from "../../../images/close-modal-icon.png";

import ProjectForm from "../../pages/Forms/Project";
import Project from "../../pages/Forms/Project";

Modal.setAppElement('#app');

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 545,
    height: 536
  }
};

class BoardMoveModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProjekt: undefined,
    }
  }

  onReject = () => {
    this.props.handleClose();
  };

  handleCancelClick = () => {
    this.props.handleClose();
  }

  handleProjektClick = (projektId) => {
    if (projektId === this.state.selectedProjekt) {
      this.setState({ selectedProjekt: undefined });
    } else {
      this.setState({ selectedProjekt: projektId });
    }
  }

  handleMoveClick = () => {
    console.log('handleMoveClick');
    this.props.onFavoritesMove(this.state.selectedProjekt).then(() => {
      this.setState({ selectedProjekt: undefined });
    });
  }

  render() {
    const { t, isOpen, projekts = [] } = this.props;
    const { selectedProjekt } = this.state;

    return (
      <Modal isOpen={isOpen} style={modalStyle} onRequestClose={this.onReject}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <FormHeader>{t("title.move-to-board")}</FormHeader>
          </div>
          <div className={styles.body}>
            <div className={styles.projektsList}>
              {projekts.map(projekt => {
                return (
                  <div
                    key={projekt.id}
                    onClick={() => this.handleProjektClick(projekt.id)}
                    className={cn(styles.projekt, (selectedProjekt === projekt.id) && styles.projektActive )}
                  >
                    <div className={styles.projektTile}>
                      <div className={styles.projektTileItem}/>
                      <div className={styles.projektTileItem}/>
                      <div className={styles.projektTileItem}/>
                      <div className={styles.projektTileItem}/>
                      <div className={styles.projektTileItem}/>
                      <div className={styles.projektTileItem}/>
                    </div>
                    <div className={styles.projektName}>{projekt.name}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.footer}>
            <Button onClick={this.handleMoveClick} disabled={!selectedProjekt} className={styles.buttonFirst} kind="primary">
              {t("button.move")}
            </Button>
            <Button onClick={this.handleCancelClick} kind="bordered">
              {t("button.cancel")}
            </Button>
          </div>
        </div>

      </Modal>
    );
  }
}

export default withTranslation()(BoardMoveModal);
