import React, { Component } from 'react';
import cn from 'classnames';
import Check from 'rc-checkbox';
import styles from './Checkbox.module.scss';

export default class Checkbox extends Component {
  handleChange = (e) => {
    this.props.onChange(e.target.checked);
  }

  render() {
    const { value, label, name, count, size, errors = [] } = this.props;
    let realValue = value;

    if (value === 'false') realValue = false;
    if (value === 'true') realValue = true;

    return (
      <div className={styles.wrapper}>
        <label className={styles.label} htmlFor={name}>
          <Check onChange={this.handleChange} checked={realValue} id={name}/>
          <span className={cn(styles.labelText, size === 'small' && styles.labelTextSmall)}>
            {label}
            {count ? (<span className={styles.count}>&nbsp;({count})</span>) : null}
          </span>
        </label>
        {errors.length > 0 && (<p className={styles.errors}>{errors.length > 0 ? errors.join(', ') : null}</p>)}
      </div>
    )
  }
}
