import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import Modal from 'react-modal';
import styles from './DropzonePhoto.module.scss';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import Slider from '../RCRange/RCSlider';
import getCroppedImg from '../Dropzone/cropImage';
import get from 'lodash/get';

class DropzonePhoto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      cropedImage: undefined
    }
  }

  handleFileDrop = (acceptedFiles, files) => {
    const { field } = this.props;

    console.log(acceptedFiles, files);

    if (files.length > 1) {
      this.props.form.setFields({ [field]: {
        value: this.props.form.getFieldValue(field),
        errors: [new Error('Выберите один файл')]
      }});
    }

    if (files[0] && !['image/png', 'image/jpg', 'image/jpeg'].includes(files[0].type)) {
      this.props.form.setFields({ [field]: {
        value: this.props.form.getFieldValue(field),
        errors: [new Error('Выберите файл с расширением .png или .jpg/.jpeg')]
      }});
    }

    this.setState({ isLoading: true });

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => {
        this.setState({ isLoading: false });
        console.log('file reading was aborted')
      }
      reader.onerror = () => {
        this.setState({ isLoading: false });
        console.log('file reading has failed')
      }
      reader.onload = () => {
      // Do whatever you want with the file contents
        const base64 = reader.result

        if (this.props.withCrop) {
          this.setState({ cropedImage: base64 });
        } else {
          this.props.onChange(field, base64);
        }

        this.setState({ isModalOpen: true, isLoading: false });
      }
      reader.readAsDataURL(file);
    })
  }

  handleCropSave = () => {
    this.setState({ isLoading: true, isModalOpen: false });

    setTimeout(() => {
      getCroppedImg(this.state.cropedImage || get(this.props.value, 'path'), this.state.croppedAreaPixels, 0)
      .then((base64) => {
        this.props.onChange(this.props.field, base64);
        this.setState({ cropedImage: base64, isLoading: false, crop: { x: 0, y: 0 }, zoom: 1 });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      })
    }, 200);
  }

  onZoomChange = zoom => this.setState({ zoom });

  onCropChange = crop => this.setState({ crop });

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedArea, croppedAreaPixels });
  }

  handleCropOpen = () => this.setState({ isModalOpen: true });

  handleCropClose = () => this.setState({ isModalOpen: false });

  handleResetClick = () => this.setState({ crop: { x: 0, y: 0 }, zoom: 1 });

  handlePhotoRemove = () => {
    this.setState({ cropedImage: undefined });
    this.props.onRemove(this.props.value.id);
  }

  render() {
    const { t, value = {}, errors = [], title, withCrop } = this.props;
    const { isModalOpen, cropedImage } = this.state;

    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };

    return (
      <div className={styles.block}>
        <Loader isLoading={this.state.isLoading} />
        {!(value.path || cropedImage) && (
          <Dropzone
            onDrop={this.handleFileDrop}
            multiple={false}
            accept={['image/png', 'image/jpg', 'image/jpeg']}
          >
            {({getRootProps, getInputProps}) => (
              <section className={styles.wrapper}>
                <div className={styles.inner} {...getRootProps()}>
                  <input {...getInputProps()} />
                  {title && <p className={styles.title}>{title}</p>}
                  <p className={styles.text}>{t('gallery.text')}</p>
                </div>
              </section>
            )}
          </Dropzone>
        )}
        {errors.length > 0 && (
          <p className={styles.errors}>{errors.join(', ')}</p>
        )}
        {(cropedImage || value.path) && (
          <div className={styles.imageContainer}>
            <div className={styles.removeIcon} onClick={this.handlePhotoRemove} />
            {this.props.withCrop && (
              <div className={styles.editIcon} onClick={this.handleCropOpen} />
            )}
            <div className={styles.imagePreview} style={{ backgroundImage: `url(${this.state.cropedImage || value.path})` }} />
          </div>
        )}
        {withCrop && (
          <Modal
            isOpen={isModalOpen}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className={styles.cropWrapper}>
              <div className={styles.cropInner}>
                <Cropper
                  minZoom={1}
                  image={cropedImage || get(value, 'path')}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={this.props.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  restrictPosition={true}
                />
              </div>
              <div className={styles.sliderWrapper}>
                <Slider wrapperClassName={styles.slider} onChange={this.onZoomChange} value={this.state.zoom} min={0.4} max={3} step={0.1}/>
                <Button onClick={this.handleResetClick} kind="bordered" size="small">{t("button.reset")}</Button>
              </div>
              <p className={styles.help}>*{t("text.crop-photo-help")}</p>
              <div className={styles.imageInfo}>
                <Button onClick={this.handleCropSave}>{t("button.save")}</Button>
                {/* <div onClick={this.handleCropClose} className={styles.imageButtonPrimary}>Закрыть</div> */}
              </div>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

export default withTranslation()(DropzonePhoto);
