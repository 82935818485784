import React, { Component } from 'react';
import Button from '../Button/Button';
import styles from './CastingsList.module.scss';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

class CastingsList extends Component {
  render() {
    const { t, castings } = this.props;

    return (
      <div className={styles.list}>
        {castings.map(casting => (
          <div key={casting.id} className={styles.casting}>
            <div className={styles.castingName}>{casting.projekt_type} {casting.projekt_name}</div>
            <div className={styles.castingBody}>
              <table className={styles.castingData}>
                <tbody>
                  <tr>
                    <td className={styles.castingDataCell}>
                      <div className={styles.castingDataTitle}>{t("input.shooting-cities")}</div>
                      <div className={styles.castingDataValue}>{casting.shooting_cities}</div>
                    </td>
                    <td className={styles.castingDataCell}>
                      <div className={styles.castingDataTitle}>{t("label.project-role")}</div>
                      <div className={styles.castingDataValue}>{casting.role_type}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.castingDataCell}>
                      <div className={styles.castingDataTitle}>{t("label.dates")}</div>
                      <div className={styles.castingDataValue}>
                        <span>{moment(casting.casting_start_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</span>
                        &nbsp;–&nbsp;
                        <span>{moment(casting.casting_end_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</span>
                      </div>
                    </td>
                    <td className={styles.castingDataCell}>
                      <div className={styles.castingDataTitle}>{t("input.shift-rate")}</div>
                      <div className={styles.castingDataValue}>{casting.rate}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={styles.castingButton}>
                <Button kind="primary" size="small">{t("button.more")}</Button>
              </div>
            </div>
          </div>
        ))
        }
      </div>
    )
  }
}

export default withTranslation()(CastingsList);
