import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import get from "lodash/get";
import { NotificationManager } from 'react-notifications';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Button, Loader, AdvCard, VacancyCard, BoardCard, BoardCardCreate } from "../../components";
import BoardFavorites from '../BoardFavorites/BoardFavorites';
import { findProjectInfo } from "../../utils/projects";
import GuidePopup from "../../components/GuidePopup/GuidePopup";

import styles from "./Boards.module.scss";
import cabinetStyles from "../Cabinet/Cabinet.module.scss";
import menuIcon from '../../../images/bread-menu-icon.png';
import starIcon from '../../../images/bread-star-icon.png';
import Modal from "react-modal";
import closeImage from "../../../images/close-modal-icon.png";

const maxDeep = 2;

@inject("user", "dataStore", "navigate")
@observer
class Boards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      data: null,
      activeModalBoard: null
    };
  }

  getProjects = () => {
    const { dataStore, ctx } = this.props;

    const boardId = get(ctx, "params.boardId");

    if (boardId) {
      const info = findProjectInfo(dataStore.projectsList, boardId, 1);

      const subProjects = get(info, "project.projekts", []);

      return { projects: subProjects, level: info.level, project: toJS(info.project) };
    }

    return { projects: dataStore.projectsList, level: 0 };
  };

  handleBoardCreate = () => {
    const { t, ctx, user } = this.props;

    const boardId = get(ctx, "params.boardId");
    const userId = get(user, "user.role_object.id");

    const userType = get(user, "user.role");

    const projektable_type = boardId ? "projekt" : userType;
    const projektable_id = boardId ? boardId : userId;

    this.props.dataStore.createProject({
      name: t("title.new-board"),
      projektable_id,
      projektable_type:
        projektable_type.charAt(0).toUpperCase() + projektable_type.substring(1)
    });
  }

  handleBoardUpdate = (id, name) => {
    return this.props.dataStore.updateProject({ name, id });
  }

  renderProjects = () => {
    const { projects, level } = this.getProjects();
    const { isSpecialBoard } = this.getBoardInfo();

    return (
      <TransitionGroup className={styles.boards}>
        {projects.map(board => (
          <CSSTransition
            key={board.id}
            timeout={300}
            classNames="item"
          >
            <div className={styles.board}>
              <BoardCard
                data={board}
                handleDelete={() => this.handleBoardRemoveToggleButton(board)}
                onClick={this.navigateToBoard}
                onUpdate={this.handleBoardUpdate}
              />
            </div>
          </CSSTransition>
        ))}
        {((level < maxDeep && !isSpecialBoard) || (isSpecialBoard && level === 1)) ? (
          <CSSTransition
            key={'create'}
            timeout={300}
            classNames="item"
          >
            <div className={styles.board}>
              <BoardCardCreate onClick={this.handleBoardCreate} />
            </div>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    );
  };

  onDeleteBoard = id => {
    this.props.dataStore.deleteProject(id);
    this.toggleRemoveModal()
  };

  toggleRemoveModal = () => {
    this.props.user.toggleBoardRemoveModal();
  }

  navigateToBoard = id => {
    const { user, navigate } = this.props;

    const userType = get(user, "user.role");

    navigate({ path: `/app/${userType}/boards/${id}` }, true);
  };

  navigateToCreateCasting = () => {
    const { user, ctx, navigate } = this.props;

    const userType = get(user, "user.role");
    const boardId = get(ctx, "params.boardId", 0);

    navigate({ path: `/app/${userType}/boards/${boardId}/create_casting` }, true);
  };

  navigateToCreateExtras = () => {
    const { user, ctx, navigate } = this.props;

    const userType = get(user, "user.role");
    const boardId = get(ctx, "params.boardId", 0);

    navigate({ path: `/app/${userType}/boards/${boardId}/create_extras` }, true);
  };

  navigateToCreateVacancy = () => {
    const { user, ctx, navigate } = this.props;

    const userType = get(user, "user.role");
    const boardId = get(ctx, "params.boardId", 0);

    navigate({
      path: `/app/${userType}/boards/${boardId}/create_vacancy`
    }, true);
  };

  navigateBack = () => {
    const { user, navigate } = this.props;

    const userType = get(user, "user.role");

    navigate({ path: `/app/${userType}/boards` });
  }

  componentWillMount() {
    const { user } = this.props;
    const { role } = get(user, 'user');

    if (user.isUnderModeration) {
      this.props.navigate({ path: `/app/${role}` });

      NotificationManager.error(this.props.t("text.wait-for-moderation-menu"));
    }
  }

  componentDidMount() {
    this.props.dataStore.fetchProjects();
    this.props.dataStore.fetchFavoriteTalents();
    this.props.dataStore.fetchFavoriteStaffs();
    this.props.dataStore.fetchFavoriteCompanies();
    this.props.dataStore.fetchMovieTypesList();
    this.props.dataStore.fetchCareersList();
  }

  handleTabChange = tab => {
    const { level } = this.getProjects();

    if (level === 0) {
      switch (tab) {
        case 'talents': this.props.dataStore.fetchFavoriteTalents(); break;
        case 'staffs': this.props.dataStore.fetchFavoriteStaffs(); break;
        case 'companies': this.props.dataStore.fetchFavoriteCompanies(); break;
        default: break;
      }
    }
  }

  handleInviteClick = (userRoleIds, tab) => {
    const { projectCastings, projectVacancies, projectCrowdings } = this.getBoardInfo();
    const boardId = get(this.props.ctx, "params.boardId");
    const userType = get(this.props.user, "user.role");

    if (tab === 'talent') {
      const projectTheCasting =  projectCastings.length > 0 ? projectCastings : projectCrowdings
      const castingId = get(projectTheCasting, '[0].id', 0);

      this.props.navigate({
        path: `/app/${userType}/boards/${boardId}/casting/${castingId}/invitation`,
        query: { selected: userRoleIds }
      })
    }

    if (tab === 'staff' || tab === 'company') {
      const vacancyId = get(projectVacancies, '[0].id', 0);

      this.props.navigate({
        path: `/app/${userType}/boards/${boardId}/vacancy/${vacancyId}/invitation`,
        query: { selected: userRoleIds, selected_role: tab }
      })
    }
  }

  getBoardInfo = (board) => {
    const { project } = this.getProjects();
    const projectData = board || project;
    let type = undefined;

    const projectVacancies = get(projectData, 'vacancies', []);
    const projectCastings = get(projectData, 'advertisements', []).filter(a => a.form === 0);
    const projectCrowdings = get(projectData, 'advertisements', []).filter(a => a.form === 1);

    const isCastingBoard = projectCastings.length > 0;
    if (isCastingBoard) type = 'casting';
    const isCrowdingBoard = projectCrowdings.length > 0;
    if (isCrowdingBoard) type = 'crowding';
    const isVacancyBoard = projectVacancies.length > 0;
    if (isVacancyBoard) type = 'vacancy';
    const isSpecialBoard = isCastingBoard || isCrowdingBoard || isVacancyBoard;

    return {
      projectVacancies, projectCastings, projectCrowdings, type,
      isCastingBoard, isCrowdingBoard, isVacancyBoard, isSpecialBoard,
    };
  }

  handleBreadcrumbClick = projectId => {
    const { user } = this.props;

    const userType = get(user, "user.role");

    if (projectId) {
      this.navigateToBoard(projectId);
    } else {
      this.props.navigate({ path: `/app/${userType}/boards` }, true);
    }
  }

  handleBoardRemoveToggleButton = (board) => {
    const stateBoard = this.state.activeModalBoard
    const activeModalBoardPresent = board && stateBoard && stateBoard.id !== board.id
    const activeModalBoardEmpty = board && !stateBoard

    if (activeModalBoardEmpty || activeModalBoardPresent) {
      this.setState({activeModalBoard: board})
    } else {
      this.setState({activeModalBoard: null})
    }

    this.toggleRemoveModal()
  };

  renderBreadcrumbs = () => {
    const { t } = this.props;
    const breadcrumbs = [{ name: t("board.favorites") }];
    const { level, project } = this.getProjects();

    if (level === 1 && project) {
      const { type, isSpecialBoard } = this.getBoardInfo(project);
      let name = project.name;
      if (isSpecialBoard) name += ` (${t(`board.type.${type}`)})`;

      breadcrumbs.push({ name, id: project.id });
    }

    if (level === 2 && project) {
      const parent = this.props.dataStore.projects.find(p => p.id === project.projektable_id);

      if (parent) {
        const { type, isSpecialBoard } = this.getBoardInfo(parent);
        let name = parent.name;
        if (isSpecialBoard) name += ` (${t(`board.type.${type}`)})`;

        breadcrumbs.push({ name, id: parent.id });
      }

      breadcrumbs.push({ name: project.name, id: project.id });
    }

    return (
      <div className={styles.breadcrumbs}>
        <img className={styles.breadcrumbsIcon} src={menuIcon} alt=""/>
        {breadcrumbs.map((breadcrumb, index) => (
          <>
            <div onClick={() => this.handleBreadcrumbClick(breadcrumb.id)}>{breadcrumb.name}</div>
            <span>{(index !== breadcrumbs.length - 1) && (<>&nbsp;/&nbsp;</>)}</span>
          </>
        ))}
      </div>
    );
  }

  renderModalContent = () => {
    const { user, t } = this.props;

    return (
      <div className={styles.modalContentWrapper}>
        <img
          src={closeImage}
          onClick={() => this.handleBoardRemoveToggleButton()}
          className={styles.closeIcon}
        />
        <p>{t("title.board-remove")} «{this.state.activeModalBoard.name}» ?</p>

        <div className={styles.removeButtons}>
          <Button
            onClick={() => this.onDeleteBoard(this.state.activeModalBoard.id)}
            className={styles.profileButtonEdit}
            kind="primary"
          >
            {t("button.yes")}
          </Button>

          <Button
            onClick={() => this.handleBoardRemoveToggleButton()}
            className={styles.profileButtonCV}
            kind="gray">
            {t("button.no")}
          </Button>
        </div>



      </div>
    );
  };

  render() {
    const { t, dataStore, ctx, user } = this.props;

    const { level, project } = this.getProjects();
    const {
      projectVacancies, projectCastings, projectCrowdings,
      isCastingBoard, isCrowdingBoard, isVacancyBoard, isSpecialBoard,
    } = this.getBoardInfo();

    const favoriteTalents = project ? get(project ,'favorite_talents', []) : dataStore.favoriteTalents;
    const favoriteStaffs = project ? get(project ,'favorite_staffs', []) : dataStore.favoriteStaffs;
    const favoriteCompanies = project ? get(project ,'favorite_companies', []) : dataStore.favoriteCompanies;
    const favoritesCount = favoriteTalents.length + favoriteStaffs.length + favoriteCompanies.length;

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 545,
        maxHeight: 288
      }
    };

    // Тут из всех объектов user.guides выявляем нужный нам для страницы Досок с темой "boards_guide_popup"
    const guidePopupEvent = get(user, 'user.events').find(event => event.theme === 'boards_guide_popup')

    return (
      <div className={cabinetStyles.page}>
        <Loader isLoading={dataStore.isBoardUpdating} />

        <GuidePopup guideState={guidePopupEvent && guidePopupEvent.state} theme={'boards_guide_popup'} title={t("text.guide-title-2")} videoLink={'https://www.youtube.com/watch?v=HGYrLR3WZ-0 '} />

        <div className={cabinetStyles.inner}>
          {this.renderBreadcrumbs()}
          {(level < 1 && !isSpecialBoard) &&
          (
            <div className={styles.blockBody}>
              <div className={styles.buttons}>
                <Button
                  wrapperClassName={styles.button}
                  kind="bordered"
                  onClick={this.navigateToCreateCasting}
                >
                  {t("button.announce-casting")}
                </Button>
                <Button
                  wrapperClassName={styles.button}
                  onClick={this.navigateToCreateExtras}
                >
                  {t("button.collect-extras")}
                </Button>
                <Button
                  wrapperClassName={styles.button}
                  kind="gray"
                  onClick={this.navigateToCreateVacancy}
                >
                  {t("button.post-vacancies")}
                </Button>
                {level > 0 && (<div className={styles.buttonRight}>
                  <Button
                    className={styles.button}
                    kind="bordered"
                    onClick={this.navigateBack}
                  >
                    {t("button.back")}
                  </Button>
                </div>)}
              </div>
            </div>
          )}
          {(isCastingBoard || isCrowdingBoard) && (
            [...projectCastings, ...projectCrowdings].map(adv => (
              <AdvCard isOwner key={adv.id} data={adv} />
            ))
          )}

          {isVacancyBoard && (projectVacancies.map(v => (
            <VacancyCard isOwner key={v.id} data={v} />
          )))}

          { level < 1 && this.renderProjects() }

          {favoritesCount > 0 && (
            <BoardFavorites
              ctx={ctx}
              level={level}
              talent={favoriteTalents}
              staff={favoriteStaffs}
              company={favoriteCompanies}
              onInviteClick={this.handleInviteClick}
              onTabChange={this.handleTabChange}
              notCopyWhenMove={isSpecialBoard || level === 2}
              withStatuses={true}
            />
          )}
        </div>

        <Modal
          isOpen={user.isBoardRemoveModalOpen}
          style={modalStyle}
          onRequestClose={this.handleBoardRemoveToggleButton}
        >
          {this.state.activeModalBoard && this.renderModalContent()}
        </Modal>

      </div>
    );
  }
}

export default withTranslation()(Boards);
