import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import Button from '../Button/Button';
import moment from 'moment';
import get from 'lodash/get';

import ModalInfo from './ModalInfo';

@inject("user", "staff", "company", "dataStore")
@observer
class ModalVacancy extends Component {
  handleApproveClick = () => {
    const { role } = this.props.user.user;

    this.props[role].acceptFavoriteProjekt(this.props.data.projekt_id)
      .then(() => this.props.onClose());
  }

  handleDownloadDocument = () => {
    const { name, path } = this.props.data.document;
    this.props.user.downloadFile(name, path);
  }

  render() {
    const { t, isOpen, data, staff, company, isOwner, dataStore } = this.props;

    const startDate = moment(data.start_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const endDate = moment(data.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY');

    const dataArray = [
      { label: t("label.project"), value: `${dataStore.getMovieTypeById(data.projekt_type)} ${get(data, 'projekt_name')}` },
      { label: t("label.producer"), value: data.director ? data.director : '–' },
      {
        label: t("label.about-project"),
        value: data.document ? (<Button onClick={this.handleDownloadDocument}  size="small" kind="primary">{t('button.download')}</Button>) : '–' },
      { label: t("label.looking-for"), value: data.target ? data.target : '–' },
      { label: t("input.shift-rate"), value: data.rate ? `${data.rate} ${data.currency}` : '–' },
      { label: t("label.date"), value: data.start_date ? `${startDate} – ${endDate}` : '–' },
      { label: t("label.address"), value: data.place ? data.place : '–' },
      { label: t("label.description"), value: data.about ? data.about :  '–' },
      {
        label: t("label.author"),
        value: (
          <>
            <p>{data.sender_first_name} {data.sender_last_name}</p>
            <p>{data.phone}</p>
            <p>{data.email}</p>
          </>
        )
      }
    ];

    return (
      <ModalInfo
        title={t('label.vacancy')}
        isOpen={isOpen}
        dataArray={dataArray}
        data={data}
        isUpdating={staff.isUpdating || company.isUpdating}
        onClose={this.props.onClose}
      >
        {!isOwner && (
          data.is_participant ? (
            <p>{t("text.you-applied-to-vacancy")}</p>
          ): (
            <Button onClick={this.handleApproveClick}>{t('button.apply')}</Button>
          )
        )}
      </ModalInfo>
    );
  }
}

export default withTranslation()(ModalVacancy);
