import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import Button from '../Button/Button';
import FormItem from '../FormItem/FormItem';
import styles from './FormItemControls.module.scss';
import get from 'lodash/get';

@inject('user')
@observer
class FormItemControls extends Component {
  handleRemove = () => {
    const { entity, user } = this.props;
    const { role } = user.user;
    const data = {
      [role]: {
        [`${entity}_attributes`]: [{
          id: get(this.props.data, 'id'),
          _destroy: '1'
        }],
      }
    };

    this.props.user.update(data, true);
  }

  render() {
    const { t, data = {} } = this.props;

    return (
      <FormItem>
        {data.id ? (
          <div className={styles.buttons}>
            <Button className={styles.buttonFirst} kind="primary" type="submit">{t("button.update")}</Button>
            <Button onClick={this.handleRemove} kind="bordered">{t("button.remove")}</Button>
          </div>
        ) : (
          <Button kind="primary" type="submit">{t("button.add")}</Button>
        )}
      </FormItem>
    )
  }
}

export default withTranslation()(FormItemControls);
