import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Title from '../../components/Title/Title';
import FormItem from '../../components/FormItem/FormItem';
import styles from './NewPassword.module.scss';
import { withTranslation } from 'react-i18next';

@inject('user', 'navigate')
@observer
class NewPassword extends Component {
  handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();

    this.props.form.validateFields({
      // firstFields: false,
    }, (error, values) => {
      if (!error) {
        this.props.user.updatePassword({ user: {
          ...values,
          reset_password_token: this.props.token
        }});
        console.log('ok', values);
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator, getFieldError } = this.props.form;

    console.log('this.props', this.props)
    return (
      <div className={styles.wrapper}>
        <Title>{t('pages.new-password.title')}</Title>
        <form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('password', {
              initialValue: '',
              rules: [
                { required: true, message: t('validation.password.required') },
                { min: 6, message: t('validation.password.minlength') }
              ]
            })(
              <Input
                required
                name="password"
                type="password"
                autocomplete="new-password"
                placeholder={t('input.password')}
                errors={getFieldError('password')}
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password_confirmation', {
              initialValue: '',
              rules: [
                { required: true, message: t('validation.password-confirmation.required') },
                { min: 6, message: t('validation.password.minlength') }
              ]
            })(
              <Input
                required
                name="password_confirmation"
                type="password"
                autocomplete="new-password"
                placeholder={t('input.password-confirmation')}
                errors={getFieldError('password_confirmation')}
              />
            )}
          </FormItem>
          <div className={styles.buttons}>
            <Button kind="primary" type="submit" className={styles.button}>{t('button.reset-password')}</Button>
          </div>
        </form>
      </div>
    )
  }
}

export default withTranslation()(createForm()(NewPassword));
