import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './Footer.module.scss';

import fbImage from "../../../images/fb-icon.png"
import instImage from "../../../images/inst-icon.png"
import vkImage from "../../../images/vk-icon.png"
import youtubeImage from "../../../images/youtube-icon.png"

class Footer extends Component {
  render() {
    const { t } = this.props;
    const lang = this.props.i18n.language

    return (
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div className={styles.left}>
            <div className={styles.menu}>
              <a href={"/" + lang + "/home/oferta"} className={styles.menuItem}>{t('menu.contract-offer')}</a>
              <a href={"/" + lang + "/home/agreement_talent"} className={styles.menuItem}>{t('menu.talent-agreement')}</a>
              <a href={"/" + lang + "/home/agreement_staff"} className={styles.menuItem}>{t('menu.staff-agreement')}</a>
              <a href={"/" + lang + "/home/agreement_company"} className={styles.menuItem}>{t('menu.company-agreement')}</a>

              <a href={"/" + lang + "/home/faq"} className={styles.menuItem}>Q&A</a>
            </div>
            <p className={styles.copyright}>2018 - 2021 © Etalents.pro</p>
          </div>
          <div className={styles.right}>
            <div className={styles.socialItems}>
              <a target="_blank" href="https://www.instagram.com/etalents.pro/" className={styles.socialItem}>
                <svg className={styles.socialItemImage} id="Instagram" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <title>instagram</title>
                  <path d="M18.94586,6.28931a6.60561,6.60561,0,0,0-.41833-2.18463,4.41189,4.41189,0,0,0-1.03809-1.59412,4.41189,4.41189,0,0,0-1.59412-1.03809,6.60561,6.60561,0,0,0-2.18463-.41833C12.75073,1.01038,12.44427,1,10,1s-2.75073.01038-3.71069,0.05414a6.60561,6.60561,0,0,0-2.18463.41833A4.41189,4.41189,0,0,0,2.51056,2.51056,4.41189,4.41189,0,0,0,1.47247,4.10468a6.60561,6.60561,0,0,0-.41833,2.18463C1.01038,7.24927,1,7.55573,1,10s0.01038,2.75073.05414,3.71069a6.60561,6.60561,0,0,0,.41833,2.18463,4.41189,4.41189,0,0,0,1.03809,1.59412,4.41109,4.41109,0,0,0,1.59412,1.03809,6.60561,6.60561,0,0,0,2.18463.41833C7.24927,18.98969,7.55573,19,10,19s2.75073-.01031,3.71069-0.05414a6.60561,6.60561,0,0,0,2.18463-.41833,4.60208,4.60208,0,0,0,2.6322-2.6322,6.60561,6.60561,0,0,0,.41833-2.18463C18.98962,12.75073,19,12.44427,19,10S18.98962,7.24927,18.94586,6.28931Zm-1.61993,7.34747a4.97824,4.97824,0,0,1-.30994,1.67114A2.98017,2.98017,0,0,1,15.30792,17.016a4.9786,4.9786,0,0,1-1.67114.30994C12.68787,17.3692,12.40326,17.37836,10,17.37836s-2.68787-.00916-3.63678-0.05243A4.9786,4.9786,0,0,1,4.69208,17.016a2.78769,2.78769,0,0,1-1.03485-.67322A2.78769,2.78769,0,0,1,2.984,15.30792a4.97824,4.97824,0,0,1-.30994-1.67114C2.6308,12.68774,2.62164,12.40314,2.62164,10s0.00916-2.68774.05243-3.63678A4.9786,4.9786,0,0,1,2.984,4.69208a2.78769,2.78769,0,0,1,.67322-1.03485A2.78769,2.78769,0,0,1,4.69208,2.984a4.9786,4.9786,0,0,1,1.67114-.30994C7.31226,2.6308,7.59686,2.62164,10,2.62164s2.68774,0.00916,3.63678.05243a4.9786,4.9786,0,0,1,1.67114.30994,2.78769,2.78769,0,0,1,1.03485.67322A2.78769,2.78769,0,0,1,17.016,4.69208a4.9786,4.9786,0,0,1,.30994,1.67114c0.04327,0.949.05243,1.2337,0.05243,3.63678S17.3692,12.68774,17.32593,13.63678ZM10,5.37836A4.62164,4.62164,0,1,0,14.62164,10,4.62169,4.62169,0,0,0,10,5.37836ZM10,13a3,3,0,1,1,3-3A3,3,0,0,1,10,13Zm5.88422-7.8042a1.08,1.08,0,1,1-1.08-1.08A1.08,1.08,0,0,1,15.88422,5.1958Z" transform="translate(-1 -1)" fill="#ff0202"/>
                </svg>
              </a>
              <a target="_blank" href="https://www.youtube.com/channel/UCrF4w6XpzImihl3ZSBLu0cw" className={styles.socialItem}>
                <svg className={styles.socialItemImage} id="Youtube" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10,2.3C0.172,2.3,0,3.174,0,10s0.172,7.7,10,7.7s10-0.874,10-7.7S19.828,2.3,10,2.3z M13.205,10.334l-4.49,2.096C8.322,12.612,8,12.408,8,11.974V8.026C8,7.593,8.322,7.388,8.715,7.57l4.49,2.096C13.598,9.85,13.598,10.15,13.205,10.334z" fill="#ff0202"/>
                </svg>
              </a>
              <a target="_blank" href="https://www.facebook.com/eurasiatalents/" className={styles.socialItem}>
                <svg className={styles.socialItemImage} id="Facebook" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M17,1H3C1.9,1,1,1.9,1,3v14c0,1.101,0.9,2,2,2h7v-7H8V9.525h2V7.475c0-2.164,1.212-3.684,3.766-3.684l1.803,0.002v2.605h-1.197C13.378,6.398,13,7.144,13,7.836v1.69h2.568L15,12h-2v7h4c1.1,0,2-0.899,2-2V3C19,1.9,18.1,1,17,1z" fill="#ff0202"/>
                </svg>
              </a>
              <a target="_blank" href="https://vk.com/eurasia_talents" className={styles.socialItem}>
                <svg className={styles.socialItemImage} id="VK" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.802,12.298c0,0,1.617,1.597,2.017,2.336c0.011,0.016,0.016,0.028,0.018,0.035c0.163,0.273,0.203,0.487,0.123,0.645c-0.135,0.261-0.592,0.392-0.747,0.403c0,0-2.778,0-2.858,0c-0.199,0-0.613-0.052-1.117-0.4c-0.385-0.269-0.768-0.712-1.139-1.145c-0.554-0.643-1.033-1.201-1.518-1.201c-0.062,0-0.122,0.01-0.18,0.03c-0.367,0.116-0.833,0.639-0.833,2.032c0,0.436-0.344,0.684-0.585,0.684c0,0-1.248,0-1.309,0c-0.446,0-2.768-0.156-4.827-2.327c-2.523-2.658-4.789-7.99-4.811-8.037C-0.105,5.008,0.191,4.82,0.511,4.82h2.886c0.387,0,0.513,0.234,0.601,0.444c0.102,0.241,0.48,1.205,1.1,2.288c1.004,1.762,1.621,2.479,2.114,2.479c0.093,0,0.181-0.023,0.264-0.07c0.644-0.354,0.524-2.654,0.494-3.128c0-0.092-0.001-1.027-0.331-1.479C7.403,5.03,7.001,4.904,6.758,4.858c0.065-0.094,0.203-0.238,0.38-0.323c0.441-0.22,1.238-0.252,2.029-0.252h0.439c0.858,0.012,1.08,0.067,1.392,0.146c0.628,0.15,0.64,0.557,0.585,1.943c-0.016,0.396-0.033,0.842-0.033,1.367c0,0.112-0.005,0.237-0.005,0.364c-0.019,0.711-0.044,1.512,0.458,1.841c0.064,0.04,0.139,0.062,0.217,0.062c0.174,0,0.695,0,2.108-2.425c0.62-1.071,1.1-2.334,1.133-2.429c0.028-0.053,0.112-0.202,0.214-0.262c0.078-0.048,0.182-0.056,0.236-0.056h3.395c0.37,0,0.621,0.056,0.67,0.196c0.082,0.227-0.016,0.92-1.566,3.016c-0.261,0.349-0.49,0.651-0.691,0.915C16.314,10.805,16.314,10.898,17.802,12.298z" fill="#ff0202"/>
                </svg>
              </a>
            </div>
            <p className={styles.rights}>{t('menu.all-rights-reserved')}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Footer)
