import React from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import Select from "../../../components/Select/Select";
import FormItem from "../../../components/FormItem/FormItem";
import FormTitle from "../../../components/FormTitle/FormTitle";
import InputDatepicker from "../../../components/InputDatepicker/InputDatepicker";
import InputTimepicker from "../../../components/InputTimepicker/InputTimepicker";
import Input from "../../../components/Input/Input";

import styles from "../EditForms.module.scss";

const General = ({ form, initialValues, t, dataStore, type, advType }) => {
  const { getFieldDecorator, getFieldError, getFieldValue } = form;

  return (
    <>
      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.project-type")}</FormTitle>
            {getFieldDecorator("projekt_type", {
              initialValue: initialValues.projekt_type,
              rules: [
                { required: true, message: t("validation.input.required") }
              ]
            })(
              <Select
                required
                placeholder={t("input.select-project-type")}
                options={dataStore.movieTypesOptions}
                errors={getFieldError("projekt_type")}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormTitle>{t("label.project-name")}</FormTitle>
          {getFieldDecorator("projekt_name", {
            initialValue: initialValues.projekt_name,
            rules: [{ required: true, message: t("validation.input.required") }]
          })(
            <Input
              required
              name="projekt_name"
              placeholder={t("input.enter-title")}
              errors={getFieldError("projekt_name")}
            />
          )}
        </div>
      </div>

      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.producer")}</FormTitle>
            {getFieldDecorator("director", {
              initialValue: initialValues.director
            })(
              <Input
                name="director"
                errors={getFieldError("director")}
                placeholder={t("input.producer-first-name")}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormTitle>{t("label.shooting-in")}</FormTitle>
          {getFieldDecorator("shooting_cities", {
            initialValue: initialValues.shooting_cities,
            rules: type === 'extras' ? [{ required: true, message: t("validation.input.required") }] : []
          })(
            <Input
              required={type === 'extras' ? true : false}
              name="shooting_cities"
              errors={getFieldError("shooting_cities")}
              placeholder={t("input.one-or-more-cities")}
            />
          )}
        </div>
      </div>

      {
        advType === 'invitation' && (
          <div className={styles.formGrid20_0}>
            <div className={styles.photoGrid2}>
              <FormTitle>{type === "extras" ? t("label.extra-active") : t("label.casting-date")}</FormTitle>
              <div style={{ display: 'flex' }}>
                <div className={styles.formPartLeft}>
                  {getFieldDecorator("casting_date", {
                    initialValue: initialValues.casting_date,
                    rules: [
                      { required: true, message: t("validation.input.required") }
                    ]
                  })(
                    <InputDatepicker
                      required
                      minDate={new Date()}
                      errors={getFieldError("casting_date")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.photoGrid2}>
              <FormItem styles={{ marginBottom: 10 }}>
                <FormTitle>{type === "extras" ? t("label.extra-active") : t("label.casting-time")}</FormTitle>
                <div style={{ display: 'flex' }}>
                  <div className={styles.formPartLeft}>
                    {getFieldDecorator("casting_time", {
                      initialValue: initialValues.casting_time,
                      rules: [
                        { required: true, message: t("validation.input.required") }
                      ]
                    })(
                      <InputTimepicker
                        placeholder="00:00"
                        errors={getFieldError("casting_time")}
                      />
                    )}
                  </div>
                </div>
              </FormItem>
            </div>
          </div>
        )
      }

      <div className={styles.formGrid20_0}>
        {
          advType === 'not_invitation' && (
            <div className={styles.photoGrid2}>
              <FormItem styles={{ marginBottom: 10 }} >
                <FormTitle>{type === "extras" ? t("label.extra-active") : t("label.casting-active")}</FormTitle>
                <div style={{ display: 'flex' }}>
                  <div className={styles.formPartLeft}>
                    <FormTitle styles={{ maxHeight: 39 }} lowercase>{t("input.from")}</FormTitle>
                    {getFieldDecorator("casting_start_date", {
                      initialValue: initialValues.casting_start_date,
                      rules: [
                        { required: true, message: t("validation.input.required") }
                      ]
                    })(
                      <InputDatepicker
                        required
                        minDate={new Date()}
                        errors={getFieldError("casting_start_date")}
                      />
                    )}
                  </div>
                  <div className={styles.formPartRight}>
                    <FormTitle styles={{ maxHeight: 39 }} lowercase>{t("input.to")}</FormTitle>
                    {getFieldDecorator("casting_end_date", {
                      initialValue: initialValues.casting_end_date,
                      rules: [
                        { required: true, message: t("validation.input.required") }
                      ]
                    })(
                      <InputDatepicker
                        required
                        minDate={getFieldValue("casting_start_date") || new Date()}
                        errors={getFieldError("casting_end_date")}
                      />
                    )}
                  </div>
                </div>
              </FormItem>
            </div>
          )
        }

        <div className={styles.photoGrid2}>
          <FormItem styles={{ marginBottom: 10 }}>
            <FormTitle>{t("label.shooting-dates")}</FormTitle>
            <div style={{ display: 'flex' }}>
              <div className={styles.formPartLeft}>
                <FormTitle styles={{ maxHeight: 39 }} lowercase>{t("input.from")}</FormTitle>
                {getFieldDecorator("start_date", {
                  initialValue: initialValues.start_date,
                  rules: [
                    { required: false, message: t("validation.input.required") }
                  ]
                })(
                  <InputDatepicker
                    required
                    minDate={new Date()}
                    errors={getFieldError("start_date")}
                  />
                )}
              </div>
              <div className={styles.formPartRight}>
                <FormTitle styles={{ maxHeight: 39 }} lowercase>{t("input.to")}</FormTitle>
                {getFieldDecorator("end_date", {
                  initialValue: initialValues.end_date,
                  rules: [
                    { required: false, message: t("validation.input.required")}
                  ]
                })(
                  <InputDatepicker
                    required
                    minDate={getFieldValue("start_date") || new Date()}
                    errors={getFieldError("end_date")}
                  />
                )}
              </div>
            </div>
          </FormItem>
        </div>
      </div>

      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{type === 'extras' ? t("label.gathering-place") : t("label.address")}</FormTitle>
            {getFieldDecorator("place", { initialValue: initialValues.place })(
              <Input
                name="place"
                errors={getFieldError("place")}
                placeholder={t("input.enter-address")}
              />
            )}
          </FormItem>
        </div>
      </div>
    </>
  );
};

export default compose(
  withTranslation(),
  inject("dataStore", "navigate"),
  observer
)(General);
