import { observable, flow, computed, action, configure } from "mobx";
import Status from "./store-status";
import api from "../api";
import navigate from "../router/navigate";
import get from "lodash/get";
import isNull from "lodash/isNull";
import elasticsearch from 'elasticsearch';
import i18n from '../i18n';
import { NotificationManager } from 'react-notifications';

const isDev = process.env.NODE_ENV === 'development';

const devHost = [{
  host: 'localhost',
  port: 9200
}];

const prodHost = [{
  host: 'etalents.pro',
  auth: 'elastic:GbxU6lo0PA274ZoeDS7C',
  protocol: 'https',
  port: 9200
}]

const client = new elasticsearch.Client({
  host: isDev ? devHost : prodHost,
  // log: 'trace',
  apiVersion: '7.5'
});

configure({ enforceActions: "observed" });

export default class User {
  @observable status = Status.PENDING;
  @observable advStatus = Status.INITIAL;
  @observable profileStatus = Status.INITIAL;
  @observable isAdvModalOpen = false;
  @observable isBoardRemoveModalOpen = false;
  @observable isInvModalOpen = false;
  @observable activeInvitation = {};
  @observable updatingStatus = Status.DONE;
  @observable isAuthenticated = false;
  @observable user = null;
  @observable talent = null;
  @observable language = localStorage.getItem("lang") || "ru"; // localStorage.getItem('lang') || ru
  @observable tutorialsList = null;
  @observable tutorialsCount = 0;
  @observable tutorialsCategories = [];
  @observable searchCountToShow = 24;
  @observable searchCountToShowDefault = 24;
  @observable searchResult = [];
  @observable searchAggregations = {};
  @observable resultsCount = 0;
  @observable tutorial = null;

  constructor(root) {
    this.root = root;
  }

  @computed get isLoading() {
    return this.status === Status.PENDING;
  }

  @computed get isLightLoading() {
    return this.profileStatus === Status.PENDING;
  }

  @computed get isAdvertizingLoading() {
    return this.advStatus === Status.PENDING;
  }

  @computed get isUpdating() {
    return this.updatingStatus === Status.PENDING;
  }

  @computed get isLoaded() {
    return this.status === Status.DONE;
  }

  @computed get isAdvertisingLoaded() {
    return this.advStatus === Status.DONE;
  }

  @computed get isLoadedFailure() {
    return this.status === Status.ERROR;
  }

  @computed get isAdvertisingFailure() {
    return this.advStatus === Status.ERROR;
  }

  @computed get fullName() {
    return this.firstName + " " + this.lastName;
  }

  @computed get firstName() {
    return get(this, `user.role_object.info.first_name_${this.language}`);
  }

  @computed get lastName() {
    return get(this, `user.role_object.info.last_name_${this.language}`);
  }

  @computed get gender() {
    return get(this, "user.role_object.info.gender") === "male"
      ? this.language === "ru"
        ? "Мужской"
        : "Male"
      : this.language === "ru"
      ? "Женский"
      : "Female";
  }

  @computed get staffStatus() {
    return get(this, "user.role_object.status") === "professional"
      ? this.language === "ru"
        ? "Профессионал"
        : "Professional"
      : this.language === "ru"
        ? "Любитель"
        : "Amateur";
  }

  @computed get avatar() {
    return get(this, "user.role_object.avatar.profile.url");
  }

  @computed get age() {
    const birthday = get(this, "user.role_object.info.birth_date");
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  @computed get roleString() {
    const { role } = this.user;

    switch (role) {
      case "talent": return 'talents';
      case "staff": return 'staffs';
      case "company": return 'companies';
      default: return '';
    }
  }

  @computed get isUnderModeration() {
    const moderationStatus = get(this, "user.role_object.moderation_status", "");
    return moderationStatus === 'on_moderation' || moderationStatus === 'refused';
  }

  @action changeLanguage(i18n) {
    const value = this.language === "ru" ? "en" : "ru";

    this.language = value;
    localStorage.setItem("lang", value);
    i18n.changeLanguage(value);
  }

  @action setActiveInvitation(invitation, participant_status) {
    if (participant_status) {
      invitation.participant_status = participant_status
    }
    this.activeInvitation = invitation;
  }

  @computed get isPublic() {
    return get(this, "user.role_object.profile_status") === "public";
  }

  @computed get isNotPublic() {
    return (
      get(this, "user.role_object.profile_status") === "not_public" ||
      isNull(get(this, "user.role_object.profile_status"))
    );
  }

  @computed get isRoleNull() {
    return isNull(get(this, "user.role_object"));
  }

  @computed get currentStep() {
    let step = 1;

    if (get(this, "user.role") === "talent") {
      if (get(this, "user.role_object.info.id")) step = 2;

      if (
        get(this, "user.role_object.educations[0]") ||
        !isNull(get(this, "user.role_object.has_education")) ||
        get(this, "user.role_object.career.id")
      )
        step = 3;

      if (get(this, "user.role_object.phones[0]")) step = 4;
    }

    if (get(this, "user.role") === "staff") {
      if (get(this, "user.role_object.info.id")) step = 2;

      if (get(this, "user.role_object.career.id")) step = 3;

      if (
        get(this, "user.role_object.educations[0]") ||
        !isNull(get(this, "user.role_object.has_education"))
      )
        step = 4;
    }

    if (get(this, "user.role") === "company") {
      if (get(this, "user.role_object.name")) step = 2;
    }

    return step;
  }

  fetchProfile = flow(function*(isFromAuthPage) {
    // this.status = Status.PENDING;

    try {
      const response = yield api.fetchProfile();

      if (response.status === 200) {
        this.isAuthenticated = true;
        this.user = response.data.user;

        if (isFromAuthPage) {
          this.checkSecondaryRegistration();
        }
      }

      this.status = Status.DONE;

      return response;
    } catch (error) {
      this.status = Status.ERROR;
    }
  });

  signIn = flow(function*(data, form) {
    this.status = Status.PENDING;

    try {
      const response = yield api.signIn(data);

      if (response.status === 200) {
        this.isAuthenticated = true;
        this.user = response.data.user;

        localStorage.setItem("eta_token", response.data.token);

        this.checkSecondaryRegistration();
      }

      this.status = Status.DONE;
    } catch (error) {

      const isSignatureError = error.response.data.error === "Signature has expired"

      if (isSignatureError) {
        localStorage.removeItem("eta_token");
        this.signIn(data, form)
      }

      if (get(error, "response.status") === 401) {
        form.setFields({
          email: {
            value: form.getFieldValue("email"),
            errors: isSignatureError ? [] : [new Error(error.response.data.error)]
          }
        });
      }

      this.status = Status.ERROR;
    }
  });

  signUp = flow(function*(data, form) {
    this.status = Status.PENDING;

    try {
      const response = yield api.signUp(data);

      if (response.status === 200) {
        if (!response.data.user.confirmed) {
          this.status = Status.DONE;
          // NotificationManager.success(i18n.t("text.sign-up-success-check-email"), '', 10000);
          return form.setFields({
            email: {
              value: form.getFieldValue("email"),
              // errors: [new Error(i18n.t("text.sign-up-success-check-email"))]
              errors: []
            }
          });
        }

        this.isAuthenticated = true;
        this.user = response.data.user;

        localStorage.setItem("eta_token", response.data.token);

        this.checkSecondaryRegistration();
      }

      this.status = Status.DONE;
    } catch (error) {
      if (get(error, "response.status") === 401) {
        form.setFields({
          email: {
            value: form.getFieldValue("email"),
            errors: [new Error(error.response.data.error)]
          }
        });
      }

      if (get(error, "response.status") === 500) {
        form.setFields({
          email: {
            value: form.getFieldValue("email"),
            errors: [new Error(get(error, "response.data.errors.email"))]
          }
        });
      }

      this.status = Status.ERROR;
    }
  });

  signUpWithSocial = flow(function*(data, form) {
    this.status = Status.PENDING;

    try {
      const response = yield api.signIn(data);

      // console.log("response", response);

      if (response.status === 200) {
        this.isAuthenticated = true;
        this.user = response.data.user;

        localStorage.setItem("eta_token", response.data.user.token);

        if (this.isRoleNull) {
          navigate({ path: "/app/signup-social" });
        } else {
          this.navigateToCabinet();
        }
      }

      this.status = Status.DONE;
    } catch (error) {
      if (get(error, "response.status") === 401) {
        form.setFields({
          social: {
            errors: [new Error(i18n.t("text.sign-up-success-check-email"))]
          }
        });
      }

      if (get(error, "response.status") === 500) {
        navigate({ path: "/app/signin" });
      }

      this.status = Status.ERROR;
    }
  });

  restorePassword = flow(function*(data, form) {
    this.status = Status.PENDING;

    try {
      const response = yield api.restorePassword(data);

      if (response.status === 200) {
        form.setFields({
          email: {
            errors: [new Error(i18n.t("text.forgot-password-check-email")), 'notRealError']
          }
        });
      }

      this.status = Status.DONE;
    } catch (error) {
      console.log("error", error.response);
      if (get(error, "response.status") === 401) {
        form.setFields({
          email: {
            errors: [new Error(i18n.t("text.forgot-password-check-email-error"))]
          }
        });
      }

      this.status = Status.ERROR;
    }
  });

  updatePassword = flow(function*(data) {
    this.status = Status.PENDING;

    try {
      const response = yield api.updatePassword(data);

      if (response.status === 200) {
        navigate({ path: "/app/signin" });
      }

      NotificationManager.success(i18n.t("notification.password-change-success-text"), i18n.t("notification.user-update-title"));
      this.status = Status.DONE;

    } catch (error) {
      NotificationManager.error(i18n.t("notification.password-change-not-success-text"), i18n.t("notification.user-update-title"));
      console.log("error", error.response);
      if (get(error, "response.status") === 500) {
      }

      this.status = Status.ERROR;
    }
  });

  changePassword = flow(function*(data, form) {
    this.updatingStatus = Status.PENDING;

    try {
      const response = yield api.changePassword(data);

      this.updatingStatus = Status.DONE;
      NotificationManager.success(i18n.t("notification.password-change-success-text"), i18n.t("notification.user-update-title"));

      return response;
    } catch (error) {
      console.log("error", error.response);

      if (get(error, "response.status") === 500) {
        const errors = error.response.data;

        form.setFields({
          current_password: {
            value: form.getFieldValue("current_password"),
            errors: [new Error(errors.join(", "))]
          }
        });
      }

      this.updatingStatus = Status.ERROR;
    }
  });

  changeLanguageBD = flow(function*(userId) {
    const value = this.language === "ru" ? "en" : "ru";

    this.updatingStatus = Status.PENDING;

    try {
      if (userId) {
        const response = yield api.changeLanguage({ id: userId, locale: value });
      } else {
        const response = yield api.changeLocale({ locale: value });
      }

      this.updatingStatus = Status.DONE;

    } catch (error) {
      console.log("error", error.response);

      if (get(error, "response.status") === 500) {
        const errors = error.response.data;
      }
      this.updatingStatus = Status.ERROR;
    }
  });

  freezeAccount = flow(function*(freeze) {
    this.updatingStatus = Status.PENDING;
    const { user_id, id } = this.user.role_object;

    try {
      let response;

      if (freeze) {
        response = yield api.freezeAccount(user_id, id);
      } else {
        response = yield api.unfreezeAccount(user_id, id);
      }

      if (response.status === 200) {
        this.fetchProfile();
      }

      this.updatingStatus = Status.DONE;

      const notificationMessage = freeze ? i18n.t("notification.freeze-success") : i18n.t("notification.unfreeze-success")
      NotificationManager.success(notificationMessage, i18n.t("notification.user-update-title"));

      return response;
    } catch (error) {
      console.log("error", error.response);
      this.updatingStatus = Status.ERROR;
    }
  });

  navigateToCabinet = () => navigate({ path: `/app/${this.user.role}` }, true);

  checkSecondaryRegistration = () => {
    const { role } = this.user;

    if (!this.user.condition) {
      return navigate({ path: `/app/${role}-agreement` });
    }

    if (this.isPublic) {
      this.fetchProfile();

      return this.navigateToCabinet();
    }

    return navigate({ path: `/app/${role}-steps` });
  };

  logout = flow(function*() {
    this.status = Status.PENDING;

    try {
      const response = yield api.logout();

      // console.log("response", response);

      if (response.status === 200 || response.status === 204) {
        localStorage.removeItem("eta_token");

        this.isAuthenticated = false;

        navigate({ path: "/app/signin" });

        this.user = null;
      }

      this.status = Status.DONE;
    } catch (error) {
      this.status = Status.ERROR;
    }
  });

  update = flow(function*(data, fromCabinet) {
    this.profileStatus = Status.PENDING;

    const isVideoAttributes = (data.talent && data.talent.video_attributes) ||
                        (data.staff && data.staff.video_links_attributes) ||
                        (data.company && data.company.video_links_attributes)

    try {
      const { user_id, id } = this.user.role_object;
      const role = this.user.role;

      const response = yield api.updateRoleObject(user_id, id, this.roleString, data);

      if (response.status === 200) {
        if (!fromCabinet) {
          if ((this.currentStep === 2 && role === "company") || this.currentStep === 4) {
            this.fetchProfile();

            this.navigateToCabinet();
          }
        } else {
          NotificationManager.success(i18n.t("notification.update-success-text"), i18n.t("notification.user-update-title"));
        }

        this.user.role_object = response.data[role];
      }

      this.profileStatus = Status.DONE;
    } catch (error) {
      console.log("Error while updating user: ", error);
      if (isVideoAttributes) {
        NotificationManager.error(i18n.t("notification.update-video-text"), i18n.t("notification.user-update-title"));
      } else {
        NotificationManager.error(i18n.t("notification.update-failure-text"), i18n.t("notification.user-update-title"));
      }

      this.profileStatus = Status.ERROR;
    }
  });

  updateUser = flow(function*(data) {
    this.status = Status.PENDING;

    try {
      const { id } = this.user;
      const role = this.user.role;

      data.user.token = localStorage.getItem("eta_token");
      data.user.id = this.user.id;

      const response = yield api.updateUser(id, data);

      // console.log("res", response);
      if (response.status === 200) {
        // console.log(response.data);

        this.user = response.data.user;
        this.checkSecondaryRegistration();
      }

      this.status = Status.DONE;
    } catch (error) {
      this.status = Status.ERROR;
    }
  });

  acceptAgreement = flow(function*(data) {
    this.status = Status.PENDING;

    try {
      const { id } = this.user;
      const role = this.user.role;

      const response = yield api.updateUser(id, {
        user: { id: this.user.id, condition: true }
      });

      // console.log("res", response);
      if (response.status === 200) {
        this.user = response.data.user;
        this.checkSecondaryRegistration();
      }

      this.status = Status.DONE;
    } catch (error) {
      this.status = Status.ERROR;
    }
  });

  fetchTutorialsList = flow(function*(data) {
    this.updatingStatus = Status.PENDING;

    try {
      const response = yield api.fetchTutorialsList(data);

      if (response.status === 200) {
        this.tutorialsList = response.data;

        this.tutorialsCount =
          Number(response.data.texts_count)
          // Number(response.data.videos_count);
      }

      this.updatingStatus = Status.DONE;

      return response;
    } catch (error) {
      this.updatingStatus = Status.ERROR;
      console.log(error);
    }
  });

  fetchTutorial = flow(function*({ type, id }) {
    this.updatingStatus = Status.PENDING;

    try {
      let response;

      if (type === "video") {
        response = yield api.fetchVideoTutorial(id);
      } else {
        response = yield api.fetchTextTutorial(id);
      }

      if (response.status === 200) {
        this.tutorial = response.data[`tutorial_${type}`];
      }

      this.updatingStatus = Status.DONE;
    } catch (error) {
      console.log(error);
      this.updatingStatus = Status.ERROR;
    }
  });

  fetchTutorialsCategories = flow(function*() {
    try {
      const response = yield api.fetchTutorialsCategories();

      if (response.status === 200)
        this.tutorialsCategories = response.data.tutorial_categories;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  paySubscription = flow(function*(data) {
    this.updatingStatus = Status.PENDING;

    try {
      const response = yield api.paySubscription(data);

      this.updatingStatus = Status.DONE;
      if (response.status === 200) {
        window.location.assign(response.data.path);
        // win.focus();
      }

      return response;
    } catch (error) {
      console.log(error);
      this.updatingStatus = Status.ERROR;
    }
  });

  payAutoSubscription = flow(function*(data) {
    this.updatingStatus = Status.PENDING;

    try {
      const response = yield api.payAutoSubscription(data);

      this.updatingStatus = Status.DONE;
      if (response.status === 200) {

        if (response.data.change_subscription) {
          this.fetchProfile();
          NotificationManager.success(i18n.t("text.change-plan-to-pro-success"), '', 10000)
        } else {
          window.location.assign(response.data.path);
        }
        // win.focus();
      }

      return response;
    } catch (error) {
      console.log(error);
      this.updatingStatus = Status.ERROR;
    }
  });

  unSubscribe = flow(function*(data) {
    this.updatingStatus = Status.PENDING;

    try {
      const response = yield api.unSubscribe(data);

      this.updatingStatus = Status.DONE;
      if (response.status === 200) {
        this.fetchProfile();
        NotificationManager.success(i18n.t("text.unsubscribed-success"), '', 10000)
        // win.focus();
      }
    } catch (error) {
      console.log(error);
      this.updatingStatus = Status.ERROR;
      window.location.assign(response.data.path);
    }
  });

  @observable featuresFields = [
    'lameness', 'scar', 'piercing', 'have_twin', 'large_tattoo',
    'veneer', 'gold_crowns', 'small_cosmetic_facial_surgery', 'birthmark',
    'vitiligo', 'albinism', 'pockmark'
  ];

  @observable skillsFields = [
    'i_model', 'dubbing', 'voice_acting',
    'opera', 'pop', 'folk', 'jazz',
    'soprano', 'baritone', 'tenor', 'contralto', 'mezzo_soprano', 'bass',
    'dance_ballroom', 'dance_folk', 'dance_modern',
    'dance_hip_hop',  'dance_ballet', 'dance_step',
    'stunt', 'horse', 'car', 'acrobatic', 'fight', 'fall_from_height',
    'working_on_cables', 'burning_man', 'parkour', 'stage_combat', 'fencing',
    'clown', 'juggler', 'acrobat', 'magician', 'equilibrist', 'trainer',
    'vehicle_car', 'vehicle_motorcycle', 'vehicle_bus',
    'vehicle_extreme_driving', 'vehicle_riding'
  ];

  @observable appearanceFields = [
    'physique' ,'eye_color' ,'hair_color',
    'clothing_size_up', 'clothing_size_bottom', 'shoe_size'
  ];

  @observable multiSkillsFields = ['instrument', 'sport', 'lang'];

  @observable rangeFields = [
  ];

  @observable professionFields = [
    'director', 'tv_director', 'documentary_director', 'stunt_coordinator',
    'director_of_photography', 'director_of_photography_for_television',
    'documentary_director_of_photography', 'production_designer', 'general_producer',
    'creative_producer', 'executive_producer', 'line_producer', 'producer_assistant',
    'production_manager', 'set_manager', 'location_manager', 'location_scout',
    'first_assistant_director', 'second_assistant_director',
    'clapper_assistant_director', 'casting_director', 'assistant_casting_director',
    'assistant_casting_director_extras', 'first_camera_assistant', 'focus_puller',
    'playback', 'clapper_camera_assistant', 'camera_technician', 'motion_control_grip',
    'jib_grip', 'gaffer', 'grip', 'best_boy', 'dolly_grip', 'screenwriter', 'text_editor',
    'script_supervisor', 'supervising_sound_editor', 're_recording_mixer',
    'production_sound_mixer', 'boom_operator', 'sound_editor', 'composer',
    'dubbing_director', 'set_decorator', 'set_dresser', 'propmaker', 'costume_designer',
    'costume_supervisor', 'costume_standby', 'key_makeup_artist',
    'special_make_up_effects_artist', 'wig_maker', 'hair_stylist', 'make_up_artist',
    'art_director', 'pyrotechnician', 'armourer', 'stunt_director', 'stuntsman',
    'animal_wrangler', 'visual_effects_supervisor', 'editor', 'film_editor', 'colorist',
    'animator', 'animator_3d', 'pr_manager', 'movie_distributor', 'product_placement_manager',
    'catering', 'barmaid', 'driver', 'worker', 'still_photographer', 'designer'
  ];

  trueFalseFilters = (data, params) => {
    return params.reduce((filters, param) => {
      if (data[param] === true || data[param] === 'true') {
        filters.push({ "term": { [param]: true }});
      }
      return filters;
    }, []);
  }

  rangeFilters = (data, params) => {
    return params.reduce((filters, param) => {
      const [start, end] = get(data, param, []);
      if (start) filters.push({ "range": { [param]: { "gte": start } }});
      if (end) filters.push({ "range": { [param]: { "lte": end } }});
      return filters;
    }, []);
  }

  multivalueFilters = (data, params) => {
    return params.reduce((filters, param) => {
      if (data[param] && data[param].length > 0) {
        filters.push({ "terms": { [param]: data[param] }});
      }
      return filters;
    }, []);
  }

  crossBooleanFilters = (data, params) => {
    return params.reduce((filters, [field, value1, value2]) => {
      if ((data[value1] === true || data[value1] === 'true') && (!data[value2] || data[value2] === 'false')) {
        filters.push({ "term": { [field]: value1 }});
      }
      if ((data[value2] === true || data[value2] === 'true') && (!data[value1] || data[value1] === 'false')) {
        filters.push({ "term": { [field]: value2 }});
      }
      return filters;
    }, []);
  }

  sortArrayTalent = (data) => {
    if (data.search_order === 'name') {
      return [{ pay_subscription_status: { order: 'desc' }, actor_status: { order: 'desc' }, first_name: { order: 'asc' } }];
    } else {
      return [{ pay_subscription_status: { order: 'desc' }, actor_status: { order: 'desc' }, created_at: { order: 'desc' } }];
    }
  }

  sortArray = (data) => {
    if (data.search_order === 'date') {
      return [{ created_at: { "order": "desc" } }];
    }

    if (data.search_order === 'name') {
      return [{ first_name: { "order": "asc" } }];
    }
  }

  @action resetSearch() {
    this.searchResult = [];
    this.resultsCount = 0;
    this.searchCountToShow = this.searchCountToShowDefault;
    this.searchAggregations = {};
  }

  searchTalents = flow(function*(data, nextPage) {
    this.updatingStatus = Status.PENDING;

    if (nextPage) {
      this.searchCountToShow += this.searchCountToShowDefault;
    }

    const query = { bool: {} };
    const size = this.searchCountToShow;
    const filter = [
      { "term": { moderation_status: "approved" }},
      { "term": { frozen_status: false }},
    ];
    const sort = this.sortArrayTalent(data);

    if (data.q) {
      query.bool.should = {
        multi_match: {
          query: data.q,
          type: "best_fields",
          fields: ['first_name.analyzed', 'last_name.analyzed'],
          fuzziness: 2
        }
      };
    }

    const trueFalseParams = [
      'crowd_scene', 'low_budget', 'long_trip', 'model_work',
      ...this.featuresFields, ...this.skillsFields,
    ];

    filter.push(...this.trueFalseFilters(data, trueFalseParams));

    const multivalueParams = [
      ...this.appearanceFields,
      ...this.multiSkillsFields,
      'cities', 'country_code', 'appearance'
    ];

    filter.push(...this.multivalueFilters(data, multivalueParams));

    const crossBooleanParams = [
      ['gender', 'male', 'female'],
      ['actor_status', 'amateur', 'professional'],
    ];

    filter.push(...this.crossBooleanFilters(data, crossBooleanParams));

    filter.push(...this.rangeFilters(data, this.rangeFields));

    if (data.rate_from) filter.push({ "range": { "rate_start": { "gte": data.rate_from } }});
    if (data.rate_to) filter.push({ "range": { "rate_start": { "lte": data.rate_to } }});

    if (data.age_from) filter.push({ "range": { "age": { "gte": data.age_from } }});
    if (data.age_to) filter.push({ "range": { "age": { "lte": data.age_to } }});

    if (data.height_from) filter.push({ "range": { "height": { "gte": data.height_from } }});
    if (data.height_to) filter.push({ "range": { "height": { "lte": data.height_to } }});

    if (data.weight_from) filter.push({ "range": { "weight": { "gte": data.weight_from } }});
    if (data.weight_to) filter.push({ "range": { "weight": { "lte": data.weight_to } }});

    if (data.chest_girth_from) filter.push({ "range": { "chest_girth": { "gte": data.chest_girth_from } }});
    if (data.chest_girth_to) filter.push({ "range": { "chest_girth": { "lte": data.chest_girth_to } }});

    if (data.waist_girth_from) filter.push({ "range": { "waist_girth": { "gte": data.waist_girth_from } }});
    if (data.waist_girth_to) filter.push({ "range": { "waist_girth": { "lte": data.waist_girth_to } }});

    if (data.hip_girth_from) filter.push({ "range": { "hip_girth": { "gte": data.hip_girth_from } }});
    if (data.hip_girth_to) filter.push({ "range": { "hip_girth": { "lte": data.hip_girth_to } }});

    if (data.head_circumference_from) filter.push({ "range": { "head_circumference": { "gte": data.head_circumference_from } }});
    if (data.head_circumference_to) filter.push({ "range": { "head_circumference": { "lte": data.head_circumference_to } }});

    const aggs = [
      'actor_status', 'gender',
      'crowd_scene', 'low_budget', 'long_trip', 'model_work',
      ...this.featuresFields,
      ...this.appearanceFields,
      ...this.skillsFields,
      ...this.multiSkillsFields,
    ].reduce((acc, val) => { acc[val] = { "terms": { "field": val } }; return acc; }, {});

    ['city', 'cities', 'city_ru', 'city_en', 'country_code', 'appearance'].forEach(field => {
      aggs[field] = { "global": {}, "aggs": { [field]: { "terms": { "field": field, "size": 100 } } } };
    })

    try {
      query.bool.must = { bool: { filter } };
      const min_score = data.q ? 0.1 : 0;

      const response = yield client.search({
        index: `talents_${process.env.NODE_ENV}`,
        body: { min_score, query, size, sort, aggs }
      });

      if (response) {
        const result = get(response, 'hits.hits').map(r => r._source);

        this.searchResult = result;

        this.searchAggregations = Object.entries(get(response, 'aggregations'))
          .reduce((acc, [key, object]) => {
            acc[key] = object.buckets;
            if (object[key] && object[key].buckets) {
              acc[key] = object[key].buckets;
            }
            return acc;
          }, {});

        this.resultsCount = get(response, 'hits.total.value') || get(response, 'hits.total');

        this.updatingStatus = Status.DONE;
      }
    } catch (error) {
      console.log(error);

      this.updatingStatus = Status.ERROR;
    }
  });

  searchStaffs = flow(function*(data, nextPage, callback) {
    this.updatingStatus = Status.PENDING;

    if (nextPage) {
      this.searchCountToShow += this.searchCountToShowDefault;
    }

    const query = { bool: {} };
    const size = this.searchCountToShow;
    const filter = [
      { "term": { moderation_status: "approved" }},
      { "term": { frozen_status: false }},
      { "term": { status: 'professional' }},
    ];
    const sort = this.sortArray(data);

    if (data.q) {
      query.bool.should = {
        multi_match: {
          query: data.q,
          type: "best_fields",
          fields: ['first_name.analyzed', 'last_name.analyzed'],
          fuzziness: 2
        }
      };
    }

    const trueFalseParams = ['low_budget', 'long_trip'];

    filter.push(...this.trueFalseFilters(data, trueFalseParams));

    filter.push(...this.multivalueFilters(data, ['cities', 'country_code', 'lang']));

    const crossBooleanParams = [
      ['gender', 'male', 'female'],
      ['work_status', 'company', 'independent']
    ];

    filter.push(...this.crossBooleanFilters(data, crossBooleanParams));

    filter.push(...this.rangeFilters(data, ['age']));

    if (data.rate_from) filter.push({ "range": { "rate_start": { "gte": data.rate_from } }});
    if (data.rate_to) filter.push({ "range": { "rate_start": { "lte": data.rate_to } }});

    if (data.age_from) filter.push({ "range": { "age": { "gte": data.age_from } }});
    if (data.age_to) filter.push({ "range": { "age": { "lte": data.age_to } }});

    if (data.profession && data.profession.length > 0) {
      data.profession.forEach(p => filter.push({ "term": { [p]: true }}));
    }

    const aggs = [
      'gender', 'work_status', 'lang',
      'vehicle_car', 'vehicle_motorcycle', 'vehicle_bus',
      'vehicle_extreme_driving', 'vehicle_riding',
      'low_budget', 'long_trip',
      ...this.professionFields
    ].reduce((acc, val) => { acc[val] = { "terms": { "field": val } }; return acc; }, {});

    ['city', 'cities', 'city_ru', 'city_en', 'country_code'].forEach(field => {
      aggs[field] = { "global": {}, "aggs": { [field]: { "terms": { "field": field, "size": 50 } } } };
    });

    try {
      query.bool.must = { bool: { filter } };
      const min_score = data.q ? 0.1 : 0;

      const response = yield client.search({
        index: `staffs_${process.env.NODE_ENV}`,
        body: { min_score, query, size, sort, aggs }
      });

      if (response) {
        const result = get(response, 'hits.hits').map(r => r._source);

        this.searchResult = result;

        this.searchAggregations = Object.entries(get(response, 'aggregations'))
          .reduce((acc, [key, object]) => {
            acc[key] = object.buckets;
            if (object[key] && object[key].buckets) {
              acc[key] = object[key].buckets;
            }
            return acc;
          }, {});

        this.resultsCount = get(response, 'hits.total.value') || get(response, 'hits.total');

        this.updatingStatus = Status.DONE;
      }
    } catch (error) {
      console.log(error);

      this.updatingStatus = Status.ERROR;
    }
  });


  searchCompanies = flow(function*(data, nextPage, callback) {
    this.updatingStatus = Status.PENDING;

    if (nextPage) {
      this.searchCountToShow += this.searchCountToShowDefault;
    }

    const query = { bool: {} };
    const size = this.searchCountToShow;
    const filter = [
      { "term": { moderation_status: "approved" }},
    ];
    const sort = this.sortArray(data);

    if (data.q) {
      query.bool.should = {
        multi_match: {
          query: data.q,
          type: "best_fields",
          fields: ['name.analyzed'],
          fuzziness: 2
        }
      };
    }

    const formOfCompany = ['too', 'ao', 'ip'];
    const companyFormFilter = { "terms": { form_of_company: [] }};

    formOfCompany.forEach(f => {
      if (data[f] === true || data[f] === 'true') {
        companyFormFilter.terms.form_of_company.push(f);
      }
    });

    if (companyFormFilter.terms.form_of_company.length > 0) {
      filter.push(companyFormFilter);
    }

    const companyServiceFilter = { "terms": { professions: [] }};
    if (data.service && data.service.length > 0) {
      data.service.forEach(s => {
        companyServiceFilter.terms.professions.push(s);
      });
    }

    if (companyServiceFilter.terms.professions.length > 0) {
      filter.push(companyServiceFilter);
    }

    const aggs = ['form_of_company', 'professions']
      .reduce((acc, val) => { acc[val] = { "terms": { "field": val } }; return acc; }, {})

    try {
      query.bool.must = { bool: { filter } };
      const min_score = data.q ? 0.1 : 0;

      const response = yield client.search({
        index: `companies_${process.env.NODE_ENV}`,
        body: { min_score, query, size, sort, aggs }
      });

      if (response) {
        const result = get(response, 'hits.hits').map(r => r._source);

        this.searchResult = result;

        this.searchAggregations = Object.entries(get(response, 'aggregations'))
          .reduce((acc, [key, object]) => { acc[key] = object.buckets; return acc; }, {})

        this.resultsCount = get(response, 'hits.total.value') || get(response, 'hits.total');

        this.updatingStatus = Status.DONE;
      }
    } catch (error) {
      console.log(error);

      this.updatingStatus = Status.ERROR;
    }
  });


  deleteTalentPhoto = flow(function*(data) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id, id } = this.user.role_object;
      const response = yield api.deleteTalentPhoto(user_id, id, data);

      if (response.status === 200) {
        this.fetchProfile();

        this.updatingStatus = Status.DONE;
      }
    } catch (error) {
      console.log(error);

      this.updatingStatus = Status.ERROR;
    }
  });

  downloadCV = flow(function*() {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id, id } = this.user.role_object;
      const response = yield api.downloadCV(user_id, id, this.roleString, this.language);
      let filename = this.fullName;

      if (this.user.role === 'company') {
        filename = this.user.role_object.name;
      }

      if (response.status === 200) {
        this.updatingStatus = Status.DONE;

        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      return response;
    } catch (error) {
      this.updatingStatus = Status.ERROR;
      console.log(error);
    }
  });


  downloadFile = flow(function*(filename, path) {
    this.updatingStatus = Status.PENDING;

    try {
      const link = document.createElement("a");
      link.href = path;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.updatingStatus = Status.DONE;
    } catch (error) {
      this.updatingStatus = Status.ERROR;
      console.log(error);
    }
  });

  downloadManyCV = flow(function*(data) {
    this.updatingStatus = Status.PENDING;
    console.log(123, data)
    try {
      const { user_id, id } = this.user.role_object;
      const response = yield api.downloadManyCV(user_id, this.roleString, id, data);

      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = 'board';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.updatingStatus = Status.DONE;
    } catch (error) {
      this.updatingStatus = Status.ERROR;
      console.log(error);
    }
  });

  advertisingRequest = flow(function*(data) {
    this.advStatus = Status.PENDING;

    try {
      const { user_id } = this.user.role_object;
      const response = yield api.advertisingRequest(user_id);

      if (response.status === 200) {
        this.advStatus = Status.DONE;
      }
    } catch (error) {
      console.log(error);

      this.advStatus = Status.ERROR;
    }
  });

  toggleAdvModal = flow(function*() {
    try {
      this.isAdvModalOpen = !this.isAdvModalOpen;
    } catch (error) {
      console.log(error);
    }
  });

  toggleBoardRemoveModal = flow(function*() {
    try {
      this.isBoardRemoveModalOpen = !this.isBoardRemoveModalOpen;
    } catch (error) {
      console.log(error);
    }
  });

  toggleInvModal = flow(function*() {
    try {
      this.isInvModalOpen = !this.isInvModalOpen;
    } catch (error) {
      console.log(error);
    }
  });

  deleteFile = flow(function*(data) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id } = this.user.role_object;
      const response = yield api.deleteFile(user_id, data);

      if (response.status === 200) {
        this.fetchProfile();

        this.updatingStatus = Status.DONE;
      }
    } catch (error) {
      console.log(error);

      this.updatingStatus = Status.ERROR;
    }
  });
}
