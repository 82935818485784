import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import get from "lodash/get";
import VacancyForm from "../Forms/Vacancy/Vacancy";

import formStyles from '../Forms/EditForms.module.scss';

@inject("user", "dataStore", "navigate")
@observer
class CreateVacancy extends Component {
  componentDidMount() {
    const boardId = get(this.props.ctx, "params.boardId");
    const id = get(this.props.ctx, "params.id");

    if (id) {
      this.props.dataStore.fetchProject(boardId);
    }
  }

  render() {
    const { ctx, dataStore, type } = this.props;

    const boardId = get(ctx, "params.boardId");
    const id = get(ctx, "params.id");
    const vacancy = get(dataStore.project, 'vacancies', []).find(a => a.id == id);
    const isEdit = type === 'edit';

    return (
      <div className={formStyles.smallInner}>
        <VacancyForm isEdit={isEdit} data={isEdit ? vacancy : undefined} projectId={boardId} />
      </div>
    );
  }
}

export default withTranslation()(CreateVacancy);
