import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Input from '../../components/Input/Input';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import FormItemControls from '../../components/FormItemControls/FormItemControls';
import styles from './EditForms.module.scss';
import get from 'lodash/get';

@inject('user', 'navigate')
@observer
class PortfolioVideoEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const { role } = this.props.user.user;

        const data = {
          [role]: {
            video_links_attributes: [{
              id: get(this.props.data, 'id'),
              video: values.video,
            }],
            moderated_fields: ['video'],
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, form, data = {} } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.formGrid}>
          <div className={styles.formGrid2}>
            <FormItem>
              <FormTitle>{t('label.video')}</FormTitle>
              {getFieldDecorator('video', {
                initialValue: get(data, 'video', ''),
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="video_link"
                  placeholder={t('label.video-link')}
                  errors={getFieldError('video')}
                />
              )}
            </FormItem>
          </div>
          <div style={{ marginTop: 27 }} className={styles.formGrid2}>
            <FormItemControls data={data} entity="video_links"/>
          </div>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(PortfolioVideoEdit));
