import axios from 'axios';
import { Enum } from 'enumify';
import qs from 'qs';

let unauthorizedCallback = Function.prototype;


class Method extends Enum {}
Method.initEnum({
  CREATE: { get http() { return 'post'; } },
  READ: { get http() { return 'get'; } },
  UPDATE: { get http() { return 'put'; } },
  DELETE: { get http() { return 'delete'; } },
});

/**
* Checks user permissions for API methods
*/
function checkPermission(path, method) {
  return true; // TODO
}

/**
* Converts given params to url string
* @param {string} path
* @param {Object} query
* @returns {string}
*/
function getUrl(path, query) {
  if (query === undefined) {
    return path;
  }

  const queryString = qs.stringify(query, { addQueryPrefix: true });

  return `${path}${queryString}`;
}


/**
* Performs API request
* @param {string} path
* @param {object}
* @returns {Promise}
*/
export function apiService(path, params = {}) {
  const { query, data } = params;
  let method = params.method;

  if (typeof method === 'string') {
    method = Method.enumValueOf(method);
  }

  if (!method) {
    method = Method.READ;
  }


  if (!checkPermission(path, method)) {
    return Promise.reject('Access denied');
  }

  const token = localStorage.getItem('eta_token');

  // console.log('read token', token);

  const headers = params.headers || {};

  if (token) {
    const csrfToken = document.querySelector('[name=csrf-token]').content
    headers['Authorization'] = `Bearer ${token}`;
    headers['X-CSRF-TOKEN'] = csrfToken;
  }

  const request = {
    method: method.http,
    url: getUrl(`/api/v1${path}`, query),
    data,
    headers,
    responseType: params.responseType || 'json',
  };

  const result = axios(request);

  result.catch(error => {
    if (error.response && error.response.status === 401) {
      unauthorizedCallback();
    }
  });

  return result;
}

const apiClient = {
  read: (path, params) => apiService(path, { ...params, method: Method.READ }),
  create: (path, params) => apiService(path, { ...params, method: Method.CREATE }),
  update: (path, params) => apiService(path, { ...params, method: Method.UPDATE }),
  delete: (path, params) => apiService(path, { ...params, method: Method.DELETE }),
};

export default apiClient;
