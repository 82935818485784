import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import Button from "../Button/Button";
import styles from "./EducationCard.module.scss";

@inject("user", "navigate")
@observer
class EducationCard extends Component {
  handleNavigate = () => {
    const { user, type, id } = this.props;
    const path = `/app/${user.user.role}/education/${type}/${id}`;

    this.props.navigate({ path });
  };

  render() {
    const { user, category, date, categoryId, title, preview, previewMobile, type, handleSelectCategory, read_time } = this.props;
    const previewPhoto = window.innerWidth < 720 && previewMobile ? previewMobile : preview

    return (
      <div className={styles.wrapper} >
        <div
          onClick={this.handleNavigate}
          className={cn(styles.header, {
            [styles.headerVideo]: type === "video"
          })}
          style={{ backgroundImage: `url('${previewPhoto}')` }}
        ></div>
        <div className={styles.body}>
          <div className={styles.info}>
            <div className={styles.category} onClick={ () => handleSelectCategory(categoryId) }>{category}</div>
            <div className={styles.date}>/ {date}</div>
            <div className={styles.date}>/ { read_time[user.language] }</div>
          </div>
          <div onClick={this.handleNavigate} className={styles.title} dangerouslySetInnerHTML={{__html: title}} ></div>
        </div>
      </div>
    );
  }
}

export default EducationCard;
