import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import FormItem from '../../components/FormItem/FormItem';
import DropzonePhoto from '../../components/DropzonePhoto/DropzonePhoto';
import styles from './EditForms.module.scss';


@inject('user', 'navigate')
@observer
class PortfolioPhotoEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cropedImage: undefined,
    };
  }

  handlePhotoSelect = (field, value) => {
    const { role } = this.props.user.user;
    const data = {
      [role]: {
        photos: [{ data: value }],
        moderated_fields: ['photo']
      },
    };

    this.props.user.update(data, true);
  }

  handleFileSelect =  (e) => {
    const { photos } = this.props;
    const oldPhotos = photos.map(data => data.code);
    const { role } = this.props.user.user;
    const files = e.target.files;
    const promises = [];

    for (let file of files) {
      const filePromise = new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onabort = () => console.log('PortfolioPhotoEdit file reading was aborted');
        reader.onerror = () => console.log('PortfolioPhotoEdit file reading has failed');
        reader.onload = () => resolve({ data: reader.result, filename: decodeURI(file.name) });
      });

      promises.push(filePromise);
    }

    Promise.all(promises).then(fileContents => {
      const data = {
        [role]: {
          photos: [...fileContents, ...oldPhotos],
          moderated_fields: ['photo']
        },
      };

      this.props.user.update(data, true);
    });
  }

  handlePhotoRemove = (id) => {
    this.props.user.deleteFile({ file_id: id });
  }

  render() {
    const { t, form, data = {}, photos } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.formGrid} style={{ flexWrap: 'wrap' }}>
          {photos.map(p => (
            <div className={styles.formGrid4} style={{ marginBottom: 10 }}>
              <FormItem styles={{ height: '100%', minHeight: 120 }}>
                {getFieldDecorator('photo', { initialValue: p })(
                  <DropzonePhoto
                    field={'photo'}
                    onChange={this.handlePhotoSelect}
                    onRemove={this.handlePhotoRemove}
                    form={form}
                    errors={getFieldError('photo')}
                  />
                )}
              </FormItem>
            </div>
          ))}
        </div>
        <div className={styles.formGrid}>
          <FormItem>
            <input multiple onChange={this.handleFileSelect} className={styles.fileUploadInput} id="photo" name="audio" accept="image/png, image/jpg, image/jpeg" type="file" />
            <label className={styles.fileUpload} htmlFor="photo">{t('title.upload-photo')}</label>
          </FormItem>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(PortfolioPhotoEdit));
