import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Title from '../../components/Title/Title';
import FormItem from '../../components/FormItem/FormItem';
import styles from './ForgotPassword.module.scss';
import { withTranslation } from 'react-i18next';

@inject('user', 'navigate')
@observer
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { emailValue: '' };
  }

  handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();

    this.props.form.validateFields({
      // firstFields: false,
    }, (error, values) => {
      if (!error) {
        this.props.user.restorePassword({ user: values }, this.props.form);
        this.setState({ emailValue: values.email })
        console.log('ok', values);
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator, getFieldError } = this.props.form;

    const errorValue = getFieldError('email') ? [getFieldError('email')[0]] : undefined
    const initialValue = errorValue && getFieldError('email').includes('notRealError') ? '' : this.state.emailValue

    return (
      <div className={styles.wrapper}>
        <Title>{t('pages.forgot-password.title')}</Title>
        <form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('email', {
              initialValue: initialValue,
              rules: [
                { type: 'email', message: t('validation.email.notvalid') },
                { required: true, message: t('validation.email.required') },
              ]
            })(
              <Input
                required
                name="email"
                type="email"
                autocomplete="email"
                placeholder={t('input.email')}
                errors={errorValue}
              />
            )}
          </FormItem>
          <div className={styles.buttons}>
            <Button kind="primary" type="submit" className={styles.button}>{t('button.reset-password')}</Button>
          </div>
        </form>
      </div>
    )
  }
}

export default withTranslation()(createForm()(ForgotPassword));
