import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { createForm } from "rc-form";
import get from "lodash/get";
import pick from "lodash/pick";
import isNull from "lodash/isNull";
import moment from 'moment';

import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Button from "../../../components/Button/Button";

import General from "./General";
import Description from "./Description";
import Contacts from "../Advertisement/Contacts";

import styles from "../EditForms.module.scss";

import { Title } from "../Components";

@inject("user", "dataStore", "navigate")
@observer
class Vacancy extends Component {
  constructor(props) {
    super(props);

    this.fields = [
      'projekt_name', 'director', 'shooting_cities', 'start_date', 'end_date',
      'vacancy_start_date', 'vacancy_end_date',
      'place', 'rate', 'about', 'target', 'time_start', 'time_end',
      'sender_first_name', 'sender_last_name', 'phone', 'email'
    ]
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          vacancy: {
            form: 0,
            ...pick(values, this.fields),
            currency: get(values, "currency.value"),
            projekt_type: get(values, "projekt_type.value"),
            document: get(values, "document", [])[0],
          }
        };

        if (this.props.isEdit) {
          this.props.dataStore.updateVacancy(this.props.projectId, this.props.data.id, data);
        } else {
          this.props.dataStore.createVacancy(this.props.projectId,  data).then((res) => {
            this.props.navigate({ path: `/app/${this.props.user.user.role}/boards` });
          });
        }
      } else {
        console.log("Vacancy error", error, values);
      }
    });
  };

  componentDidMount() {
    this.props.dataStore.fetchMovieTypesList();
    this.props.dataStore.fetchCurrenciesList();
  }

  render() {
    const { dataStore, data, t, form, isEdit } = this.props;

    const initialValues = {
      ...pick(data, this.fields),
      currency: dataStore.currencyOptions.find(c => c.value === get(data, 'currency')),
      projekt_type: dataStore.movieTypesOptions.find(o => o.value == get(data, "projekt_type")),
      time_start: get(data, "time_start") ? moment.utc(get(data, "time_start")) : undefined,
      time_end: get(data, "time_end") ? moment.utc(get(data, "time_end")) : undefined,
      document: isNull(get(data, "document")) ? undefined : get(data, "document"),
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <Title title={isEdit ? "title.edit-vacancy" : "title.publish-vacancy"} />

        <SectionTitle>{t("title.common-information")}</SectionTitle>

        <General form={form} initialValues={initialValues} />

        <SectionTitle>{t("title.vacancy-description")}</SectionTitle>

        <Description form={form} initialValues={initialValues} />

        <SectionTitle>{t("title.contacts")}</SectionTitle>

        <Contacts form={form} initialValues={initialValues} type="vacancy" />

        <div className={styles.buttons}>
          <Button className={styles.button} kind="black" type="submit">
            {t("button.done")}
          </Button>
          <Button className={styles.button} onClick={() => window.history.back()} kind="bordered">
            {t("button.cancel")}
          </Button>
        </div>
      </form>
    );
  }
}

export default withTranslation()(createForm()(Vacancy));
