import React, {Component} from 'react';
import cn from 'classnames';
import styles from './GuidePopup.module.scss';
import ReactPlayer from 'react-player'
import { withTranslation } from "react-i18next";

import {inject, observer} from "mobx-react";
import Button from "../Button/Button";
@inject("user", "dataStore", "navigate")
@observer

class GuidePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      guideState: this.props.guideState
    };
  }

  componentDidMount() {
    const { guideState } = this.props

    if (this.state.show === false && guideState !== 'hidden') {
      setTimeout(() => {
        this.setState({ show: true });
      }, 1000)
    }
  }

  componentDidUpdate() {
    const { guideState } = this.props

    if (this.state.show === false && guideState !== 'hidden') {
      setTimeout(() => {
        this.setState({ show: true });
      }, 500)
    }
  }

  handleCloseGuidePopup = (state) => {
    this.setState({ show: false, guideState: state });

    this.props.dataStore.markGuide({
      theme: this.props.theme,
      state: state
    });
  };

  render() {

    const { title, videoLink, t } = this.props
    const { show, guideState } = this.state

    const guideBigClasses = cn(styles.guidePopup, { [styles.guidePopupSlowAppearance]: show })
    const guideSmallClasses = cn(styles.guidePopupSmall, { [styles.guidePopupSlowAppearance]: show })

    return (
      <>
        { show && ( guideState === 'big' || guideState === undefined )  &&
        <div className={guideBigClasses}>
          <div
            onClick={ () => this.handleCloseGuidePopup('small') }
            className={styles.guidePopupClose}
          />
          <div className={styles.guidePopupTitle}>
            { title }
          </div>
          <div className={styles.guidePopupVideoWrapper}>
            <ReactPlayer className={styles.guidePopupVideo} url={ videoLink } muted={true} playing={true} loop={true} controls={true}/>
          </div>
          <div className={styles.guidePopupLinkBlock}>
            <a className={styles.guidePopupLink} onClick={ () => this.handleCloseGuidePopup('hidden') }>
              {t("button.show-no-more")}
            </a>
          </div>
        </div>
        }

        { show && guideState === 'small' &&
          <div className={guideSmallClasses} onClick={ () => this.handleCloseGuidePopup('big') }>
            <span className={styles.guidePopupHelp}>?</span>
          </div>
        }
      </>
    )
  }
}

export default withTranslation()(GuidePopup)
