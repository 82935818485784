import React, { Component } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import styles from './Stepper.module.scss';

class Stepper extends Component {
  render() {
    const { t, step, data } = this.props;
    const itemsCount = data.length;
    const itemWidth = 100/itemsCount;
    const activeIndex = step;
    const containerWidth = `calc(${itemsCount * 44 * (1 - itemsCount/(itemsCount - 1))}px + ${itemsCount/(itemsCount - 1) * 100}%)`;

    console.log('containerWidth', containerWidth)
    return (
      <div className={styles.wrapper}>
        <div className={styles.line} />
        <div style={{ width: '100%', minWidth: containerWidth }} className={styles.items}>
          {data.map(item => {
            const isActive = item.index === activeIndex;
            const isDone = activeIndex > item.index;

            return (
              <div
                key={item.index}
                style={{ flex: `1 1 ${itemWidth}%`}}
                className={cn(
                  styles.item,
                  { [`${styles.itemActive}`]: isActive },
                  { [`${styles.itemDone}`]: isDone },
                )}
              >
                <div className={styles.step}>{item.index}</div>
                <div className={styles.info}>
                  <div className={styles.stepTitle}>{t("label.step")} {item.index}</div>
                  <div className={styles.stepDesc}>{item.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Stepper);
