import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Stepper from '../../components/Stepper/Stepper';
import Title from '../../components/Title/Title';
import { withTranslation } from 'react-i18next';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

import styles from './TalentSignUpStepper.module.scss';

@inject('user', 'navigate')
@observer
class TalentSignUpStepper extends Component {
  render() {
    const { t, i18n, user } = this.props;

    console.log('step', user.currentStep)

    return (
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <Title>{t('pages.signup-stepper.title')}</Title>
          <p className={styles.subtitle}>{t("text.progress-full-desc")}</p>

          <Stepper
            step={user.currentStep}
            data={[
              { index: 1, title: t("title.common-data") },
              { index: 2, title: t("title.education") },
              { index: 3, title: t("title.contacts") },
              { index: 4, title: t("title.area-of-work") },
            ]}
          />

          {user.currentStep === 1 && (<Step1 />)}

          {user.currentStep === 2 && (<Step2 />)}

          {user.currentStep === 3 && (<Step3 />)}

          {user.currentStep === 4 && (<Step4 />)}
        </div>
      </div>
    )
  }
}

export default withTranslation()(TalentSignUpStepper);
