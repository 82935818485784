import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from 'react-google-login';

import facebookImage from '../../../images/icon-facebook.png';
import googleImage from '../../../images/icon-google.png';
import vkImage from '../../../images/icon-vk.png';

import styles from './SocialButtons.module.scss';
import get from "lodash/get";

@inject('navigate', 'user')
@observer
export default class SocialButtons extends Component {
  handleFacebookLoginCallback = (response) => {
    const queryState = get(this.props, 'query.state')

    if (response && queryState !== 'vk') {
      this.props.user.signUpWithSocial({
        token: response.accessToken,
        platform: 'facebook'
      }, this.props.form);
    }
  }

  fbCallBack = (renderProps) => {
    renderProps.onClick()
  }

  handleGoogleLoginCallback = (response) => {
    if (response) {
      this.props.user.signUpWithSocial({
        token: response.accessToken,
        platform: 'google'
      }, this.props.form);
    }
  }

  handleVKLogin = () => {
    // VK.Auth.login(r => console.log(r), {
    //   scope: 'email'
    // });
    window.open('https://oauth.vk.com/authorize?client_id=7721766&display=page&redirect_uri=https://etalents.pro/app/signin&scope=email&state=vk&response_type=code&v=5.103', '_self');
  }

  componentDidMount() {
    VK.init({ apiId: "7721766" });

    if (get(this.props, 'query.state') === 'vk') {
      this.props.user.signUpWithSocial({
        token: this.props.query.code,
        platform: 'vk',
      }, this.props.form)
    }
  }

  render() {
    const facebookState = (get(this.props, 'query.state') !== 'vk')

    return (
      <div className={styles.socialButtons}>
        {
          facebookState ? (
            <FacebookLogin
              appId="3707923455961724"
              autoLoad={false}
              fields="name,email,picture"
              render={renderProps => (
                <div onClick={() => this.fbCallBack(renderProps)} className={styles.socialButton}>
                  <img className={styles.socialButtonImage} src={facebookImage}/>
                </div>
              )}
              onFailure={(err) => { console.log('fb err', err)}}
              callback={this.handleFacebookLoginCallback}
            />
          ) : (
            <div className={styles.socialButton}>
              <img className={styles.socialButtonImage} src={facebookImage}/>
            </div>
          )
        }

        <GoogleLogin
          clientId="167239141474-16fppn5vs5cn6mt07edg8eml094a1fqu.apps.googleusercontent.com"
          render={renderProps => (
            <div onClick={renderProps.onClick} className={styles.socialButton}>
              <img className={styles.socialButtonImage} src={googleImage}/>
            </div>
          )}
          onSuccess={this.handleGoogleLoginCallback}
          onFailure={response => {
            console.log(response);
          }}
          cookiePolicy={"single_host_origin"}
        />
        <div onClick={this.handleVKLogin} className={styles.socialButton}>
          <img className={styles.socialButtonImage} src={vkImage}/>
        </div>
      </div>
    )
  }
}
