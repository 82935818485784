import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import FormItem from '../../components/FormItem/FormItem';
import styles from './EditForms.module.scss';
import get from "lodash/get";

@inject('user', 'navigate')
@observer
class PortfolioAudioEdit extends Component {
  handleFileSelect =  (e) => {
    const { audios } = this.props;
    const oldAudios = audios.map(data => data.code);
    const { role } = this.props.user.user;
    const files = e.target.files;
    const promises = [];

    for (let file of files) {
      const filePromise = new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onabort = () => console.log('PortfolioAudioEdit file reading was aborted');
        reader.onerror = () => console.log('PortfolioAudioEdit file reading has failed');
        reader.onload = () => resolve({ data: reader.result, filename: decodeURI(file.name) });
      });

      promises.push(filePromise);
    }

    Promise.all(promises).then(fileContents => {
      const data = {
        [role]: {
          audios: [...fileContents, ...oldAudios],
          moderated_fields: ['audio']
        },
      };

      this.props.user.update(data, true);
    });
  }

  handleRemove = () => {
    this.props.user.deleteFile({ file_id: this.props.data.id });
  }

  showAudioInput = () => {
    if (this.props.audios.length === 0) {
      return true
    }

    if (this.props.user.user.role === 'talent') {
      const role_object = this.props.user.user.role_object
      const payStatus = get(role_object, 'subscription.status');

      return this.props.audios.length < 5;
    }

    return true
  }

  render() {
    const { t, user, audios, data = {} } = this.props;

    return (
      <div>
        {data.id ? (
          <div className={styles.formGrid}>
            <div className={styles.formGrid2}>
              <FormItem>
                <audio className={styles.audio} controls>
                  <source src={data.path} type="audio/mpeg" />
                </audio>
              </FormItem>
            </div>
            <div style={{ marginTop: 10 }} className={styles.formGrid2}>
              <Button onClick={this.handleRemove} kind="bordered">{t("button.remove")}</Button>
            </div>
          </div>
        ) : (
          this.showAudioInput() ? (
            <div className={styles.formGrid}>
              <FormItem>
                <input onChange={this.handleFileSelect} className={styles.fileUploadInput} id="audio" name="audio" accept=".mp3, .mp4, .m4a" type="file" />
                <label className={styles.fileUpload} htmlFor="audio">{t('title.upload-audio')}</label>
              </FormItem>
            </div>
          ) : <></>
        )}
      </div>
    )
  }
}

export default withTranslation()(PortfolioAudioEdit);
