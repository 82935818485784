import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import Button from '../Button/Button';
import styles from "./AdvertisingInfo.module.scss";
import Loader from "../Loader/Loader";
import Modal from "react-modal";
import closeImage from "../../../images/close-modal-icon.png";

@inject("navigate", "user", "dataStore")
@observer
class AdvertisingInfo extends Component {

  componentDidMount() {
    this.props.dataStore.fetchAdBannersList();
  }

  handleAdvertisingToggleButton = () => {
    this.props.user.toggleAdvModal();
  };

  handleAdvertisingRequestButton = () => {
    this.props.user.advertisingRequest();
    this.handleAdvertisingToggleButton()
  };

  renderModalContent = () => {
    const { user, t, dataStore } = this.props;

    return (
      <div className={styles.modalContentWrapper}>
        <img
          src={closeImage}
          onClick={this.handleAdvertisingToggleButton}
          className={styles.closeIcon}
        />
        <p className={styles.modalTitle}>{t("title.adv-sent")}</p>
        <p>{t("text.adv-req-on-review")}</p>
      </div>
    );
  };

  render() {
    const { t, user } = this.props;

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 545,
        maxHeight: 288
      }
    };

    return (
      <div className={styles.vacanciesInfo}>
        <div className={styles.vacanciesInfoBlock}>
          <p>{t("text.reklama-there-staff")}</p>

          <Loader isLoading={user.isAdvertisingLoading} />

          <Modal
            isOpen={user.isAdvModalOpen}
            style={modalStyle}
            onRequestClose={this.handleAdvertisingToggleButton}
          >
            {this.renderModalContent()}
          </Modal>

          <Button
            size="big"
            kind="primary"
            onClick={this.handleAdvertisingRequestButton}
          >
            {t("button.leave-request")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AdvertisingInfo);
