import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import { Input, FormItem, FormTitle, FormItemControls } from '../../components';
import get from 'lodash/get';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class PortfolioClientsEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          company: {
            clients_attributes: [{
              name: values.name,
              location: values.location,
              website: values.website,
              id: get(this.props.data, 'id')
            }],
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('PortfolioClientsEdit error', error, values);
      }
    });
  }

  render() {
    const { t, form, data = {} } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={formStyles.formGrid} style={{ marginBottom: 0 }}>
          <div className={formStyles.formGrid3}>
            <FormItem>
              <FormTitle>{t('label.name')}</FormTitle>
              {getFieldDecorator('name', {
                initialValue: get(data, 'name', ''),
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="client_name"
                  placeholder={t('label.name')}
                  errors={getFieldError('name')}
                />
              )}
            </FormItem>
          </div>
          <div className={formStyles.formGrid3}>
            <FormItem>
              <FormTitle>{t('label.geolocation')}</FormTitle>
              {getFieldDecorator('location', { initialValue: get(data, 'location', '') })(
                <Input
                  name="location"
                  placeholder={t('label.geolocation')}
                  errors={getFieldError('location')}
                />
              )}
            </FormItem>
          </div>
          <div className={formStyles.formGrid3}>
            <FormItem>
              <FormTitle>{t('label.site')}</FormTitle>
              {getFieldDecorator('website', { initialValue: get(data, 'website', '') })(
                <Input
                  name="website"
                  placeholder={t('label.site')}
                  errors={getFieldError('website')}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className={formStyles.formGrid}>
          <FormItemControls data={data} entity="clients"/>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(PortfolioClientsEdit));
