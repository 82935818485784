import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputMaskFacebook from '../../components/InputMask/InputMaskFacebook';
import InputMaskInstagram from '../../components/InputMask/InputMaskInstagram';
import InputMaskVK from '../../components/InputMask/InputMaskVK';
import InputPhone from '../../components/InputPhone/InputPhone';
import FormItem from '../../components/FormItem/FormItem';
import { withTranslation } from 'react-i18next';

import styles from './CompanySignUpStepper.module.scss';

@inject('user', 'navigate')
@observer
class Step2 extends Component {
  handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();

    this.props.form.validateFields({
      // firstFields: false,
    }, (error, values) => {
      if (!error) {
        const data = {
          company: {
            phones_attributes: [{ number: values.phone_number }],
            fb: values.fb,
            instagram: values.instagram,
            vk: values.vk,
            website: values.website,
            agent_first_name: values.agent_first_name,
            agent_second_name: values.agent_second_name,
            agent_middle_name: values.agent_middle_name,
            agent_email: values.agent_email,
            agent_position: values.agent_position,
            agent_nums_attributes: [{ number: values.agent_phone }],
            profile_status: 'public',
            moderation_status: 0
           }
        };
        console.log('ok', data);

        this.props.user.update(data);
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, i18n, form, user } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;

    return (
      <form onSubmit={this.handleSubmit}>
        <p className={styles.rateTitle}>{t("title.company-contacts")}</p>
        <div className={styles.formGroup}>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('phone_number', {
                initialValue: user.user.phone || '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <InputPhone
                  required
                  locale={this.props.user.language}
                  placeholder={t('input.your-mobile-phone')}
                  errors={getFieldError('phone_number')}
                />
              )}
            </FormItem>
            <FormItem>
              <InputMaskInstagram form={form} initialValue={''} />
            </FormItem>
            <FormItem>
              {getFieldDecorator('website', { initialValue: '' })(
                <Input
                  name="website"
                  placeholder={t('input.website-company')}
                  errors={getFieldError('website')}
                />
              )}
            </FormItem>
          </div>
          <div className={styles.formGroupItem}>
            <FormItem>
              <InputMaskVK form={form} initialValue={''} />
            </FormItem>
            <FormItem>
              <InputMaskFacebook form={form} initialValue={''} />
            </FormItem>
          </div>
        </div>

        <p className={styles.rateTitle}>{t("title.representative-contacts")}</p>

        <div className={styles.formGroup}>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('agent_first_name', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="agent_first_name"
                  placeholder={t('input.agent-first-name')}
                  errors={getFieldError('agent_first_name')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('agent_second_name', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="agent_second_name"
                  placeholder={t('input.agent-second-name')}
                  errors={getFieldError('agent_second_name')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('agent_middle_name', {
                initialValue: '',
              })(
                <Input
                  name="agent_middle_name"
                  placeholder={t('input.agent-middle-name')}
                  errors={getFieldError('agent_middle_name')}
                />
              )}
            </FormItem>
          </div>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('agent_position', {
                initialValue: '',
                rules: []
              })(
                <Input
                  name="agent_position"
                  autocomplete="off"
                  placeholder={t('input.agent-position')}
                  errors={getFieldError('agent_position')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('agent_phone', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <InputPhone
                  required
                  locale={this.props.user.language}
                  placeholder={t('input.agent-phone')}
                  errors={getFieldError('agent_phone')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('agent_email', {
                initialValue: '',
                rules: [
                  { type: 'email', message: t('validation.email.notvalid') },
                  { required: true, message: t('validation.input.required') }
                ]
              })(
                <Input
                  required
                  type="email"
                  name="agent_email"
                  placeholder={t('input.agent-email')}
                  errors={getFieldError('agent_email')}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            kind="primary"
            type="submit"
            className={styles.button}
          >{t('button.next')}</Button>
        </div>
      </form>
    )
  }
}


export default withTranslation()(createForm()(Step2));
