import React from 'react';
import cn from 'classnames';
import styles from './FormTitle.module.scss';

export default function FormTitle(props) {
  return (
    <div className={cn(styles.wrapper, props.lowercase && styles.wrapperLower)} style={{ ...props.styles }}>
      <span className={cn (styles.title, props.lowercase && styles.lowercase, props.size === 'small' && styles.titleSmall)}>{props.children}</span>
      {props.progress && (
        <span className={cn(styles.progress, { [styles.progressActive]: props.isActive })}>{props.progress}%</span>
      )}
    </div>
  )
}
