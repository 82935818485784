import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import FormItem from "../../../components/FormItem/FormItem";
import FormTitle from "../../../components/FormTitle/FormTitle";
import RCRange from "../../../components/RCRange/RCRange";
import get from "lodash/get";

class SearchRangeInput extends Component {
  render() {
    const { form, t, queries = {}, title, field, range, postfix } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const initialValue = get(queries, field, []);

    return (
      <FormItem styles={{ marginBottom: 25 }}>
        <FormTitle size="small">{t(title)}</FormTitle>
          {getFieldDecorator(field, { initialValue })(
            <RCRange
              size="small"
              errors={getFieldError(field)}
              count={1}
              min={range[0]}
              max={range[1]}
              postfix={postfix}
            />
          )}
      </FormItem>
    );
  }
}

export default withTranslation()(SearchRangeInput);
