import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import Button from '../Button/Button';
import ModalCasting from '../ModalInfo/ModalCasting';
import ModalCrowding from '../ModalInfo/ModalCrowding';
import ModalInvitation from '../ModalInfo/ModalInvitation';
import moment from 'moment';
import styles from './AdvCard.module.scss';

@inject("user", "dataStore", "navigate")
@observer
class AdvCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }
  }

  handleClick = () => {
    this.props.onClick();
  }

  handleEditClick = () => {
    const { user, navigate, data } = this.props;
    const form = data.form === 0 ? 'casting' : 'crowding';

    navigate({ path: `/app/${user.user.role}/boards/${data.projekt_id}/${form}/${data.id}` });
  }

  handleRemoveClick = () => {
    const { data, dataStore } = this.props;

    dataStore.removeAdvertisement(data.projekt_id, data.id);
  }

  handleShowMoreClick = () => this.setState({ isOpen: true });

  handleModalClose = () => this.setState({ isOpen: false });

  isExpired = () => {
    const data = this.props.data

    const dateNow = moment(new Date().toLocaleString("en-US", {timeZone: "Asia/Almaty"})).format('YYYY-MM-DD HH:mm')
    if (data.casting_end_date) return data.casting_end_date < dateNow
    return data.end_date < dateNow
  }

  render() {
    const { t, data, user, dataStore, isOwner } = this.props;

    return (
      <div className={styles.block}>
        <div className={styles.blockName}>{dataStore.getMovieTypeById(data.projekt_type)} {data.projekt_name}</div>
        <div className={styles.blockBody}>
          <table className={styles.blockData}>
            <tbody>
              <tr>
                <td className={styles.blockDataCell}>
                  <div className={styles.blockDataTitle}>{t("input.shooting-cities")}</div>
                  <div className={styles.blockDataValue}>{data.shooting_cities || '–'}</div>
                </td>
                <td className={styles.blockDataCell}>
                  <div className={styles.blockDataTitle}>{t("label.project-role")}</div>
                  <div className={styles.blockDataValue}>{dataStore.getRoleById(data.role_type)}</div>
                </td>
              </tr>
              <tr>
                <td className={styles.blockDataCell}>
                  <div className={styles.blockDataTitle}>{t("label.dates")}</div>
                  {data.start_date ? (<div className={styles.blockDataValue}>
                    <span>{moment(data.start_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</span>
                    {` – `}
                    <span>{moment(data.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</span>
                  </div>) : (<div className={styles.blockDataValue}>–</div>)}
                </td>
                <td className={styles.blockDataCell}>
                  <div className={styles.blockDataTitle}>{t("input.shift-rate")}</div>
                  <div className={styles.blockDataValue}>{data.rate ? `${data.rate} ${data.currency}` : '–'}</div>
                </td>
              </tr>
            </tbody>
          </table>
          {(<div className={styles.blockButton}>
            {isOwner && (
              <Button
                wrapperClassName={styles.button}
                onClick={this.handleEditClick}
                kind="primary"
                size="small"
              >{ this.isExpired() ? t("button.resume") : t("button.edit") }</Button>
            )}
            {isOwner && (
              <div className={styles.buttonLast}>
                <Button
                  wrapperClassName={styles.button}
                  onClick={this.handleRemoveClick}
                  kind="bordered"
                  size="small"
                >{t("button.remove")}</Button>
              </div>
            )}
            <Button
              wrapperClassName={styles.button}
              onClick={this.handleShowMoreClick}
              kind={isOwner ? 'black' : 'primary'}
              size="small"
            >{t("button.more")}</Button>

          </div>)}
        </div>
        {data.form === 0 && (
          <ModalCasting
            data={data}
            isOwner={isOwner}
            isOpen={this.state.isOpen}
            onClose={this.handleModalClose}
          />
        )}
        {data.form === 1 && (
          <ModalCrowding
            data={data}
            isOwner={isOwner}
            isOpen={this.state.isOpen}
            onClose={this.handleModalClose}
          />
        )}
        {data.form === 2 && (
          <ModalInvitation
            data={data}
            isOwner={isOwner}
            isOpen={this.state.isOpen}
            onClose={this.handleModalClose}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(AdvCard);
