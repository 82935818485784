import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputMaskFacebook from '../../components/InputMask/InputMaskFacebook';
import InputMaskInstagram from '../../components/InputMask/InputMaskInstagram';
import InputMaskVK from '../../components/InputMask/InputMaskVK';
import InputPhone from '../../components/InputPhone/InputPhone';
import FormItem from '../../components/FormItem/FormItem';
import { withTranslation } from 'react-i18next';

import styles from './StaffSignUpStepper.module.scss';

@inject('user', 'navigate')
@observer
class Step4 extends Component {
  handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          staff: {
            phones_attributes: [{ number: values.phone_number }],
            website: values.website,
            fb: values.fb,
            instagram: values.instagram,
            vk: values.vk,
            imdb: values.imdb,
            kinopoisk: values.kinopoisk,
            profile_status: 'public',
            moderation_status: 0
           }
        };
        console.log('ok', data);

        this.props.user.update(data);
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, form, user } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.formGroup}>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('phone_number', {
                initialValue: user.user.phone || '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <InputPhone
                  required
                  locale={this.props.user.language}
                  placeholder={t('input.your-mobile-phone')}
                  errors={getFieldError('phone_number')}
                />
              )}
            </FormItem>
            <FormItem>
              <InputMaskFacebook form={form} initialValue={''} />
            </FormItem>
            <FormItem>
              <InputMaskInstagram form={form} initialValue={''} />
            </FormItem>
            <FormItem>
              {getFieldDecorator('kinopoisk', { initialValue: '' })(
                <Input
                  name="kinopoisk"
                  placeholder={t('input.your-kinopoisk-profile')}
                  errors={getFieldError('kinopoisk')}
                />
              )}
            </FormItem>
          </div>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('website', {
                initialValue: '',
                rules: []
              })(
                <Input
                  name="website"
                  placeholder={t('input.your-site')}
                  errors={getFieldError('website')}
                />
              )}
            </FormItem>
            <FormItem>
              <InputMaskVK form={form} initialValue={''} />
            </FormItem>
            <FormItem>
              {getFieldDecorator('imdb', { initialValue: '' })(
                <Input
                  name="imdb"
                  placeholder={t('input.your-imdb-profile')}
                  errors={getFieldError('imdb')}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            kind="primary"
            type="submit"
            className={styles.button}
          >{t('button.next')}</Button>
        </div>
      </form>
    )
  }
}


export default withTranslation()(createForm()(Step4));
