import React, { Component } from "react";
import { toJS } from 'mobx';
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import styles from "./CabinetPaymentTalent.module.scss";
import Button from "../../components/Button/Button";
import BlockHeader from "../../components/BlockHeader/BlockHeader";
import get from "lodash/get";
import cn from "classnames";
import moment from "moment";

import cabinetStyles from '../Cabinet/Cabinet.module.scss';

@inject("user", "navigate")
@observer
class CabinetPaymentTalent extends Component {
  handlePayBtn = type => () => {
    const { user } = this.props;

    const userId = get(user, "user.id");
    const talentId = get(user, "user.role_object.id");

    user.paySubscription({ userId, talentId, type });
  };

  handleSubscribeBtn = type => () => {
    const { user } = this.props;

    const userId = get(user, "user.id");
    const talentId = get(user, "user.role_object.id");

    user.payAutoSubscription({ userId, talentId, type });
  };

  handleUnsubscribeBtn = () => {
    const { user } = this.props;

    const userId = get(user, "user.id");
    const talentId = get(user, "user.role_object.id");

    user.unSubscribe({ userId, talentId });
  };

  render() {
    const { user, t } = this.props;
    const { role_object } = user.user;
    const subscription = toJS(get(role_object, "subscription"));
    const isPayButtonActive = !subscription || subscription.status === 'not_paid';
    const isUserChangePlan = subscription && subscription.status === 'paid' && subscription.plan === 'pro_month' && subscription.next_plan === 'pro_plan'

    return (
      <div className={cabinetStyles.page}>
        <div className={cabinetStyles.inner}>
          {!subscription &&<div className={cabinetStyles.pageGrid}>
            <div style={{ marginTop: 20 }} className={cabinetStyles.pageGrid10}>
              <BlockHeader>{t('text.start-your-way-in-movies')} <span>ETALENTS.PRO</span></BlockHeader>
            </div>
          </div>}

          {subscription && <div className={styles.detailsHeader}>
            <div className={styles.paymentStatus}>
              {t("text.payment-status")}:&nbsp;
              <span>
                { subscription.next_plan !== "not_present" && t("plan.status.subscribed") }
                { subscription.status === 'paid' && subscription.next_plan === "not_present" && t("plan.status.paid") }
                { isPayButtonActive && t("plan.status.unsubscribed") }
              </span>
            </div>
          </div>}
          {subscription && <div className={cabinetStyles.pageGrid}>
            <div className={styles.pageGrid4}>
              <div className={styles.blockBodyMini}>
                <div>
                  <p className={styles.paymentText}>
                    {t("text.your-plan")}{" "}
                    <span>
                      {
                        subscription.status === 'paid'
                        ? t(`plan.${subscription.plan}`)
                        : 'FREE'
                      }
                    </span>
                  </p>
                  {
                    subscription.status === 'paid' &&
                    (
                      <>
                        <div className={styles.blockBodyMiniSeparator}></div>
                        <p className={styles.paymentText}>
                          { subscription.next_plan !== "not_present"
                            ? t("text.next-withdrawal") + " "
                            : t("text.subscription-term") + " "
                          }
                          <span>
                            {moment(
                              get(role_object, "subscription.expires_at")
                            ).format("DD.MM.YYYY")}
                          </span>
                        </p>
                      </>
                    )
                  }
                  {
                    isUserChangePlan &&
                    (
                      <>
                        <div className={styles.blockBodyMiniSeparator}></div>
                        <p className={styles.paymentText}>
                          { t("text.next-withdrawal-plan") + " "}
                          <span>
                            {moment(
                              get(role_object, "subscription.expires_at")
                            ).format("DD.MM.YYYY")}
                          </span>
                        </p>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
            <div
              className={cn(
                styles.pageGrid6,
                styles.paymentSubscriptionWrapper
              )}
            >
              {subscription.next_plan !== 'not_present' && (
                <div className={styles.blockBodyMini}>

                  <div className={styles.paymentSubscription}>
                    <p className={styles.paymentSubscriptionText}>
                      {t("payment.unsubscribe")}
                    </p>
                    <Button
                      className={styles.paymentSubscriptionButton}
                      kind="primary"
                      onClick={this.handleUnsubscribeBtn}
                    >
                      {t("button.unsubscribe")}
                    </Button>
                  </div>
                </div>
              )}
              {subscription.next_plan === 'pro_month_plan' && (
                <div className={styles.blockBodyMini}>
                  <div className={styles.paymentSubscription}>
                    <p className={styles.paymentSubscriptionText}>
                      {t("payment.switch-to-annual-rate-next-time")}
                    </p>
                    <Button
                      className={styles.paymentSubscriptionButton}
                      kind="primary"
                      onClick={this.handleSubscribeBtn("pro")}
                    >
                      PRO year
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>}
          <div className={cabinetStyles.pageGrid}>
            <div className={cabinetStyles.pageGrid10}>
              <div className={styles.fares}>
                <div className={styles.fare}>
                  <div className={styles.fareTitle}>
                    {t("payment.free.title")}
                  </div>
                  <div className={styles.fareSubtitle}>
                    &nbsp;
                  </div>
                  <div className={styles.fareInfo}>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.free.profile")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.free.cv")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.free.photos")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.free.video")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.free.audio")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.fare}>
                  <div className={styles.fareTitle}>
                    {t("payment.pro-month.title")}
                  </div>
                  <div className={styles.fareSubtitle}>
                    {t("payment.pro-month.subtitle")}
                  </div>
                  <div className={styles.fareInfo}>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-month.profile")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-month.cv")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-month.photos")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-month.videos")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-month.audio")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitleBold}>
                        {t("payment.pro-month.shooting")}
                      </div>
                    </div>
                  </div>
                  <div className={styles.fareButtonWrapper}>
                    {isPayButtonActive && (
                      <Button
                        className={styles.fareButton}
                        kind="primary"
                        onClick={this.handleSubscribeBtn("pro_month")}
                      >
                        {t("button.subscribe")}
                      </Button>
                    )}
                  </div>
                </div>


                <div className={styles.fare}>
                  <div className={styles.fareTitle}>
                    {t("payment.pro-year.title")}
                  </div>
                  <div className={styles.fareSubtitle}>
                    {t("payment.pro-year.subtitle")}
                  </div>
                  <div className={styles.fareInfo}>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-year.profile")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-year.cv")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-year.photos")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-year.videos")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitle}>
                        {t("payment.pro-year.audio")}
                      </div>
                    </div>
                    <div className={styles.fareInfoItem}>
                      <div className={styles.fareInfoTitleBold}>
                        {t("payment.pro-year.shooting")}
                      </div>
                    </div>
                  </div>
                  <div className={styles.fareButtonWrapper}>
                    {isPayButtonActive && (
                      <Button
                        className={styles.fareButton}
                        kind="primary"
                        onClick={this.handleSubscribeBtn("pro")}
                      >
                        {t("button.subscribe")}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CabinetPaymentTalent);
