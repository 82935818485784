import {computed, configure, flow, observable} from "mobx";
import api from "../api";
import i18n from "../i18n";
import navigate from "../router/navigate";
import get from "lodash/get";
import range from "lodash/range";
import Status from './store-status';
import {NotificationManager} from 'react-notifications';

configure({ enforceActions: "observed" });

export default class Data {
  @observable educationList = null;
  @observable careersList = {
    departments: {},
    position_ru: {},
    position_en: {}
  };
  @observable movieTypesList = null;
  @observable companyServicesList = null;
  @observable countriesList = null;
  @observable adBannersList = null;
  @observable currenciesList = null;
  @observable instrumentsList = null;
  @observable sportsList = null;
  @observable languagesList = null;
  @observable appearanciesList = null;
  @observable physiquesList = null;
  @observable eyesList = null;
  @observable hairsList = null;
  @observable favoriteTalents = [];
  @observable favoriteStaffs = [];
  @observable favoriteCompanies = [];

  @observable project = null;
  @observable projects = [];

  @observable boardStatus = Status.DONE;
  @observable favoritesStatus = Status.PENDING;


  constructor(root) {
    this.root = root;
  }

  @computed get isBoardUpdating() {
    return this.boardStatus === Status.PENDING;
  }

  @computed get isFavoritesLoading() {
    return this.favoritesStatus === Status.PENDING;
  }

  @computed get companyTypes() {
    const companyTypes = {
      ru: [
        { value: "too", label: i18n.t("input.company-type-too") },
        { value: "ip", label: i18n.t("input.company-type-ip") },
        { value: "ao", label: i18n.t("input.company-type-ao") }
      ],
      en: [
        { value: "too", label: i18n.t("input.company-type-too") },
        { value: "ip", label: i18n.t("input.company-type-ip") },
        { value: "ao", label: i18n.t("input.company-type-ao") }
      ]
    };
    return companyTypes[this.root.user.language];
  }

  @computed get genderOptions() {
    const gender = [
      { value: "male", label: i18n.t("input.male"), isDefault: true },
      { value: "female", label: i18n.t("input.female") }
    ];
    const genderTypes = { ru: gender, en: gender };

    return genderTypes[this.root.user.language];
  }

  @computed get workStatusOptions() {
    const workStatuses = [
      { value: 'company', label: i18n.t('input.in-company'), isDefault: true },
      { value: 'independent', label: i18n.t('input.independent') },
    ]
    const data = { ru: workStatuses, en: workStatuses };

    return data[this.root.user.language];
  }

  @computed get statusOptions() {
    const statuses = [
      { value: 'amateur', label: i18n.t('input.amateur'), isDefault: true },
      { value: 'professional', label: i18n.t('input.professional') },
    ]
    const data = { ru: statuses, en: statuses };

    return data[this.root.user.language];
  }

  @computed get yearOptions() {
    const currentYear = new Date().getFullYear()
    return range(currentYear, 1900).map(item => ({
      value: item.toString(),
      label: item
    }));
  }

  @computed get countryOptions() {
    return this.countriesList ? this.countriesList[this.root.user.language] : [];
  }

  @computed get adBannersOptions() {
    return this.adBannersList ? this.adBannersList : []
  }

  @computed get currencyOptions() {
    return this.currenciesList ? this.currenciesList.map(c => ({
      value: c.name,
      label: c.name
    })) : [];
  }

  @computed get languagesOptions() {
    return this.languagesList
      ? this.languagesList[this.root.user.language].map((l, i) => ({
          value: l.id,
          label: l.name,
        }))
      : [];
  }

  @computed get languagesLevelOptions() {
    const languagesLevels = {
      ru: [
        { value: 0, label: "Базовый" },
        { value: 1, label: "Разговорный" },
        { value: 2, label: "Свободный" }
      ],
      en: [
        { value: 0, label: "Basic" },
        { value: 1, label: "Spoken" },
        { value: 2, label: "Fluent" }
      ]
    };
    return languagesLevels[this.root.user.language];
  }

  @computed get movieTypesOptions() {
    return this.movieTypesList
      ? this.movieTypesList[this.root.user.language].map(t => ({
          value: t.id,
          label: t.project
        }))
      : [];
  }

  getMovieTypeById = (id) => {
    return get(this.movieTypesOptions.find(r => r.value == id), 'label', '');
  }

  @computed get companyServicesOptions() {
    return this.companyServicesList
    ? this.companyServicesList[this.root.user.language].map(i => ({ value: i.id, label: i.name }))
    : [];
  }

  @computed get instrumentsOptions() {
    return this.instrumentsList
      ? this.instrumentsList[this.root.user.language].map(i => ({
          value: i.id,
          label: i.name
        }))
      : [];
  }

  @computed get instrumentsLevelOptions() {
    return this.instrumentsList
      ? this.instrumentsList.levels[this.root.user.language].map((o, i) => ({
          value: this.instrumentsList.levels["ru"][i],
          label: o
        }))
      : [];
  }

  @computed get sportsOptions() {
    return this.sportsList
      ? this.sportsList[this.root.user.language].map(i => ({
          value: i.id,
          label: i.name
        }))
      : [];
  }

  @computed get sportsLevelOptions() {
    return this.sportsList
      ? this.sportsList.levels[this.root.user.language].map((l, i) => ({
          value: this.sportsList.levels["ru"][i],
          label: l
        }))
      : [];
  }

  @computed get appearanciesOptions() {
    return this.appearanciesList
      ? this.appearanciesList[this.root.user.language].map(i => ({
          value: i.id.toString(),
          label: i.name
        }))
      : [];
  }

  @computed get physiquesOptions() {
    return this.physiquesList
      ? this.physiquesList[this.root.user.language].map(i => ({
          value: i.id.toString(),
          label: i.name
        }))
      : [];
  }

  @computed get eyesOptions() {
    return this.eyesList
      ? this.eyesList[this.root.user.language].map(i => ({
          value: i.id.toString(),
          label: i.name
        }))
      : [];
  }

  @computed get hairsOptions() {
    return this.hairsList
      ? this.hairsList[this.root.user.language].map(i => ({
          value: i.id.toString(),
          label: i.name
        }))
      : [];
  }

  @computed get universitiesOptions() {
    return this.educationList
      ? this.educationList[this.root.user.language].map(i => ({
          value: i.id,
          label: i.name
        }))
      : [];
  }

  @computed get universityStatusTypes() {
    const statusTypes = [
      { value: 0, label: i18n.t("input.university-status-higher") },
      { value: 1, label: i18n.t("input.university-status-master") },
      { value: 2, label: i18n.t("input.university-status-doctor") },
      { value: 3, label: i18n.t("input.university-status-incomplete") },
    ];
    return this.root.user.language === 'ru' ? statusTypes : statusTypes;
  }

  @computed get roleStatusTypes() {
    const roleStatuses = [
      { value: 0, label: i18n.t("label.main-role") },
      { value: 1, label: i18n.t("label.second-role") },
      { value: 2, label: i18n.t("label.episode-role") },
      { value: 3, label: i18n.t("label.crowd-role") },
    ];
    return this.root.user.language === 'ru' ? roleStatuses : roleStatuses;
  }

  getRoleById = (id) => {
    return get(this.roleStatusTypes.find(r => r.value == id), 'label', '–');
  }

  @observable ageRange = [1, 120];

  @observable heightRange = [54, 251];

  @computed get heightOptions() {
    return range(...this.heightRange).map(i => ({ value: i, label: `${i} ${i18n.t("label.cm")}`}));
  }

  @observable weightRange = [1, 300];

  @computed get weightOptions() {
    return range(...this.weightRange).map(i => ({ value: i, label: `${i} ${i18n.t("label.kg")}`}));
  }

  @observable chestGirthRange = [20, 200];

  @computed get chestGirthOptions() {
    return range(...this.chestGirthRange).map(i => ({ value: i, label: `${i} ${i18n.t("label.cm")}`}));
  }

  @observable waistGirthRange = [20, 200];

  @computed get waistGirthOptions() {
    return range(...this.waistGirthRange).map(i => ({ value: i, label: `${i} ${i18n.t("label.cm")}`}));
  }

  @observable hipGirthRange = [20, 200];

  @computed get hipGirthOptions() {
    return range(...this.hipGirthRange).map(i => ({ value: i, label: `${i} ${i18n.t("label.cm")}`}));
  }

  @observable headCircumferenceRange = [10, 90];

  @computed get headCircumferenceOptions() {
    return range(...this.headCircumferenceRange).map(i => ({ value: i, label: `${i} ${i18n.t("label.cm")}`}));
  }

  @computed get shoeSizeOptions() {
    return range(1, 60).map(i => ({ value: i, label: i }));
  }

  @computed get clothingSizeOptions() {
    return [
      { value: 'XXS', label: 'XXS' },
      { value: 'XS', label: 'XS' },
      { value: 'S', label: 'S' },
      { value: 'M', label: 'M' },
      { value: 'L', label: 'L' },
      { value: 'XL', label: 'XL' },
      { value: 'XXL', label: 'XXL' },
    ];
  }

  @computed get getFavoriteTalents() {
    return this.favoriteTalents ? this.favoriteTalents : [];
  }

  fetchEducationList = flow(function*() {
    try {
      const response = yield api.fetchEducationList();

      if (response.status === 200) {
        this.educationList = response.data.universities;
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchAppearanciesList = flow(function*() {
    try {
      const response = yield api.fetchAppearanciesList();

      if (response.status === 200)
        this.appearanciesList = response.data.appearance;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchPhysiquesList = flow(function*() {
    try {
      const response = yield api.fetchPhysiquesList();

      if (response.status === 200) this.physiquesList = response.data.physiques;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchEyesList = flow(function*() {
    try {
      const response = yield api.fetchEyesList();

      if (response.status === 200) this.eyesList = response.data.eyes;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchHairsList = flow(function*() {
    try {
      const response = yield api.fetchHairsList();

      if (response.status === 200) this.hairsList = response.data.hairs;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchLanguagesList = flow(function*() {
    try {
      const response = yield api.fetchLanguagesList();

      if (response.status === 200) this.languagesList = response.data.languages;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchCareersList = flow(function*() {
    try {
      const response = yield api.fetchCareersList();

      if (response.status === 200) this.careersList = response.data;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchInstrumentsList = flow(function*() {
    try {
      const response = yield api.fetchInstrumentsList();

      if (response.status === 200)
        this.instrumentsList = response.data.music_dictionary;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchSportsList = flow(function*() {
    try {
      const response = yield api.fetchSportsList();

      if (response.status === 200)
        this.sportsList = response.data.sport_dictionary;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchMovieTypesList = flow(function*() {
    try {
      const response = yield api.fetchMovieTypesList();

      if (response.status === 200)
        this.movieTypesList = response.data.movie_types;

      return response;
    } catch (error) {
      console.log(error);
    }
  });

  fetchFavoriteTalents = flow(function*() {
    this.favoritesStatus = Status.PENDING;

    if (!this.root.user.isAuthenticated) return false;

    try {
      const response = yield api.fetchFavoriteTalents(this.root.user.user.id);

      if (response.status === 200) {
        this.favoriteTalents = get(response, "data.favorite_talents", []);

        this.favoritesStatus = Status.DONE;
      }

    } catch (error) {
      console.log('fetchFavoriteTalents', error);
      this.favoritesStatus = Status.ERROR;
    }
  });

  fetchFavoriteStaffs = flow(function*() {
    this.favoritesStatus = Status.PENDING;

    if (!this.root.user.isAuthenticated) return false;

    try {
      const response = yield api.fetchFavoriteStaffs(this.root.user.user.id);

      if (response.status === 200) {
        this.favoriteStaffs = get(response, "data.favorite_staffs", []);

        this.favoritesStatus = Status.DONE;
      }

    } catch (error) {
      console.log('fetchFavoriteStaffs', error);
      this.favoritesStatus = Status.ERROR;
    }
  });

  fetchFavoriteCompanies = flow(function*() {
    this.favoritesStatus = Status.PENDING;

    if (!this.root.user.isAuthenticated) return false;

    try {
      const response = yield api.fetchFavoriteCompanies(this.root.user.user.id);

      if (response.status === 200) {
        this.favoriteCompanies = get(response, "data.favorite_companies", []);

        this.favoritesStatus = Status.DONE;
      }

    } catch (error) {
      console.log('fetchFavoriteCompanies', error);
      this.favoritesStatus = Status.ERROR;
    }
  });

  fetchFavoritesByRole = (role) => {
    switch (role) {
      case 'talent': return this.fetchFavoriteTalents();
      case 'staff': return this.fetchFavoriteStaffs();
      case 'company': return this.fetchFavoriteCompanies();
    }
  }

  favoritesByRole = (role) => {
    switch (role) {
      case 'talent': return this.favoriteTalents;
      case 'staff': return this.favoriteStaffs;
      case 'company': return this.favoriteCompanies;
    }
  }

  addFavorite = flow(function*(userRoleId, data) {
    try {
      const response = yield api.addFavorite(this.root.user.user.id, userRoleId, data);

      if (response.status === 200) {
        NotificationManager.success(response.data.text);

        this.fetchFavoritesByRole(data.favorite_role);
      }

      return response;
    } catch (error) {
      NotificationManager.error(error.response.data.error);

      console.log(error);
    }
  });

  removeFavorites = flow(function*(data) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.removeFavorites(this.root.user.user.id, data);

      if (response.status === 200) {
        if (response.data.text) {
          NotificationManager.success(response.data.text);
        }

        this.fetchFavoritesByRole(data.favorite_role);
        this.fetchProjects();

        this.boardStatus = Status.DONE;
      }

      return response;
    } catch (error) {
      console.log(error);
      this.boardStatus = Status.ERROR;
    }
  });

  markGuide = flow(function*(data) {
    try {
      return yield api.markEvent(this.root.user.user.id, data);
    } catch (error) {
      console.log(error);
    }
  });

  fetchCompanyServicesList = flow(function*() {
    try {
      const response = yield api.fetchCompanyServicesList();

      if (response.status === 200) this.companyServicesList = response.data.company_services;

      return response;
    } catch (error) { console.log(error) }
  })

  fetchCountriesList = flow(function*() {
    try {
      const response = yield api.fetchCountriesList();

      if (response.status === 200) this.countriesList = response.data.countries;

      return response;
    } catch (error) { console.log(error) }
  })

  fetchAdBannersList = flow(function*() {
    try {
      const response = yield api.fetchAdBannersList();

      if (response.status === 200) this.adBannersList = response.data.ad_banners;

      return response;
    } catch (error) { console.log(error) }
  })

  fetchCurrenciesList = flow(function*() {
    try {
      const response = yield api.fetchCurrenciesList();

      if (response.status === 200) this.currenciesList = response.data.currencies;

      return response;
    } catch (error) { console.log(error) }
  })

  fetchProject = flow(function*(projektId) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.fetchProject(this.root.user.user.id, projektId);

      if (response.status === 200) this.project = response.data.projekt;

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      this.boardStatus = Status.ERROR;
      console.log(error);
    }
  })

  fetchProjects = flow(function*() {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.fetchProjects(this.root.user.user.id);

      if (response.status === 200) this.projects = response.data;

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      this.boardStatus = Status.ERROR;
      console.log(error);
    }
  })

  @computed get projectsList() {
    return  this.projects ? this.projects : [];
  }

  addFavoritesToProjekt = flow(function*(projektId, data) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.addFavoritesToProjekt(this.root.user.user.id, projektId, data);

      if (response.status === 200) {
        this.fetchProjects();
      }

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      this.boardStatus = Status.ERROR;
      console.log(error);
    }
  })

  createProject = flow(function*(values) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.createProject(this.root.user.user.id, values);

      if (response.status === 200) this.fetchProjects()

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      this.boardStatus = Status.ERROR;

      if (get(error, 'response.data.error')) {
        NotificationManager.error(get(error, 'response.data.error'));
      }

      console.log(error);
    }
  })

  updateProject = flow(function*(values) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.updateProject(this.root.user.user.id, values.id, values);

      if (response.status === 200) {
        yield this.fetchProjects();
      }

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      this.boardStatus = Status.ERROR;
      console.log(error);
    }
  })

  deleteProject = flow(function*(id) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.deleteProject(this.root.user.user.id, id);

      if (response.status === 200) this.fetchProjects();

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      console.log(error);
      this.boardStatus = Status.ERROR;
    }
  })

  createAdvertisement = flow(function*(projektId, values) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.createAdvertisement(this.root.user.user.id, projektId, values);

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      console.log(error);

      if (get(error, 'response.data.error')) {
        NotificationManager.error(get(error, 'response.data.error'));
      }

      this.boardStatus = Status.ERROR;
    }
  })

  updateAdvertisement = flow(function*(projektId, id, values) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.updateAdvertisement(this.root.user.user.id, projektId, id, values);

      if (response.status === 200) {
        navigate({ path: `/app/${this.root.user.user.role}/boards/${projektId}` });
      }

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      console.log(error);

      if (get(error, 'response.data.error')) {
        NotificationManager.error(get(error, 'response.data.error'));
      }

      this.boardStatus = Status.ERROR;
    }
  })

  removeAdvertisement = flow(function*(projektId, id) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.removeAdvertisement(this.root.user.user.id, projektId, id);

      if (response.status === 200) {
        this.fetchProjects();
        navigate({ path: `/app/${this.root.user.user.role}/boards` });
      }

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      console.log(error);
      this.boardStatus = Status.ERROR;
    }
  })

  createVacancy = flow(function*(projektId, values) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.createVacancy(this.root.user.user.id, projektId, values);

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      console.log(error);

      if (get(error, 'response.data.error')) {
        NotificationManager.error(get(error, 'response.data.error'));
      }

      this.boardStatus = Status.ERROR;
    }
  })

  updateVacancy = flow(function*(projektId, id, values) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.updateVacancy(this.root.user.user.id, projektId, id, values);

      if (response.status === 200) {
        this.fetchProjects();
        navigate({ path: `/app/${this.root.user.user.role}/boards/${projektId}` });
      }

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      console.log(error);

      if (get(error, 'response.data.error')) {
        NotificationManager.error(get(error, 'response.data.error'));
      }

      this.boardStatus = Status.ERROR;
    }
  })

  removeVacancy = flow(function*(projektId, id) {
    this.boardStatus = Status.PENDING;
    try {
      const response = yield api.removeVacancy(this.root.user.user.id, projektId, id);

      if (response.status === 200) {
        this.fetchProjects()
        navigate({ path: `/app/${this.root.user.user.role}/boards/${projektId}` });
      }

      this.boardStatus = Status.DONE;
      return response;
    } catch (error) {
      console.log(error);
      this.boardStatus = Status.ERROR;
    }
  })

  @computed get availableDepartments() {
    return Object.entries(this.careersList.departments).map(item => ({
      value: item[0],
      label: item[1][this.root.user.language].name
    }));
  }

  @computed get allProfessions() {
    return Object.entries(
      this.careersList[`position_${this.root.user.language}`]
    ).map(item => ({ value: item[0], label: item[1] }));
  }

  professionsByDepartment = dep => {
    if (!dep) return [];

    return Object.entries(
      this.careersList.departments[dep.value][this.root.user.language].list
    ).map(item => ({ value: item[0], label: item[1] }));
  };
}
