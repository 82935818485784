import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Button from '../Button/Button';
import styles from './ProjectsList.module.scss';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import ModalVacancyInvitation from '../ModalInfo/ModalVacancyInvitation';
import ModalInvitation from "../ModalInfo/ModalInvitation";

@inject("user", "dataStore")
@observer
class ProjectsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      invitation: {},
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.invitation.projekt_id && props.user.activeInvitation.projekt_id) {
      return {
        invitation: props.user.activeInvitation
      }
    }

    return null
  }

  handleClick = () => {
    this.props.onClick();
  }

  handleShowMoreClick = (invitation) => {
    this.setState({ invitation }, () => {
      this.props.user.setActiveInvitation(invitation, null)
      this.setState({ isOpen: true });
    });
  }

  handleModalClose = () => {
    this.setState({ isOpen: false }, () => {
      this.setState({ invitation: {} });
    });
  }

  render() {
    const { t, user, invitations, dataStore } = this.props;

    return (
      <div className={styles.list}>
        {invitations.map(invitation => (
          <div key={invitation.id} className={styles.project}>
            <div className={styles.projectHeader}>
              <div className={styles.projectName}>{dataStore.getMovieTypeById(invitation.projekt_type)} {invitation.projekt_name}</div>
              <div className={styles.projectTime}>
                {moment(invitation.created_at).locale(user.language).fromNow()}
              </div>
            </div>
            <div className={styles.projectBody}>
              <div className={styles.projectData}>
                <div className={styles.projectDataCell}>
                  <div className={styles.projectDataTitle}>{t("input.shooting-cities")}</div>
                  <div className={styles.projectDataValue}>{invitation.shooting_cities}</div>
                </div>
                <div className={styles.projectDataCell}>
                  <div className={styles.projectDataTitle}>{invitation.target ? t("label.position") : t("label.project-role")}</div>
                  <div className={styles.projectDataValue}>{invitation.target ? invitation.target : dataStore.getRoleById(invitation.role_type)}</div>
                </div>
                <div className={styles.projectDataCell}>
                  <div className={styles.projectDataTitle}>{t("label.casting-date")}</div>
                  <div className={styles.projectDataValue}>
                    { invitation.casting_date &&
                      moment(invitation.casting_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                    }
                  </div>
                </div>
                <div className={styles.projectDataCell}>
                  <div className={styles.projectDataTitle}>{t("input.shift-rate")}</div>
                  <div className={styles.projectDataValue}>{invitation.rate} {invitation.currency}</div>
                </div>
              </div>
              <div className={styles.projectButton}>
              <Button  onClick={() => this.handleShowMoreClick(invitation)} kind="primary" size="small">{t("button.more")}</Button>
              </div>
            </div>
          </div>
        ))
        }
        { this.props.role === 'talent'
          ? (<ModalInvitation
               data={this.state.invitation}
               isOpen={this.state.isOpen}
               onClose={this.handleModalClose} />)
          : (<ModalVacancyInvitation
                data={this.state.invitation}
                isOpen={this.state.isOpen}
                onClose={this.handleModalClose} />)
        }

      </div>
    )
  }
}


export default withTranslation()(ProjectsList);
