import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { createForm } from "rc-form";
import {
  Input, InputMask, Textarea, InputDatepicker, InputPlacesAutocomplete, Button,
  Checkbox, Dropzone, Select, MultiSelect, RadioGroup, FormItem, FormTitle
} from "../../components";
import get from "lodash/get";
import isArray from "lodash/isArray";
import range from "lodash/range";
import uploadExample1 from "../../../images/upload-example-1.png";
import uploadExample2 from "../../../images/upload-example-2.png";
import uploadExample3 from "../../../images/upload-example-3.png";
import uploadExample4 from "../../../images/upload-example-4.png";
import uploadExample5 from "../../../images/upload-example-5.png";
import moment from "moment";

import formStyles from "../Forms/EditForms.module.scss";

let scopedFormChange = () => {};

let onValuesChange = () => {
  setTimeout(scopedFormChange, 0);
};

@inject('user', 'dataStore', 'navigate')
@observer
class CommonEdit extends Component {
  constructor(props) {
    super(props);

    this.state = { cropedImage: undefined };

    scopedFormChange = this.validateFields;
  }

  componentDidMount() {
    this.props.dataStore.fetchCountriesList();
    this.props.dataStore.fetchCurrenciesList();
  }

  validateFields = () => {
    const { form, t } = this.props;

    const playAgeStart = get(form.getFieldsValue(), "play_age_start");
    const playAgeEnd = get(form.getFieldsValue(), "play_age_end");

    let error;

    if (playAgeEnd) {
      if (playAgeStart) {
        if (Number(playAgeEnd.value) < Number(playAgeStart.value)) {
          error = t("validation.input.tooBig");
        }
      } else {
        error = t("validation.input.required");
      }
    }

    form.setFields({
      play_age_start: { value: playAgeStart, errors: error ? [error] : [] }
    });

    error = "";

    if (playAgeStart) {
      if (playAgeEnd) {
        if (Number(playAgeEnd.value) < Number(playAgeStart.value)) {
          error = t("validation.input.tooSmall");
        }
      } else {
        error = t("validation.input.required");
      }
    }

    form.setFields({
      play_age_end: { value: playAgeEnd, errors: error ? [error] : [] }
    });
  };

  handleSubmit = (e, next) => {
    e.preventDefault();

    this.props.form.validateFields({},
      (error, values) => {
        if (!error) {
          const { role, role_object } = this.props.user.user;
          const currentHobbies = get(role_object, "hobbies");
          const newHobbies = values.hobbies.map(h => ({
            name: h.label,
            id: h.value,
            isNew: h.__isNew__
          }));
          const removedHobbies = currentHobbies.map(h => {
            if (!newHobbies.find(nh => h.id === nh.id)) {
              h._destroy = "1";
            }
            return h;
          });
          const addedHobbies = newHobbies
            .filter(h => h.isNew)
            .map(h => ({ name: h.name }));
          const hobbies = [...removedHobbies, ...addedHobbies];

          const isBirthdayChanged = !moment(new Date(values.birthday)).isSame(
            moment(new Date(get(role_object, "info.birth_date"))),
            "day"
          );
          const importantFields = {
            avatar: Boolean(this.state.cropedImage),
            first_name:
              get(role_object, "info.first_name") !== values.first_name,
            last_name: get(role_object, "info.last_name") !== values.last_name,
            gender: get(role_object, "info.gender") !== values.gender,
            birth_date: isBirthdayChanged
          };

          const moderatedFields = Object.keys(importantFields).filter(
            key => importantFields[key]
          );

          const data = {
            talent: {
              info_attributes: {
                first_name: values.first_name,
                last_name: values.last_name,
                first_name_en: values.first_name_en,
                last_name_en: values.last_name_en,
                gender: values.gender,
                birth_date: values.birthday,
                city_ru: isArray(values.city) ? values.city[0] : get(role_object, 'info.city_ru'),
                city_en: isArray(values.city) ? values.city[1] : get(role_object, 'info.city_en'),
                country_code: get(values, "country.value"),
                citizenship: get(values, "citizenship.value")
              },
              avatar: this.state.cropedImage,
              about: values.about,
              health: values.health,
              play_age_start: get(values, "play_age_start.value"),
              play_age_end: get(values, "play_age_end.value"),
              rate_attributes: {
                id: get(role_object, "rate.id"),
                rate_start: values.shift_rate,
                currency: get(values, 'currency.value'),
              },
              hobbies_attributes: hobbies,
              crowd_scene: values.crowd_scene,
              low_budget: values.low_budget,
              long_trip: values.long_trip,
              model_work: values.model_work,
              moderated_fields: moderatedFields
            }
          };

          this.props.user.update(data, true).then(() => {
            if (next) {
              this.props.navigate({ path: `/app/${role}/edit`, query: { tab: 'appearance' } }, true);
            }
          });
        } else {
          console.log("error", error, values);
        }
      }
    );
  };

  handleSaveAndNext = (e) => {
    this.handleSubmit(e, true);
  }

  calculateAge(birthday) {
    // birthday is a date
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  handlePhotoCrop = cropedImage => this.setState({ cropedImage });

  render() {
    const { user, t, form, dataStore } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;
    const role_object = get(user, "user.role_object", {});
    const selectedCountry = dataStore.countryOptions.find(c => c.value === get(role_object, 'info.country_code'));
    const selectedCurrency = dataStore.currencyOptions.find(c => c.value === get(role_object, 'rate.currency'));
    const selectedCitizenship = dataStore.countryOptions.find(c => c.value === get(role_object, 'info.citizenship'));

    const dataHobbies = get(role_object, "hobbies").map(h => ({
      value: h.id,
      label: h.name
    }));

    const playAgeStart = getFieldValue("play_age_start")
      ? getFieldValue("play_age_start").value
      : get(role_object, "play_age_start");
    const playAgeStartRange = range(0, 100).map(age => ({
      value: age,
      label: age
    }));
    const playAgeEndRange = (playAgeStart
      ? range(playAgeStart, playAgeStart + 6)
      : range(0, 100)
    ).map(age => ({ value: age, label: age }));

    const dataPlayAgeStart = playAgeStartRange.find(
      a => a.value === get(role_object, "play_age_start")
    );
    const dataPlayAgeEnd = playAgeEndRange.find(
      a => a.value === get(role_object, "play_age_end")
    );

    return (
      <div className={formStyles.form}>
        <form onSubmit={this.handleSubmit}>
          <div className={formStyles.formTitle}>{t("title.main-information")}</div>
          <div className={formStyles.formSeparator} />
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle>{t("input.first-name")}</FormTitle>
                {getFieldDecorator("first_name", {
                  initialValue: get(role_object, "info.first_name"),
                  rules: [
                    { required: true, message: t("validation.input.required") }
                  ]
                })(
                  <Input
                    required
                    name="first_name"
                    placeholder={t("input.your-first-name")}
                    errors={getFieldError("first_name")}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle>{t("input.last-name")}</FormTitle>
                {getFieldDecorator("last_name", {
                  initialValue: get(role_object, "info.last_name"),
                  rules: [
                    { required: true, message: t("validation.input.required") }
                  ]
                })(
                  <Input
                    required
                    name="last_name"
                    placeholder={t("input.your-last-name")}
                    errors={getFieldError("last_name")}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem styles={{ marginBottom: -10 }}>
                <FormTitle>{t("label.gender")}</FormTitle>
                <div style={{ marginTop: 15 }}>
                  {getFieldDecorator("gender", {
                    initialValue: get(role_object, "info.gender")
                  })(
                    <RadioGroup
                      isRow
                      labelTextStyle={{ fontSize: 14 }}
                      data={dataStore.genderOptions}
                    />
                  )}
                </div>
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle>{t('input.first-name-en')}</FormTitle>
                {getFieldDecorator('first_name_en', {
                  initialValue: get(role_object, 'info.first_name_en'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Input
                    required
                    name="first_name_en"
                    placeholder={t('input.your-first-name')}
                    errors={getFieldError('first_name_en')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle>{t('input.last-name-en')}</FormTitle>
                {getFieldDecorator('last_name_en', {
                  initialValue: get(role_object, 'info.last_name_en'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Input
                    required
                    name="last_name_en"
                    placeholder={t('input.your-last-name')}
                    errors={getFieldError('last_name_en')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle>{t("input.birthday")}</FormTitle>
                {getFieldDecorator("birthday", {
                  initialValue: get(role_object, "info.birth_date"),
                  rules: [
                    { required: true, message: t("validation.input.required") }
                  ]
                })(
                  <InputDatepicker
                    required
                    maxDate={new Date()}
                    placeholder={t("placeholder.birthday")}
                    errors={getFieldError("birthday")}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle>{t("input.country")}</FormTitle>
                {getFieldDecorator("country", {
                  initialValue: selectedCountry,
                  rules: [
                    { required: true, message: t("validation.input.required") }
                  ]
                })(
                  <Select
                    required
                    placeholder={t('input.country')}
                    options={dataStore.countryOptions}
                    errors={getFieldError('country')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle>{t("input.city")}</FormTitle>
                {getFieldDecorator("city", {
                  initialValue: get(role_object, `info.city_${user.language}`),
                  rules: [
                    {
                      required: true,
                      message: t("validation.input-city.required")
                    }
                  ]
                })(
                  <InputPlacesAutocomplete
                    required
                    autocompletionRequest={{
                      types: ["(cities)"],
                      componentRestrictions: {
                        country: getFieldValue('country') ? getFieldValue('country').value : undefined,
                      }
                    }}
                    placeholder={t("input.city")}
                    errors={getFieldError("city")}
                    extra={`*${t("text.select-city-from-list")}`}
                  />
                )}
              </FormItem>
            </div>
          </div>
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={30} isActive={selectedCitizenship}>
                  {t("label.citizenship")}
                </FormTitle>
                {getFieldDecorator("citizenship", {
                  initialValue: selectedCitizenship
                })(
                  <Select
                    placeholder={t('label.citizenship')}
                    options={dataStore.countryOptions}
                    errors={getFieldError('citizenship')}
                  />
                )}
              </FormItem>
            </div>
          </div>
          <div className={formStyles.formTitle}>{t("title.about-yourself")}</div>
          <div className={formStyles.formSeparator} />

          <div className={formStyles.formGrid20_0}>
            <FormItem styles={{ marginBottom: 0, width: "100%" }}>
              <FormTitle
                progress={10}
                isActive={get(role_object, "about.body")}
              >
                {t("label.about-myself")}
              </FormTitle>
              {getFieldDecorator("about", {
                initialValue: get(role_object, "about.body")
              })(
                <Textarea
                  name="last_name"
                  placeholder={t("label.about-myself")}
                  errors={getFieldError("about")}
                  symbolsCount={1000}
                />
              )}
            </FormItem>
          </div>

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle progress={10} isActive={dataHobbies.length}>
                  {t("label.hobbies-and-pastime")}
                </FormTitle>
                {getFieldDecorator("hobbies", {
                  initialValue: dataHobbies
                })(
                  <MultiSelect
                    isCreatable
                    maxCount={20}
                    placeholder={t("label.hobbies")}
                    errors={getFieldError("hobbies")}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle
                  progress={10}
                  isActive={get(role_object, "health.body")}
                >
                  {t("label.health")}
                </FormTitle>
                {getFieldDecorator("health", {
                  initialValue: get(role_object, "health.body")
                })(
                  <Input
                    name="health"
                    placeholder={t("label.health")}
                    errors={getFieldError("health")}
                    extra={t("text.health-help")}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formTitle}>{t("title.area-of-work")}</div>
          <div className={formStyles.formSeparator} />

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle
                  progress={10}
                  isActive={dataPlayAgeStart || dataPlayAgeEnd}
                >
                  {t("label.game-age")}
                </FormTitle>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 10 }}>
                    {getFieldDecorator("play_age_start", {
                      initialValue: dataPlayAgeStart
                    })(
                      <Select
                        placeholder={t("input.from")}
                        options={playAgeStartRange}
                        errors={getFieldError("play_age_start")}
                      />
                    )}
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    {getFieldDecorator("play_age_end", {
                      initialValue: dataPlayAgeEnd
                    })(
                      <Select
                        placeholder={t("input.to")}
                        options={playAgeEndRange}
                        errors={getFieldError("play_age_end")}
                      />
                    )}
                  </div>
                </div>
              </FormItem>
              <FormItem>
                <FormTitle
                  progress={30}
                  isActive={get(role_object, "rate.rate_start")}
                >
                  {t("input.shift-rate")}
                </FormTitle>
                <div className={formStyles.currencyRate}>
                  {getFieldDecorator('shift_rate', { initialValue: get(role_object, 'rate.rate_start')})(
                    <InputMask
                      wrapperClassName={formStyles.currencyRateInput}
                      mask={"9999999999"}
                      name="shift_rate"
                      autocomplete="off"
                      errors={getFieldError('shift_rate')}
                    />
                  )}
                  {getFieldDecorator('currency', {
                    initialValue: selectedCurrency,
                  })(
                    <Select
                      wrapperClassName={formStyles.currencyRateSelect}
                      placeholder={t('input.currency')}
                      options={dataStore.currencyOptions}
                      errors={getFieldError('currency')}
                    />
                  )}
                </div>
              </FormItem>
            </div>

            <div className={formStyles.formGrid2}>
              <FormItem>
                {getFieldDecorator("crowd_scene", {
                  initialValue: get(role_object, "crowd_scene")
                })(
                  <Checkbox name="crowd_scene" label={t("input.crowd-scene")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("low_budget", {
                  initialValue: get(role_object, "low_budget")
                })(
                  <Checkbox name="low_budget" label={t("input.low-budget")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("long_trip", {
                  initialValue: get(role_object, "long_trip")
                })(<Checkbox name="long_trip" label={t("input.long-trip")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("model_work", {
                  initialValue: get(role_object, "model_work")
                })(
                  <Checkbox name="model_work" label={t("input.model-work")} />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formTitle}>{t("title.profile-photo")}</div>
          <div className={formStyles.formSeparator} />
          <div className={formStyles.dropzoneWrapper}>
            <div className={formStyles.dropzoneLeft}>
              <FormItem>
                <FormTitle>{t("title.photo")}</FormTitle>
              </FormItem>
              {getFieldDecorator("photo", {
                initialValue: user.avatar,
                rules: [
                  { required: true, message: t("validation.input.required") }
                ]
              })(
                <Dropzone
                  cropedImage={this.state.cropedImage}
                  onCrop={this.handlePhotoCrop}
                  form={form}
                  errors={getFieldError("photo")}
                />
              )}
            </div>
            <div className={formStyles.dropzoneRight}>
              <div className={formStyles.dropzoneManual}>
                <div className={formStyles.dropzoneManualTitle}>
                {t("text.photo-drop-title-talent-1")} <br /> {t("text.photo-drop-title-talent-2")}
                </div>
                <div className={formStyles.dropzoneManualPhotos}>
                  <div className={formStyles.dropzoneManualPhotosLeft}>
                    <div
                      className={formStyles.dropzoneManualPhotoSmall}
                      style={{ backgroundImage: `url(${uploadExample1})` }}
                    ></div>
                    <div
                      className={formStyles.dropzoneManualPhotoSmall}
                      style={{ backgroundImage: `url(${uploadExample2})` }}
                    ></div>
                  </div>
                  <div className={formStyles.dropzoneManualPhotosCenter}>
                    <div
                      className={formStyles.dropzoneManualPhotoLarge}
                      style={{ backgroundImage: `url(${uploadExample5})` }}
                    ></div>
                  </div>
                  <div className={formStyles.dropzoneManualPhotosRight}>
                    <div
                      className={formStyles.dropzoneManualPhotoSmall}
                      style={{ backgroundImage: `url(${uploadExample3})` }}
                    ></div>
                    <div
                      className={formStyles.dropzoneManualPhotoSmall}
                      style={{ backgroundImage: `url(${uploadExample4})` }}
                    ></div>
                  </div>
                </div>
                <div className={formStyles.dropzoneManualList}>
                  {t("text.photo-drop-checklist")}
                </div>
                <div className={formStyles.dropzoneManualItem}>
                  {t("text.photo-drop-checklist-1")}
                </div>
                <div className={formStyles.dropzoneManualItem}>
                  {t("text.photo-drop-checklist-2")}
                </div>
                <div className={formStyles.dropzoneManualItem}>
                  {t("text.photo-drop-checklist-3")}
                </div>
              </div>
            </div>
          </div>

          <div className={formStyles.buttons}>
            <Button className={formStyles.button} kind="primary" type="submit">{t("button.save")}</Button>
            <Button onClick={this.handleSaveAndNext} className={formStyles.buttonLast} kind="bordered" type="button">{t("button.save-and-next-step")}</Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(
  createForm({
    onValuesChange
  })(CommonEdit)
);
