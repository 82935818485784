import React, { Component } from 'react';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { DatePickerInput } from 'rc-datepicker';
import styles from './InputDatepicker.module.scss';
import moment from 'moment';

@inject('user')
@observer
class InputDatepicker extends Component {
  handleChange = (data) => {
    this.props.onChange(moment(data).format('YYYY-MM-DD HH:mm'));
  }

  render() {
    const { extra, minDate, maxDate, placeholder, required, wrapperClassName, value, errors = [] } = this.props;

    return (
      <div
        style={{ marginBottom: extra ? -18 : 0 }}
        className={cn(styles.wrapper, wrapperClassName)}
      >
        <DatePickerInput
          onChange={this.handleChange}
          value={value}
          showOnInputClick={true}
          locale={this.props.user.language}
          minDate={minDate}
          maxDate={maxDate}
          displayFormat="DD.MM.YYYY"
          format="DD.MM.YYYY"
          placeholder={required && placeholder ? placeholder + ' *' : placeholder}
        />
        {extra && (<p className={styles.extra}>{extra}</p>)}
        <p className={styles.errors}>{errors.length > 0 ? errors.join(', ') : null}</p>
      </div>
    )
  }
}

export default InputDatepicker;
