import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Modal from "react-modal";
import closeImage from "../../../images/close-modal-icon.png";

import ProjectForm from "../../pages/Forms/Project";

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 467,
    height: 332
  }
};

class BoardCardCreate extends Component {
  onReject = () => {
    this.props.handleClose();
  };

  onSubmit = values => {
    this.props.handleSubmit(values);
  };

  render() {
    const { isOpen, type, data } = this.props;
    const isEdit = type === "edit";

    const titleTitle = isEdit ? "title.edit-board" : "title.create-board";
    const submitTitle = isEdit ? "button.edit" : "button.create";
    const rejectTitle = "button.reject";

    console.log("data: ", data);

    return (
      <Modal isOpen={isOpen} style={modalStyle} onRequestClose={this.onReject}>
        <ProjectForm
          submit={this.onSubmit}
          titleTitle={titleTitle}
          submitTitle={submitTitle}
          rejectTitle={rejectTitle}
          data={data}
        />

        <img
          style={{
            height: 15,
            width: 15,
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer"
          }}
          onClick={this.onReject}
          src={closeImage}
        />
      </Modal>
    );
  }
}

export default withTranslation()(BoardCardCreate);
