import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import { TrixEditor } from "react-trix";
import Input from '../../components/Input/Input';
import InputFile from '../../components/InputFile/InputFile';
import Textarea from '../../components/Textarea/Textarea';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import InputDatepicker from '../../components/InputDatepicker/InputDatepicker';
import Button from '../../components/Button/Button';
import Dropzone from '../../components/Dropzone/Dropzone';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import get from 'lodash/get';
import moment from 'moment';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class CommonEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { cropedImage: undefined }
  }

  componentDidMount() {
    this.props.dataStore.fetchCompanyServicesList();
  }

  handleSaveAndNext = (e) => {
    this.handleSubmit(e, true);
  }

  handleSubmit = (e, next) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const { role, role_object } = this.props.user.user;
        const isYearChanged = !moment(new Date(values.year)).isSame(moment(new Date(get(role_object, 'year'))), 'day');

        const currentServices = get(role_object, 'company_services');
        const newServices = values.company_services.map(s => {
          const service = currentServices.find(cs => cs.company_service_dictionary_id === s.value);

          return {
            id: service ? service.id : undefined,
            company_service_dictionary_id: s.value
          }
        });
        const removedServices = currentServices.map(s => {
          if (!newServices.find(ns => s.company_service_dictionary_id === ns.company_service_dictionary_id)) { s._destroy = "1"; }
          return s;
        });
        const addedServices = newServices.filter(s => s._destroy !== '1');
        const services = [...removedServices, ...addedServices];

        const isServicesChanged = removedServices.filter(s => s._destroy === '1').length
          || addedServices.filter(s => !s.id).length;

        const importantFields = {
          'avatar': Boolean(this.state.cropedImage),
          'name': get(role_object, 'name') !== values.name,
          'year': isYearChanged,
          'company_services': isServicesChanged
        };

        const moderatedFields = Object.keys(importantFields)
          .filter(key => importantFields[key]);

        const data = {
          company: {
            avatar: this.state.cropedImage,
            name: values.name,
            year: values.year,
            about: values.about,
            actual_address: values.actual_address,
            company_services_attributes: services,
            moderated_fields: moderatedFields,
          },

        };

        this.props.user.update(data, true).then(() => {
          if (next) {
            this.props.navigate({ path: `/app/${role}/edit`, query: { tab: 'portfolio' } }, true);
          }
        });
      } else {
        console.log('error', error, values);
      }
    });
  }

  handlePhotoCrop = (cropedImage) => this.setState({ cropedImage });

  handlePresentationSelect = (data) => {
    this.props.user.update({
      company: { media_documents: data }
    }, true);
  }

  handlePresentationRemove = (id) => {
    this.props.user.deleteFile({ file_id: id });
  }

  render() {
    const { user, dataStore, t, form } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const role_object = get(user, 'user.role_object', {});

    const dataCompanyServices = get(role_object, 'company_services').map(s => ({
      value: s.company_service_dictionary_id, label: s[`name_${user.language}`]
    }));

    const presentationAccept = '.ppt, .pptx, .pdf, application/pdf, application/mspowerpoint, application/powerpoint, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/x-mspowerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation';

    return (
      <div className={formStyles.form}>
        <form onSubmit={this.handleSubmit}>
          <div className={formStyles.formTitle}>{t("title.main-information")}</div>
          <div className={formStyles.formSeparator}/>

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.company-name')}</FormTitle>
                {getFieldDecorator('name', {
                  initialValue: get(role_object, 'name'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Input
                    required
                    name="company_name"
                    autocomplete="company_name"
                    placeholder={t('input.company-name')}
                    errors={getFieldError('name')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.actual-address')}</FormTitle>
                {getFieldDecorator('actual_address', {
                  initialValue: get(role_object, 'actual_address'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Input
                    required
                    name="actual_address"
                    autocomplete="actual_address"
                    placeholder={t('input.actual-address')}
                    errors={getFieldError('actual_address')}
                  />
                )}
              </FormItem>
            </div>
          </div>
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.company-reg-date')}</FormTitle>
                {getFieldDecorator('year', {
                  initialValue: get(role_object, 'year'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <InputDatepicker
                    required
                    maxDate={new Date()}
                    placeholder={t('input.company-reg-date')}
                    errors={getFieldError('year')}
                    extra={`*${t("text.date-format")}`}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.company-activity')}</FormTitle>
                {getFieldDecorator('company_services', {
                  initialValue: dataCompanyServices,
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <MultiSelect
                    required
                    options={dataStore.companyServicesOptions}
                    placeholder={t('input.company-activity')}
                    errors={getFieldError('company_services')}
                    extra={t("text.service.extra")}
                  />
                )}
              </FormItem>
            </div>
          </div>
          <div className={formStyles.formTitle}>{t("title.about-company")}</div>
          <div className={formStyles.formSeparator}/>

          <div className={formStyles.formGrid}>
            <FormItem styles={{ width: '100%' }}>
              <FormTitle progress={50} isActive={get(role_object, 'about.body')}>{t('title.who-are-we')}</FormTitle>
              {getFieldDecorator('about', { initialValue: get(role_object, 'about.body') })(
                <TrixEditor placeholder={t('title.who-are-we')} />
              )}
            </FormItem>
          </div>
          <div className={formStyles.formGrid}>
            <FormItem>
              <FormTitle progress={50} isActive={get(role_object, 'media.media_documents[0]')}>{t('label.company-presentation')}</FormTitle>
              <InputFile
                value={get(role_object, 'media.media_documents[0]')}
                accept={presentationAccept}
                onSelect={this.handlePresentationSelect}
                onRemove={this.handlePresentationRemove}
                extra={`${t("text.available-formats")}: pdf, ppt, pptx`}
              />
            </FormItem>
          </div>

          <div className={formStyles.formTitle}>{t("title.your-logo")}</div>
          <div className={formStyles.formSeparator}/>

          <div className={formStyles.dropzoneWrapper}>
            <div className={formStyles.dropzoneLeft}>
              <FormItem>
                <FormTitle>{t("title.photo")}</FormTitle>
              </FormItem>
              {getFieldDecorator('photo', {
                initialValue: user.avatar,
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Dropzone
                  cropedImage={this.state.cropedImage}
                  onCrop={this.handlePhotoCrop}
                  form={form}
                  format={'image/png'}
                  cropAspect={1}
                  errors={getFieldError('photo')}
                />
              )}
            </div>
            <div className={formStyles.dropzoneRight}>
              <div className={formStyles.dropzoneManual}>
                <div className={formStyles.dropzoneManualItem}>{t("text.upload-photo-item-1")} 2x2</div>
                <div className={formStyles.dropzoneManualItem}>{t("text.upload-photo-item-4")}</div>
              </div>
            </div>
          </div>

          <div className={formStyles.buttons}>
            <Button className={formStyles.button} kind="primary" type="submit">{t("button.save")}</Button>
            <Button onClick={this.handleSaveAndNext} className={formStyles.buttonLast} kind="bordered" type="button">{t("button.save-and-next-step")}</Button>
          </div>
        </form>
      </div>
    )
  }
}

export default withTranslation()(createForm()(CommonEdit));
