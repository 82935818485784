import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { createForm } from "rc-form";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import FormItem from "../../components/FormItem/FormItem";
import FormTitle from "../../components/FormTitle/FormTitle";
import get from "lodash/get";

import SkillsLanguageEdit from "../Forms/SkillsLanguageEdit";
import SkillsSportEdit from "../Forms/SkillsSportEdit";
import SkillsInstrumentEdit from "../Forms/SkillsInstrumentEdit";

import formStyles from "../Forms/EditForms.module.scss";

@inject("user", "dataStore", "navigate")
@observer
class SkillsEdit extends Component {
  handleSubmit = (e, next) => {
    e.preventDefault();

    this.props.form.validateFields({},(error, values) => {
      if (!error) {
        const data = {
          talent: {
            special_skill_attributes: {
              agency: values.agency,
              dubbing: values.dubbing,
              voice_acting: values.voice_acting,
              i_model: values.i_model
            },
            vocal_attributes: {
              opera: values.opera,
              folk: values.folk,
              jazz: values.jazz,
              pop: values.pop,
              soprano: values.soprano,
              baritone: values.baritone,
              tenor: values.tenor,
              contralto: values.contralto,
              mezzo_soprano: values.mezzo_soprano,
              bass: values.bass
            },
            cirque_attributes: {
              clown: values.clown,
              acrobat: values.acrobat,
              equilibrist: values.equilibrist,
              juggler: values.juggler,
              magician: values.magician,
              trainer: values.trainer
            },
            dance_attributes: {
              ballroom: values.dance.ballroom,
              folk: values.dance.folk,
              modern: values.dance.modern,
              hip_hop: values.dance.hip_hop,
              ballet: values.dance.ballet,
              step: values.dance.step
            },
            stunt_attributes: {
              horse: values.stunt.horse,
              car: values.stunt.car,
              fight: values.stunt.fight,
              stunt: values.stunt.stunt,
              school: values.stunt.school,
              acrobatic: values.stunt.acrobatic,
              fall_from_height: values.stunt.fall_from_height,
              working_on_cables: values.stunt.working_on_cables,
              burning_man: values.stunt.burning_man,
              parkour: values.stunt.parkour,
              stage_combat: values.stunt.stage_combat,
              fencing: values.stunt.fencing
            },
            vehicle_attributes: {
              car: values.vehicle.car,
              motorcycle: values.vehicle.motorcycle,
              bus: values.vehicle.bus,
              extreme_driving: values.vehicle.extreme_driving,
              riding: values.vehicle.riding
            }
          }
        };

        this.props.user.update(data, true).then(() => {
          if (next) {
            this.props.navigate({ path: `/app/talent/edit`, query: { tab: 'portfolio' } }, true);
          }
        });
      } else {
        console.log("error", error, values);
      }
    });
  };

  componentDidMount() {
    this.props.dataStore.fetchInstrumentsList();
    this.props.dataStore.fetchSportsList();
    this.props.dataStore.fetchLanguagesList();
  }

  handleSaveAndNext = (e) => {
    this.handleSubmit(e, true);
  }

  render() {
    const { user, t, form } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;
    const role_object = get(user, "user.role_object", {});
    const languages = get(role_object, "languages");
    const sports = get(role_object, "sports");
    const instruments = get(role_object, "instruments");

    return (
      <div className={formStyles.form}>
        <form onSubmit={this.handleSubmit}>
          <div className={formStyles.formTitle}>{t("title.special-skills")}</div>
          <div className={formStyles.formSeparator} />

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                {getFieldDecorator("i_model", {
                  initialValue: get(role_object, "special_skill.i_model")
                })(<Checkbox name="i_model" label={t("skill.model")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("dubbing", {
                  initialValue: get(role_object, "special_skill.dubbing")
                })(<Checkbox name="dubbing" label={t("skill.dubbing")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("voice_acting", {
                  initialValue: get(role_object, "special_skill.voice_acting")
                })(<Checkbox name="voice_acting" label={t("skill.scoring")} />)}
              </FormItem>
            </div>
            {getFieldValue("i_model") && (
              <div className={formStyles.formGrid2}>
                <FormItem>
                  <FormTitle>{t("skill.model-school")}</FormTitle>
                  {getFieldDecorator("agency", {
                    initialValue: get(role_object, "special_skill.agency")
                  })(
                    <Input
                      name="agency"
                      placeholder={t("skill.model-school")}
                      errors={getFieldError("agency")}
                    />
                  )}
                </FormItem>
              </div>
            )}
          </div>

          <div className={formStyles.formGrid}>
            <div className={`${formStyles.formGrid2} ${formStyles.formGrid0_30}`}>
              <div className={formStyles.formTitle}>{t("title.vocal")}</div>
              <div className={formStyles.formSeparator} />

              <FormItem>
                {getFieldDecorator("opera", {
                  initialValue: get(role_object, "vocal.opera")
                })(<Checkbox name="opera" label={t("skill.opera-singing")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("pop", {
                  initialValue: get(role_object, "vocal.pop")
                })(<Checkbox name="pop" label={t("skill.pop-singing")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("folk", {
                  initialValue: get(role_object, "vocal.folk")
                })(<Checkbox name="folk" label={t("skill.folk-singing")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("jazz", {
                  initialValue: get(role_object, "vocal.jazz")
                })(<Checkbox name="jazz" label={t("skill.jazz-singing")} />)}
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <div className={formStyles.formTitle}>{t("title.voice-type")}</div>
              <div className={formStyles.formSeparator} />
              <div className={formStyles.formGrid} style={{ marginBottom: 0 }}>
                <div className={formStyles.formGrid2}>
                  <FormItem>
                    {getFieldDecorator("soprano", {
                      initialValue: get(role_object, "vocal.soprano")
                    })(<Checkbox name="soprano" label={t("skill.soprano")} />)}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator("baritone", {
                      initialValue: get(role_object, "vocal.baritone")
                    })(
                      <Checkbox name="baritone" label={t("skill.baritone")} />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator("tenor", {
                      initialValue: get(role_object, "vocal.tenor")
                    })(<Checkbox name="tenor" label={t("skill.tenor")} />)}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator("contralto", {
                      initialValue: get(role_object, "vocal.contralto")
                    })(
                      <Checkbox name="contralto" label={t("skill.contralto")} />
                    )}
                  </FormItem>
                </div>
                <div className={formStyles.formGrid2}>
                  <FormItem>
                    {getFieldDecorator("mezzo_soprano", {
                      initialValue: get(role_object, "vocal.mezzo_soprano")
                    })(
                      <Checkbox
                        name="mezzo_soprano"
                        label={t("skill.mezzo-soprano")}
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator("bass", {
                      initialValue: get(role_object, "vocal.bass")
                    })(<Checkbox name="bass" label={t("skill.bass")} />)}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>

          <div className={formStyles.formGrid}>
            <div className={`${formStyles.formGrid3} ${formStyles.formGrid0_30}`}>
              <div className={formStyles.formTitle}>{t("title.cirque-skills")}</div>
              <div className={formStyles.formSeparator} />
              <FormItem>
                {getFieldDecorator("clown", {
                  initialValue: get(role_object, "cirque.clown")
                })(<Checkbox name="clown" label={t("skill.clownery")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("juggler", {
                  initialValue: get(role_object, "cirque.juggler")
                })(<Checkbox name="juggler" label={t("skill.juggler")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("acrobat", {
                  initialValue: get(role_object, "cirque.acrobat")
                })(<Checkbox name="acrobat" label={t("skill.acrobatics")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("magician", {
                  initialValue: get(role_object, "cirque.magician")
                })(<Checkbox name="magician" label={t("skill.magician")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("equilibrist", {
                  initialValue: get(role_object, "cirque.equilibrist")
                })(
                  <Checkbox name="equilibrist" label={t("skill.equilibrist")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("trainer", {
                  initialValue: get(role_object, "cirque.trainer")
                })(<Checkbox name="trainer" label={t("skill.trainer")} />)}
              </FormItem>
            </div>
            <div className={`${formStyles.formGrid3} ${formStyles.formGrid0_30}`}>
              <div className={formStyles.formTitle}>{t("title.dance-skills")}</div>
              <div className={formStyles.formSeparator} />

              <FormItem>
                {getFieldDecorator("dance.ballroom", {
                  initialValue: get(role_object, "dance.ballroom")
                })(
                  <Checkbox name="dance.ballroom" label={t("skill.ballroom")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("dance.folk", {
                  initialValue: get(role_object, "dance.folk")
                })(<Checkbox name="dance.folk" label={t("skill.folk")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("dance.modern", {
                  initialValue: get(role_object, "dance.modern")
                })(<Checkbox name="dance.modern" label={t("skill.modern")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("dance.hip_hop", {
                  initialValue: get(role_object, "dance.hip_hop")
                })(
                  <Checkbox name="dance.hip_hop" label={t("skill.hip-hop")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("dance.ballet", {
                  initialValue: get(role_object, "dance.ballet")
                })(<Checkbox name="dance.ballet" label={t("skill.ballet")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("dance.step", {
                  initialValue: get(role_object, "dance.step")
                })(<Checkbox name="dance.step" label={t("skill.step")} />)}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <div className={formStyles.formTitle}>{t("title.driving")}</div>
              <div className={formStyles.formSeparator} />
              <FormItem>
                {getFieldDecorator("vehicle.car", {
                  initialValue: get(role_object, "vehicle.car")
                })(<Checkbox name="vehicle.car" label={t("skill.car")} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("vehicle.motorcycle", {
                  initialValue: get(role_object, "vehicle.motorcycle")
                })(
                  <Checkbox
                    name="vehicle.motorcycle"
                    label={t("skill.motorcycle")}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("vehicle.bus", {
                  initialValue: get(role_object, "vehicle.bus")
                })(
                  <Checkbox
                    name="vehicle.bus"
                    label={t("skill.oversized-transport")}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("vehicle.extreme_driving", {
                  initialValue: get(role_object, "vehicle.extreme_driving")
                })(
                  <Checkbox
                    name="vehicle.extreme_driving"
                    label={t("skill.extreme-driving")}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("vehicle.riding", {
                  initialValue: get(role_object, "vehicle.riding")
                })(
                  <Checkbox
                    name="vehicle.riding"
                    label={t("skill.horseback-riding")}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formTitle}>{t("title.stunt-skills")}</div>
          <div className={formStyles.formSeparator} />

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem styles={{ marginBottom: 25 }}>
                {getFieldDecorator("stunt.stunt", {
                  initialValue: get(role_object, "stunt.stunt")
                })(<Checkbox name="stunt.stunt" label={t("skill.stuntman")} />)}
              </FormItem>
              {getFieldValue("stunt.stunt") && (
                <FormItem>
                  <FormTitle>{t("skill.stunt-school")}</FormTitle>
                  {getFieldDecorator("stunt.school", {
                    initialValue: get(role_object, "stunt.school")
                  })(
                    <Input
                      name="stunt.school"
                      placeholder={t("skill.stunt-school")}
                      errors={getFieldError("stunt.school")}
                    />
                  )}
                </FormItem>
              )}
            </div>
            {getFieldValue("stunt.stunt") && (
              <div className={formStyles.formGrid3}>
                <FormItem>
                  {getFieldDecorator("stunt.horse", {
                    initialValue: get(role_object, "stunt.horse")
                  })(
                    <Checkbox
                      name="stunt.horse"
                      label={t("skill.horse-stunts")}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("stunt.car", {
                    initialValue: get(role_object, "stunt.car")
                  })(
                    <Checkbox name="stunt.car" label={t("skill.auto-tricks")} />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("stunt.acrobatic", {
                    initialValue: get(role_object, "stunt.acrobatic")
                  })(
                    <Checkbox
                      name="stunt.acrobatic"
                      label={t("skill.acrobatics")}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("stunt.fight", {
                    initialValue: get(role_object, "stunt.fight")
                  })(
                    <Checkbox
                      name="stunt.fight"
                      label={t("skill.battle-scenes")}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("stunt.fall_from_height", {
                    initialValue: get(role_object, "stunt.fall_from_height")
                  })(
                    <Checkbox
                      name="stunt.fall_from_height"
                      label={t("skill.falling-from-height")}
                    />
                  )}
                </FormItem>
              </div>
            )}
            {getFieldValue("stunt.stunt") && (
              <div className={formStyles.formGrid3}>
                <FormItem>
                  {getFieldDecorator("stunt.working_on_cables", {
                    initialValue: get(role_object, "stunt.working_on_cables")
                  })(
                    <Checkbox
                      name="stunt.working_on_cables"
                      label={t("skill.cable-work")}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("stunt.burning_man", {
                    initialValue: get(role_object, "stunt.burning_man")
                  })(
                    <Checkbox
                      name="stunt.burning_man"
                      label={t("skill.burning-man")}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("stunt.parkour", {
                    initialValue: get(role_object, "stunt.parkour")
                  })(
                    <Checkbox name="stunt.parkour" label={t("skill.parkour")} />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("stunt.stage_combat", {
                    initialValue: get(role_object, "stunt.stage_combat")
                  })(
                    <Checkbox
                      name="stunt.stage_combat"
                      label={t("skill.stage-battle")}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("stunt.fencing", {
                    initialValue: get(role_object, "stunt.fencing")
                  })(
                    <Checkbox name="stunt.fencing" label={t("skill.fencing")} />
                  )}
                </FormItem>
              </div>
            )}
          </div>
        </form>

        <div className={formStyles.formGrid}>
          <div style={{ flexGrow: 1, flexShrink: 1, flexBasis: 100 }}>
            <div className={formStyles.formTitle}>{t("title.music-skills")}</div>
            <div className={formStyles.formSeparator} />

            {instruments.map(instrument => (
              <SkillsInstrumentEdit key={instrument.id} data={instrument} />
            ))}

            <SkillsInstrumentEdit />
          </div>
        </div>

        <div className={formStyles.formGrid}>
          <div style={{ flexGrow: 1, flexShrink: 1, flexBasis: 100 }}>
            <div className={formStyles.formTitle}>{t("title.sport-skills")}</div>
            <div className={formStyles.formSeparator} />

            {sports.map(sport => (
              <SkillsSportEdit key={sport.id} data={sport} />
            ))}

            <SkillsSportEdit />
          </div>
        </div>

        <div className={formStyles.formGrid}>
          <div style={{ flexGrow: 1, flexShrink: 1, flexBasis: 100 }}>
            <div className={formStyles.formTitle}>
              {t("title.languages-skills")}
            </div>
            <div className={formStyles.formSeparator} />

              {languages.map(l => (
                <SkillsLanguageEdit key={l.id} data={l} />
              ))}

            <SkillsLanguageEdit />
          </div>
        </div>

        <div className={formStyles.buttons}>
          <Button onClick={e => this.handleSubmit(e)} className={formStyles.button} kind="primary" type="submit">{t("button.save")}</Button>
          <Button onClick={this.handleSaveAndNext} className={formStyles.buttonLast} kind="bordered" type="button">{t("button.save-and-next-step")}</Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(createForm()(SkillsEdit));
