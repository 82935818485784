import React, { Component } from "react";
import cn from 'classnames';
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import EduTextCard from "../../components/EduTextCard/EduTextCard";
import brainIcon from "../../../images/brain-icon.png";

import styles from './EducationAnnounces.module.scss';
import cabinetStyles from '../Cabinet/Cabinet.module.scss';
import sortBy from "lodash/sortBy";


@inject("user", "navigate")
@observer
class EducationAnnounces extends Component {
  handleOpenTutorial = (type, id) => {
    const { user} = this.props;
    const path = `/app/${user.user.role}/education/${type}/${id}`;

    this.props.navigate({ path });
  }

  render() {
    const { t, isUnderModeration, texts } = this.props;

    const sortedTutorials = sortBy(texts, "created_at");

    return (
      <div className={cn(styles.pageGrid, styles.tutorialBlock)}>
        {isUnderModeration && <div style={{ borderRadius: 10 }} className={cabinetStyles.overlay}>
          <p>{t("text.wait-for-moderation")}</p>
        </div>}
        <div className={styles.pageGrid5}>
          {sortedTutorials.length ? (
            <div className={styles.textEduList}>
              {sortedTutorials.slice(-6).reverse().map(t => (
                <EduTextCard key={t.id} data={t} />
              ))}
            </div>
          ) : (
            <div style={{ minHeight: 320 }} className={cabinetStyles.blockBodyEmpty}>
              <img src={brainIcon} alt="" />
              <p>{t("text.no-tutotials")}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(EducationAnnounces);
