import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Header, Footer, Loader, BurgerMenu } from '../../components';
import { NotificationContainer } from 'react-notifications';
import burgerImage from '../../../images/burger.png';
import styles from './AuthLayout.module.scss';


@inject('navigate', 'user')
@observer
class AuthLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: props.user.isAuthenticated,
      isMenuOpen: false,
    }
  }

  componentDidMount() {
    let isFromAuthPage = true;

    if (this.props.withoutRedirect) {
      isFromAuthPage = false;
    }

    if (!this.state.isAuthenticated) {
      this.props.user.fetchProfile(isFromAuthPage);
    }
  }

  handleLoginClick = () => {
    this.props.navigate({ path: '/app/signin' });
  }

  handleLogoutClick = () => {
    this.props.user.logout();
  }

  handleSwitchLanguage = () => {
    this.props.user.changeLanguageBD(null);
    this.props.user.changeLanguage(this.props.i18n);
  }

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true });
  }

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  }

  render() {
    const { user, t, backgroundImage } = this.props;

    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <div className={styles.inner}>
            <Header />
            {this.props.children}
          </div>
          <div style={{ backgroundImage: `url(${backgroundImage})`}} className={styles.bg}>
            <div className={styles.info}>
              {user.isAuthenticated ? (
                <div onClick={this.handleLogoutClick} className={styles.signinButton}>{t('button.logout')}</div>
              ) : (
                <div onClick={this.handleLoginClick} className={styles.signinButton}>{t('button.signin')}</div>
              )}
              <div
                onClick={this.handleSwitchLanguage}
                className={styles.langSwitcher}
              >{user.language === 'ru' ? 'en' : 'ru'}</div>
              <img onClick={this.handleMenuOpen} src={burgerImage} className={styles.burger} />
              <BurgerMenu onClose={this.handleMenuClose} isOpen={this.state.isMenuOpen}/>
            </div>
          </div>
        </div>
        <NotificationContainer/>
        <Footer />
        <Loader isLoading={user.isLoading || user.isLightLoading} />
      </div>
    );
  }
}

export default withTranslation()(AuthLayout);
