import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Input from '../../components/Input/Input';
import InputPhone from '../../components/InputPhone/InputPhone';
import InputMaskFacebook from '../../components/InputMask/InputMaskFacebook';
import InputMaskInstagram from '../../components/InputMask/InputMaskInstagram';
import InputMaskVK from '../../components/InputMask/InputMaskVK';
import Button from '../../components/Button/Button';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import get from 'lodash/get';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'navigate')
@observer
class ContactsEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          company: {
            phones_attributes: [{
              id: get(this.props.user.user.role_object, 'phones[0].id'),
              number: values.phone_number
            }],
            email: values.email,
            website: values.website,
            fb: values.fb,
            instagram: values.instagram,
            vk: values.vk,
            agent_first_name: values.agent_first_name,
            agent_second_name: values.agent_second_name,
            agent_middle_name: values.agent_middle_name,
            agent_email: values.agent_email,
            agent_position: values.agent_position,
            agent_nums_attributes: [{
              id: get(this.props.user.user.role_object, 'agent_nums[0].id'),
              number: values.agent_phone
            }],
           }
        };

        this.props.user.update(data, true);
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { user, t, form } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const role_object = get(user, 'user.role_object', {});
    const isSocialLinksFilled = get(role_object, 'instagram') && get(role_object, 'fb') && get(role_object, 'vk') && get(role_object, 'website');

    return (
      <div className={formStyles.form}>
        <form onSubmit={this.handleSubmit}>
          <div className={formStyles.formTitle}>
            <span>{t("title.company-contacts")}</span>
            <FormTitle progress={50} isActive={isSocialLinksFilled}></FormTitle>
          </div>
          <div className={formStyles.formSeparator}/>

          <div className={formStyles.formGrid20_0}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('label.phone-number')}</FormTitle>
                {getFieldDecorator('phone_number', {
                  initialValue: get(role_object, 'phones[0].number'),
                  rules: [
                    { required: true, message: t('validation.phone.required') },
                  ]
                })(
                  <InputPhone
                    required
                    locale={this.props.user.language}
                    placeholder={t('label.phone-number')}
                    errors={getFieldError('phone_number')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.email')}</FormTitle>
                {getFieldDecorator('email', {
                  initialValue: get(role_object, 'email'),
                  rules: [
                    { type: 'email', message: t('validation.email.notvalid') },
                    { required: true, message: t('validation.email.required') },
                  ]
                })(
                  <Input
                    required
                    name="email"
                    type="email"
                    autocomplete="email"
                    placeholder={t('input.email')}
                    errors={getFieldError('email')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>Instagram</FormTitle>
                <InputMaskInstagram form={form} initialValue={get(role_object, 'instagram')} />
              </FormItem>
              <FormItem>
                <FormTitle>Facebook</FormTitle>
                <InputMaskFacebook form={form} initialValue={get(role_object, 'fb')} />
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>VK</FormTitle>
                <InputMaskVK form={form} initialValue={get(role_object, 'vk')} />
              </FormItem>
              <FormItem>
                <FormTitle>{t('label.site')}</FormTitle>
                {getFieldDecorator('website', { initialValue: get(role_object, 'website') })(
                  <Input
                    name="site"
                    placeholder={t('label.site')}
                    errors={getFieldError('website')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formTitle}>
            <span>{t("title.representative-contacts")}</span>
            <FormTitle progress={50} isActive={isSocialLinksFilled}></FormTitle>
          </div>
          <div className={formStyles.formSeparator}/>

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.agent-first-name')}</FormTitle>
                {getFieldDecorator('agent_first_name', {
                  initialValue: get(role_object, 'agent_first_name'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Input
                    required
                    name="agent_first_name"
                    placeholder={t('input.agent-first-name')}
                    errors={getFieldError('agent_first_name')}
                  />
                )}
              </FormItem>
              <FormItem>
                <FormTitle>{t('input.agent-second-name')}</FormTitle>
                {getFieldDecorator('agent_second_name', {
                  initialValue: get(role_object, 'agent_second_name'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Input
                    required
                    name="agent_second_name"
                    placeholder={t('input.agent-second-name')}
                    errors={getFieldError('agent_second_name')}
                  />
                )}
              </FormItem>
              <FormItem>
                <FormTitle>{t('input.agent-middle-name')}</FormTitle>
                {getFieldDecorator('agent_middle_name', {
                  initialValue: get(role_object, 'agent_middle_name'),
                })(
                  <Input
                    name="agent_middle_name"
                    placeholder={t('input.agent-middle-name')}
                    errors={getFieldError('agent_middle_name')}
                  />
                )}
              </FormItem>
            </div>

            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.agent-position')}</FormTitle>
                {getFieldDecorator('agent_position', {
                  initialValue: get(role_object, 'agent_position'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Input
                    name="agent_position"
                    autocomplete="off"
                    placeholder={t('input.agent-position')}
                    errors={getFieldError('agent_position')}
                  />
                )}
              </FormItem>
              <FormItem>
                <FormTitle>{t('input.agent-phone')}</FormTitle>
                {getFieldDecorator('agent_phone', {
                  initialValue: get(role_object, 'agent_nums[0].number'),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <InputPhone
                    required
                    locale={this.props.user.language}
                    placeholder={t('input.agent-phone')}
                    errors={getFieldError('agent_phone')}
                  />
                )}
              </FormItem>
              <FormItem>
                <FormTitle>{t('input.agent-email')}</FormTitle>
                {getFieldDecorator('agent_email', {
                  initialValue: get(role_object, 'agent_email'),
                  rules: [
                    { type: 'email', message: t('validation.email.notvalid') },
                    { required: true, message: t('validation.input.required') }
                  ]
                })(
                  <Input
                    required
                    type="email"
                    name="agent_email"
                    placeholder={t('input.agent-email')}
                    errors={getFieldError('agent_email')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <Button kind="primary" type="submit">{t("button.save")}</Button>
        </form>
      </div>
    )
  }
}

export default withTranslation()(createForm()(ContactsEdit));
