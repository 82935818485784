import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import Button from '../Button/Button';
import moment from 'moment';
import get from 'lodash/get';

import styles from './ModalInfo.module.scss';

import ModalInfo from './ModalInfo';
import Modal from "react-modal";
import ad_styles from "../Advertising/Advertising.module.scss";
import closeImage from "../../../images/close-modal-icon.png";

@inject("user", "staff", "talent", "company", "dataStore")
@observer
class ModalVacancyInvitation extends Component {
  handleAcceptClick = () => {
    const { role } = this.props.user.user;

    this.props.user.setActiveInvitation(this.props.data, 'approved')
    this.props[role].approveInvitation(this.props.data.id)
      .then(() => this.props.onClose())
      .then(() =>  this.props.user.toggleInvModal());
  }

  handleRefuseClick = () => {
    const { role } = this.props.user.user;

    this.props.user.setActiveInvitation(this.props.data, 'refused')
    this.props[role].refuseInvitation(this.props.data.id)
      .then(() => this.props.onClose())
      .then(() =>  this.props.user.toggleInvModal());
  }

  handleDownloadDocument = () => {
    const { name, path } = this.props.data.document;
    this.props.user.downloadFile(name, path);
  }

  handleAdvertisingToggleButton = () => {
    this.props.user.toggleInvModal();
  };

  renderModalContent = () => {
    const { t, data } = this.props;

    return (
      <div className={ad_styles.modalContentWrapper}>
        <img
          src={closeImage}
          onClick={this.handleAdvertisingToggleButton}
          className={ad_styles.closeIcon}
        />
        <p className={ad_styles.modalTitle}>{data.participant_status && t(`title.adv-sent-${data.participant_status}`)}</p>
        { data.participant_status === 'approved' &&
        <p>{t("text.adv-req-on-review-author-notified")}</p>
        }
      </div>
    );
  };

  render() {
    const { t, isOpen, data, staff, company, dataStore, isOwner, user } = this.props;

    const startDate = moment(data.start_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const endDate = moment(data.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY');

    const dataArray = [
      { label: t("label.project"), value: `${dataStore.getMovieTypeById(data.projekt_type)} ${get(data, 'projekt_name')}` },
      { label: t("label.producer"), value: data.director ? data.director : '–' },
      {
        label: t("label.about-project"),
        value: data.document ? (<Button onClick={this.handleDownloadDocument}  size="small" kind="primary">{t('button.download')}</Button>) : '–' },
      { label: t("label.invite-position"), value: data.target ? data.target : '–' },
      { label: t("input.shift-rate"), value: data.rate ? `${data.rate} ${data.currency}` : '–' },
      { label: t("label.date"), value: data.start_date ? `${startDate} – ${endDate}` : '–' },
      { label: t("label.address"), value: data.place ? data.place : '–' },
      { label: t("label.description"), value: data.about ? data.about : '–' },
      {
        label: t("label.author"),
        value: (
          <>
            <p>{data.sender_first_name} {data.sender_last_name}</p>
            <p>{data.phone}</p>
            <p>{data.email}</p>
          </>
        )
      }
    ];

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 545,
        maxHeight: 288
      }
    };

    return (
      <>
        <ModalInfo
          title={t('label.vacancy-invitation')}
          isOpen={isOpen}
          dataArray={dataArray}
          data={data}
          isUpdating={staff.isUpdating || company.isUpdating}
          onClose={this.props.onClose}
          status={(
            <span className={styles.titleStatus}>
              {data.participant_status === 'approved' && t("label.accepted")}
              {data.participant_status === 'refused' && t("label.refused")}
            </span>
          )}
        >
          {!isOwner && (
            data.participant_status === 'waiting' && (
              <div className={styles.buttons}>
                <Button className={styles.buttonFirst} onClick={this.handleAcceptClick}>{t('button.accept')}</Button>
                <Button kind="bordered" onClick={this.handleRefuseClick}>{t('button.refuse')}</Button>
              </div>
            )
          )}
        </ModalInfo>

        { data.participant_status !== 'waiting' &&
          <Modal
            isOpen={user.isInvModalOpen}
            style={modalStyle}
            onRequestClose={this.handleAdvertisingToggleButton}
          >
            {this.renderModalContent()}
          </Modal>
        }
      </>
    );
  }
}

export default withTranslation()(ModalVacancyInvitation);
