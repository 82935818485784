import React from "react";
import { Collapse } from "react-collapse";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import get from 'lodash/get';

import SearchRangeInput from './components/SearchRangeInput';
import SearchMultiselect from './components/SearchMultiselect';

import styles from "./Search.module.scss";
import SearchInputsRange from "../../components/SearchInputsRange/SearchInputsRange";

@inject("user", "dataStore")
@observer
class DimensionSearchBlock extends React.Component {
  handleCollapse = () => this.props.toggleCollapse('sizes');

  render() {
    const { user, form, isOpen, t, dataStore, queries = {} } = this.props;
    const { searchAggregations } = user;
    const { getFieldDecorator, getFieldError } = form;

    const {
      chestGirthRange, waistGirthRange, hipGirthRange,
      headCircumferenceRange, clothingSizeOptions, shoeSizeOptions,
    } = dataStore;

    const aggrFilter = (field, options) => options
      .filter(o => get(searchAggregations, field, []).map(c => c.key).includes(o.value)
    );

    const filteredClothingSizeUp = aggrFilter('clothing_size_up', clothingSizeOptions);
    const filteredClothingSizeBottom = aggrFilter('clothing_size_bottom', clothingSizeOptions);
    const filteredShoeSize = aggrFilter('shoe_size', shoeSizeOptions);

    const formProps = { form, queries };

    return (
      <div className={styles.collapseWrapper}>
        <div
          onClick={this.handleCollapse}
          className={cn(styles.collapseHeader, {
            [styles.collapseHeaderActive]: isOpen
          })}
        >
          {t("title.sizes")}
        </div>
        <Collapse isOpened={isOpen}>
          <div className={styles.collapseBody}>

            <SearchInputsRange {...formProps} title="label.chest-girth" fieldsNames={['chest_girth_from', 'chest_girth_to']}/>
            <SearchInputsRange {...formProps} title="label.waist-girth" fieldsNames={['waist_girth_from', 'waist_girth_to']}/>
            <SearchInputsRange {...formProps} title="label.hip-girth" fieldsNames={['hip_girth_from', 'hip_girth_to']}/>
            <SearchInputsRange {...formProps} title="label.head-circumference" fieldsNames={['head_circumference_from', 'head_circumference_to']}/>

            <SearchMultiselect
              {...formProps}
              title="label.clothing-size-up"
              field="clothing_size_up"
              options={filteredClothingSizeUp}
            />

            <SearchMultiselect
              {...formProps}
              title="label.clothing-size-bottom"
              field="clothing_size_bottom"
              options={filteredClothingSizeBottom}
            />

            <SearchMultiselect
              {...formProps}
              title="label.shoe-size"
              field="shoe_size"
              options={filteredShoeSize}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}
export default withTranslation()(DimensionSearchBlock);
