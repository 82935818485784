import React, { Component } from 'react';
import loaderImage from '../../../images/loader.gif';
import styles from './Loader.module.scss';

export default class Loader extends Component {
  render() {
    if (this.props.isLoading || this.props.isLightLoading) {
      if (this.props.isAbsolute) {
        return (
          <div className={styles.overlayAbsolute}>
            <img className={styles.loaderAbsolute} src={loaderImage} alt="Loading..." />
          </div>
        );
      }

      return (
        <div className={styles.overlay}>
          <img className={styles.image} src={loaderImage} alt="Loading..." />
        </div>
      );
    }

    return null;
  }
}
