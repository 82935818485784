import React, { Component } from 'react';
import cn from 'classnames';
import isNull from 'lodash/isNull';
import styles from './Input.module.scss';

export default class Input extends Component {
  render() {
    const { extra, type = 'text', min, max, name, autoFocus, hidden, required, placeholder, autocomplete, wrapperClassName, className, onChange, onBlur, onKeyPress, cleanValue, errors = [] } = this.props;

    const value = cleanValue ? '' : this.props.value

    return (
      <div
        style={{ marginBottom: extra ? -18 : 0 }}
        className={cn(styles.wrapper, wrapperClassName)}
      >
        <input
          name={name}
          type={type}
          value={isNull(value) ? '' : value}
          hidden={hidden}
          placeholder={required ? placeholder + ' *' : placeholder}
          autoComplete={autocomplete}
          className={cn(styles.input, className, this.props.size === 'small' && styles.inputSmall)}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          autoFocus={autoFocus}
          min={min}
          max={max}
        />
        {extra && (<p className={styles.extra}>{extra}</p>)}
        <p className={styles.errors}>{errors.length > 0 ? errors.join(', ') : null}</p>
      </div>
    )
  }
}
