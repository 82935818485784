import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputMaskFacebook from '../../components/InputMask/InputMaskFacebook';
import InputMaskInstagram from '../../components/InputMask/InputMaskInstagram';
import InputMaskVK from '../../components/InputMask/InputMaskVK';
import InputPhone from '../../components/InputPhone/InputPhone';
import FormItem from '../../components/FormItem/FormItem';
import Checkbox from '../../components/Checkbox/Checkbox';
import { withTranslation } from 'react-i18next';
import isUndefined from 'lodash/isUndefined';


import styles from './TalentSignUpStepper.module.scss';

@inject('user', 'navigate')
@observer
class Step3 extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          talent:{
            phones_attributes: [{ number: values.phone_number }],
            website: values.website,
            fb: values.fb,
            instagram: values.instagram,
            vk: values.vk,
            kinopoisk: values.kinopoisk,
            imdb: values.imdb,
            kinolift: values.kinolift,
            agent_full_name: values.agent_full_name,
            agent_email: values.agent_email,
            agency: values.agency,
            only_agent: values.only_agent,
            agent_nums_attributes: [{ number: values.agent_phone }]
           }
        };

        this.props.user.update(data);
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, form, user } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;
    const haveAgent = isUndefined(getFieldValue('only_agent')) ? true : getFieldValue('only_agent');

    return (
      <form onSubmit={this.handleSubmit}>
        <FormItem>
          {getFieldDecorator('only_agent', { initialValue: true })(
            <Checkbox name="only_agent" label={t('input.have-agent')} />
          )}
        </FormItem>
        {haveAgent && <p className={styles.rateTitle}>{t("title.agent-contacts")}</p>}
        <div className={styles.formGroup}>
          <div className={styles.formGroupItem}>
            {haveAgent && <FormItem>
              {getFieldDecorator('agent_phone', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <InputPhone
                  required
                  locale={this.props.user.language}
                  placeholder={t('input.agent-phone')}
                  errors={getFieldError('agent_phone')}
                />
              )}
            </FormItem>}
            {haveAgent && <FormItem>
              {getFieldDecorator('agent_full_name', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="agent_full_name"
                  placeholder={t('input.agent-fio')}
                  errors={getFieldError('agent_full_name')}
                />
              )}
            </FormItem>}
          </div>
          <div className={styles.formGroupItem}>
            {haveAgent && <FormItem>
              {getFieldDecorator('agent_email', {
                initialValue: '',
                rules: [
                  { type: 'email', message: t('validation.email.notvalid') },
                  { required: true, message: t('validation.input.required') }
                ]
              })(
                <Input
                  required
                  type="email"
                  name="agent_email"
                  placeholder={t('input.agent-email')}
                  errors={getFieldError('agent_email')}
                />
              )}
            </FormItem>}
            {haveAgent && <FormItem>
              {getFieldDecorator('agency', {
                initialValue: ''
              })(
                <Input
                  name="agency"
                  placeholder={t('input.agency-name')}
                  errors={getFieldError('agency')}
                />
              )}
            </FormItem>}
          </div>
        </div>
        <p className={styles.rateTitle}>{t("title.your-contacts")}</p>
        <div className={styles.formGroup}>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('phone_number', {
                initialValue: user.user.phone || '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <InputPhone
                  required
                  locale={this.props.user.language}
                  placeholder={t('input.your-mobile-phone')}
                  errors={getFieldError('phone_number')}
                />
              )}
            </FormItem>
            <FormItem>
              <InputMaskFacebook form={form} initialValue={''} />
            </FormItem>
            <FormItem>
              <InputMaskInstagram form={form} initialValue={''} />
            </FormItem>
            <FormItem>
              {getFieldDecorator('kinopoisk', { initialValue: '' })(
                <Input
                  name="kinopoisk"
                  placeholder={t('input.your-kinopoisk-profile')}
                  errors={getFieldError('kinopoisk')}
                />
              )}
            </FormItem>
          </div>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('website', { initialValue: '' })(
                <Input
                  name="website"
                  placeholder={t('input.your-site')}
                  errors={getFieldError('website')}
                />
              )}
            </FormItem>
            <FormItem>
              <InputMaskVK form={form} initialValue={''} />
            </FormItem>
            <FormItem>
              {getFieldDecorator('imdb', { initialValue: '' })(
                <Input
                  name="imdb"
                  placeholder={t('input.your-imdb-profile')}
                  errors={getFieldError('imdb')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('kinolift', { initialValue: '' })(
                <Input
                  name="kinolift"
                  placeholder={t('input.your-kinolift-profile')}
                  errors={getFieldError('kinolift')}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            kind="primary"
            type="submit"
            className={styles.button}
          >{t('button.next')}</Button>
        </div>
      </form>
    )
  }
}


export default withTranslation()(createForm()(Step3));
