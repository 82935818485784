import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { FormTitle, Button } from '../../components';
import get from 'lodash/get';

import PortfolioProjectEdit from './PortfolioProjectEdit';
import PortfolioAwardEdit from '../Forms/PortfolioAwardEdit';
import PortfolioClientsEdit from './PortfolioClientsEdit';
import PortfolioPhotoEdit from '../Forms/PortfolioPhotoEdit';
import PortfolioVideoEdit from '../Forms/PortfolioVideoEdit';
import PortfolioAudioEdit from '../Forms/PortfolioAudioEdit';
import PortfolioFilesEdit from '../Forms/PortfolioFilesEdit';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class PortfolioEdit extends Component {
  componentDidMount() {
    this.props.dataStore.fetchMovieTypesList();
    this.props.dataStore.fetchCountriesList();
  }

  handleNextStep = () => {
    this.props.navigate({
      path: `/app/${this.props.user.user.role}/edit`,
      query: { tab: 'contacts' }
    }, true);
  }

  render() {
    const { user, t } = this.props;
    const role_object = get(user, 'user.role_object', {});

    const projects = get(role_object, 'projects');
    const awards = get(role_object, 'awards');
    const clients = get(role_object, 'clients');
    const videoLinks = get(role_object, 'video_links');
    const audios = get(role_object, 'media.audios');
    const photos = get(role_object, 'media.photos');
    const files = get(role_object, 'media.files');

    return (
      <div className={formStyles.form}>
        <div className={formStyles.formTitle}>
          <span>{t("title.projects-do")}</span>
          <FormTitle progress={50} isActive={projects.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>

        {projects.map(data => (
          <PortfolioProjectEdit key={data.id} data={data}/>
        ))}

        <PortfolioProjectEdit/>

        <div className={formStyles.formTitle}>{t("title.awards")}</div>
        <div className={formStyles.formSeparator}/>

        {awards.map(data => (
          <PortfolioAwardEdit key={data.id} data={data}/>
        ))}

        <PortfolioAwardEdit/>

        <div className={formStyles.formTitle}>
          <span>{t("title.our-clients")}</span>
          <FormTitle progress={50} isActive={clients.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>


        {clients.map(data => (
          <PortfolioClientsEdit key={data.id} data={data}/>
        ))}

        <PortfolioClientsEdit/>

        <div className={formStyles.formTitle}>
          <span>{t("title.video")}</span>
          <FormTitle progress={50} isActive={videoLinks.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>

        {videoLinks.map(data => (
          <PortfolioVideoEdit key={data.id} data={data}/>
        ))}

        <PortfolioVideoEdit/>

        <div className={formStyles.formTitle}>
          <span>{t("title.audio")}</span>
          <FormTitle progress={50} isActive={audios.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>
        <p className={formStyles.formDesc}>{t("text.audio-desc")}</p>

        {audios.map(data => (
          <PortfolioAudioEdit key={data.id} data={data}/>
        ))}

        <PortfolioAudioEdit audios={audios}/>


        <div className={formStyles.formTitle}>
          <span>{t("title.images")}</span>
          <FormTitle progress={50} isActive={photos.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>

        <PortfolioPhotoEdit photos={photos}/>


        <div className={formStyles.formTitle}>
          <span>{t("title.files")}</span>
          <FormTitle progress={50} isActive={files.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>

        <PortfolioFilesEdit files={files}/>

        <Button onClick={this.handleNextStep} kind="bordered" type="button">{t("button.next-step")}</Button>
      </div>
    )
  }
}

export default withTranslation()(PortfolioEdit);
