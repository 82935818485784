import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { NotificationContainer } from 'react-notifications';
import UserHeader from '../../components/UserHeader/Header';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import styles from './Layout.module.scss';
import get from 'lodash/get';

@inject('navigate', 'user')
@observer
class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: props.user.isAuthenticated,
    }
  }

  componentDidMount() {
    if (!this.state.isAuthenticated) {
      this.props.user.fetchProfile().then(() => {
        if (!this.props.user.isPublic) {
          this.props.user.checkSecondaryRegistration();
        }
      });
    } else {
      if (!this.props.user.isPublic) {
        this.props.user.checkSecondaryRegistration();
      }
    }
  }

  handleSwitchLanguage = () => {
    this.props.user.changeLanguage(this.props.i18n);
  }

  handleLoginClick = () => {
    this.props.navigate({ path: '/app/signin' });
  }

  handleLogoutClick = () => {
    this.props.user.logout();
  }

  handleLoginClick = () => {
    this.props.navigate({ path: '/app/signin' });
  }

  render() {
    const { user, ctx, t, backgroundImage } = this.props;
    const role = get(user, 'user.role');

    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <div className={styles.inner}>
            {user.isLoaded && (<UserHeader path={ctx.pathname} />)}
            {user.isLoaded && this.props.children}
          </div>
        </div>
        <Footer />
        <NotificationContainer/>
        <Loader isLoading={user.isLoading || user.isUpdating || user.isLightLoading} />
      </div>
    );
  }
}

export default withTranslation()(Layout)
