import UniversalRouter from 'universal-router';
import routes from './routes';

const options = {
  baseUrl: '/app',
  errorHandler(error, context) {
    console.error(error)
    console.info(context)
    return error.status === 404
      ? '<h1>Page Not Found</h1>'
      : '<h1>Oops! Something went wrong</h1>'
  }
};

const router = new UniversalRouter(routes, options);

export default router;
