import React, { Component } from 'react';
import cn from 'classnames';
import ReactTooltip from "react-tooltip";
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import closeImage from '../../../images/close-icon.png';
import styles from './CabinetBurgerMenu.module.scss';

@inject("user")
@observer
class CabinetBurgerMenu extends Component {
  render() {
    const { t, isOpen, tabs, path, user } = this.props;
    const { role } = user.user;

    return isOpen ? (
      <div className={styles.overlay}>
        <div onClick={this.props.onClose} className={styles.close}>
          <img className={styles.closeImage} src={closeImage} alt="Close" />
        </div>
        <div className={styles.menu}>
          {tabs.map((tab) => (
            <a
              key={tab.title}
              href={tab.path}
              onClick={(e) => this.props.onItemClick(e, tab.path, tab.isBlocked)}
              className={cn(
                styles.menuItem,
                (!tab.isBlocked && tab.path !== `/app/${role}` && path.includes(tab.path)) && `${styles.menuItemActive}`,
                (!tab.isBlocked && tab.path === path && tab.path === `/app/${role}`) && `${styles.menuItemActive}`,
                tab.isBlocked && `${styles.menuItemBlocked}`
              )}
            >
              <div
                data-tip={tab.tooltipId}
                data-for={tab.tooltipId}
              >
                {t(tab.title)}
              </div>

              {tab.tooltipTitle && (
                <ReactTooltip
                  className={styles.tooltip}
                  id={tab.tooltipId}
                  place="bottom"
                  effect="solid"
                >
                  <span>{t(tab.tooltipTitle)}</span>
                </ReactTooltip>
              )}
            </a>
          ))}
          <span className={styles.menuItem} onClick={() => {
            this.props.onLogout()
            this.props.onClose()
          }}>{t('button.logout')}</span>
        </div>
      </div>
    ) : null;
  }
}

export default withTranslation()(CabinetBurgerMenu);
