import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import FormItem from "../../../components/FormItem/FormItem";
import FormTitle from "../../../components/FormTitle/FormTitle";
import Checkbox from "../../../components/Checkbox/Checkbox";
import get from "lodash/get";

class SearchCheckboxGroup extends Component {
  render() {
    const { form, t, queries = {}, title, options } = this.props;
    const { getFieldDecorator } = form;

    return options.length > 0 ? (
      <FormItem styles={{ marginBottom: 30 }}>
        <FormTitle size="small">{t(title)}</FormTitle>
        <div style={{ marginTop: 15 }}>
          {options.map(item => (
            <FormItem key={item.field}>
              {getFieldDecorator(item.field, { initialValue: get(queries, item.field) })(
                <Checkbox size="small" name={item.field} label={t(item.label)} count={item.count} />
              )}
            </FormItem>
          ))}
        </div>
      </FormItem>
    ) : null;
  }
}

export default withTranslation()(SearchCheckboxGroup);
