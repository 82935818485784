import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { createForm } from "rc-form";
import FormItem from "../../components/FormItem/FormItem";
import DropzonePhoto from "../../components/DropzonePhoto/DropzonePhoto";
import get from "lodash/get";

import formStyles from "../Forms/EditForms.module.scss";

@inject("user", "navigate")
@observer
class PortfolioPhotoEdit extends Component {
  handlePhotoSelect = (field, value) => {
    const data = {
      talent: {
        [field]: { data: value },
        moderated_fields: ["photo"]
      }
    };

    this.props.user.update(data, true);
  };

  handlePhotoRemove = id => {
    this.props.user.deleteTalentPhoto({ id });
  };

  renderDropzonePhoto = (data) => {
    const { getFieldError } = this.props.form;
    const { role_object } = this.props.user.user;
    const { title, field, aspect, styles = {} } = data;

    return (
      <div
        className={formStyles.dropzoneAspectWrapper}
        style={{ paddingTop: `${1/(aspect)*100}%`, ...styles }}
      >
        <div className={formStyles.dropzoneAspectInner}>
          <DropzonePhoto
            title={title}
            field={field}
            onChange={this.handlePhotoSelect}
            onRemove={this.handlePhotoRemove}
            form={this.props.form}
            withCrop
            aspect={aspect}
            value={get(role_object, `media.photos.${field}`)}
            errors={getFieldError(field)}
          />
        </div>
      </div>
    );
  }

  render() {
    const { user, t } = this.props;
    const role_object = get(user, 'user.role_object', {});
    const payStatus = get(role_object, 'subscription.status');

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={formStyles.formGrid}>
          <div className={formStyles.photoGrid3}>
            {this.renderDropzonePhoto({
              title: t('gallery.height'),
              field: 'photo_to_height',
              aspect: 8/15
            })}
          </div>
          <div className={formStyles.photoGrid3}>
            {this.renderDropzonePhoto({
              title: t('gallery.smile'),
              field: 'slight_smile',
              aspect: 4/3,
              styles: { marginBottom: `${((15/8)-(3/4)*2)*100}%`}
            })}
            {this.renderDropzonePhoto({
              title: t('gallery.frowning'),
              field: 'frowning',
              aspect: 4/3
            })}
          </div>
          <div className={formStyles.photoGrid3}>
            {this.renderDropzonePhoto({
              title: t('gallery.mouth'),
              field: 'smile_in_the_mouth',
              aspect: 4/3,
              styles: { marginBottom: `${((15/8)-(3/4)*2)*100}%`}
            })}
            {this.renderDropzonePhoto({
              title: t('gallery.positive'),
              field: 'positive_surprise',
              aspect: 4/3
            })}
          </div>
        </div>

        <div className={formStyles.formGrid}>
          <div className={formStyles.photoGrid3}>
            {this.renderDropzonePhoto({
              title: t('gallery.three-quarter'),
              field: 'photo_in_three_quarter',
              aspect: 8/15
            })}
          </div>
          <div className={formStyles.photoGrid3}>
            {this.renderDropzonePhoto({
              title: t('gallery.back'),
              field: 'hands_from_the_back',
              aspect: 4/3,
              styles: { marginBottom: `${((15/8)-(3/4)*2)*100}%`}
            })}
            
            {this.renderDropzonePhoto({
                field: 'small_photo_1',
                aspect: 4/3
            })}
          </div>

          <div className={formStyles.photoGrid3}>
            {this.renderDropzonePhoto({
              title: t('gallery.profile'),
              field: 'profile_photo',
              aspect: 4/3,
              styles: { marginBottom: `${((15/8)-(3/4)*2)*100}%`}
            })}

            {this.renderDropzonePhoto({
              field: 'small_photo_2',
              aspect: 4/3
            })}
          </div>
        </div>

        {(
          <>
            <div className={formStyles.formGrid}>
              <div className={formStyles.photoGrid2}>
                {this.renderDropzonePhoto({
                  field: 'medium_photo_1',
                  aspect: 7/5
                })}
              </div>
              <div className={formStyles.photoGrid2}>
                {this.renderDropzonePhoto({
                  field: 'medium_photo_2',
                  aspect: 7/5
                })}
              </div>
            </div>

            <div className={formStyles.formGrid}>
              <div className={formStyles.photoGrid3}>
                {this.renderDropzonePhoto({
                  field: 'vertical_photo_1',
                  aspect: 2/3
                })}
              </div>
              <div className={formStyles.photoGrid3}>
                {this.renderDropzonePhoto({
                  field: 'vertical_photo_2',
                  aspect: 2/3
                })}
              </div>
              <div className={formStyles.photoGrid3}>
                {this.renderDropzonePhoto({
                  field: 'vertical_photo_3',
                  aspect: 2/3
                })}
              </div>
            </div>

            <div className={formStyles.formGrid}>
              {this.renderDropzonePhoto({
                field: 'big_photo',
                aspect: 13/7
              })}
            </div>

            <div className={formStyles.formGrid}>
              <div className={formStyles.photoGrid2}>
                {this.renderDropzonePhoto({
                  field: 'medium_photo_3',
                  aspect: 7/5
                })}
              </div>
              <div className={formStyles.photoGrid2}>
                {this.renderDropzonePhoto({
                  field: 'medium_photo_4',
                  aspect: 7/5
                })}
              </div>
            </div>
          </>
        )}
      </form>
    );
  }
}

export default withTranslation()(createForm()(PortfolioPhotoEdit));
