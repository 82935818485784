import { observable, flow, computed, action, configure } from "mobx";
import Status from "./store-status";
import api from "../api";

configure({ enforceActions: "observed" });

export default class Talent {
  @observable updatingStatus = Status.DONE;
  @observable castings = [];
  @observable invitations = [];
  @observable crowdings = [];

  @observable castingsStatus = Status.DONE;
  @observable crowdingsStatus = Status.DONE;
  @observable invitationsStatus = Status.DONE;


  constructor(root) {
    this.root = root;
  }

  @computed get isCastingsFetching() {
    return this.castingsStatus === Status.PENDING;
  }

  @computed get isCrowdingsFetching() {
    return this.crowdingsStatus === Status.PENDING;
  }

  @computed get isInvitationsFetching() {
    return this.invitationsStatus === Status.PENDING;
  }

  @computed get isUpdating() {
    return this.updatingStatus === Status.PENDING;
  }

  fetchTalentCastings = flow(function*() {
    this.castingsStatus = Status.PENDING;

    try {
      const { user_id, id } = this.root.user.user.role_object;
      const response = yield api.fetchTalentCastings(user_id, id);

      if (response.status === 200) this.castings = response.data.castings;

      this.castingsStatus = Status.DONE;
    } catch (error) {
      console.log(error);
      this.castingsStatus = Status.ERROR;
    }
  })

  fetchTalentInvitations = flow(function*() {
    this.invitationsStatus = Status.PENDING;

    try {
      const { user_id, id } = this.root.user.user.role_object;
      const response = yield api.fetchTalentInvitations(user_id, id);

      if (response.status === 200) this.invitations = response.data.invitations;

      this.invitationsStatus = Status.DONE;
    } catch (error) {
      console.log(error);
      this.invitationsStatus = Status.ERROR;
    }
  })

  fetchTalentCrowdings = flow(function*() {
    this.crowdingsStatus = Status.PENDING;

    try {
      const { user_id, id } = this.root.user.user.role_object;
      const response = yield api.fetchTalentCrowdings(user_id, id);

      if (response.status === 200) this.crowdings = response.data.crowdings;

      this.crowdingsStatus = Status.DONE;
    } catch (error) {
      console.log(error)
      this.crowdingsStatus = Status.ERROR;
    }
  })

  approveInvitation = flow(function*(id) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id } = this.root.user.user.role_object;
      const response = yield api.approveInvitation(user_id, id);

      if (response.status === 200) {
        this.fetchTalentInvitations();
      }

      this.updatingStatus = Status.DONE;

      return response;
    } catch (error) {
      console.log(error)
      this.updatingStatus = Status.ERROR;
    }
  })

  refuseInvitation = flow(function*(id) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id } = this.root.user.user.role_object;
      const response = yield api.refuseInvitation(user_id, id);

      if (response.status === 200) {
        this.fetchTalentInvitations();
      }

      this.updatingStatus = Status.DONE;

      return response;
    } catch (error) {
      console.log(error)
      this.updatingStatus = Status.ERROR;
    }
  })

  deleteInvitation = flow(function*(id) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id } = this.root.user.user.role_object;
      const response = yield api.deleteInvitation(user_id, id);

      if (response.status === 200) {
        this.fetchTalentInvitations();
      }

      this.updatingStatus = Status.DONE;

      return response;
    } catch (error) {
      console.log(error)
      this.updatingStatus = Status.ERROR;
    }
  })

  acceptFavoriteProjekt = flow(function*(id) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id } = this.root.user.user.role_object;
      const response = yield api.acceptFavoriteProjekt(user_id, id);

      this.updatingStatus = Status.DONE;

      return response;
    } catch (error) {
      console.log(error)
      this.updatingStatus = Status.ERROR;
    }
  })
}
