import qs from "qs";
import isNil from "lodash/isNil";
import pickBy from "lodash/pickBy";

import history from "./history";

/**
 *
 * @param path url main part
 * @param query
 */
export default function({ path = history.location.pathname, query = {} }, next, replace) {
  const newQuery = pickBy(query, (...args) => !isNil(...args));

  const queryString = qs.stringify(newQuery, { addQueryPrefix: true });

  if (replace) {
    history.replace(`${path}${queryString}`);
  } else {
    history.push(`${path}${queryString}`);
  }

  if (next) window.scrollTo(0, 0);
}
