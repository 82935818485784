import React, { Component } from 'react';
import cn from 'classnames';
import styles from './Tag.module.scss';

export default class Tag extends Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const { children, onClick, isClosable } = this.props;

    return (
      <div onClick={this.handleClick} className={cn(styles.tag, { [styles.tagActive]: onClick })}>
        <span>{children}</span>
        {isClosable && (<div onClick={this.handleClose} className={styles.tagClose} />)}
      </div>
    );
  }
}
