import React, { Component } from "react";
import cn from "classnames";
import { DatePickerInput } from "rc-datepicker";
import styles from "./InputTimepicker.module.scss";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "./InputTimepicker.scss";
import "rc-time-picker/assets/index.css";

export default class InputDatepicker extends Component {
  handleChange = data => {
    this.props.onChange(data);
    console.log("data: ", data);
  };

  render() {
    const {
      extra,
      maxDate,
      placeholder,
      required,
      wrapperClassName,
      value,
      errors = []
    } = this.props;

    return (
      <div style={{ marginBottom: extra ? -18 : 0 }}>
        <TimePicker
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
          showSecond={false}
        />
        {extra && <p className={styles.extra}>{extra}</p>}
        <p className={styles.errors}>
          {errors.length > 0 ? errors.join(", ") : null}
        </p>
      </div>
    );
  }
}
