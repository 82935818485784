import React, { Component } from 'react';
import Input from '../Input/Input';
import { withTranslation } from 'react-i18next';
import isURL from 'validator/lib/isURL';

class InputMaskFacebook extends Component {
  render() {
    const { t, form } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    return (
      getFieldDecorator('fb', {
        initialValue: this.props.initialValue,
        rules: [
          {validator(rule, value) {
            if (value && !isURL(value, {
              require_protocol: true,
              protocols: ['http','https'],
              host_whitelist: ['facebook.com', 'web.facebook.com', 'www.facebook.com']
            })) {
              return [new Error(t("validation.input.facebook"))];
            }

            return [];
          }}
        ]
      })(
        <Input
          name="fb"
          placeholder={t('input.your-facebook-profile')}
          errors={getFieldError('fb')}
        />
      )
    )
  }
}

export default withTranslation()(InputMaskFacebook);
