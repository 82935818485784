import { inject, observer } from "mobx-react";
import moment from "moment";
import get from "lodash/get";
import defaultTo from "lodash/defaultTo";

import { withTranslation } from "react-i18next";

import { compose, withProps, lifecycle, withHandlers } from "recompose";

import TutorialView from "../components/TutorialView";

const getVideoId = video => {
  return defaultTo(video, "")
    .split("/")
    .splice(-1)[0];
};

const TutorialViewContainer = compose(
  inject("user", "navigate"),
  observer,
  withProps(({ user, ctx }) => {
    const lang = 'ru';

    const type = get(ctx, "pathname").includes("video") ? "video" : "text";

    const date = moment(get(user, "tutorial.created_at"));

    let formattedDate;
    if (date.isValid()) {
      const createdAt = get(user, "tutorial.created_at");

      formattedDate = `${moment(createdAt).format("DD.MM.YYYY")}`;
    }

    const article = defaultTo(get(user, `tutorial.[${lang}_article]`), "");
    const announcement = defaultTo(get(user, `tutorial.[${lang}_announcement]`), "");

    const category = defaultTo(
      get(user, `tutorial.category[${lang}_name]`),
      ""
    );

    const videoLinks = defaultTo(get(user, `tutorial.video_links`), []);

    const videoSources = videoLinks.map(link => ({
      type: "video",
      sources: [{ src: getVideoId(link.video), provider: "youtube" }]
    }));

    const title = defaultTo(
      get(user, `tutorial.${lang}_title`),
      ""
    );

    const read_time = get(user, "tutorial.read_time", "ru")
    const preview = get(user, "tutorial.preview", "");
    const previewMobile = get(user, "tutorial.preview_mobile", "");

    return {
      isLoading: user.isLoading,
      preview,
      previewMobile,
      title,
      videoSources,
      date: formattedDate,
      category,
      article,
      announcement,
      type,
      read_time
    };

  }),
  withHandlers({
    goBack: () => () => {
      window.history.back();
    }
  }),
  lifecycle({
    componentDidMount() {
      const { ctx, user, type } = this.props;

      const id = get(ctx, "params.id", null);

      if (id) {
        user.fetchTutorial({ id, type });
      }
    }
  }),
  withTranslation()
)(TutorialView);

export default TutorialViewContainer;
