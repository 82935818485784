import React from "react";
import { Collapse } from "react-collapse";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import { Checkbox, FormTitle, FormItem, InputMask } from "../../components"

import get from 'lodash/get';

import SearchRangeInput from './components/SearchRangeInput';
import SearchCheckboxGroup from './components/SearchCheckboxGroup';
import SearchMultiselect from './components/SearchMultiselect';
import SearchInputsRange from "../../components/SearchInputsRange/SearchInputsRange";

import styles from "./Search.module.scss";

@inject("user", "dataStore")
@observer
class CommonSearchBlock extends React.Component {
  handleCollapse = () => this.props.toggleCollapse('common');

  render() {
    const { form, user, dataStore, isOpen, t, queries = {} } = this.props;
    const { searchAggregations } = user;
    const role = queries.role || 'talent';
    const lang = user.language;

    const { getFieldDecorator, getFieldError } = form;

    const { ageRange, countryOptions } = dataStore;

    const formProps = { form, queries };

    const genderAggr = get(searchAggregations, 'gender', []);
    const maleCount = get(genderAggr.find(i => i.key === 'male'), 'doc_count', 0);
    const femaleCount = get(genderAggr.find(i => i.key === 'female'), 'doc_count', 0);

    const statusAggr = get(searchAggregations, 'actor_status', []);
    const amateurCount = get(statusAggr.find(i => i.key === 'amateur'), 'doc_count', 0);
    const professionalCount = get(statusAggr.find(i => i.key === 'professional'), 'doc_count', 0);

    const countryKeys = get(searchAggregations, 'country_code', []).map(c => c.key);
    const filteredCountries = countryOptions.filter(c => countryKeys.includes(c.value));

    const citiesRuOptions = get(searchAggregations, 'city_ru', []).map(c => ({ value: c.key, label: c.key }));
    const citiesEnOptions = get(searchAggregations, 'city_en', []).map(c => ({ value: c.key, label: c.key }));
    const citiesOptions = lang === 'ru' ? citiesRuOptions : citiesEnOptions;

    const fieldCount = field => get(
      get(searchAggregations, field, []).find(i => i.key_as_string === "true"), 'doc_count', 0
    );

    return (
      <div className={styles.collapseWrapper}>
        <div
          onClick={this.handleCollapse}
          className={cn(styles.collapseHeader, {
            [styles.collapseHeaderActive]: isOpen
          })}
        >
          {t("title.common-data")}
        </div>
        <Collapse isOpened={isOpen}>
          <div className={styles.collapseBody}>
            <FormItem styles={{ marginBottom: 25 }}>
              <FormTitle size="small">{t("label.gender")}</FormTitle>

              <div style={{ marginTop: 15 }}>
                <FormItem styles={{ marginRight: 20 }}>
                  {getFieldDecorator("male", { initialValue: get(queries, "male") })(
                    <Checkbox size="small" name="male" label={t("input.male")} count={maleCount}/>)}
                </FormItem>

                <FormItem>
                  {getFieldDecorator("female", { initialValue: get(queries, "female")})(
                    <Checkbox size="small" name="female" label={t("input.female")} count={femaleCount} />)}
                </FormItem>
              </div>
            </FormItem>

            <SearchInputsRange {...formProps} title="label.age" fieldsNames={['age_from', 'age_to']}/>

            {role === 'talent' && (
              <FormItem styles={{ marginBottom: 25 }}>
                <FormTitle size="small">{t("label.actor-status")}</FormTitle>

                <div style={{ marginTop: 15 }}>
                  <FormItem styles={{ marginRight: 20 }}>
                    {getFieldDecorator("amateur", {
                      initialValue: get(queries, "amateur")
                    })(<Checkbox size="small" name="amateur" label={t("label.amateur")} count={amateurCount} />)}
                  </FormItem>

                  <FormItem>
                    {getFieldDecorator("professional", {
                      initialValue: get(queries, "professional")
                    })(<Checkbox size="small" name="professional" label={t("label.professional")} count={professionalCount} />)}
                  </FormItem>
                </div>
              </FormItem>
            )}

            <SearchMultiselect
              {...formProps}
              title="input.country"
              field="country_code"
              options={filteredCountries}
            />

            <SearchMultiselect
              {...formProps}
              title="input.city"
              field="cities"
              options={citiesOptions}
            />

            <SearchCheckboxGroup
              {...formProps }
              title="label.show-who-is-ready"
              options={[
                { field: 'crowd_scene', label: "input.crowd-scene-short", hide: role !== 'talent' },
                { field: 'low_budget', label: role !== 'talent' ? "input.low-budget-short-staff" : "input.low-budget-short" },
                { field: 'long_trip', label: "input.long-trip-short" },
                { field: 'model_work', label: "input.model-work-short", hide: role !== 'talent' },
              ].filter(o => !o.hide).map(o => { o.count = fieldCount(o.field); return o; })}
            />

            <SearchInputsRange {...formProps} title="input.shift-rate" fieldsNames={['rate_from', 'rate_to']}/>
          </div>
        </Collapse>
      </div>
    );
  }
}
export default withTranslation()(CommonSearchBlock);
