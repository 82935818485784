import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import Button from '../Button/Button';
import moment from 'moment';
import get from 'lodash/get';

import styles from './ModalInfo.module.scss';
import ad_styles from "../../components/Advertising/Advertising.module.scss";

import ModalInfo from './ModalInfo';
import Modal from "react-modal";
import closeImage from "../../../images/close-modal-icon.png";
import Loader from "../Loader/Loader";
import ModalSuccess from "./ModalSuccess";
Modal.setAppElement('#app');

@inject("talent", "user", "dataStore")
@observer
class ModalInvitation extends Component {
  handleAcceptClick = () => {
    this.props.user.setActiveInvitation(this.props.data, 'approved')

    this.props.talent.approveInvitation(this.props.data.id)
      .then(() =>  this.props.onClose())
      .then(() =>  this.props.user.toggleInvModal());
  }

  handleRefuseClick = () => {
    this.props.user.setActiveInvitation(this.props.data, 'refused')

    this.props.talent.refuseInvitation(this.props.data.id)
      .then(() =>  this.props.onClose())
      .then(() =>  this.props.user.toggleInvModal());
  }

  handleDeleteClick = () => {
    this.props.user.setActiveInvitation(this.props.data, 'refused')

    this.props.talent.deleteInvitation(this.props.data.id)
      .then(() =>  this.props.onClose())
  }

  handleDownloadDocument = () => {
    const { name, path } = this.props.data.document;
    this.props.user.downloadFile(name, path);
  }

  handleAdvertisingToggleButton = () => {
    this.props.user.toggleInvModal();
  };

  renderModalContent = () => {
    const { t, data } = this.props;

    return (
      <div className={ad_styles.modalContentWrapper}>
        <img
          src={closeImage}
          onClick={this.handleAdvertisingToggleButton}
          className={ad_styles.closeIcon}
        />

        <div className={ad_styles.wrapper}>
          {
            data.participant_status === 'approved'
            ? <>
                <div className={ad_styles.title}>
                  <p className={ad_styles.titleText}>{t(`title.adv-sent-approved`)}</p>
                </div>
                <div style={{ lineHeight: 1.4, fontSize: '16px', fontWeight: 'normal' }}>{t("text.adv-req-on-review-author-notified")}</div>
                <Button onClick={this.handleAdvertisingToggleButton}>{t('button.close')}</Button>
              </>
            : <>
                <p className={ad_styles.titleText}>{t(`title.adv-sent-approved`)}</p>
              </>
          }
        </div>
      </div>
    );
  };


  render() {
    const { t, isOpen, data, talent, dataStore, isOwner, user } = this.props;

    const startDate = moment(data.start_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const endDate = moment(data.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY');

    const startTime = moment(data.time_start).format('HH:mm');
    const endTime = moment(data.time_end).format('HH:mm');

    const castingDate = moment(data.casting_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const castingTime = moment.utc(data.casting_time).format('HH:mm');

    const dataArray = [
      { label: t("label.project"), value: `${dataStore.getMovieTypeById(data.projekt_type)} ${get(data, 'projekt_name')}` },
      { label: t("label.producer"), value: data.director ? data.director : '–' },
      { label: t("label.shooting-in"), value: data.shooting_cities },
      { label: t("label.dates"), value: data.start_date ? `${startDate} – ${endDate}` : '–' },
      { label: t("label.casting-date"), value: data.casting_date ? castingDate : '–' },
      { label: t("label.time-casting"), value: data.casting_time ? castingTime : '–' },
      { label: t("label.address"), value: data.place ? data.place : '–' },
      { label: t("label.payment"), value: data.rate ? `${data.rate} ${data.currency}` : '–' },
      { label: t("label.description"), value: data.about ? data.about : '–' },
      {
        label: t("label.scene-text"),
        value: data.document ? (<Button onClick={this.handleDownloadDocument} size="small" kind="primary">{t('button.download')}</Button>) : '–'
      },
      {
        label: t("label.author"),
        value: (
          <>
            <p>{data.sender_first_name} {data.sender_last_name}</p>
            <p>{data.phone}</p>
            <p>{data.email}</p>
          </>
        )
      }
    ];

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 545,
        maxHeight: 288
      }
    };

    const dateNow = moment(new Date().toLocaleString("en-US", {timeZone: "Asia/Almaty"})).format('YYYY-MM-DD HH:mm')
    const expired = data.casting_date < dateNow
    const invitationWaitingAndExpired = data.participant_status === 'waiting' && ( !data.casting_date || expired )
    const invitationWaitingAndNotExpired =  data.participant_status === 'waiting' && ( data.casting_date && !expired )
    const notOwnerAndExpired = !isOwner && ( !data.casting_date || expired )

    return (
      <>
        <ModalInfo
          title={t('label.invitation')}
          isOpen={isOpen}
          dataArray={dataArray}
          data={data}
          attention={data.attention}
          isUpdating={talent.isUpdating}
          onClose={this.props.onClose}
          status={(
            <div className={styles.buttonsGroup}>
              <div style={{alignSelf: 'center'}}>
                {data.participant_status === 'approved' && t("label.accepted")}
                {data.participant_status === 'refused' && t("label.refused")}
                {invitationWaitingAndExpired && t("label.not-answered")}
              </div>
              { notOwnerAndExpired &&
                <div>
                  <Button className={styles.buttonDelete} onClick={this.handleDeleteClick}>{t('button.delete')}</Button>
                </div>
              }
            </div>
          )}
        >
          {!isOwner && (
            invitationWaitingAndNotExpired && (
              <div className={styles.buttons}>
                <Button className={styles.buttonFirst} onClick={this.handleAcceptClick}>{t('button.accept')}</Button>
                <Button kind="bordered" onClick={this.handleRefuseClick}>{t('button.refuse')}</Button>
              </div>
            )
          )}
        </ModalInfo>

        { data.participant_status !== 'waiting' &&
          <Modal
            isOpen={user.isInvModalOpen}
            style={modalStyle}
            onRequestClose={this.handleAdvertisingToggleButton}
          >
            {this.renderModalContent()}
          </Modal>
        }
      </>
    );
  }
}

export default withTranslation()(ModalInvitation);
