import React, { Component } from 'react';
import Input from '../Input/Input';
import { withTranslation } from 'react-i18next';
import isURL from 'validator/lib/isURL';

class InputMaskVK extends Component {
  render() {
    const { t, form } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    return (
      getFieldDecorator('vk', {
        initialValue: this.props.initialValue,
        rules: [
          {validator(rule, value) {
            if (value && !isURL(value, {
              require_protocol: true,
              protocols: ['http','https'],
              host_whitelist: ['vk.com', 'www.vk.com']
            })) {
              return [new Error(t("validation.input.vk"))];
            }

            return [];
          }}
        ]
      })(
        <Input
          name="vk"
          placeholder={t('input.your-vk-profile')}
          errors={getFieldError('vk')}
        />
      )
    )
  }
}

export default withTranslation()(InputMaskVK);
