import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import FormItem from '../../components/FormItem/FormItem';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class CareerEdit extends Component {
  componentDidMount() {
    this.props.dataStore.fetchCareersList();
  }

  handleSubmit = (e, next) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      const { user, dataStore } = this.props;
      const role_object = get(user, 'user.role_object', {});
      const allProfessions = toJS(dataStore.allProfessions);
      const career = Object.entries(get(role_object, 'career'));

      const dataMainProfession = allProfessions.find(p => p.value === get(role_object, 'main_profession'));
      const dataProfessions = allProfessions.filter(p => career.find(c => c[0] === p.value && c[1] && c[0] !== get(role_object, 'main_profession')));
      const selectedProfessions = [dataMainProfession, ...dataProfessions];

      const currentValues = values.profession.map(v => v.value)
      const deletedProfessions = selectedProfessions.filter(p => !currentValues.includes(p.value))

      if (!error) {
        const { role, role_object } = this.props.user.user;
        const moderationFields = [];

        if (values.profession[0].value !== get(role_object, 'main_profession')) {
          moderationFields.push('new_main_profession');
        }

        const career = get(role_object, 'career');
        const isProfessionsChanged = values.profession.filter(p => !career[p.value]);

        if (isProfessionsChanged) {
          moderationFields.push('career_update');
        }

        if (values.work_status !== get(role_object, 'career.work_status')) {
          moderationFields.push('career_work_status_update');
        }

        if (deletedProfessions.length) {
          const modifiedDeletedProfessions = []

          deletedProfessions.forEach((p) => {
            modifiedDeletedProfessions.push ({
              value: p.value,
              status: false
            })
          })

          values.profession.push(modifiedDeletedProfessions)
        }

        const professions = values.profession.flat().reduce((acc, cur) => ({ ...acc, [cur.value]: cur.status !== false }), {})

        const data = {
          staff: {
            main_profession: values.profession[0].value,
            career_attributes: {
              id: get(this.props.user.user.role_object, 'career.id'),
              work_status: values.work_status,
              ...professions
            },
            moderated_fields: moderationFields,
          }
        };

        this.props.user.update(data, true).then(() => {
          if (next) {
            this.props.navigate({ path: `/app/${role}/edit`, query: { tab: 'education' } }, true);
          }
        });
      } else {
        console.log('error', error, values);
      }
    });
  }

  handleSaveAndNext = (e) => {
    this.handleSubmit(e, true);
  }

  handleSelectProfession = (data) => {
    const currentProfessions = this.props.form.getFieldValue('profession');
    const newData = uniq([...currentProfessions, ...data]);

    this.props.form.setFieldsValue({ profession: newData });
  }

  render() {
    const { user, t, form, dataStore } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const role_object = get(user, 'user.role_object', {});
    const allProfessions = toJS(dataStore.allProfessions);

    const career = Object.entries(get(role_object, 'career'));
    const dataMainProfession = allProfessions.find(p => p.value === get(role_object, 'main_profession'));
    const dataProfessions = allProfessions.filter(p => career.find(c => c[0] === p.value && c[1] && c[0] !== get(role_object, 'main_profession')));
    const selectedProfessions = [dataMainProfession, ...dataProfessions];

    return (
      <div className={formStyles.form}>
        <form onSubmit={this.handleSubmit}>
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <div className={formStyles.formTitle}>{t("title.working")}</div>
              <div className={formStyles.formSeparator}/>

              <FormItem styles={{ display: 'flex' }}>
                {getFieldDecorator('work_status', { initialValue: get(role_object, 'career.work_status') })(
                  <RadioGroup isColumn labelTextStyle={{ fontSize: 16 }} data={dataStore.workStatusOptions} />
                )}
              </FormItem>
            </div>

            <div className={formStyles.formGrid2}>
              <div className={formStyles.formTitle}>{t('title.career')}</div>
              <div className={formStyles.formSeparator}/>
              <FormItem>
                {getFieldDecorator('profession', {
                  initialValue: selectedProfessions,
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <MultiSelect
                    required
                    maxCount={3}
                    onChange={this.handleSelectProfession}
                    placeholder={t('input.profession')}
                    options={allProfessions}
                    errors={getFieldError('profession')}
                    extra={t('extra.profession')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.buttons}>
            <Button className={formStyles.button} kind="primary" type="submit">{t("button.save")}</Button>
            <Button onClick={this.handleSaveAndNext} className={formStyles.buttonLast} kind="bordered" type="button">{t("button.save-and-next-step")}</Button>
          </div>
        </form>
      </div>
    )
  }
}

export default withTranslation()(createForm()(CareerEdit));
