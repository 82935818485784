import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import get from 'lodash/get';
import { NotificationManager } from 'react-notifications';
import VacancyCard from '../../components/VacancyCard/VacancyCard';
import Loader from '../../components/Loader/Loader';
import styles from './CabinetVacancies.module.scss';
import inboxIcon from "../../../images/inbox-icon.png";

import cabinetStyles from '../Cabinet/Cabinet.module.scss';

@inject('user', 'staff', 'company', 'navigate')
@observer
class CabinetVacancies extends Component {
  componentWillMount() {
    const { user } = this.props;
    const { role } = get(user, 'user');

    this.props[role].fetchInvitations()

    if (user.isUnderModeration) {
      this.props.navigate({ path: `/app/${role}` });

      NotificationManager.error(this.props.t("text.wait-for-moderation-menu"));
    }
  }

  componentDidMount() {
    const tab = this.props.tab || 'vacancies';

    this.fetchDataByTab(tab);
  }

  fetchDataByTab(tab) {
    const { role } = this.props.user.user;

    switch (tab) {
      case 'vacancies': this.props[role].fetchVacancies(); break;
      case 'invitations': this.props[role].fetchInvitations(); break;
      default: break;
    }
  }

  handleTabClick = tab => {
    const { role } = this.props.user.user;

    this.props.navigate({
      path: `/app/${role}/vacancies`,
      query: { tab }
    });
  }

  renderContent = () => {
    switch (this.props.tab || 'vacancies') {
      case 'vacancies': return this.renderVacancies();
      case 'invitations': return this.renderInvitations();
      default: break;
    }
  }

  renderVacancies = () => {
    const { t } = this.props;
    const { role } = this.props.user.user;
    const roleData = this.props[role];

    return (
      roleData.isVacanciesFetching ? (
        <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
          <Loader isAbsolute={true} isLoading={true}/>
        </div>
      ) : (
        roleData.vacancies.length > 0 ? (
          <div className={cabinetStyles.blocksList}>
            {roleData.vacancies.map(vacancy => (
              <div key={vacancy.id} className={cabinetStyles.block}>
                <VacancyCard data={vacancy} />
              </div>
            ))}
          </div>
        ) : (
          <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
            <img src={inboxIcon} alt=""/>
            <p>{t('text.no-vacancies-yet')}</p>
          </div>
        )
      )
    );
  }

  renderInvitations = () => {
    const { t } = this.props;
    const { role } = this.props.user.user;
    const roleData = this.props[role];

    return (
      roleData.isInvitationsFetching ? (
        <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
          <Loader isAbsolute={true} isLoading={true}/>
        </div>
      ) : (
        roleData.invitations.length > 0 ? (
          <div className={cabinetStyles.blocksList}>
            {roleData.invitations.map(invitation => (
              <div className={cabinetStyles.block}>
                <VacancyCard key={invitation.id} data={invitation} />
              </div>
            ))}
          </div>
        ) : (
          <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
            <img src={inboxIcon} alt=""/>
            <p>{t('text.no-invites-yet')}</p>
          </div>
        )
      )
    );
  }

  render() {
    const { t } = this.props;
    const activeTab = this.props.tab || 'vacancies';
    const { role } = this.props.user.user;
    const roleData = this.props[role];

    return (
      <div className={cabinetStyles.page}>
        <div className={cabinetStyles.inner}>
          <div className={styles.tabs}>
            <div
              onClick={() => this.handleTabClick('vacancies')}
              className={cn(styles.tab, { [styles.tabActive]: activeTab === 'vacancies'})}
            >{t("title.vacancies")}</div>
            <div
              onClick={() => this.handleTabClick('invitations')}
              className={cn(styles.tab, { [styles.tabActive]: activeTab === 'invitations'})}
            >{t("title.invitations")} ({roleData.invitations.length})</div>
          </div>

          {this.renderContent()}

        </div>
      </div>
    )
  }
}

export default withTranslation()(CabinetVacancies);
