import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Select from '../../components/Select/Select';
import FormTitle from '../../components/FormTitle/FormTitle';
import FormItemControls from '../../components/FormItemControls/FormItemControls';
import styles from './EditForms.module.scss';
import get from 'lodash/get';

@inject('user', 'dataStore', 'navigate')
@observer
class SkillsLanguageEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    const { role } = this.props.user.user;

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          [role]: {
            languages_attributes: [{
              id: get(this.props.data, 'id'),
              language_dictionary_id: get(values, 'language_dictionary.value'),
              level_type: get(values, 'level_type.value'),
            }],
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('SkillsLanguageEdit error', error, values);
      }
    });
  }

  render() {
    const { dataStore, t, form, data = {} } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const { languagesOptions, languagesLevelOptions } = dataStore;

    const dataLangName = data.id
      ? languagesOptions.find(i => i.value == data.language_dictionary_id)
      : null;
    const dataLangLevel = data.id
      ? languagesLevelOptions.find(i => i.value == data.level_type)
      : null;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.formGrid} style={{ marginBottom: 0 }}>
          <div className={styles.photoGrid2}>
            <FormTitle>{t('skill.language')}</FormTitle>
            {getFieldDecorator('language_dictionary', {
              initialValue: dataLangName,
              rules: [{ required: true, message: t('validation.input.required') }]
            })(
              <Select
                required
                placeholder={t('skill.language')}
                options={languagesOptions}
                errors={getFieldError('language_dictionary')}
              />
            )}
          </div>
          <div className={styles.photoGrid2}>
            <FormTitle>{t('skill.level')}</FormTitle>
            {getFieldDecorator('level_type', {
              initialValue: dataLangLevel,
              rules: [{ required: true, message: t('validation.input.required') }]
            })(
              <Select
                required
                placeholder={t('skill.level')}
                options={languagesLevelOptions}
                errors={getFieldError('level_type')}
              />
            )}
          </div>
        </div>
        <div className={styles.formGrid}>
          <FormItemControls data={data} entity="languages"/>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(SkillsLanguageEdit));
