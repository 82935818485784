import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import ReactTooltip from "react-tooltip";
import cn from "classnames";
import CabinetBurgerMenu from "../CabinetBurgerMenu/CabinetBurgerMenu";
import burgerBlack from "../../../images/burger-black.png";
import logoImageBlack from "../../../images/logo-black.png";
import get from 'lodash/get';

import styles from "./Header.module.scss";

@inject("navigate", "user")
@observer
class Header extends Component {
  state = {
    isMenuOpen: false
  };

  handleLoginClick = () => {
    this.props.navigate({ path: "/app/signin" });
  };

  handleLogoutClick = () => {
    this.props.user.logout();
  };

  handleSwitchLanguage = () => {
    this.props.user.changeLanguageBD(this.props.user.user.id);
    this.props.user.changeLanguage(this.props.i18n);
  };

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true });
  };

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  };

  handleItemClick = (e, path, isBlocked) => {
    e.preventDefault();

    if (path) this.props.navigate({ path });

    if (!isBlocked) this.setState({ isMenuOpen: false });
  }

  linkBlockedInfo = () => {
    const { t, user } = this.props;

    const userOnModeration = get(user, 'user.role_object.moderation_status') === 'on_moderation'
    const userRefused = get(user, 'user.role_object.moderation_status') === 'refused'

    if (userOnModeration) {
      return t("text.wait-for-moderation-menu")
    } else if (userRefused) {
      return t("text.refused-menu")
    } else {
      return undefined
    }
  }

  tabs = (role) => {
    const { t, user } = this.props;
    const isUnderModeration = user.isUnderModeration;

    const tabs = {
      talent: [
        { title: "menu.workspace", path: "/app/talent" },
        {
          title: "menu.castings",
          path: isUnderModeration ? '' : "/app/talent/castings",
          tooltipId: 'castings',
          tooltipTitle: this.linkBlockedInfo(),
          isBlocked: isUnderModeration
        },
        {
          title: "menu.education",
          path: isUnderModeration ? '' : "/app/talent/education",
          tooltipId: 'education',
          tooltipTitle: this.linkBlockedInfo(),
          isBlocked: isUnderModeration
        },
        // TODO: Disable payments
        // { title: "menu.payment", path: "/app/talent/payment" },
        { title: "menu.settings", path: "/app/talent/settings" }
      ],
      staff: [
        { title: "menu.workspace", path: "/app/staff" },
        { title: "menu.search", path: "/app/search" },
        {
          title: "menu.boards",
          path: isUnderModeration ? '' : "/app/staff/boards",
          tooltipId: 'boards',
          tooltipTitle: this.linkBlockedInfo(),
          isBlocked: isUnderModeration
        },
        {
          title: "menu.vacancies",
          path: isUnderModeration ? '' : "/app/staff/vacancies",
          tooltipId: 'vacancies',
          tooltipTitle: this.linkBlockedInfo(),
          isBlocked: isUnderModeration
        },
        {
          title: "menu.education",
          path: isUnderModeration ? '' : "/app/staff/education",
          tooltipId: 'education',
          tooltipTitle: this.linkBlockedInfo(),
          isBlocked: isUnderModeration
        },
        { title: "menu.settings", path: "/app/staff/settings" }
      ],
      company: [
        { title: "menu.workspace", path: "/app/company" },
        { title: "menu.search", path: "/app/search" },
        {
          title: "menu.boards",
          path: isUnderModeration ? '' : "/app/company/boards",
          tooltipId: 'boards',
          tooltipTitle: this.linkBlockedInfo(),
          isBlocked: isUnderModeration
        },
        {
          title: "menu.vacancies",
          path: isUnderModeration ? '' : "/app/company/vacancies",
          tooltipId: 'vacancies',
          tooltipTitle: this.linkBlockedInfo(),
          isBlocked: isUnderModeration
        },
        {
          title: "menu.education",
          path: isUnderModeration ? '' : "/app/company/education",
          tooltipId: 'education',
          tooltipTitle: this.linkBlockedInfo(),
          isBlocked: isUnderModeration
        },
        { title: "menu.settings", path: "/app/company/settings" }
      ]
    };

    return tabs[role];
  }

  render() {
    const { t, user, path } = this.props;
    const { role } = get(user, 'user', {});

    return (
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div className={styles.logo}>
            <a href={`/${user.language}`}>
              <img
                className={styles.logoImageRed}
                src={logoImageBlack}
                alt="Etalents.pro"
              />
            </a>
          </div>

          <div className={styles.menu}>
            {this.tabs(role).map((tab, i) => (
              <a
                key={i}
                onClick={(e) => this.handleItemClick(e, tab.path)}
                href={tab.path}
                className={cn(
                  styles.menuItem,
                  (!tab.isBlocked && tab.path !== `/app/${role}` && path.includes(tab.path)) && `${styles.menuItemActive}`,
                  (!tab.isBlocked && tab.path === path && tab.path === `/app/${role}`) && `${styles.menuItemActive}`,
                  tab.isBlocked && `${styles.menuItemBlocked}`
                )}
              >
                <div
                  data-tip={tab.tooltipId}
                  data-for={tab.tooltipId}
                >
                  {t(tab.title)}
                </div>

                {tab.tooltipTitle && (
                  <ReactTooltip
                    className={styles.tooltip}
                    id={tab.tooltipId}
                    place="bottom"
                    effect="solid"
                  >
                    <span>{t(tab.tooltipTitle)}</span>
                  </ReactTooltip>
                )}
              </a>
            ))}
          </div>

          <div className={styles.info}>
            {user.isAuthenticated ? (
              <div
                onClick={this.handleLogoutClick}
                className={styles.signinButton}
              >
                {t("button.logout")}
              </div>
            ) : (
              <div
                onClick={this.handleLoginClick}
                className={styles.signinButton}
              >
                {t("button.signin")}
              </div>
            )}
            <div
              onClick={this.handleSwitchLanguage}
              className={styles.langSwitcher}
            >
              {user.language === "ru" ? "en" : "ru"}
            </div>
            <img
              onClick={this.handleMenuOpen}
              src={burgerBlack}
              className={styles.burger}
            />

            <CabinetBurgerMenu
              path={path}
              tabs={this.tabs(role)}
              onClose={this.handleMenuClose}
              onItemClick={this.handleItemClick}
              isOpen={this.state.isMenuOpen}
              onLogout={this.handleLogoutClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Header);
