import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import FormItem from '../../components/FormItem/FormItem';
import get from 'lodash/get';

import formStyles from '../Forms/EditForms.module.scss';

import SkillsLanguageEdit from '../Forms/SkillsLanguageEdit';

@inject('user', 'dataStore', 'navigate')
@observer
class SkillsEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          staff: {
            vehicle_attributes: {
              car: values.vehicle.car,
              motorcycle: values.vehicle.motorcycle,
              bus: values.vehicle.bus,
              extreme_driving: values.vehicle.extreme_driving,
              riding: values.vehicle.riding,
            }
          }
        };

        this.props.user.update(data, true);
      } else {
        console.log('error', error, values);
      }
    });
  }

  componentDidMount() {
    this.props.dataStore.fetchLanguagesList();
  }

  handleNextStep = () => {
    this.props.navigate({
      path: `/app/${this.props.user.user.role}/edit`,
      query: { tab: 'portfolio' }
    }, true);
  }

  render() {
    const { user, t, form } = this.props;
    const { getFieldDecorator } = form;
    const role_object = get(user, 'user.role_object', {});
    const languages = get(role_object, 'languages')

    return (
        <div className={formStyles.form}>
          <div className={formStyles.formGrid}>
            <div style={{flexGrow: 1, flexShrink: 1, flexBasis: 100}}>
              <form onSubmit={this.handleSubmit}>
                <div className={formStyles.formTitle}>{t("title.driving")}</div>
                <div className={formStyles.formSeparator}/>
                <FormItem>
                  {getFieldDecorator('vehicle.car', { initialValue: get(role_object, 'vehicle.car') })(
                    <Checkbox name="vehicle.car" label={t("skill.car")} />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('vehicle.motorcycle', { initialValue: get(role_object, 'vehicle.motorcycle') })(
                    <Checkbox name="vehicle.motorcycle" label={t("skill.motorcycle")} />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('vehicle.bus', { initialValue: get(role_object, 'vehicle.bus') })(
                    <Checkbox name="vehicle.bus" label={t("skill.oversized-transport")} />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('vehicle.extreme_driving', { initialValue: get(role_object, 'vehicle.extreme_driving') })(
                    <Checkbox name="vehicle.extreme_driving" label={t("skill.extreme-driving")} />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('vehicle.riding', { initialValue: get(role_object, 'vehicle.riding') })(
                    <Checkbox name="vehicle.riding" label={t("skill.horseback-riding")} />
                  )}
                </FormItem>
                <FormItem>
                  <Button kind="primary" type="submit">{t("button.save")}</Button>
                </FormItem>
              </form>
            </div>
          </div>

          <div className={formStyles.formGrid}>
            <div style={{flexGrow: 1, flexShrink: 1, flexBasis: 100}}>
              <div className={formStyles.formTitle}>{t("title.languages-skills")}</div>
              <div className={formStyles.formSeparator}/>

              {languages.map(l => (
                <SkillsLanguageEdit key={l.id} data={l} />
              ))}

              <SkillsLanguageEdit />
            </div>
          </div>

          <Button onClick={this.handleNextStep} kind="bordered" type="button">{t("button.next-step")}</Button>
        </div>
    )
  }
}

export default withTranslation()(createForm()(SkillsEdit));
