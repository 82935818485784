import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import FormTitle from '../../components/FormTitle/FormTitle';
import Button from '../../components/Button/Button';
import get from 'lodash/get';

import EducationUniversityEdit from './EducationUniversityEdit';
import EducationCourseEdit from '../Forms/EducationCourseEdit';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class EducationEdit extends Component {
  componentDidMount() {
    this.props.dataStore.fetchEducationList();
  }

  handleNextStep = () => {
    this.props.navigate({
      path: `/app/${this.props.user.user.role}/edit`,
      query: { tab: 'skills' }
    }, true);
  }

  render() {
    const { user, t} = this.props;
    const educations = get(user, 'user.role_object.educations', []);
    const courses = get(user, 'user.role_object.courses', []);

    return (
      <div className={formStyles.form}>
        <div className={formStyles.formTitle}>
          <span>{t("title.high-education")}</span>
          <FormTitle progress={50} isActive={educations.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>

        {educations.map(data => (
          <EducationUniversityEdit key={data.id} data={data}/>
        ))}

        <EducationUniversityEdit/>

        <div className={formStyles.formTitle}>
          <span>{t("title.courses")}</span>
          <FormTitle progress={50} isActive={courses.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>

        {courses.map(data => (
          <EducationCourseEdit key={data.id} data={data}/>
        ))}

        <EducationCourseEdit/>

        <Button onClick={this.handleNextStep} kind="bordered" type="button">{t("button.next-step")}</Button>
      </div>
    )
  }
}

export default withTranslation()(EducationEdit);
