import React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import Modal from "react-modal";

import Loader from '../Loader/Loader';
import closeImage from "../../../images/close-modal-icon.png";
import Button from "../Button/Button";

import styles from "./Advertising.module.scss";

Modal.setAppElement('#app');

@inject("user", "dataStore")
@observer
class Advertising extends React.Component {

  componentDidMount() {
    this.props.dataStore.fetchAdBannersList();
  }

  handleAdvertisingToggleButton = () => {
    this.props.user.toggleAdvModal();
  };
  handleAdvertisingRequestButton = () => {
    this.props.user.advertisingRequest();
  };

  renderModalContent = () => {
    const { user, t } = this.props;

    if (!user.isAdvertisingLoaded) {
      return (
        <div className={styles.modalContentWrapper}>
          <img
            src={closeImage}
            className={styles.closeIcon}
            onClick={this.handleAdvertisingToggleButton}
          />
          <p className={styles.modalTitle}>{t("title.want-adv")}</p>
          <p>{t("text.leave-adv-req")}</p>
          <Button
            size="big"
            kind="primary"
            className={styles.reklamaButton}
            onClick={this.handleAdvertisingRequestButton}
          >
            {t("button.submit-application")}
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.modalContentWrapper}>
        <img
          src={closeImage}
          onClick={this.handleAdvertisingToggleButton}
          className={styles.closeIcon}
        />
        <p className={styles.modalTitle}>{t("title.adv-sent")}</p>
        <p>{t("text.adv-req-on-review")}</p>
      </div>
    );
  };

  render() {
    const { t, user } = this.props;
    const { role } = this.props.user.user;

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 545,
        maxHeight: 288
      }
    };

    const { dataStore } = this.props;

    if (dataStore.adBannersOptions.length !== 0 && dataStore.adBannersOptions[role][0]) {
      const adBanners = dataStore.adBannersOptions[role]
      const random = Math.floor(Math.random() * Math.floor(adBanners.length))
      const banner = dataStore.adBannersOptions[role][random]

      const adBannerImg = banner[`${user.language}_image`]
      const adBannerLink = banner['link']

      return (
        <a className={styles.reklamaBlockLink} href={adBannerLink} target='_blank' rel='nofollow'>
          <div className={styles.reklamaBlock} style={{backgroundImage: `url(${adBannerImg})`}}>
          </div>
        </a>
      );
    }

    return (
      <div className={styles.reklamaBlock}>
        <Loader isLoading={user.isAdvertisingLoading} />

        <Modal
          isOpen={user.isAdvModalOpen}
          style={modalStyle}
          onRequestClose={this.handleAdvertisingToggleButton}
        >
          {this.renderModalContent()}
        </Modal>

        <p>{t("text.reklama-here")}</p>

        <Button
          size="big"
          kind="primary"
          className={styles.reklamaButton}
          onClick={this.handleAdvertisingToggleButton}
        >
          {t("button.submit-application")}
        </Button>
      </div>
    );
  }
}

export default withTranslation()(Advertising);
