import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import cn from "classnames";
import styles from "./TabsHeader.module.scss";

@inject("user", "navigate")
@observer
class TabsHeader extends Component {
  handleClick = tab => {
    const { user } = this.props;

    this.props.navigate({
      path: `/app/${user.user.role}/edit`,
      query: { tab }
    });
  };

  handleBackClick = () => {
    const { user } = this.props;

    this.props.navigate({ path: `/app/${user.user.role}` });
  };

  render() {
    const { t, tabs, activeTab } = this.props;

    return (
      <div className={styles.header}>
        <div className={styles.tabs}>
          {tabs.map(tab => (
            <div
              key={tab.tab}
              onClick={() => this.handleClick(tab.tab)}
              className={cn(
                styles.tab,
                { [styles.tabFilled]: tab.filled },
                { [styles.tabActive]: tab.tab === activeTab }
              )}
            >
              {tab.title}&nbsp;
              <div className={styles.check}></div>
            </div>
          ))}
        </div>
        <div onClick={this.handleBackClick} className={styles.backButton}>
          {t("button.back")}
        </div>
      </div>
    );
  }
}

export default withTranslation()(TabsHeader);
