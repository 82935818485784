import React, { Component } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import arrowIcon from "../../../images/bordered-arrow-down.png";

import cn from "classnames";

import styles from "./Select.module.scss";

class CustomSelect extends Component {
  handleChange = data => {
    this.props.onChange(data);
  };

  render() {
    const {
      options,
      required,
      placeholder = '',
      errors = [],
      value,
      bordered,
      size,
      isClearable,
      isSearchable = true,
      wrapperClassName,
      t
    } = this.props;

    return (
      <div className={wrapperClassName}>
        <Select
          value={value}
          isClearable={isClearable}
          onChange={this.handleChange}
          className={cn("react-select-container", size === 'small' && 'react-select--small')}
          components={{
            DropdownIndicator: () => (
              <img style={{ width: 16, height: 16 }} src={arrowIcon} alt="" />
            )
          }}
          classNamePrefix={cn(
            "react-select",
            bordered && "react-select-bordered"
          )}
          placeholder={required ? placeholder + " *" : placeholder}
          options={options}
          noOptionsMessage={() => <span>{t("text.no-options")}</span>}
          isSearchable={isSearchable}
        />
        <p className={styles.errors}>
          {errors.length > 0 ? errors.join(", ") : null}
        </p>
      </div>
    );
  }
}

export default withTranslation()(CustomSelect);
