import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { AdvCard, BlockHeader, Button, Loader, Advertising, ProjectsList } from '../../components';
import styles from "./TalentCabinet.module.scss";
import cn from "classnames";
import inboxIcon from "../../../images/inbox-icon.png";
import get from "lodash/get";
import defaultTo from "lodash/defaultTo";
import moment from "moment";
import EducationAnnounces from "../EducationAnnounces/EducationAnnounces";
import SmallAdvertising from "../../components/Advertising/SmallAdvertising";

import cabinetStyles from "../Cabinet/Cabinet.module.scss";

@inject("user", "talent", "navigate")
@observer
class TalentCabinet extends Component {
  componentWillMount() {
    const { role } = this.props.user.user;

    if (role !== 'talent') {
      this.props.navigate({ path: `/app/${role}`});
    }
  }

  componentDidMount() {
    const { user } = this.props;

    if (!user.isUnderModeration) {
      this.props.user.fetchTutorialsList();
    }

    this.props.talent.fetchTalentCastings();
    this.props.talent.fetchTalentInvitations();
    this.props.talent.fetchTalentCrowdings();
  }

  handleEditClick = () => {
    this.props.navigate({ path: "/app/talent/edit" });
  };

  handleClickToPay = () => {
    this.props.navigate({ path: "/app/talent/payment" });
  };

  // handleDownloadCV = () => {
  //   this.props.user.downloadCV();
  // };

  getModerationStatus = status =>
    ({
      not_yet: this.props.t("status.in-progress"),
      on_moderation: this.props.t("status.in-progress"),
      refused: this.props.t("status.moderation-refused"),
      approved: this.props.t("status.moderation-approved"),
      "": ""
    }[status]);

  render() {
    const { user, talent, t } = this.props;
    const profile = user.user;
    const role_object = defaultTo(get(profile, "role_object", {}), {});
    const lang = user.language;
    const textTutorials = toJS(get(user, "tutorialsList.texts", []));
    const isSubscriptionExpiresSoon =
      moment(get(role_object, "subscription.expires_at")).diff(
        moment(),
        "days"
      ) < 7;

    const moderationStatus = get(role_object, "moderation_status", "");
    const isUnderModeration = user.isUnderModeration;

    const subscription = toJS(get(role_object, "subscription"));
    const statusNotPaid = !subscription || subscription.status === 'not_paid';

    // Формируется ссылка для скачивания pdf
    const pathToFile = role_object.cvs[`cv_${user.language}_contacts_true`]
    const downloadCVUrl =  `https://etalents.pro${pathToFile}`

    return (
      <div className={cn(cabinetStyles.page, cabinetStyles.inner)}>
        <div className={cn(cabinetStyles.pageGrid, styles.firstContentBlock)}>
          <div className={cabinetStyles.pageGrid7}>
            {/* Moderation status */}
            <div
              className={cn(
                styles.detailsHeader,
                styles.detailsHeaderWithStatus
              )}
            >
              <BlockHeader>{t("title.my-profile")}</BlockHeader>

              {
                role_object.frozen_status
                ? <div className={cabinetStyles.moderationStatus}>
                    <span title="Разморозить можно в Настройках">{t("notification.freeze-success")}</span>
                  </div>
                : <div className={cabinetStyles.moderationStatus}>
                    {t("text.moderation-status")}:&nbsp;
                    <span>{this.getModerationStatus(moderationStatus)}</span>
                  </div>
              }
            </div>

            {/* Profile block */}
            <div className={cn(cabinetStyles.blockBody, styles.profile)}>
              <div className={styles.profilePhotoWrapper}>
                <img className={styles.profilePhoto} src={user.avatar} alt="" />
                <div className={styles.profileMobileName}>{user.fullName}</div>
              </div>

              <div className={styles.profileInfo}>
                <div className={styles.profileName}>{user.fullName}</div>

                <table className={cabinetStyles.profileData}>
                  <tbody>
                    <tr>
                      <td className={cabinetStyles.profileDataCell}>
                        <div className={cabinetStyles.profileDataTitle}>
                          {t("label.actor-status")}
                        </div>
                        <div className={cabinetStyles.profileDataValue}>
                          {t(`label.${role_object.actor_status}`)}
                        </div>
                      </td>
                      <td className={cabinetStyles.profileDataCell}>
                        <div className={cabinetStyles.profileDataTitle}>
                          {t("label.gender")}
                        </div>
                        <div className={cabinetStyles.profileDataValue}>
                          {user.gender}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={cabinetStyles.profileDataCell}>
                        <div className={cabinetStyles.profileDataTitle}>
                          {t("label.age")}
                        </div>
                        <div className={cabinetStyles.profileDataValue}>
                          {user.age}
                        </div>
                      </td>
                      <td className={cabinetStyles.profileDataCell}>
                        <div className={cabinetStyles.profileDataTitle}>
                          {t("label.place-of-residence")}
                        </div>
                        <div className={cabinetStyles.profileDataValue}>
                          {get(role_object, `info.city_${lang}`)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={styles.profileRight}>
                <div data-tip data-for="progress" className={cabinetStyles.progress}>
                  <div className={cabinetStyles.progressText}>
                    {t("text.filled-out")}{" "}
                    {get(role_object, "progress.percent", 0)}%
                  </div>
                  <div className={cabinetStyles.progressBar}>
                    <div
                      className={cabinetStyles.progressBarValue}
                      style={{
                        width: `${get(role_object, "progress.percent", 0)}%`
                      }}
                    />
                  </div>
                </div>
                <ReactTooltip
                  className={cabinetStyles.tooltip}
                  id="progress"
                  effect="solid"
                >
                  <span>{t("text.progress-desc-talent")}</span>
                </ReactTooltip>
                {get(role_object, "progress.percent") <= 25 ? (
                  <p className={cabinetStyles.statusTooltipMobile}>{t("text.progress-desc-staff-short")}</p>
                ) : (
                  <p className={cabinetStyles.statusTooltipMobile}>{t("text.progress-desc-staff")}</p>
                )}
                <a
                  href={`/${user.language}/users/${get(role_object, "slug")}/information`}
                  target="_blank"
                >
                  <Button className={styles.profileButtonCV} kind="gray">
                    {t("button.view")}
                  </Button>
                </a>
                <Button
                  onClick={this.handleEditClick}
                  className={styles.profileButtonEdit}
                  kind="primary"
                >
                  {t("button.edit")}
                </Button>
                <a href={downloadCVUrl} target="_blank">
                  <Button
                    className={styles.profileButtonDownload}
                    kind="bordered"
                  >
                    {t("button.download-cv")}
                  </Button>
                </a>
              </div>
            </div>
          </div>

          {/* Subscription */}
          <div className={cabinetStyles.pageGrid3}>
            <BlockHeader className={styles.paymentBlockHeader}>{t("title.payment")}</BlockHeader>
            {
              statusNotPaid
              ? (
                  <div className={cn(styles.blockBodyMini, styles.blockBodyMiniGray)}>
                    <div className={styles.paymentStatus}>
                      <div className={styles.paymentRate}>
                        <div className={styles.paymentRateContent}>
                          <p className={styles.paymentText}>
                            {t("text.your-plan")}&nbsp;
                          </p>
                          <p className={styles.paymentText}>
                            <span>
                              FREE
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.blockBodyMiniSeparator} />
                    <p className={styles.paymentTextBold}>
                      {t("text.your-plan.detail")}
                    </p>
                    {/* <a className={styles.paymentLink} href={`/app/${user.user.role}/payment`}>
                      {t("text.your-plan.link")}
                    </a> */}
                  </div>
                )
              : (
                  <div className={styles.blockBodyMini}>
                    <div className={styles.paymentStatus}>
                      <div className={styles.paymentRate}>
                        <div className={styles.paymentRateContent}>
                          <p className={styles.paymentText}>
                            {t("text.your-plan")}&nbsp;
                          </p>
                          <p className={styles.paymentText}>
                            <span>
                              {t(`plan.${get(role_object, "subscription.plan")}`)}
                            </span>
                          </p>
                        </div>
                        <p className={styles.paymentCaption}>
                          · {' '}
                          { subscription.next_plan !== "not_present" && t("plan.status.subscribed") }
                          { subscription.status === 'paid' && subscription.next_plan === "not_present" && t("plan.status.paid") }
                          { statusNotPaid && t("plan.status.unsubscribed") }
                        </p>
                      </div>
                      <div className={styles.paymentDate}>
                        <p className={styles.paymentText}>
                          { subscription.next_plan !== "not_present"
                            ? t("text.next-withdrawal") + " "
                            : t("text.subscription-term") + " "
                          }
                        </p>
                        <p className={styles.paymentText}>
                          <span>
                            {moment(
                              get(role_object, "subscription.expires_at")
                            ).format("DD.MM.YYYY")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )
            }
            <div className={cabinetStyles.blockReferral}>
            </div>
          </div>
        </div>

        {/* Invitation */}
        <div className={cn(cabinetStyles.pageGrid, styles.commercialBlock)}>
          <div className={cabinetStyles.pageGrid7}>
            <BlockHeader className={styles.paymentBlockHeader}>{t("title.project-invitations")}</BlockHeader>
            <div className={styles.invitationsContainer}>
              {isUnderModeration && <div style={{ borderRadius: 10 }} className={cabinetStyles.overlay}>
                <p>{t("text.wait-for-moderation")}</p>
              </div>}
              {talent.isInvitationsFetching ? (
                <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
                  <Loader isAbsolute={true} isLoading={true}/>
                </div>
              ) : (
                talent.invitations.length > 0 ? (
                  <div className={cabinetStyles.blockBody}>
                    <ProjectsList invitations={talent.invitations} role={'talent'}/>
                  </div>
                ) : (
                  <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
                    <img src={inboxIcon} alt=""/>
                    <p>{t('text.no-projects-yet')}</p>
                  </div>
                )
              )}
            </div>
          </div>
          <div className={cabinetStyles.pageGrid3}>
            <Advertising />
          </div>
        </div>
        <div className={cabinetStyles.pageGrid}>
          <div className={cabinetStyles.pageGrid10}>
            <BlockHeader>{t("title.interests-castings")}</BlockHeader>
            <div className={styles.invitationsContainer}>
              {isUnderModeration && <div style={{ borderRadius: 10 }} className={cabinetStyles.overlay}>
                <p>{t("text.wait-for-moderation")}</p>
              </div>}
              {talent.isCastingsFetching ? (
                <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
                  <Loader isAbsolute={true} isLoading={true}/>
                </div>
              ) : (
                talent.castings.length > 0 ? (
                  <div className={cabinetStyles.blocksList}>
                    {talent.castings.map(casting => (
                      <div key={casting.id} className={cabinetStyles.block}>
                        <AdvCard data={casting} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
                    <img src={inboxIcon} alt=""/>
                    <p>{t('text.no-projects-yet')}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        {/* Tutorials */}
        <BlockHeader>{t("title.last-tutorials")}</BlockHeader>
        <EducationAnnounces
          isUnderModeration={isUnderModeration}
          texts={textTutorials}
        />
      </div>
    );
  }
}

export default withTranslation()(TalentCabinet);
