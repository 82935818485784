import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { createForm } from "rc-form";
import get from "lodash/get";
import pick from "lodash/pick";
import isNull from "lodash/isNull";
import compact from "lodash/compact";
import moment from 'moment';
import ModalSuccess from '../../../components/ModalInfo/ModalSuccess';

import RCRange from "../../../components/RCRange/RCRange";
import FormItem from "../../../components/FormItem/FormItem";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import FormTitle from "../../../components/FormTitle/FormTitle";
import Checkbox from "../../../components/Checkbox/Checkbox";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import Button from "../../../components/Button/Button";
import InputMask from "../../../components/InputMask/InputMask";
import Select from "../../../components/Select/Select";
import Textarea from "../../../components/Textarea/Textarea";

import General from "./General";
import Contacts from "./Contacts";

import styles from "../EditForms.module.scss";

import { Title } from "../Components";

@inject("user", "dataStore", "navigate")
@observer
class Extras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccessModal: false,
    }

    this.fields = [
      'projekt_name', 'director', 'shooting_cities', 'start_date', 'end_date',
      'place', 'casting_start_date', 'casting_end_date', 'male', 'female',
      'rate', 'about', 'attention', 'sender_first_name', 'sender_last_name', 'phone', 'email'
    ];
  }

  handleSubmit = e => {
    e.preventDefault();

    console.log('handleSubmit')

    this.props.form.validateFields({}, (error, values) => {
      console.log('error', error, values)
      if (!error) {
        const data = {
          projekt: {
            form: 1,
            ...pick(values, this.fields),
            currency: get(values, "currency.value"),
            projekt_type: get(values, "projekt_type.value"),
            age_start: get(values, "age[0]"),
            age_end: get(values, "age[1]"),
            time_start: get(values, "time_start") ? get(values, "time_start").format("HH:mm") : undefined,
            time_end: get(values, "time_end") ? get(values, "time_end").format("HH:mm") : undefined,
            appearance_types: get(values, "appearance_types").map(a => a.value),
          }
        };

        if (this.props.isEdit) {
          this.props.dataStore.updateAdvertisement(this.props.projectId, this.props.data.id, data);
        } else {
          this.props.dataStore.createAdvertisement(this.props.projectId, data).then(() => {
            this.setState({ showSuccessModal: true });
          });
        }
      } else {
        console.log("Extras error", error, values);
      }
    });
  };

  componentDidMount() {
    this.props.dataStore.fetchMovieTypesList();
    this.props.dataStore.fetchAppearanciesList();
    this.props.dataStore.fetchCurrenciesList();
  }

  handleCloseModal = () => {
    this.setState({ showSuccessModal: false });
    this.props.navigate({ path: `/app/${this.props.user.user.role}/boards` });
  }

  render() {
    const { dataStore, t, data, form, isEdit } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;

    const initialValues = {
      ...pick(data, this.fields),
      currency: dataStore.currencyOptions.find(c => c.value === get(data, 'currency')),
      projekt_type: dataStore.movieTypesOptions.find(o => o.value == get(data, "projekt_type")),
      time_start: moment.utc(get(data, "time_start")),
      time_end: moment.utc(get(data, "time_end")),
      appearance_types: get(data, "appearance_types", []).map(t => dataStore.appearanciesOptions.find(o => o.value == t)),
      document: isNull(get(data, "document")) ? undefined : get(data, "document"),
      age: compact([get(data, "age_start"), get(data, "age_end")]),
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <ModalSuccess
          isOpen={this.state.showSuccessModal}
          onClose={this.handleCloseModal}
          title={t("text.crowding-create-title")}
        >
          <div style={{ marginBottom: 30, lineHeight: 1.4 }}>{t("text.crowding-create-text")}</div>
          <Button onClick={this.handleCloseModal}>{t('button.close')}</Button>
        </ModalSuccess>

        <Title title={isEdit ? "title.edit-crowding" : "title.announce-crowding"} />

        <SectionTitle>{t("title.common-information")}</SectionTitle>

        <General form={form} initialValues={initialValues} type="extras" />

        <SectionTitle>{t("title.who-we-invite")}</SectionTitle>

        <div className={styles.formGrid20}>
          <div className={styles.photoGrid2}>
            <FormItem>
              <FormTitle>{t("label.age")}</FormTitle>
              {getFieldDecorator("age", {
                initialValue: initialValues.age,
                rules: [
                  { required: true, message: t("validation.input.required") }
                ]
              })(
                <RCRange
                  errors={getFieldError("age")}
                  count={1}
                  min={1}
                  max={120}
                />
              )}
            </FormItem>
          </div>
          <div className={styles.photoGrid2}>
            <FormTitle>{t("label.gender")}</FormTitle>
            <div style={{ display: "flex", marginTop: 15, marginBottom: 15 }}>
              <div style={{ marginRight: 10 }}>
                {getFieldDecorator("male", {
                  initialValue: initialValues.male
                })(<Checkbox name="male" label={t("input.male")} />)}
              </div>
              {getFieldDecorator("female", {
                initialValue: initialValues.female,
                validateTrigger: false,
                rules: [
                  {validator(rule, value) {
                    const maleValue = getFieldValue('male');
                    const errors = [];

                    if (!maleValue && !value) {
                      errors.push(new Error(t("validation.input.one-or-both")))
                    }

                    return errors;
                  }}
                ]
              })(<Checkbox name="female" label={t("input.female")} />)}
            </div>
            {(getFieldError("female") && getFieldError("female").length > 0) && (
              <p className={styles.errors}>{getFieldError("female").join(', ')}</p>
            )}
          </div>
        </div>

        <div className={styles.formGrid15_10}>
          <div className={styles.photoGrid2}>
            <FormTitle>{t("label.type-of-appearance")}</FormTitle>

            {getFieldDecorator("appearance_types", {
              initialValue: initialValues.appearance_types
            })(
              <MultiSelect
                placeholder={t("input.select-appearance-type")}
                options={dataStore.appearanciesOptions}
                errors={getFieldError("appearance_types")}
              />
            )}
          </div>
        </div>

        <div className={styles.formGrid20}>
          <div style={{ flexGrow: 1, flexShrink: 1, flexBasis: 100 }}>
            <FormTitle>{t("label.pay-attention")}</FormTitle>

            {getFieldDecorator("attention", {
              initialValue: initialValues.attention
            })(
              <Textarea name="attention" errors={getFieldError("attention")} extra={t("text.full-crowding-info")} />
            )}
          </div>
        </div>

        <div className={styles.formGrid20}>
          <div className={styles.photoGrid2}>
            <FormTitle>{t("input.shift-rate")}</FormTitle>

            <div className={styles.currencyRate}>
              {getFieldDecorator("rate", { initialValue: initialValues.rate })(
                <InputMask
                  wrapperClassName={styles.currencyRateInput}
                  mask={"9999999999"}
                  name="rate"
                  autocomplete="off"
                  placeholder={t("input.shift-rate-placeholder")}
                  errors={getFieldError('rate')}
                />
              )}
              {getFieldDecorator('currency', {
                initialValue: initialValues.currency,
                rules: [{ required: getFieldValue('rate'), message: t('validation.input.required') }]
              })(
                <Select
                  wrapperClassName={styles.currencyRateSelect}
                  placeholder={t('input.currency')}
                  options={dataStore.currencyOptions}
                  errors={getFieldError('currency')}
                />
              )}
            </div>
          </div>
        </div>

        <SectionTitle>{t("title.contacts")}</SectionTitle>

        <Contacts form={form} initialValues={initialValues} type="extras" />

        <div className={styles.buttons}>
          <Button className={styles.button} kind="black" type="submit">
            {t("button.done")}
          </Button>
          <Button className={styles.button} onClick={() => window.history.back()} kind="bordered">
            {t("button.cancel")}
          </Button>
        </div>
      </form>
    );
  }
}

export default withTranslation()(createForm()(Extras));
