import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { createForm } from "rc-form";

import Input from "../../components/Input/Input";
import FormItem from "../../components/FormItem/FormItem";
import Button from "../../components/Button/Button";
import FormTitle from "../../components/FormTitle/FormTitle";
import FormHeader from "../../components/FormHeader/FormHeader";

import styles from "./EditForms.module.scss";

@inject("user", "dataStore", "navigate")
@observer
class Project extends Component {
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        this.props.submit(values);
      } else {
        console.log("error", error, values);
      }
    });
  };

  render() {
    const {
      t,
      form,
      data = {},
      submitTitle,
      rejectTitle,
      titleTitle,
      submit,
      reject
    } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const titleT = titleTitle ? titleTitle : "title.create-board";
    const submitT = submitTitle ? submitTitle : "button.create";
    const rejectT = rejectTitle ? rejectTitle : "button.reject";

    return (
      <form onSubmit={this.handleSubmit}>
        <div
          style={{
            marginBottom: 0,
            display: "flex",
            padding: 25,
            width: "100%",
            boxSizing: "border-box"
          }}
        >
          <FormItem styles={{ width: "100%" }}>
            <FormHeader>{t(titleT)}</FormHeader>
            <FormTitle>{t("input.name")}</FormTitle>
            {getFieldDecorator("name", {
              initialValue: data.name,
              rules: [
                { required: true, message: t("validation.input.required") }
              ]
            })(
              <Input
                required
                name="name"
                placeholder={t("input.border-name")}
                errors={getFieldError("name")}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.formGrid}>
          <div style={{ display: "flex", paddingLeft: 25 }}>
            {reject && (
              <Button onClick={this.handleRemove} kind="primary">
                {t(rejectT)}
              </Button>
            )}
            {submit && (
              <Button
                className={styles.buttonFirst}
                kind="bordered"
                type="submit"
              >
                {t(submitT)}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(createForm()(Project));
