import { observable, flow, computed, action, configure } from "mobx";
import Status from "./store-status";
import api from "../api";

configure({ enforceActions: "observed" });

export default class Company {
  @observable updatingStatus = Status.DONE;
  @observable vacancies = [];
  @observable invitations = [];

  @observable vacanciesStatus = Status.DONE;
  @observable invitationsStatus = Status.DONE;


  constructor(root) {
    this.root = root;
  }

  @computed get isVacanciesFetching() {
    return this.vacanciesStatus === Status.PENDING;
  }

  @computed get isInvitationsFetching() {
    return this.invitationsStatus === Status.PENDING;
  }

  @computed get isUpdating() {
    return this.updatingStatus === Status.PENDING;
  }

  fetchVacancies = flow(function*() {
    this.vacanciesStatus = Status.PENDING;

    try {
      const { user_id, id } = this.root.user.user.role_object;
      const response = yield api.fetchCompanyVacancies(user_id, id);

      if (response.status === 200) this.vacancies = response.data.vacancies;

      this.vacanciesStatus = Status.DONE;
    } catch (error) {
      console.log(error);
      this.vacanciesStatus = Status.ERROR;
    }
  })

  fetchInvitations = flow(function*() {
    this.invitationsStatus = Status.PENDING;

    try {
      const { user_id, id } = this.root.user.user.role_object;
      const response = yield api.fetchCompanyInvitations(user_id, id);

      if (response.status === 200) this.invitations = response.data.invitations;

      this.invitationsStatus = Status.DONE;
    } catch (error) {
      console.log(error);
      this.invitationsStatus = Status.ERROR;
    }
  })

  acceptFavoriteProjekt = flow(function*(id) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id } = this.root.user.user.role_object;
      const response = yield api.acceptFavoriteProjekt(user_id, id);

      if (response.status === 200) {
        this.fetchVacancies();
      }

      this.updatingStatus = Status.DONE;

      return response;
    } catch (error) {
      console.log(error)
      this.updatingStatus = Status.ERROR;
    }
  })


  approveInvitation = flow(function*(id) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id } = this.root.user.user.role_object;
      const response = yield api.approveInvitation(user_id, id);

      if (response.status === 200) {
        this.fetchInvitations();
      }

      this.updatingStatus = Status.DONE;

      return response;
    } catch (error) {
      console.log(error)
      this.updatingStatus = Status.ERROR;
    }
  })

  refuseInvitation = flow(function*(id) {
    this.updatingStatus = Status.PENDING;

    try {
      const { user_id } = this.root.user.user.role_object;
      const response = yield api.refuseInvitation(user_id, id);

      if (response.status === 200) {
        this.fetchInvitations();
      }

      this.updatingStatus = Status.DONE;

      return response;
    } catch (error) {
      console.log(error)
      this.updatingStatus = Status.ERROR;
    }
  })
}
