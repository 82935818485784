import React, { Component } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import closeImage from '../../../images/close-icon.png';
import styles from './BurgerMenu.module.scss';

class BurgerMenu extends Component {

  configurePath = () => {
    const t = this.props.t
    const lang = this.props.i18n.language

    const pathArray = [
      { path: `/${lang}`, label: t('menu.home') },
      { path: '/app/search', label: t('menu.search') },
      { path: `/${lang}/home/for_talent`, label: t('menu.for-talents') },
      { path: `/${lang}/home/for_staff`, label: t('menu.for-staff') },
      { path: `/${lang}/home/for_company`, label: t('menu.for-company') },
      { path: `/${lang}/home/locations`, label: t('menu.locations') },
    ]

    if (lang === 'ru') {
      pathArray.push({ path: `/${lang}/articles`, label: t('menu.articles') })
    }

    pathArray.push({ path: `/${lang}/home/about`, label: t('menu.about-us') })

    return pathArray
  }

  render() {
    const { t } = this.props;

    return this.props.isOpen ? (
      <div className={styles.overlay}>
        <div onClick={this.props.onClose} className={styles.close}>
          <img className={styles.closeImage} src={closeImage} alt="Close" />
        </div>
        <div className={styles.menu}>
          {this.configurePath().map(item => (
            <a
              key={item.path}
              href={item.path}
              className={cn(styles.menuItem, location.pathname === item.path && styles.menuItemActive)}
            >{item.label}</a>
          ))}
          <span className={styles.menuItem} onClick={() => {
            this.props.onLogout()
            this.props.onClose()
          }}>{t('button.logout')}</span>
        </div>
      </div>
    ) : null;
  }
}

export default withTranslation()(BurgerMenu);
