import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import InputFile from '../../components/InputFile/InputFile';
import FormItem from '../../components/FormItem/FormItem';
import styles from './EditForms.module.scss';

@inject('user', 'navigate')
@observer
class PortfolioFilesEdit extends Component {
  handleFileSelect = (data) => {
    const { role } = this.props.user.user;
    const field = this.props.field || 'files';

    this.props.user.update({
      [role]: {
        [field]: data,
        moderated_fields: ['file']
      },
    }, true);
  }

  handleFileRemove = (id) => {
    this.props.user.deleteFile({ file_id: id });
  }

  render() {
    const { t, user, files } = this.props;
    const { role } = user.user;
    const documentsAccept = '.doc, .docx, .txt, .rtf, .pdf, .xls, .xlsx, .ppt, .pptx, application/mspowerpoint, application/powerpoint, application/vnd.ms-powerpoint, application/x-mspowerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/vnd.ms-excel, text/plain, application/msword';

    return (
      <div>
        <div className={styles.formGrid}>
          {files.length > 0 && (<div className={styles.photoGrid2}>
            {files.map(f => (
              <FormItem key={f.id}>
                <InputFile
                  value={f}
                  accept={documentsAccept}
                  onSelect={this.handleFileSelect}
                  onRemove={this.handleFileRemove}
                />
              </FormItem>
            ))}
          </div>)}
          <div className={styles.photoGrid2}>
            <FormItem styles={{ height: '100%' }}>
              <InputFile
                multiple
                accept={documentsAccept}
                onSelect={this.handleFileSelect}
                onRemove={this.handleFileRemove}
                extra={`${t("text.available-formats")}: doc, docx, txt, rtf, pdf, xls, xlsx, ppt, pptx ${role === 'staff' ? t("text.files-examples") : ''}`}
              />
            </FormItem>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(PortfolioFilesEdit);
