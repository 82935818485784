import React from 'react';
import AuthLayout from '../pages/AuthLayout/AuthLayout';
import SearchLayout from '../pages/SearchLayout/SearchLayout';
import AuthPostLayout from '../pages/AuthPostLayout/AuthPostLayout';

import SignInPage from '../pages/SignInPage/SignInPage';
import SignUpPage from '../pages/SignUpPage/SignUpPage';
import SignUpSocial from '../pages/SignUpSocial/SignUpSocial';
import VkSigninPage from '../pages/VkSigninPage/VkSigninPage'
import TalentSignUpStepper from '../pages/TalentSignUpStepper/TalentSignUpStepper';
import TalentAgreement from '../pages/TalentAgreement/TalentAgreement';
import StaffSignUpStepper from '../pages/StaffSignUpStepper/StaffSignUpStepper';
import StaffAgreement from '../pages/StaffAgreement/StaffAgreement';
import CompanySignUpStepper from '../pages/CompanySignUpStepper/CompanySignUpStepper';
import CompanyAgreement from '../pages/CompanyAgreement/CompanyAgreement';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import NewPassword from '../pages/NewPassword/NewPassword';
import Layout from '../pages/Layout/Layout';
import CreateCasting from '../pages/CreateCasting/CreateCasting';
import CreateExtras from '../pages/CreateExtras/CreateExtras';
import CreateVacancy from '../pages/CreateVacancy/CreateVacancy';
import CreateCastingInvitation from '../pages/CreateCastingInvitation/CreateCastingInvitation';
import CreateVacancyInvitation from '../pages/CreateVacancyInvitation/CreateVacancyInvitation';

import TalentCabinet from '../pages/TalentCabinet/TalentCabinet';
import TalentCabinetEdit from '../pages/TalentCabinetEdit/TalentCabinetEdit';
import TalentCabinetCastings from '../pages/TalentCabinetCastings/TalentCabinetCastings';

import CabinetEducation from '../pages/CabinetEducation/CabinetEducation';
import TutorialView from '../modules/TutorialView/containers/TutorialView';
import CabinetSettings from '../pages/CabinetSettings/CabinetSettings';
import CabinetPaymentTalent from '../pages/CabinetPaymentTalent/CabinetPaymentTalent';

import StaffCabinet from '../pages/StaffCabinet/StaffCabinet';
import StaffCabinetEdit from '../pages/StaffCabinetEdit/StaffCabinetEdit';

import CompanyCabinet from '../pages/CompanyCabinet/CompanyCabinet';
import CompanyCabinetEdit from '../pages/CompanyCabinetEdit/CompanyCabinetEdit';

import CabinetVacancies from '../pages/CabinetVacancies/CabinetVacancies';

import Search from '../pages/Search/Search';
import Boards from '../pages/Boards/Boards';

import signinBgImage from '../../images/signin-bg.png';
import signupBgImage from '../../images/signup-bg.png';

import i18n from "../i18n";

export default [
  {
    path: '/signin',
    action: (ctx) => ({
      component: (<AuthLayout backgroundImage={signinBgImage}><SignInPage query={ctx.query} /></AuthLayout>),
      title: i18n.t('page-title.signin'),
    })
  },
  {
    path: '/vk-signin',
    action: (ctx) => ({
      component: (<VkSigninPage code={ctx.query.code}/>),
      title: i18n.t('page-title.signin'),
    })
  },
  {
    path: '/signup',
    action: () => ({
      component: <AuthLayout backgroundImage={signupBgImage}><SignUpPage /></AuthLayout>,
      title: i18n.t('page-title.signup'),
    })
  },
  {
    path: '/signup-social',
    action: () => ({
      component: <AuthPostLayout backgroundImage={signupBgImage}><SignUpSocial /></AuthPostLayout>,
      title: i18n.t('page-title.signup-social'),
    })
  },
  {
    path: '/talent-steps',
    action: () => ({
      component: <AuthPostLayout backgroundImage={signupBgImage}><TalentSignUpStepper /></AuthPostLayout>,
      title: i18n.t('page-title.signup-steps'),
    })
  },
  {
    path: '/talent-agreement',
    action: () => ({
      component: <AuthPostLayout backgroundImage={signupBgImage}><TalentAgreement /></AuthPostLayout>,
      title: i18n.t('page-title.talent-agreement'),
    })
  },
  {
    path: '/staff-steps',
    action: () => ({
      component: <AuthPostLayout backgroundImage={signupBgImage}><StaffSignUpStepper /></AuthPostLayout>,
      title: i18n.t('page-title.signup-steps'),
    })
  },
  {
    path: '/staff-agreement',
    action: () => ({
      component: <AuthPostLayout backgroundImage={signupBgImage}><StaffAgreement /></AuthPostLayout>,
      title: i18n.t('page-title.staff-agreement'),
    })
  },
  {
    path: '/company-steps',
    action: () => ({
      component: <AuthPostLayout backgroundImage={signupBgImage}><CompanySignUpStepper /></AuthPostLayout>,
      title: i18n.t('page-title.signup-steps'),
    })
  },
  {
    path: '/company-agreement',
    action: () => ({
      component: <AuthPostLayout backgroundImage={signupBgImage}><CompanyAgreement /></AuthPostLayout>,
      title: i18n.t('page-title.company-agreement'),
    })
  },
  {
    path: '/forgot-password',
    action: () => ({
      component: <AuthLayout backgroundImage={signupBgImage}><ForgotPassword /></AuthLayout>,
      title: i18n.t('page-title.forgot-password'),
    })
  },
  {
    path: '/new-password',
    action: (ctx) => ({
      component: <AuthLayout backgroundImage={signupBgImage}><NewPassword
          token={ctx.query.reset_password_token}/></AuthLayout>,
      title: i18n.t('page-title.new-password'),
    })
  },
  {
    path: '/search',
    action: (ctx) => ({
      component: <SearchLayout><Search queries={ctx.query} /></SearchLayout>,
      title: i18n.t('page-title.search'),
    })
  },
  {
    path: '/talent',
    action: () => console.log('checking child routes for /posts'),
    children: [
      {
        path: '', // optional, matches both "/posts" and "/posts/"
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TalentCabinet /></Layout>),
          title: i18n.t('page-title.talent-workspace'),
        })
      },
      {
        path: '/edit',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TalentCabinetEdit tab={ctx.query.tab} /></Layout>),
          title: 'Редактирование анкеты',
        })
      },
      {
        path: '/education',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CabinetEducation queries={ctx.query} /></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/education/video/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TutorialView ctx={ctx} /></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/education/text/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TutorialView ctx={ctx}/></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/castings',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TalentCabinetCastings tab={ctx.query.tab} /></Layout>),
          title: i18n.t('page-title.castings')
        })
      },
      {
        path: '/settings',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CabinetSettings /></Layout>),
          title: i18n.t('page-title.settings')
        })
      },
      // TODO: Disable payments
      // {
      //   path: '/payment',
      //   action: (ctx) => ({
      //     component: (<Layout ctx={ctx}><CabinetPaymentTalent /></Layout>),
      //     title: i18n.t('page-title.payment')
      //   })
      // },
    ],
  },
  {
    path: '/staff',
    action: () => console.log('checking child routes for /posts'),
    children: [
      {
        path: '', // optional, matches both "/posts" and "/posts/"
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><StaffCabinet /></Layout>),
          title: i18n.t('page-title.staff-workspace'),
        })
      },
      {
        path: '/edit',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><StaffCabinetEdit tab={ctx.query.tab} /></Layout>),
          title: i18n.t('page-title.profile-edit'),
        })
      },
      {
        path: '/education',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CabinetEducation queries={ctx.query} /></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/education/video/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TutorialView ctx={ctx} /></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/education/text/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TutorialView ctx={ctx}/></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/boards',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><Boards ctx={ctx}/></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><Boards ctx={ctx}/></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/create_casting',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateCasting ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/casting/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateCasting ctx={ctx} type='edit' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/casting/:id/invitation',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateCastingInvitation ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/create_extras',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateExtras ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/crowding/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateExtras ctx={ctx} type='edit' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/create_vacancy',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateVacancy ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/vacancy/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateVacancy ctx={ctx} type='edit' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/vacancy/:id/invitation',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateVacancyInvitation ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/vacancies',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CabinetVacancies tab={ctx.query.tab} /></Layout>),
          title: i18n.t('page-title.vacancies'),
        })
      },
      {
        path: '/settings',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CabinetSettings /></Layout>),
          title: i18n.t('page-title.settings')
        })
      },
    ],
  },
  {
    path: '/company',
    action: () => console.log('checking child routes for /posts'),
    children: [
      {
        path: '', // optional, matches both "/posts" and "/posts/"
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CompanyCabinet /></Layout>),
          title: i18n.t('page-title.company-workspace'),
        })
      },
      {
        path: '/edit',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CompanyCabinetEdit tab={ctx.query.tab} /></Layout>),
          title: i18n.t('page-title.profile-edit'),
        })
      },
      {
        path: '/education',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CabinetEducation queries={ctx.query} /></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/education/video/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TutorialView ctx={ctx} /></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/education/text/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><TutorialView ctx={ctx}/></Layout>),
          title: i18n.t('page-title.education'),
        })
      },
      {
        path: '/boards',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><Boards ctx={ctx}/></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><Boards ctx={ctx}/></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/create_casting',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateCasting ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/casting/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateCasting ctx={ctx} type='edit' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/casting/:id/invitation',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateCastingInvitation ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/create_extras',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateExtras ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/crowding/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateExtras ctx={ctx} type='edit' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/create_vacancy',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateVacancy ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/vacancy/:id',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateVacancy ctx={ctx} type='edit' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/boards/:boardId/vacancy/:id/invitation',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CreateVacancyInvitation ctx={ctx} type='create' /></Layout>),
          title: i18n.t('page-title.boards'),
        })
      },
      {
        path: '/vacancies',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CabinetVacancies tab={ctx.query.tab} /></Layout>),
          title: i18n.t('page-title.vacancies')
        })
      },
      {
        path: '/settings',
        action: (ctx) => ({
          component: (<Layout ctx={ctx}><CabinetSettings /></Layout>),
          title: i18n.t('page-title.settings')
        })
      },
    ],
  }
]
