import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import get from 'lodash/get';

import formStyles from "../Forms/EditForms.module.scss";

@inject('user', 'navigate')
@observer
class PortfolioVideoEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const moderationFields = [];
        const { role_object } = this.props.user.user;
        if (
          get(role_object, 'video.video_business.link') !== values.video_business ||
          get(role_object, 'video.second_video.link') !== values.second_video ||
          get(role_object, 'video.third_video.link') !== values.third_video ||
          get(role_object, 'video.fourth_video.link') !== values.fourth_video ||
          get(role_object, 'video.fifth_video.link') !== values.fifth_video
        ) {
          moderationFields.push('video');
        }

        const data = {
          talent: {
            video_attributes: {
              video_business: values.video_business,
              second_video: values.second_video,
              third_video: values.third_video,
              fourth_video: values.fourth_video,
              fifth_video: values.fifth_video,
            },
            moderated_fields: moderationFields
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { user, t, form, data = {} } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const { role_object } = user.user;
    const payStatus = get(role_object, 'subscription.status');

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={formStyles.formGrid}>
          <div className={formStyles.formGrid2}>
            <FormItem>
              <FormTitle>{t('label.video-business')}</FormTitle>
              {getFieldDecorator('video_business', {
                initialValue: get(role_object, 'video.video_business.link'),
              })(
                <Input
                  name="video_business"
                  placeholder={t('label.video-link')}
                  errors={getFieldError('video_business')}
                />
              )}
            </FormItem>
          </div>
          
          {(
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('label.video')} 2</FormTitle>
                {getFieldDecorator('second_video', {
                  initialValue: get(role_object, 'video.second_video.link'),
                })(
                  <Input
                    name="second_video"
                    placeholder={t('label.video-link')}
                    errors={getFieldError('second_video')}
                  />
                )}
              </FormItem>
            </div>
          )}
        </div>
        
        {(
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('label.video')} 3</FormTitle>
                {getFieldDecorator('third_video', {
                  initialValue: get(role_object, 'video.third_video.link'),
                })(
                  <Input
                    name="third_video"
                    placeholder={t('label.video-link')}
                    errors={getFieldError('third_video')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('label.video')} 4</FormTitle>
                {getFieldDecorator('fourth_video', {
                  initialValue: get(role_object, 'video.fourth_video.link'),
                })(
                  <Input
                    name="fourth_video"
                    placeholder={t('label.video-link')}
                    errors={getFieldError('fourth_video')}
                  />
                )}
              </FormItem>
            </div>
          </div>
        )}

        {(
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('label.video')} 5</FormTitle>
                {getFieldDecorator('fifth_video', {
                  initialValue: get(role_object, 'video.fifth_video.link'),
                })(
                  <Input
                    name="fifth_video"
                    placeholder={t('label.video-link')}
                    errors={getFieldError('fifth_video')}
                  />
                )}
              </FormItem>
            </div>
          </div>
        )}

        <div className={formStyles.formGrid}>
          <FormItem>
              <Button kind="primary" type="submit">{t("button.save")}</Button>
          </FormItem>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(PortfolioVideoEdit));
