import apiClient from "./api-client";

const signIn = async data => apiClient.create("/users/sign_in", { data });

const fetchProfile = async () => apiClient.read("/users/sign_in");

const signUp = async data => apiClient.create("/users", { data });

const restorePassword = async data =>
  apiClient.create("/users/password", { data });

const updatePassword = async data =>
  apiClient.update("/users/password", { data });

const changePassword = async data => apiClient.update("/users", { data });

const changeLanguage = async data => apiClient.update(`/users/${data.id}`, { data });

const changeLocale = async data => apiClient.update(`/users/change_locale`, {data, headers: { "Content-Type": "application/json" }})

const freezeAccount = async userId =>
  apiClient.update(`/users/${userId}/freeze_status`);

const unfreezeAccount = async userId =>
  apiClient.update(`/users/${userId}/unfreeze_status`);

const logout = async () => apiClient.delete("/users/sign_out");

const updateRoleObject = async (userId, id, roleString, data) =>
  apiClient.update(`/users/${userId}/${roleString}/${id}`, { data });

const updateUser = async (userId, data) =>
  apiClient.update(`/users/${userId}`, { data });

const fetchEducationList = async () => apiClient.read("/educations/list");

const fetchLanguagesList = async () => apiClient.read("/languages/list");

const fetchAppearanciesList = async () => apiClient.read("/appearancies/list");

const fetchCareersList = async () => apiClient.read("/careers/list");

const fetchSportsList = async () => apiClient.read("/sports/list");

const fetchInstrumentsList = async () => apiClient.read("/instruments/list");

const fetchPhysiquesList = async () => apiClient.read("/physiques/list");

const fetchHairsList = async () => apiClient.read("/hairs/list");

const fetchEyesList = async () => apiClient.read("/eyes/list");

const fetchMovieTypesList = async () => apiClient.read("/movie_types/list");

const fetchCompanyServicesList = async () => apiClient.read("/company_services/list");

const fetchCountriesList = async () => apiClient.read("/countries/list");

const fetchCurrenciesList = async () => apiClient.read("/currencies/list");

const fetchAdBannersList = async () => apiClient.read("/ad_banners/list");

const fetchTutorialsList = async data =>
  apiClient.create("/tutorials/list", { data });

const deleteTalentPhoto = async (userId, id, data) =>
  apiClient.delete(`/users/${userId}/talents/${id}/delete_photo`, { data });

const paySubscription = async data =>
  apiClient.create(
    `/users/${data.userId}/talents/${data.talentId}/payment?subscription=${data.type}`
  );

const payAutoSubscription = async data =>
  apiClient.create(
    `/users/${data.userId}/talents/${data.talentId}/payment?subscription=${data.type}`
  );

const unSubscribe = async data =>
  apiClient.read(
    `/users/${data.userId}/talents/${data.talentId}/unsubscribe`
  );


const fetchVideoTutorial = async id => apiClient.read(`/tutorials/${id}/video`);

const fetchTextTutorial = async id => apiClient.read(`/tutorials/${id}/text`);

const fetchTutorialsCategories = async () =>
  apiClient.read("/tutorials/categories");

const downloadCV = async (userId, id, roleString, locale) =>
  apiClient.read(`/users/${userId}/${roleString}/${id}/show_pdf?locale=${locale}`, {
    headers: {
      "Content-Type": "application/pdf",
      Accept: "application/pdf",
      "Content-Disposition": "attachment"
    },
    data: {},
    responseType: "blob"
  });

const downloadManyCV = async (userId, roleString, id, data) =>
  apiClient.create(`/users/${userId}/${roleString}/${id}/show_many_pdf`, {
    headers: {
      "Content-Type": "application/json",
      // Accept: "application/zip",
      // "Content-Disposition": "attachment"
    },
    data,
    responseType: "blob"
  });

const searchTalents = async data =>
  apiClient.read("/searcher/search_talents", { query: data });

const advertisingRequest = async id =>
  apiClient.read(`/users/${id}/ad_statement`);

const deleteFile = async (userId, data) =>
  apiClient.delete(`/users/${userId}/delete_file`, { data });

const fetchBoards = async userId => apiClient.read(`/users/${userId}/projekts`);

const fetchFavoriteTalents = async userId =>
  apiClient.read(`/users/${userId}/favorites/talents`);

const fetchFavoriteStaffs = async userId =>
  apiClient.read(`/users/${userId}/favorites/staffs`);

const fetchFavoriteCompanies = async userId =>
  apiClient.read(`/users/${userId}/favorites/companies`);

const addFavorite = async (userId, userRoleId, data) =>
  apiClient.create(`/users/${userId}/favorites/${userRoleId}/add_favorite`, { data });

const addFavoritesToProjekt = async (userId, projektId, data) =>
  apiClient.create(`/users/${userId}/projekts/${projektId}/add_favorite_roles`, { data });

const removeFavorites = async (userId, data) =>
  apiClient.delete(`/users/${userId}/favorites/delete_favorites`, { data });

const markEvent = async (userId, data) =>
  apiClient.create(`/users/${userId}/events`, { data });

const fetchTalentCastings = async (userId, talentId) =>
  apiClient.read(`/users/${userId}/talents/${talentId}/castings`);

const fetchTalentCrowdings = async (userId, talentId) =>
  apiClient.read(`/users/${userId}/talents/${talentId}/crowdings`);

const fetchTalentInvitations = async (userId, talentId) =>
  apiClient.read(`/users/${userId}/talents/${talentId}/invitations`);

const fetchProject = async (userId, projektId) =>
  apiClient.read(`/users/${userId}/projekts/${projektId}`);

const fetchProjects = async (userId) =>
  apiClient.read(`/users/${userId}/projekts`);

const createProject = async (userId, values) =>
  apiClient.create(`/users/${userId}/projekts`, { data: values });

const updateProject = async (userId, projektId, values) =>
  apiClient.update(`/users/${userId}/projekts/${projektId}`, { data: values });

const deleteProject = async (userId, projektId, values) =>
  apiClient.delete(`/users/${userId}/projekts/${projektId}`);

const approveInvitation = async (userId, favoriteId) =>
  apiClient.update(`/users/${userId}/favorites/${favoriteId}/approve_invitation`);

const refuseInvitation = async (userId, favoriteId) =>
  apiClient.update(`/users/${userId}/favorites/${favoriteId}/refuse_invitation`);

const deleteInvitation = async (userId, favoriteId) =>
  apiClient.update(`/users/${userId}/favorites/${favoriteId}/delete_invitation`);

const acceptFavoriteProjekt = async (userId, projektId) =>
  apiClient.create(`/users/${userId}/projekts/${projektId}/add_favorite_role/${userId}`);

const createAdvertisement = async (userId, projektId, values) =>
  apiClient.create(`/users/${userId}/projekts/${projektId}/announcements`, { data: values });

const updateAdvertisement = async (userId, projektId, id, values) =>
  apiClient.update(`/users/${userId}/projekts/${projektId}/announcements/${id}`, { data: values });

const removeAdvertisement = async (userId, projektId, id) =>
  apiClient.delete(`/users/${userId}/projekts/${projektId}/announcements/${id}`);

const createVacancy = async (userId, projektId, values) =>
  apiClient.create(`/users/${userId}/projekts/${projektId}/vacancies`, { data: values });

const updateVacancy = async (userId, projektId, id, values) =>
  apiClient.update(`/users/${userId}/projekts/${projektId}/vacancies/${id}`, { data: values });

const removeVacancy = async (userId, projektId, id) =>
  apiClient.delete(`/users/${userId}/projekts/${projektId}/vacancies/${id}`);

const fetchStaffVacancies = async (userId, staffId) =>
  apiClient.read(`/users/${userId}/staffs/${staffId}/vacancies`);

const fetchStaffInvitations = async (userId, staffId) =>
  apiClient.read(`/users/${userId}/staffs/${staffId}/invitations`);

const fetchCompanyVacancies = async (userId, companyId) =>
  apiClient.read(`/users/${userId}/companies/${companyId}/vacancies`);

const fetchCompanyInvitations = async (userId, companyId) =>
  apiClient.read(`/users/${userId}/companies/${companyId}/invitations`);

export default {
  signIn,
  fetchProfile,
  signUp,
  restorePassword,
  updatePassword,
  changePassword,
  changeLanguage,
  changeLocale,
  freezeAccount,
  unfreezeAccount,
  logout,
  updateRoleObject,
  updateUser,
  fetchEducationList,
  fetchLanguagesList,
  fetchAppearanciesList,
  fetchCareersList,
  fetchMovieTypesList,
  fetchCompanyServicesList,
  fetchCountriesList,
  fetchCurrenciesList,
  fetchSportsList,
  fetchInstrumentsList,
  fetchPhysiquesList,
  fetchHairsList,
  fetchEyesList,
  fetchAdBannersList,
  fetchTutorialsList,
  deleteTalentPhoto,
  paySubscription,
  payAutoSubscription,
  unSubscribe,
  fetchVideoTutorial,
  fetchTextTutorial,
  fetchTutorialsCategories,
  downloadCV,
  downloadManyCV,
  searchTalents,
  advertisingRequest,
  deleteFile,
  fetchBoards,
  fetchFavoriteTalents,
  fetchFavoriteStaffs,
  fetchFavoriteCompanies,
  addFavorite,
  removeFavorites,
  markEvent,
  addFavoritesToProjekt,
  fetchTalentCastings,
  fetchTalentCrowdings,
  fetchTalentInvitations,
  fetchProject,
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
  approveInvitation,
  refuseInvitation,
  deleteInvitation,
  acceptFavoriteProjekt,
  createAdvertisement,
  updateAdvertisement,
  removeAdvertisement,
  createVacancy,
  updateVacancy,
  removeVacancy,
  fetchStaffVacancies,
  fetchStaffInvitations,
  fetchCompanyVacancies,
  fetchCompanyInvitations,
};
