import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import Button from '../Button/Button';
import moment from 'moment';
import get from 'lodash/get';

import ModalInfo from './ModalInfo';
import ModalSuccess from './ModalSuccess';

@inject("talent", "dataStore")
@observer
class ModalCrowding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccessModal: false,
    }
  }

  handleApproveClick = () => {
    this.props.talent.acceptFavoriteProjekt(this.props.data.projekt_id)
      .then(() => this.setState({ showSuccessModal: true }));
  }

  handleCloseModal = () => {
    this.setState({ showSuccessModal: false });
    this.props.talent.fetchTalentCrowdings();
    this.props.onClose()
  }

  render() {
    const { t, isOpen, data, dataStore, talent, isOwner } = this.props;

    const startDate = moment(data.start_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const endDate = moment(data.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY');

    const startTime = moment(data.time_start).format('HH:mm');
    const endTime = moment(data.time_end).format('HH:mm');

    const dataArray = [
      { label: t("label.project"), value: `${dataStore.getMovieTypeById(data.projekt_type)} ${get(data, 'projekt_name')}` },
      { label: t("label.producer"), value: data.director ? data.director : '–' },
      { label: t("label.shooting-in"), value: data.shooting_cities ? data.shooting_cities : '–' },
      { label: t("label.shooting-dates"), value: data.start_date ? `${startDate} – ${endDate}` : '–' },
      { label: t("label.time-casting"), value: data.start_time ? `${startTime} – ${endTime}` : '–' },
      { label: t("label.gathering-place"), value: data.place ? data.place : '–' },
      { label: t("label.payment"), value: data.rate ? `${data.rate} ${data.currency}` : '–' },
      {
        label: t("label.author"),
        value: (
          <>
            <p>{data.sender_first_name} {data.sender_last_name}</p>
            <p>{data.phone}</p>
            <p>{data.email}</p>
          </>
        )
      }
    ];


    return this.state.showSuccessModal ? (
      <ModalSuccess
        isOpen={isOpen}
        onClose={this.handleCloseModal}
        title={t("text.casting-accept-title")}
      >
        <div style={{ marginBottom: 30, lineHeight: 1.4 }}>{t("text.casting-accept-text")}</div>
        <Button onClick={this.handleCloseModal}>{t('button.close')}</Button>
      </ModalSuccess>
    ) : (
      <ModalInfo
        title={t('label.crowding')}
        isOpen={isOpen}
        dataArray={dataArray}
        data={data}
        attention={data.attention}
        isUpdating={talent.isUpdating}
        onClose={this.props.onClose}
      >
        {!isOwner && (
          data.is_participant ? (
            <p>{t("text.you-participating")}</p>
          ): (
            <Button onClick={this.handleApproveClick}>{t('button.participate')}</Button>
          )
        )}
      </ModalInfo>
    );
  }
}

export default withTranslation()(ModalCrowding);
