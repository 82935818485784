import React from "react";
import { Collapse } from "react-collapse";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import Checkbox from "../../components/Checkbox/Checkbox";
import FormTitle from "../../components/FormTitle/FormTitle";
import FormItem from "../../components/FormItem/FormItem";

import get from 'lodash/get';

import SearchMultiselect from './components/SearchMultiselect';

import styles from "./Search.module.scss";

@inject("user", "dataStore")
@observer
class CompanyCommonSearchBlock extends React.Component {
  handleCollapse = () => this.props.toggleCollapse('common');

  render() {
    const { form, user, dataStore, isOpen, t, queries = {} } = this.props;
    const { searchAggregations } = user;
    const { getFieldDecorator, getFieldError } = form;

    const { companyServicesOptions, companyTypes } = dataStore;

    const formProps = { form, queries };

    const professionsAggr = get(searchAggregations, 'professions', []).map(p => p.key);

    const filteredCompanyServices = companyServicesOptions.filter(cs => professionsAggr.includes(cs.value));


    return (
      <div className={styles.collapseWrapper}>
        <div
          onClick={this.handleCollapse}
          className={cn(styles.collapseHeader, {
            [styles.collapseHeaderActive]: isOpen
          })}
        >
          {t("title.common-data")}
        </div>
        <Collapse isOpened={isOpen}>
          <div className={styles.collapseBody}>
            <SearchMultiselect
              {...formProps}
              title="input.company-activity"
              field="service"
              options={filteredCompanyServices}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}
export default withTranslation()(CompanyCommonSearchBlock);
