import React from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import Textarea from "../../../components/Textarea/Textarea";
import FormItem from "../../../components/FormItem/FormItem";
import FormTitle from "../../../components/FormTitle/FormTitle";
import Input from "../../../components/Input/Input";
import InputMask from "../../../components/InputMask/InputMask";
import Select from "../../../components/Select/Select";
import InputDatepicker from "../../../components/InputDatepicker/InputDatepicker";
import InputTimepicker from "../../../components/InputTimepicker/InputTimepicker";

import styles from "../EditForms.module.scss";

const Description = ({ form, initialValues, t, dataStore }) => {
  const { getFieldDecorator, getFieldError, getFieldValue } = form;

  return (
    <>
      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.invite-position")}</FormTitle>
            {getFieldDecorator("target", {
              initialValue: initialValues.target,
              rules: [{ required: true, message: t("validation.input.required") }]
            })(
              <Input
                required
                name="target"
                placeholder={t("input.enter-title")}
                errors={getFieldError("target")}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormTitle>{t("input.shift-rate")}</FormTitle>
          <div className={styles.currencyRate}>
            {getFieldDecorator("rate", { initialValue: initialValues.rate })(
              <InputMask
                wrapperClassName={styles.currencyRateInput}
                mask={"9999999999"}
                name="rate"
                autocomplete="off"
                placeholder={t("input.shift-rate-placeholder")}
                errors={getFieldError('rate')}
              />
            )}
            {getFieldDecorator('currency', {
              initialValue: initialValues.currency,
              rules: [{ required: getFieldValue('rate'), message: t('validation.input.required') }]
            })(
              <Select
                wrapperClassName={styles.currencyRateSelect}
                placeholder={t('input.currency')}
                options={dataStore.currencyOptions}
                errors={getFieldError('currency')}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem styles={{ marginBottom: 10 }}>
            <FormTitle>{t("label.shooting-dates")}</FormTitle>
            <div style={{ display: "flex" }}>
              <div className={styles.formPartLeft}>
                <FormTitle styles={{ maxHeight: 39 }} lowercase>{t("input.from")}</FormTitle>
                {getFieldDecorator("start_date", {
                  initialValue: initialValues.start_date,
                  rules: [{ required: true, message: t("validation.input.required") }]
                })(
                  <InputDatepicker
                    minDate={new Date()}
                    errors={getFieldError("start_date")}
                  />
                )}
              </div>
              <div className={styles.formPartRight}>
                <FormTitle styles={{ maxHeight: 39 }} lowercase>{t("input.to")}</FormTitle>
                {getFieldDecorator("end_date", {
                  initialValue: initialValues.end_date,
                  rules: [{ required: true, message: t("validation.input.required")}]
                })(
                  <InputDatepicker
                    minDate={getFieldValue("start_date") || new Date()}
                    errors={getFieldError("end_date")}
                  />
                )}
              </div>
            </div>
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.shooting-time")}</FormTitle>
            <div style={{ display: "flex" }}>
              <div className={styles.formPartLeft}>
                <FormTitle lowercase>{t("input.from")}</FormTitle>
                {getFieldDecorator("time_start", {
                  initialValue: initialValues.time_start
                })(
                  <InputTimepicker
                    placeholder="00:00"
                    errors={getFieldError("time_start")}
                  />
                )}
              </div>
              <div className={styles.formPartRight}>
                <FormTitle lowercase>{t("label.to")}</FormTitle>
                {getFieldDecorator("time_end", {
                  initialValue: initialValues.time_end
                })(
                  <InputTimepicker
                    placeholder="00:00"
                    errors={getFieldError("time_end")}
                  />
                )}
              </div>
            </div>
          </FormItem>
        </div>
      </div>
      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem styles={{ marginBottom: 10 }} >
            <FormTitle>{t("label.vacancy-active")}</FormTitle>
            <div style={{ display: 'flex' }}>
              <div className={styles.formPartLeft}>
                <FormTitle styles={{ maxHeight: 39 }} lowercase>{t("input.from")}</FormTitle>
                {getFieldDecorator("vacancy_start_date", {
                  initialValue: initialValues.vacancy_start_date,
                  rules: [
                    { required: true, message: t("validation.input.required") }
                  ]
                })(
                  <InputDatepicker
                    required
                    minDate={new Date()}
                    errors={getFieldError("vacancy_start_date")}
                  />
                )}
              </div>
              <div className={styles.formPartRight}>
                <FormTitle styles={{ maxHeight: 39 }} lowercase>{t("input.to")}</FormTitle>
                {getFieldDecorator("vacancy_end_date", {
                  initialValue: initialValues.vacancy_end_date,
                  rules: [
                    { required: true, message: t("validation.input.required") }
                  ]
                })(
                  <InputDatepicker
                    required
                    minDate={getFieldValue("vacancy_start_date") || new Date()}
                    errors={getFieldError("vacancy_end_date")}
                  />
                )}
              </div>
            </div>
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.address")}</FormTitle>
            {getFieldDecorator("place", {
              initialValue: initialValues.place
            })(
              <Input
                name="place"
                errors={getFieldError("place")}
                placeholder={t("input.enter-address")}
              />
            )}
          </FormItem>
        </div>
      </div>

      <div className={styles.formGrid20}>
        <FormItem styles={{ width: '100%' }}>
          <FormTitle>{t("label.description")}</FormTitle>
          {getFieldDecorator("about", {
            initialValue: initialValues.about
          })(<Textarea
            name="about"
            errors={getFieldError("about")}
            extra={t("text.full-vacancy-info")}
          />)}
        </FormItem>
      </div>
    </>
  );
};

export default compose(
  withTranslation(),
  inject("dataStore", "navigate"),
  observer
)(Description);
