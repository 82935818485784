import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./BoardCardCreate.module.scss";

class BoardCardCreate extends Component {
  handleClick = () => {
    this.props.onClick("create");
  };

  render() {
    const { t } = this.props;

    return (
      <div className={styles.wrapper} onClick={this.handleClick}>
        <div className={styles.board}>
          <div className={styles.boardCreate}></div>
        </div>
        <div className={styles.boardText}>{t("button.create-board")}</div>
      </div>
    );
  }
}

export default withTranslation()(BoardCardCreate);
