import React, { Component } from 'react';
import ReactPhoneInput from "react-phone-input-2"
import ru from 'react-phone-input-2/lang/ru.json'
import isNull from 'lodash/isNull';
import styles from './InputPhone.module.scss';

export default class InputPhone extends Component {
  render() {
    const { placeholder, required, locale, onChange, value, errors = [] } = this.props;

    return (
      <div className={styles.wrapper}>
        <ReactPhoneInput
          inputExtraProps={{
            required
          }}
          country="kz"
          localization={ru}
          preferredCountries={['kz', 'ru', 'by', 'ua']}
          areaCodes={{ kz: ['700', '701', '702', '703', '704', '705', '706', '707', '708', '709', '747', '750', '751', '760', '761', '762', '763', '764', '771', '775', '776', '777', '778'] }}
          value={isNull(value) ? '' : value}
          placeholder={required ? placeholder + ' *' : placeholder}
          onChange={onChange}
          inputClass={styles.input}
          buttonClass={styles.button}
        />
        <p className={styles.errors}>{errors.length > 0 ? errors.join(', ') : null}</p>
      </div>
    )
  }
}
