import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import FormItemControls from '../../components/FormItemControls/FormItemControls';
import get from 'lodash/get';
import moment from 'moment';

import formStyles from "../Forms/EditForms.module.scss";

@inject('user', 'dataStore', 'navigate')
@observer
class EducationUniversityEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const moderationFields = [];

        if (!get(this.props.data, 'id')) {
          moderationFields.push('new_education');
        }

        if (
          get(this.props.data, 'id') &&
          values.university.value !== get(this, 'props.data.university_dictionary_id') ||
          values.specialty !== get(this, 'props.data.specialty') ||
          values.status_type.value !== get(this, 'props.data.status_type') ||
          !(get(this, 'props.data', '').includes(values.year.value))
        ) {
          moderationFields.push('update_education');
        }

        const data = {
          talent: {
            educations_attributes: [{
              university_dictionary_id: values.university.value,
              specialty: values.specialty,
              status_type: values.status_type.value,
              year: new Date(values.year.value, 0, 2),
              id: get(this.props.data, 'id')
            }],
            moderated_fields: moderationFields
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, form, data = {}, dataStore } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const { yearOptions, universityStatusTypes, universitiesOptions } = dataStore;

    const dataUniversity = data.id
      ? universitiesOptions.find(t => t.value == data.university_dictionary_id)
      : null;
    const dataStatus = data.id
      ? universityStatusTypes.find(t => t.value == data.status_type)
      : null;
    const dataYear = data.year
      ? yearOptions.find(t => t.value === moment(data.year, 'YYYY-MM-DD').format('YYYY'))
      : null;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={formStyles.formGrid} style={{ marginBottom: 0 }}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.university')}</FormTitle>
                {getFieldDecorator('university', {
                  initialValue: dataUniversity,
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Select
                    required
                    placeholder={t('input.university')}
                    options={universitiesOptions}
                    errors={getFieldError('university')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.university-specialty')}</FormTitle>
                {getFieldDecorator('specialty', {
                  initialValue: get(data, 'specialty', ''),
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Input
                    required
                    name="specialty"
                    placeholder={t('input.university-specialty')}
                    errors={getFieldError('specialty')}
                  />
                )}
              </FormItem>
            </div>
          </div>
          <div className={formStyles.formGrid} style={{ marginBottom: 0 }}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.university-status')}</FormTitle>
                {getFieldDecorator('status_type', {
                  initialValue: dataStatus,
                  rules: [{ required: true, message: t('validation.input.required') }]
                })(
                  <Select
                    required
                    placeholder={t('input.university-status')}
                    options={universityStatusTypes}
                    errors={getFieldError('status_type')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('input.university-year')}</FormTitle>
                {getFieldDecorator('year', {
                  initialValue: dataYear,
                })(
                  <Select
                    placeholder={t('input.university-year')}
                    options={yearOptions}
                    errors={getFieldError('year')}
                  />
                )}
              </FormItem>
            </div>
          </div>
        <div className={formStyles.formGrid}>
          <FormItemControls data={data} entity="educations"/>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(EducationUniversityEdit));
