import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import closeImage from "../../../images/close-modal-icon.png";

import styles from './ModalInfo.module.scss';

Modal.setAppElement('#app');

class ModalInfo extends Component {
  handleClose = () => {
    this.props.onClose();
  }

  render() {
    const { t, isOpen, title, dataArray = [], attention, children, isUpdating, data, status } = this.props;

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 541,
        height: 'auto',
        minHeight: 'auto',
        maxHeight: 'calc(100% - 100px)'
      }
    };

    return (
      <Modal
        isOpen={isOpen}
        style={modalStyle}
        onRequestClose={this.handleClose}
      >
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <p className={styles.titleText}>{title}</p>
          </div>
          <table className={styles.dataTable}>
            <tbody>
              {dataArray.filter(item => item.value && item.value !== "–").map(item => (
                <tr className={styles.dataCell} key={item.label}>
                  <td className={styles.dataLabel}>{item.label}:</td>
                  <td className={styles.dataValue}>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {attention && (<div>
            <p className={styles.attentionLabel}>{t("label.pay-attention")}</p>
            <p className={styles.attentionText}>{attention}</p>
          </div>)}
          {children}
          <img className={styles.modalClose} onClick={this.handleClose} src={closeImage} />
          <Loader isLoading={isUpdating}/>
        </div>
        {status}
      </Modal>
    );
  }
}

export default withTranslation()(ModalInfo);
