import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import cn from 'classnames';
import axios from 'axios';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import styles from './InputPlacesAutocomplete.module.scss';

import arrowIcon from "../../../images/bordered-arrow-down.png";
const API_KEY = 'AIzaSyA9vDFB6oKnhDCfHhJh7xp1j-mHD5DMaJc';

let inputRef = undefined;

@inject("user")
@observer
class InputPlacesAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: props.value,
    }
  }

  handleSelect = (data) => {
    const ruResult = axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${data.place_id}&key=${API_KEY}&language=ru`);
    const enResult = axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${data.place_id}&key=${API_KEY}&language=en`);

    Promise.all([ruResult, enResult]).then(responses => {
      const getCityName = (res) => get(res, 'data.results[0].address_components[0].long_name');
      const cityRu = getCityName(responses[0]);
      const cityEn = getCityName(responses[1]);

      this.props.onChange([cityRu, cityEn]);
    });
  }

  static getDerivedStateFromProps(props, state) {
    const lang = props.user.language;

    if (get(props, 'value[0]') !== state.initialValue || get(props, 'value[1]') !== state.initialValue) {
      if (!props.value && inputRef) {
        inputRef.changeValue('');
      }

      return { initialValue: isArray(props.value) ? props.value[lang === 'ru' ? 0 : 1] : props.value };
    }

    return null;
  }

  render() {
    const { value, extra, size, required, wrapperClassName, placeholder, errors = [], inputClassName, autocompletionRequest } = this.props;

    return (
      <div
        style={{ marginBottom: extra ? -18 : 0, position: 'relative' }}
        className={cn(styles.wrapper, wrapperClassName)}
      >
        <GooglePlacesAutocomplete
          apiKey={API_KEY}
          ref={(input) => inputRef = input}
          initialValue={this.state.initialValue}
          inputClassName={cn(styles.input, size === 'small' && styles.inputSmall)}
          autocompletionRequest={autocompletionRequest}
          placeholder={required ? placeholder + ' *' : placeholder}
          onSelect={this.handleSelect}
        />

        <img src={arrowIcon} className={styles.dropdownIndicator} />

        {extra && (<p className={styles.extra}>{extra}</p>)}
        <p className={styles.errors}>{errors.length > 0 ? errors.join(', ') : null}</p>
      </div>
    )
  }
}

export default InputPlacesAutocomplete;
