import React, { Component } from 'react';
import Input from '../Input/Input';
import { withTranslation } from 'react-i18next';
import isURL from 'validator/lib/isURL';

class InputMaskInstagram extends Component {
  render() {
    const { t, form } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    return (
      getFieldDecorator('instagram', {
        initialValue: this.props.initialValue,
        rules: [
          {validator(rule, value) {
            if (value && !isURL(value, {
              require_protocol: true,
              protocols: ['http','https'],
              host_whitelist: ['instagram.com', 'www.instagram.com']
            })) {
              return [new Error(t("validation.input.instagram"))];
            }

            return [];
          }}
        ]
      })(
        <Input
          name="instagram"
          placeholder={t('input.your-instagram-profile')}
          errors={getFieldError('instagram')}
        />
      )
    )
  }
}

export default withTranslation()(InputMaskInstagram);
