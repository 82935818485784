import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';
import ReactTooltip from "react-tooltip";
import { withTranslation } from 'react-i18next';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import BoardMoveModal from "../../components/BoardMoveModal/BoardMoveModal";
import ModalInvitation from '../../components/ModalInfo/ModalInvitation';
import ModalVacancyInvitation from '../../components/ModalInfo/ModalVacancyInvitation';
import inboxIcon from "../../../images/favorite-big-icon.png";
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import styles from './BoardFavorites.module.scss';
import { func } from 'prop-types';

@inject('user', 'dataStore', 'navigate')
@observer
class BoardFavorites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMoveModalOpen: false,
      isInvitationModalOpen: false,
      tab: 'talent',
      selectedFavorites: [],
      visibleCVDropdown: false,
    }
  }

  componentDidMount() {
    this.props.onTabChange('talent');
  }

  handleChangeTab = (tab) => {
    this.setState({ tab, selectedFavorites: [] });
    this.props.onTabChange(tab);
  }

  handleSelectFavorite = (id) => {
    const { selectedFavorites } = this.state;

    if (!selectedFavorites.includes(id)) {
      this.setState({ selectedFavorites: [...selectedFavorites, id] });
    } else {
      this.setState({ selectedFavorites: selectedFavorites.filter(f => f !== id) });
    }
  }

  handleSelectAllClick = () => {
    const favorites = this.props[this.state.tab] || [];

    if (favorites.length === this.state.selectedFavorites.length) {
      this.setState({ selectedFavorites: [] });
    } else {
      this.setState({ selectedFavorites: favorites.map(f => f.id) });
    }
  }

  handleDownloadAllCvClick = (with_contacts) => {
    const { tab } = this.state;
    const userRoleIds = this.getRoleIdsFromFavorites();

    this.props.user.downloadManyCV({ roles_name: tab, roles_id: userRoleIds, with_contacts: with_contacts });
    this.confirm()
  }

  toggleMoveModal = () => this.setState({ isMoveModalOpen: !this.state.isMoveModalOpen });

  handleMoveClick = () => this.toggleMoveModal();

  handleShowInvitation = (id) => {
    this.setState({ isInvitationModalOpen: id });
  }

  handleInvitationModalClose = () => {
    this.setState({ isInvitationModalOpen: undefined });
  }

  handleMoveFavorites = (projektId) => {
    const currentProjektId = get(this.props.ctx, "params.boardId");
    const { tab, selectedFavorites } = this.state;
    const roleFavorites = this.props[tab] || [];
    const favorites = roleFavorites.filter(f => selectedFavorites.includes(f.id));
    const userRoleIds = favorites.map(f => f[`${tab}_id`]);

    return this.props.dataStore.addFavoritesToProjekt(projektId, {
      user_role_ids: userRoleIds,
      favorite_role: this.state.tab,
      old_projekt: this.props.notCopyWhenMove ? currentProjektId : undefined,
    }).then(() => {
      this.setState({ isMoveModalOpen: false, selectedFavorites: [] });
    });
  }

  handleRemoveFavorite = (id, advertisementId, vacancyId) => {
    this.props.dataStore.removeFavorites({
      favorite_role: this.state.tab,
      favorite_ids: [id],
      advertisement_ids: [advertisementId],
      vacancy_ids: [vacancyId]
    });
  }

  getRoleIdsFromFavorites = () => {
    const { tab, selectedFavorites } = this.state;
    const roleFavorites = this.props[tab] || [];
    const favorites = roleFavorites.filter(f => selectedFavorites.includes(f.id));
    const userRoleIds = favorites.map(f => f[`${tab}_id`]);

    return userRoleIds;
  }

  handleInviteSelected = () => {
    const userRoleIds = this.getRoleIdsFromFavorites();

    this.props.onInviteClick(userRoleIds, this.state.tab);
  }

  handleRemoveSelectedFavorites = () => {
    const { tab, selectedFavorites } = this.state;
    const favorites = this.props[tab] || [];
    const favoritesWithAdv = favorites.filter(f => selectedFavorites.includes(f.id) && f.advertisement);
    const advertisementIds = favoritesWithAdv.map(f => get(f, 'advertisement.id'));

    const req = this.props.dataStore.removeFavorites({
      favorite_role: tab,
      favorite_ids: selectedFavorites,
      advertisement_ids: advertisementIds
    })

    req.then(() => this.setState({ selectedFavorites: [] }));
  }

  onVisibleChange = (visibleCVDropdown) => {
    this.setState({
      visibleCVDropdown,
    });
  }

  selected = [];

  saveSelected = ({ selectedKeys }) => {
    this.selected = selectedKeys;
  }

  confirm = () => {
    console.log(this.selected);
    this.setState({
      visibleCVDropdown: false,
    });
  }

  render() {
    const { user, t, dataStore, i18n, level, lang } = this.props;
    const { tab, selectedFavorites } = this.state;
    const favorites = this.props[tab] || [];
    const isSelectedFavoritesWithInvititation = favorites.filter(f => selectedFavorites.includes(f.id) && f.advertisement).length > 0;
    const isSelectedFavoritesWithVacancy = favorites.filter(f => selectedFavorites.includes(f.id) && f.vacancy).length > 0;
    const isActionsBlocked = isSelectedFavoritesWithInvititation || isSelectedFavoritesWithVacancy;

    const acumProjects = (projekts) => projekts.reduce((acc, projekt) => {
        acc.push(projekt);

        if (projekt.projekts.length > 0) {
          return acc.concat(acumProjects(projekt.projekts));
        } else {
          return acc;
        }
      }, []);

    const allProjekts = acumProjects(dataStore.projectsList);

    const menu = (
      <Menu
        className={styles.dropdownMenu}
        multiple
        onSelect={this.saveSelected}
        onDeselect={this.saveSelected}
        onMouseEnter={false}
      >
        <MenuItem key="1">
          <Button
            kind="gray"
            wrapperClassName={styles.buttonSubmenuSmall}
            onClick={() => this.handleDownloadAllCvClick(true)}
          >
            {t("button.download-pdf-with-contacts")}
          </Button>
        </MenuItem>
        <MenuItem key="2">
          <Button
            kind="gray"
            wrapperClassName={styles.buttonSubmenuSmall}
            onClick={() => this.handleDownloadAllCvClick(false)}
          >
            {t("button.download-pdf-without-contacts")}
          </Button>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={styles.wrapper}>
        <BoardMoveModal
          isOpen={this.state.isMoveModalOpen}
          projekts={allProjekts}
          handleClose={this.toggleMoveModal}
          onFavoritesMove={this.handleMoveFavorites}
        />
        <div className={styles.tabs}>
          <div
            className={cn(styles.tab, tab === 'talent' && styles.tabActive)}
            onClick={() => this.handleChangeTab('talent')}
          >{t("title.talents")}</div>
          <div
            className={cn(styles.tab, tab === 'staff' && styles.tabActive)}
            onClick={() => this.handleChangeTab('staff')}
          >{t("title.staffs")}</div>
          <div
            className={cn(styles.tab, tab === 'company' && styles.tabActive)}
            onClick={() => this.handleChangeTab('company')}
          >{t("title.companies")}</div>
        </div>

        <div className={styles.favoriteActions}>
          <div className={styles.buttons}>
            <div className={styles.buttons} data-tip data-for="actions">
              <Button
                wrapperClassName={styles.button}
                kind={isActionsBlocked && 'primary'}
                disabled={selectedFavorites.length === 0 || isActionsBlocked}
                onClick={this.handleMoveClick}
              >
                {t("button.move")}
              </Button>
            {level > 0 && (
              <Button
                wrapperClassName={styles.button}
                kind={isActionsBlocked && 'primary'}
                disabled={selectedFavorites.length === 0 || isActionsBlocked}
                onClick={this.handleInviteSelected}
              >
                {t("button.send-invitation")}
              </Button>
            )}
            </div>
            {isActionsBlocked && (
              <ReactTooltip
                className={styles.tooltip}
                id="actions"
                effect="solid"
              >
                <span>{t(`text.board-cannot-do-${tab}`)}</span>
              </ReactTooltip>
            )}
            <Button
              wrapperClassName={styles.button}
              disabled={selectedFavorites.length === 0}
              onClick={this.handleRemoveSelectedFavorites}
            >
              {t("button.remove")}
            </Button>
            <Button
              kind="gray"
              wrapperClassName={styles.button}
              onClick={this.handleSelectAllClick}
            >
              {t("button.select-all")}
            </Button>
            <Dropdown
              trigger={['click']}
              onVisibleChange={this.onVisibleChange}
              visible={this.state.visibleCVDropdown}
              closeOnSelect={false}
              overlay={menu}
              animation="slide-up"
            >
              <Button
                kind="gray"
                wrapperClassName={styles.button}
              >
                {t("button.selection-pdf")}
              </Button>
            </Dropdown>
          </div>
        </div>

        <div className={styles.favorites}>
          <Loader isLoading={dataStore.isFavoritesLoading} isAbsolute/>

          {favorites.length > 0 ? (
            <div className={styles.favoritesList}>
              {favorites.map(f => {
                const isSelected = selectedFavorites.includes(f.id);
                const advertisement = f.advertisement;
                const vacancy = f.vacancy;
                const name = tab === 'company' ? f.name :  f[`full_name_${i18n.language}`];
                const dataMainProfession = dataStore.allProfessions
                  .find(p => p.value === get(f, 'main_profession'));

                return (
                  <div key={f.id} className={styles.favorite}>
                    {advertisement && <ModalInvitation
                      data={advertisement}
                      isOwner={true}
                      isOpen={this.state.isInvitationModalOpen === advertisement.id}
                      onClose={this.handleInvitationModalClose}
                    />}
                    {vacancy && <ModalVacancyInvitation
                      data={vacancy}
                      isOwner={true}
                      isOpen={this.state.isInvitationModalOpen === vacancy.id}
                      onClose={this.handleInvitationModalClose}
                    />}
                    <div className={styles.favoriteWrapper}>
                      {!isNull(f.status) && (<div className={cn(styles.favoriteStatus, styles[`${f.status}Status`])}>
                        <b>{t('label.status')}:</b>&nbsp;
                        {t(`text.status-${f.status}`)}
                      </div>)}
                      <div className={cn(styles.favoriteImage, isSelected && styles.favoriteImageActive)}>
                        <div
                          onClick={() => this.handleRemoveFavorite(f.id, get(advertisement, 'id'), get(vacancy, 'id'))}
                          className={styles.favoriteRemove}
                        />

                        <div
                          onClick={() => this.handleSelectFavorite(f.id)}
                          className={styles.favoriteSelect}
                        />

                        {advertisement && <div onClick={() => this.handleShowInvitation(advertisement.id)} className={styles.favoriteInvitation} />}
                        {vacancy && <div onClick={() => this.handleShowInvitation(vacancy.id)} className={styles.favoriteInvitation} />}
                        <a href={`/users/${f.slug}/information`} target="_blank" className={styles.favoriteName}>
                          <img className={styles.favoritePhoto} src={f.avatar.url}/>
                        </a>
                      </div>
                    </div>
                    <a href={`/users/${f.slug}/information`} target="_blank" className={styles.favoriteName}>{name}</a>
                    {tab === 'talent' && (
                      <div className={styles.favoriteDesc}>{t(`label.actor-${f.actor_status}`)}</div>
                    )}
                    {tab === 'staff' && (
                      <div className={styles.favoriteDesc}>{get(dataMainProfession, 'label', '-')}</div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={{ minHeight: 400 }} className={styles.blockBodyEmpty}>
              <img src={inboxIcon} alt=""/>
              <p>{t(`text.have-no-favorite-${tab}`)}</p>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation()(BoardFavorites);
