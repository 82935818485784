import React from "react";
import { withTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./Title.module.scss";

const defaultColor = "#FF0000";

const Title = ({ t, title, color, style = {}, className = "", children }) => {
  const translatedTitle = t(title);

  return (
    <div
      className={cn(styles.title, className)}
      style={{ color: color ? color : defaultColor, ...style }}
    >
      {title ? translatedTitle : children}
    </div>
  );
};

export default withTranslation()(Title);
