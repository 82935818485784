import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import moment from 'moment';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputDatepicker from '../../components/InputDatepicker/InputDatepicker';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import FormItem from '../../components/FormItem/FormItem';
import Dropzone from '../../components/Dropzone/Dropzone';
import InputPlacesAutocomplete from '../../components/InputPlacesAutocomplete/InputPlacesAutocomplete';
import Select from '../../components/Select/Select';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';

import styles from './StaffSignUpStepper.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class Step1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cropedImage: undefined,
    };
  }

  componentDidMount() {
    this.props.dataStore.fetchCountriesList();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (values.birthday === "Invalid date") {
        return this.props.form.setFields({ birthday: {
          value: this.props.form.getFieldValue('birthday'),
          errors: [new Error('Введите дату в формате ДД.ММ.ГГГГ или выберите в календаре')]
        }});
      }

      if (!error) {
        const data = {
          staff: {
            info_attributes: {
              first_name:  values.first_name,
              last_name:  values.last_name,
              birth_date:  values.birthday,
              country_code:  values.country.value,
              city_ru:  get(values, 'city[0]'),
              city_en:  get(values, 'city[1]'),
              gender:  values.gender,
              referer_path: 'new_form'
            },
            avatar: this.state.cropedImage || values.photo,
          }
        };
        this.props.user.update(data);
      } else {
        console.log('error', error, values);
      }
    });
  }

  handlePhotoCrop = (cropedImage) => this.setState({ cropedImage });

  render() {
    const { t, form, dataStore } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.formGroup}>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('first_name', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="first_name"
                  autocomplete="first_name"
                  placeholder={t('input.first-name')}
                  errors={getFieldError('first_name')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('last_name', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="last_name"
                  autocomplete="last_name"
                  placeholder={t('input.last-name')}
                  errors={getFieldError('last_name')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('birthday', {
                initialValue: '',
                rules: [
                  { required: true, message: t('validation.input.required') },
                  {validator(rule, value) {
                    console.log('value', value);
                    if (value) {
                      if (moment(value, 'YYYY-MM-DD HH:mm').isSameOrAfter(moment().subtract(5, 'year'))) {
                        return [new Error(t("validation.input.user-age"))];
                      }
                    }

                    return [];
                  }}
                ]
              })(
                <InputDatepicker
                  required
                  maxDate={new Date()}
                  placeholder={t('input.birthday')}
                  errors={getFieldError('birthday')}
                  extra={`*${t("text.date-format")}`}
                />
              )}
            </FormItem>
          </div>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('country', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Select
                  required
                  placeholder={t('input.country')}
                  options={dataStore.countryOptions}
                  errors={getFieldError('country')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('city', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input-city.required') }]
              })(
                <InputPlacesAutocomplete
                  required
                  autocompletionRequest={{
                    types: ['(cities)'],
                    componentRestrictions: {
                      country: getFieldValue('country') ? getFieldValue('country').value : undefined,
                    }
                  }}
                  placeholder={t('input.city')}
                  errors={getFieldError('city')}
                  extra={`*${t("text.select-city-from-list")}`}
                />
              )}
            </FormItem>
            <FormItem styles={{ paddingTop: 17, display: 'flex' }}>
              <span className={styles.formTitle}>{t('input.sex')}:&nbsp;&nbsp;</span>
              {getFieldDecorator('gender', {
                initialValue: 'female',
              })(
                <RadioGroup labelTextStyle={{ fontSize: 16 }} data={dataStore.genderOptions} />
              )}
            </FormItem>
          </div>
        </div>
        <div className={styles.formInfo}>
          <div className={styles.formGroup} style={{ alignItems: 'center' }}>
            <div className={styles.formGroupItem}>
              {getFieldDecorator('photo', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Dropzone cropedImage={this.state.cropedImage} onCrop={this.handlePhotoCrop} form={form} errors={getFieldError('photo')} />
              )}
            </div>
            <div className={styles.formGroupItem}>
              <ul className={styles.uploadList}>
                <li className={styles.uploadListItem}>{t("text.upload-photo-item-1")} 3x4</li>
                <li className={styles.uploadListItem}>{t("text.upload-photo-item-2")}</li>
                <li className={styles.uploadListItem}>{t("text.upload-photo-item-3")}</li>
                <li className={styles.uploadListItem}>{t("text.upload-photo-item-4")}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            kind="primary"
            type="submit"
            className={styles.button}
          >{t('button.next')}</Button>
        </div>
      </form>
    )
  }
}


export default withTranslation()(createForm()(Step1));
