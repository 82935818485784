import React, { Component } from "react";

import InputFile from "../InputFile/InputFile";
import { withTranslation } from "react-i18next";

class FormInputFile extends Component {
  render() {
    const { t, onChange, accept, extra, errors, value = {} } = this.props;

    return (
      <InputFile
        value={value}
        accept={accept}
        onSelect={onChange}
        onRemove={() => onChange()}
        single
        errors={errors}
        title="label.attach"
        extra={extra}
      />
    );
  }
}

export default withTranslation()(FormInputFile);
