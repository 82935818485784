import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import FormItem from '../../components/FormItem/FormItem';
import InputDatepicker from '../../components/InputDatepicker/InputDatepicker';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import Dropzone from '../../components/Dropzone/Dropzone';
import { withTranslation } from 'react-i18next';

import styles from './CompanySignUpStepper.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class Step1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cropedImage: undefined,
    };
  }

  componentDidMount() {
    this.props.dataStore.fetchCompanyServicesList();
  }

  handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          company: {
            referer_path: "information",
            name: values.name,
            year: values.company_date,
            actual_address: values.actual_address,
            avatar: this.state.cropedImage || values.photo,
            company_services_attributes: values.company_services.map(s => ({ company_service_dictionary_id: s.value }))
          }
        };
        console.log('ok', data);

        this.props.user.update(data);
      } else {
        console.log('error', error, values);
      }
    });
  }

  handlePhotoCrop = (cropedImage) => this.setState({ cropedImage });

  render() {
    const { t, i18n, form, dataStore, user } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.formGroup}>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('name', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="company_name"
                  autocomplete="company_name"
                  placeholder={t('input.company-name')}
                  errors={getFieldError('name')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('company_date')(
                <InputDatepicker
                  required
                  maxDate={new Date()}
                  placeholder={t('input.company-reg-date')}
                  errors={getFieldError('company_date')}
                  extra={`*${t("text.date-format")}`}
                />
              )}
            </FormItem>
          </div>
          <div className={styles.formGroupItem}>
            <FormItem>
              {getFieldDecorator('actual_address', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="actual_address"
                  autocomplete="actual_address"
                  placeholder={t('input.actual-address')}
                  errors={getFieldError('actual_address')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('company_services', {
                initialValue: [],
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <MultiSelect
                  required
                  options={dataStore.companyServicesOptions}
                  placeholder={t('input.company-activity')}
                  errors={getFieldError('company_services')}
                  extra={t("text.service.extra")}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className={styles.formInfo}>
          <div className={styles.formGroup} style={{ alignItems: 'center' }}>
            <div className={styles.formGroupItem}>
              {getFieldDecorator('photo', {
                initialValue: '',
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Dropzone
                  cropedImage={this.state.cropedImage}
                  onCrop={this.handlePhotoCrop}
                  form={form}
                  format={'image/png'}
                  cropAspect={1}
                  errors={getFieldError('photo')}
                />
              )}
            </div>
            <div className={styles.formGroupItem}>
              <ul className={styles.uploadList}>
                <li className={styles.uploadListItem}>{t("text.upload-photo-item-1")} 2x2</li>
                <li className={styles.uploadListItem}>{t("text.upload-photo-item-4")}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            kind="primary"
            type="submit"
            className={styles.button}
          >{t('button.next')}</Button>
        </div>
      </form>
    )
  }
}


export default withTranslation()(createForm()(Step1));
