import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import cn from 'classnames';
import logoImageWhite from '../../../images/logo-white.png';
import logoImageRed from '../../../images/logo-red.png';
import burgerImage from '../../../images/burger.png';

import styles from './Header.module.scss';


@inject('navigate', 'user')
@observer
class Header extends Component {
  state = {
    isMenuOpen: false,
  }

  handleLoginClick = () => {
    this.props.navigate({ path: '/app/signin' });
  }

  handleLogoutClick = () => {
    this.props.user.logout();
  }

  handleSwitchLanguage = () => {
    if (this.props.user.user) {
      console.log('============================================')
      this.props.user.changeLanguageBD(this.props.user.user.id);
    }

    this.props.user.changeLanguage(this.props.i18n);
  }

  handleCabinetClick = () => {
    this.props.user.navigateToCabinet();
  }

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true });
  }

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  }

  configurePath = () => {
    const t = this.props.t
    const lang = this.props.i18n.language

    const pathArray = [
      { path: `/app/search`, label: t('menu.search') },
      { path: `/${lang}/home/for_talent`, label: t('menu.for-talents') },
      { path: `/${lang}/home/for_staff`, label: t('menu.for-staff') },
      { path: `/${lang}/home/for_company`, label: t('menu.for-company') },
      { path: `/${lang}/home/locations`, label: t('menu.locations') }
    ]

    if (lang === 'ru') {
      pathArray.push({ path: `/${lang}/articles`, label: t('menu.articles') })
    }

    pathArray.push({ path: `/${lang}/home/about`, label: t('menu.about-us') })

    return pathArray
  }

  render() {
    const { t, user, isFull, isBlack, isLogoWhite, isSearch } = this.props;

    return (
      <div className={cn(styles.wrapper, { [styles.wrapperBlack]: isBlack})}>
        <div className={styles.inner}>
          <div className={styles.logo}>
            <a href="/">
              <img className={styles.logoImageWhite} src={logoImageWhite} alt="Etalents.pro"/>
              <img className={styles.logoImageRed} src={isLogoWhite ? logoImageWhite : logoImageRed} alt="Etalents.pro"/>
            </a>
          </div>
          <div className={styles.menu}>
            {this.configurePath().map(item => (
              <a
                key={item.path}
                href={item.path}
                className={cn(styles.menuItem, location.pathname === item.path && styles.menuItemActive)}
              >{item.label}</a>
            ))}
          </div>
          <div className={cn(styles.info, { [styles.infoFull]: isFull })}>
            {user.isAuthenticated ? (
              isSearch ? (
                <div onClick={this.handleCabinetClick} className={styles.signinButton}>{t("title.cabinet")}</div>
              ) : (
                <div onClick={this.handleLogoutClick} className={styles.signinButton}>{t('button.logout')}</div>
              )
            ) : (
              <div onClick={this.handleLoginClick} className={styles.signinButton}>{t('button.signin')}</div>
            )}
            <div
              onClick={this.handleSwitchLanguage}
              className={styles.langSwitcher}
            >{user.language === 'ru' ? 'en' : 'ru'}</div>
            <img onClick={this.handleMenuOpen} src={burgerImage} className={styles.burger} />
            <BurgerMenu onClose={this.handleMenuClose} isOpen={this.state.isMenuOpen}/>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Header);
