import React from "react";
import cn from "classnames";
import styles from "./FormHeader.module.scss";

export default function FormTitle(props) {
  return (
    <div className={styles.title} style={{ ...props.styles }}>
      {props.children}
    </div>
  );
}
