import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import qs from 'qs';
import { createForm } from "rc-form";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import Button from "../../components/Button/Button";
import FormTitle from "../../components/FormTitle/FormTitle";
import FormItem from "../../components/FormItem/FormItem";
import Select from "../../components/Select/Select";
import Loader from "../../components/Loader/Loader";
import cn from "classnames";
import get from "lodash/get";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import isUndefined from "lodash/isUndefined";
import debounce from "lodash/debounce";

import styles from "./Search.module.scss";
import inboxIcon from "../../../images/inbox-icon.png";
import InfiniteScroll from "react-infinite-scroll-component";

import CommonSearchBlock from "./CommonSearchBlock";
import CompanyCommonSearchBlock from "./CompanyCommonSearchBlock";
import CareerSearchBlock from "./CareerSearchBlock";
import AppearanceSearchBlock from "./AppearanceSearchBlock";
import DimensionSearchBlock from "./DimensionSearchBlock";
import FeaturesSearchBlock from "./FeaturesSearchBlock";
import SkillSearchBlock from "./SkillSearchBlock";
import GuidePopup from "../../components/GuidePopup/GuidePopup"

import TalentCard from "./TalentCard";

let scopedFormChange = () => {};

let onValuesChange = () => {
  setTimeout(scopedFormChange, 0);
};

@inject("user", "dataStore", "navigate")
@observer
class Search extends Component {
  constructor(props) {
    super(props);

    const isMobileSize = window.innerWidth <= 760 ? '' : 'common'

    this.state = {
      q: props.queries.q || "",
      activeFilter: isMobileSize,
      showPreview: false,
      page: 1
    };

    scopedFormChange = this.handleFormChange;

    this.debounceHandleFormChange = debounce(this.handleFormChange, 300);
  }

  componentWillMount() {
    const queries = this.props.queries;

    this.props.navigate({
      path: "/app/search",
      query: { ...queries }
    }, true, true);
  }

  searchByRole(queries) {
    const { user } = this.props;
    const role = get(this.props.queries, 'role', 'talent');

    switch (role) {
      case 'talent': return user.searchTalents(queries);
      case 'staff': return user.searchStaffs(queries);
      case 'company': return user.searchCompanies(queries);
    }
  }

  componentDidMount() {
    const { dataStore, queries } = this.props;

    this.searchByRole(queries);

    dataStore.fetchCareersList();
    dataStore.fetchCompanyServicesList();

    dataStore.fetchCountriesList();
    dataStore.fetchAppearanciesList();
    dataStore.fetchPhysiquesList();
    dataStore.fetchEyesList();
    dataStore.fetchHairsList();
    dataStore.fetchInstrumentsList();
    dataStore.fetchSportsList();
    dataStore.fetchLanguagesList();

    dataStore.fetchFavoriteTalents();
    dataStore.fetchFavoriteStaffs();
    dataStore.fetchFavoriteCompanies();
  }

  handleCollapseChange = (filter) => {
    const { activeFilter } = this.state;

    this.setState({ activeFilter: filter === activeFilter ? undefined : filter })
  }

  handleDocumentKeyDown = (e) => {
    const { user } = this.props;

    const activeUserIndex = user.searchResult
      .findIndex(u => u.user_id === this.state.userId);

    if (e.keyCode === 37 && activeUserIndex > 0) {
      this.handleLeftClick();
    }

    if (e.keyCode === 39 && activeUserIndex < user.resultsCount - 1) {
      this.handleRightClick();
    }

    if (e.keyCode === 27) {
      this.handleClosePreview();
    }
  }

  handleShowPreview = userId => {
    this.setState({ showPreview: true, userId });

    this.listener = document.addEventListener('keydown', this.handleDocumentKeyDown, false);
  };

  handleAddToFavorite = id => () => {
    const role = this.props.queries.role || 'talent';

    this.props.dataStore.addFavorite(id, { favorite_role: role });
  };

  handleRemoveToFavorite = id => () => {
    const role = this.props.queries.role || 'talent';

    this.props.dataStore.removeFavorites({
      favorite_role: role,
      favorite_ids: [id]
    });
  };

  handleClosePreview = () => {
    this.setState({ showPreview: false, userId: undefined });

    document.removeEventListener('keydown', this.handleDocumentKeyDown, false);
  };

  handleLeftClick = () => {
    const { user } = this.props;
    const activeUserIndex = user.searchResult.findIndex(
      u => u.user_id === this.state.userId
    );
    const newUser = user.searchResult[activeUserIndex - 1];

    if (newUser) {
      this.setState({ userId: newUser.user_id });
    }
  };

  handleRightClick = () => {
    const { user } = this.props;

    const activeUserIndex = user.searchResult.findIndex(
      u => u.user_id === this.state.userId
    );
    const newUser = user.searchResult[activeUserIndex + 1];

    if (newUser) {
      this.setState({ userId: newUser.user_id });
    }
  };

  handleNameChange = e => {
    const { value } = e.target;

    this.setState({ q: value });
  };

  handleNamePress = e => {
    if (e.key === 'Enter') {
      this.handleSearchClick();
    }
  }

  handleFormChange = (q, v, withRequest, nextPage) => {
    this.props.form.validateFields({}, (error, values) => {
      const newValues = v ? v : values;

      if (!error) {
        const queries = q ? q : this.props.queries;

        this.setState({ page: 1 });

        const role = this.props.queries.role || queries.role || 'talent';

        switch (role) {
          case 'talent': this.handleTalentsSearch(newValues, queries, withRequest, nextPage); break;
          case 'staff': this.handleStaffsSearch(newValues, queries, withRequest, nextPage); break;
          case 'company': this.handleCompaniesSearch(newValues, queries, withRequest, nextPage); break;
        }

      } else {
        console.log("error", error, values);
      }
    });
  };

  handleTalentsSearch = (newValues, queries, withRequest, nextPage) => {
    const checkboxes = [
      'amateur', 'professional', 'male', 'female', // cross
      'crowd_scene', 'low_budget', 'long_trip', 'model_work',
      ...this.props.user.featuresFields,
      ...this.props.user.skillsFields,
    ];

    const multivalues = [
      ...this.props.user.appearanceFields,
      ...this.props.user.multiSkillsFields,
      'cities', 'country_code', 'appearance'
    ];

    const multivaluesFiels = multivalues.reduce((acc, field) => {
      acc[field] = get(newValues, field, []).map(a => a.value);
      return acc;
    }, {});

    const data = {
      q: queries.q,
      ...pick(newValues, checkboxes),
      ...pick(newValues, ['rate_from', 'rate_to']),
      ...pick(newValues, ['age_from', 'age_to']),
      ...pick(newValues, ['height_from', 'height_to']),
      ...pick(newValues, ['weight_from', 'weight_to']),
      ...pick(newValues, ['chest_girth_from', 'chest_girth_to']),
      ...pick(newValues, ['waist_girth_from', 'waist_girth_to']),
      ...pick(newValues, ['hip_girth_from', 'hip_girth_to']),
      ...pick(newValues, ['head_circumference_from', 'head_circumference_to']),
      ...pick(newValues, this.props.user.rangeFields),
      ...multivaluesFiels,

      search_order: get(newValues, 'search_order.value'),
    };

    this.props.navigate({
      path: "/app/search",
      query: { ...queries, ...omitBy(data, isUndefined) }
    });

    if (withRequest) {
      this.props.user.searchTalents(omitBy(data, isUndefined), nextPage);
    }
  }

  handleStaffsSearch = (newValues, queries, withRequest, nextPage) => {
    const checkboxes = [
      'company', 'independent',
      'male', 'female', 'low_budget', 'long_trip',
      'vehicle_car', 'vehicle_motorcycle', 'vehicle_bus',
      'vehicle_extreme_driving', 'vehicle_riding'
    ];

    const data = {
      q: queries.q,
      ...pick(newValues, checkboxes),
      search_order: get(newValues, 'search_order.value'),
      country_code: get(newValues, 'country_code', []).map(a => a.value),
      cities: get(newValues, 'cities', []).map(a => a.value),
      age: newValues.age,
      profession: get(newValues, 'profession', []).map(a => a.value),
      ...pick(newValues, ['rate_from', 'rate_to']),
      ...pick(newValues, ['age_from', 'age_to']),
      lang: get(newValues, 'lang', []).map(a => a.value),
    };

    this.props.navigate({
      path: "/app/search",
      query: { ...queries, ...omitBy(data, isUndefined) }
    });

    if (withRequest) {
      this.props.user.searchStaffs(omitBy(data, isUndefined), nextPage);
    }
  }

  handleCompaniesSearch = (newValues, queries, withRequest, nextPage) => {
    const checkboxes = ['too', 'ao', 'ip'];

    const data = {
      q: queries.q,
      ...pick(newValues, checkboxes),
      search_order: get(newValues, 'search_order.value'),
      service: get(newValues, 'service', []).map(a => a.value),
    };

    this.props.navigate({
      path: "/app/search",
      query: { ...queries, ...omitBy(data, isUndefined) }
    });

    if (withRequest) {
      this.props.user.searchCompanies(omitBy(data, isUndefined), nextPage);
    }
  }

  fetchMore = () => {
    this.handleFormChange(undefined, undefined, true, true);
  };

  handleResetForm = () => {
    this.props.form.resetFields();
    this.handleFormChange({ role: this.props.queries.role || 'talent' }, {});
  };

  handleSearchClick = () => {
    const queries = this.props.queries;

    this.props.navigate({
      path: "/app/search",
      query: { ...queries, q: this.state.q }
    });
  }

  componentDidUpdate(prevProps) {
    const currValues = this.props.form.getFieldsValue();
    const prevValues = prevProps.form.getFieldsValue();
    const { queries: currQuery } = this.props;
    const { queries: prevQuery } = prevProps;

    if (
      (JSON.stringify(currValues) !== JSON.stringify(prevValues)) ||
      (prevQuery && qs.stringify(currQuery) !== qs.stringify(prevQuery))) {
        this.debounceHandleFormChange(currQuery, currValues, true);
    }
  }

  handleChangeRole = role => {
    if (this.props.queries.role !== role) {
      this.props.form.resetFields();
      this.props.user.resetSearch();
      this.handleFormChange({ role }, {});
    }
  }

  handleToggleFavorite = (userId) => {
    const role = this.props.queries.role || 'talent';
    const favorite = this.props.dataStore.favoritesByRole(role).find(t => t[`${role}_id`] == userId);

    if (favorite) {
      this.handleRemoveToFavorite(favorite.id)();
    } else {
      this.handleAddToFavorite(userId)();
    }
  }

  guidePopupEventState = (user) => {
    if (user.user && user.user.role !== 'talent') {
      const event = get(user, 'user.events').find(event => event.theme === 'search_guide_popup')
      if (event) return event.state
      return 'big'
    }
  }

  render() {
    const { user, t, queries, form, dataStore } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const lang = user.language;

    const {
      activeFilter,
      showPreview
    } = this.state;

    const role = queries.role || 'talent';

    const sortBy = [
      { value: "name", label: t("label.sort-by-name"), isDefault: true },
      { value: "date", label: t("label.sort-by-date") }
    ];
    const initialSort = queries.search_order
      ? sortBy.find(s => s.value === queries.search_order)
      : null;

    const activeUser = user.searchResult.find(
      u => u.user_id === this.state.userId
    );
    const activeUserIndex = user.searchResult.findIndex(
      u => u.user_id === this.state.userId
    );
    const isFavoriteActive = user.isAuthenticated &&
      (user.user.role === 'staff' || user.user.role === 'company') &&
      get(user, 'user.role_object.moderation_status') === 'approved';

    // Тут из всех объектов user.guides выявляем нужный нам для страницы Поиска с темой "search_guide_popup"
    const guidePopupEventState = this.guidePopupEventState(user)

    return (
      <div className={styles.page}>
        <Loader isLoading={user.isLoading || user.isUpdating}/>
        <InfiniteScroll
          style={{ height: '100%' }}
          dataLength={user.searchResult.length} //This is important field to render the next data
          hasMore={
            user.updatingStatus.name === "DONE" &&
            user.searchResult.length < user.resultsCount
          }
          next={this.fetchMore}
        >
          { guidePopupEventState &&
            <GuidePopup guideState={guidePopupEventState} theme={'search_guide_popup'} title={t("text.guide-title-1")} videoLink={'https://www.youtube.com/watch?v=zpqpve3XsRw'} />
          }

          <div className={styles.inner}>
            <div className={styles.main}>
              <div className={styles.inputControl}>
                <input
                  className={styles.searchInput}
                  type="text"
                  value={this.state.q}
                  placeholder={t("menu.search")}
                  onChange={this.handleNameChange}
                  onKeyPress={this.handleNamePress}
                />
                <Button onClick={this.handleSearchClick} kind="primary">
                  {t("menu.search")}
                </Button>
              </div>
              {get(user, 'searchResult.length', 0) > 0 && (
                <p className={styles.resultText}>
                  {t(`search.${role}-found`)}
                  &nbsp;
                  <span>({user.resultsCount})</span>
                </p>
              )}

              {(!user.isLoading && !user.isUpdating && get(user, 'searchResult.length', 0) === 0) && (
                <div
                  style={{ minHeight: 310 }}
                  className={styles.blockBodyEmpty}
                >
                  <img src={inboxIcon} alt="" />
                  <p>{t(`search.no-${role}-found`)}</p>
                </div>
              )}

              <div className={styles.resultList}>
                {user.searchResult.map(u => {
                  const allProfessions = toJS(dataStore.allProfessions);
                  const mainProfession = allProfessions
                    .find(p => p.value === get(u, 'main_profession'));

                  return (
                    <div key={u.user_id} className={styles.result}>
                      <div className={styles.resultImage}>
                        <img
                          className={styles.resultPhoto}
                          src={u.avatar_url}
                          alt=""
                        />
                        {isFavoriteActive && <div
                          data-tip
                          data-for={`favorite_${u.user_id}`}
                          className={cn(
                            styles.resultFavorite,
                            dataStore.favoritesByRole(role).find(t => t[`${role}_id`] == u.id) && styles.resultFavoriteActive
                          )}
                          onClick={() => this.handleToggleFavorite(u.id)}
                        />}
                        <ReactTooltip
                          place="top"
                          id={`favorite_${u.user_id}`}
                          effect="solid"
                        >
                          <span>{
                            dataStore.favoritesByRole(role).find(t => t[`${role}_id`] == u.id)
                            ? t("button.in-favorites") : t("button.to-favorites")
                          }</span>
                        </ReactTooltip>
                        <div
                          onClick={() => this.handleShowPreview(u.user_id)}
                          className={styles.resultPreview}
                        >
                          {t("button.quick-view")}
                        </div>
                      </div>
                      {(role === 'talent' || role === 'staff') && (
                        <a className={styles.resultName} href={`/users/${u.slug}/information`} target="_blank">
                          {get(u, `first_name_${lang}`) + ' ' + get(u, `last_name_${lang}`)}
                        </a>
                      )}
                      {role === 'company' && (
                        <a className={styles.resultName} href={`/users/${u.slug}/information`} target="_blank">
                          {`${u.name}`}
                        </a>
                      )}
                      {role === 'talent' && (<p className={styles.resultStatus}>
                        {t(`label.actor-${u.actor_status}`)}
                      </p>)}
                      {role === 'staff' && (
                        <p className={styles.resultStatus}>{get(mainProfession, 'label', '-')}</p>
                      )}
                      <div
                        onClick={() => this.handleShowPreview(u.user_id)}
                        className={styles.resultPreviewMobile}
                      >
                        {t("button.quick-view")}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.sidebar}>
              <div className={styles.tabs}>
                <div
                  onClick={() => this.handleChangeRole("talent")}
                  className={cn(styles.tab, {
                    [styles.tabActive]: role === "talent"
                  })}
                >
                  {t("roles.talent")}
                </div>
                <div
                  onClick={() => this.handleChangeRole('staff')}
                  className={cn(styles.tab, {
                    [styles.tabActive]: role === "staff"
                  })}
                >
                  {t("roles.staff")}
                </div>
                <div
                  onClick={() => this.handleChangeRole('company')}
                  className={cn(styles.tab, {
                    [styles.tabActive]: role === "company"
                  })}
                >
                  {t("roles.company")}
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <FormItem className={styles.resetButton}>
                  <Button onClick={this.handleResetForm} kind="primary">
                    {t("button.clear-all-filters")}
                  </Button>
                </FormItem>
                <FormItem
                  styles={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <FormTitle lowercase styles={{ marginBottom: 12, marginTop: -6 }} size="small">
                    {t("label.sort-by") + ":"}
                  </FormTitle>
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    {getFieldDecorator("search_order", {
                      initialValue: initialSort
                    })(
                      <Select
                        isSearchable={false}
                        placeholder={t("label.select-type")}
                        options={sortBy}
                        errors={getFieldError("search_order")}
                        bordered
                      />
                    )}
                  </div>
                </FormItem>
                {(role === 'talent' || role === 'staff') && (
                  <CommonSearchBlock
                    isOpen={activeFilter === 'common'}
                    form={form}
                    toggleCollapse={this.handleCollapseChange}
                    queries={queries}
                  />
                )}

                {role === 'company' && (
                  <CompanyCommonSearchBlock
                    isOpen={activeFilter === 'common'}
                    form={form}
                    toggleCollapse={this.handleCollapseChange}
                    queries={queries}
                  />
                )}

                {role === 'talent' && (
                  <AppearanceSearchBlock
                    isOpen={activeFilter === 'appearance'}
                    form={form}
                    toggleCollapse={this.handleCollapseChange}
                    queries={queries}
                  />
                )}

                {role === 'talent' && (
                  <DimensionSearchBlock
                    isOpen={activeFilter === 'sizes'}
                    form={form}
                    toggleCollapse={this.handleCollapseChange}
                    queries={queries}
                  />
                )}

                {role === 'talent' && (
                  <FeaturesSearchBlock
                    isOpen={activeFilter === 'features'}
                    form={form}
                    toggleCollapse={this.handleCollapseChange}
                    queries={queries}
                  />
                )}

                {(role === 'talent' || role === 'staff') && (
                  <SkillSearchBlock
                    isOpen={activeFilter === 'skills'}
                    form={form}
                    toggleCollapse={this.handleCollapseChange}
                    queries={queries}
                  />
                )}

                {role === 'staff' && (
                  <CareerSearchBlock
                    isOpen={activeFilter === 'career'}
                    form={form}
                    toggleCollapse={this.handleCollapseChange}
                    queries={queries}
                  />
                )}

              </form>
            </div>
            {showPreview && activeUser && (
              <div className={styles.modalOverlay}>
                <div className={styles.modalWindow}>
                  <TalentCard
                    lang={lang}
                    isFavoriteActive={isFavoriteActive}
                    onFavorite={this.handleToggleFavorite}
                    role={role}
                    user={activeUser}
                    language={user.language}
                    onLeftClick={this.handleLeftClick}
                    isLeftClickActive={activeUserIndex > 0}
                    onRightClick={this.handleRightClick}
                    isRightClickActive={activeUserIndex < user.resultsCount - 1}
                    userId={this.state.userId}
                  />
                  <div
                    onClick={this.handleClosePreview}
                    className={styles.modalClose}
                  />
                  {activeUserIndex > 0 && (
                    <div
                      onClick={this.handleLeftClick}
                      className={styles.modalControlLeft}
                    />
                  )}
                  {activeUserIndex < user.resultsCount - 1 && (
                    <div
                      onClick={this.handleRightClick}
                      className={styles.modalControlRight}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

export default withTranslation()(
  createForm({
    onValuesChange
  })(Search)
);
