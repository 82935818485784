import React from "react";
import { Collapse } from "react-collapse";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import get from 'lodash/get';

import SearchRangeInput from './components/SearchRangeInput';
import SearchMultiselect from './components/SearchMultiselect';

import styles from "./Search.module.scss";
import {FormItem, FormTitle, InputMask} from "../../components";
import SearchInputsRange from "../../components/SearchInputsRange/SearchInputsRange";

@inject("user", "dataStore")
@observer
class AppearanceSearchBlock extends React.Component {
  handleCollapse = () => this.props.toggleCollapse('appearance');

  render() {
    const { user, form, isOpen, t, dataStore, queries = {} } = this.props;
    const { searchAggregations } = user;
    const { getFieldDecorator, getFieldError } = form;
    const {
      appearanciesOptions, physiquesOptions, eyesOptions,
      hairsOptions, heightRange, weightRange,
    } = dataStore;

    const aggrFilter = (field, options) => options
      .filter(o => get(searchAggregations, field, []).map(c => c.key).includes(o.value)
    );

    const filteredAppearancies = aggrFilter('appearance', appearanciesOptions);
    const filteredPhysiques = aggrFilter('physique', physiquesOptions);
    const filteredEyeColors = aggrFilter('eye_color', eyesOptions);
    const filteredHairColors = aggrFilter('hair_color', hairsOptions);


    const formProps = { form, queries };

    return (
      <div className={styles.collapseWrapper}>
        <div
          onClick={this.handleCollapse}
          className={cn(styles.collapseHeader, {
            [styles.collapseHeaderActive]: isOpen
          })}
        >
          {t("title.appearance")}
        </div>
        <Collapse isOpened={isOpen}>
          <div className={styles.collapseBody}>
            <SearchMultiselect
              {...formProps}
              title="label.appearance"
              field="appearance"
              options={filteredAppearancies}
              placeholder="input.select-appearance"
            />

            <SearchMultiselect
              {...formProps}
              title="label.physique"
              field="physique"
              options={filteredPhysiques}
              placeholder="input.select-physique"
            />

            <SearchMultiselect
              {...formProps}
              title="label.eyes"
              field="eye_color"
              options={filteredEyeColors}
              placeholder="input.select-eyes"
            />

            <SearchMultiselect
              {...formProps}
              title="label.hairs"
              field="hair_color"
              options={filteredHairColors}
              placeholder="input.select-hairs"
            />


            <SearchInputsRange {...formProps} title="label.height" fieldsNames={['height_from', 'height_to']}/>
            <SearchInputsRange {...formProps} title="label.weight" fieldsNames={['weight_from', 'weight_to']}/>

          </div>
        </Collapse>
      </div>
    );
  }
}
export default withTranslation()(AppearanceSearchBlock);
