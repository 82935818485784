import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import FormItem from '../../components/FormItem/FormItem';
import FormItemControls from '../../components/FormItemControls/FormItemControls';
import FormTitle from '../../components/FormTitle/FormTitle';
import styles from './EditForms.module.scss';
import get from 'lodash/get';
import moment from 'moment';

@inject('user', 'dataStore', 'navigate')
@observer
class EducationCourseEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const { role } = this.props.user.user;
        const moderationFields = [];

        if (!get(this.props.data, 'id')) {
          moderationFields.push('new_course');
        }

        if (
          get(this.props.data, 'id') &&
          values.name !== this.props.data.name ||
          !(this.props.data && this.props.data.includes(values.year.value))
        ) {
          moderationFields.push('update_course');
        }

        const data = {
          [role]: {
            courses_attributes: [{
              name: values.name,
              year: new Date(values.year.value, 0, 2),
              id: get(this.props.data, 'id')
            }],
            moderated_fields: moderationFields,
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('EducationCourseEdit error', error, values);
      }
    });
  }

  render() {
    const { t, form, dataStore, data = {} } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const dataYear = data.year
      ? dataStore.yearOptions.find(t => t.value === moment(data.year, 'YYYY-MM-DD').format('YYYY'))
      : null;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.formGrid} style={{ marginBottom: 0 }}>
          <div className={styles.formGrid2}>
            <FormItem>
              <FormTitle>{t('input.course')}</FormTitle>
              {getFieldDecorator('name', {
                initialValue: get(data, 'name', ''),
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="course"
                  placeholder={t('input.course')}
                  errors={getFieldError('cournamenamese')}
                />
              )}
            </FormItem>
          </div>
          <div className={styles.formGrid2}>
            <FormItem>
              <FormTitle>{t('input.course-year')}</FormTitle>
              {getFieldDecorator('year', {
                initialValue: dataYear,
              })(
                <Select
                  placeholder={t('input.course-year')}
                  options={dataStore.yearOptions}
                  errors={getFieldError('year')}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className={styles.formGrid}>
          <FormItemControls data={data} entity="courses"/>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(EducationCourseEdit));
