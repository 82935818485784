import React, { Component } from "react";
import cn from "classnames";
import Slider from "rc-slider";
import get from "lodash/get";

import styles from "./RCRange.module.scss";
import "./RCRange.scss";
import "rc-slider/assets/index.css";

export default class RCSlider extends Component {
  handleChange = data => {
    this.props.onChange(data);
  };

  render() {
    const { extra, size, wrapperClassName, value, step, errors = [], min, max } = this.props;

    return (
      <div
        style={{ marginBottom: extra ? -18 : 0 }}
        className={cn(styles.wrapper, wrapperClassName)}
      >
        <Slider
          defaultValue={value}
          onChange={this.handleChange}
          value={value}
          step={step}
          min={min}
          max={max}
        />
        {extra && <p className={styles.extra}>{extra}</p>}
      </div>
    );
  }
}
