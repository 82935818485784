import React from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import Select from "../../../components/Select/Select";
import FormItem from "../../../components/FormItem/FormItem";
import FormTitle from "../../../components/FormTitle/FormTitle";
import Input from "../../../components/Input/Input";
import FormInputFile from "../../../components/FormInputFile/InputFile";

import styles from "../EditForms.module.scss";

const General = ({ form, initialValues, t, dataStore }) => {
  const { getFieldDecorator, getFieldError } = form;

  return (
    <>
      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.project-type")}</FormTitle>
            {getFieldDecorator("projekt_type", {
              initialValue: initialValues.projekt_type,
              rules: [
                { required: true, message: t("validation.input.required") }
              ]
            })(
              <Select
                required
                placeholder={t("input.select-project-type")}
                options={dataStore.movieTypesOptions}
                errors={getFieldError("projekt_type")}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormTitle>{t("label.project-name")}</FormTitle>
          {getFieldDecorator("projekt_name", {
            initialValue: initialValues.projekt_name,
            rules: [{ required: true, message: t("validation.input.required") }]
          })(
            <Input
              required
              name="projekt_name"
              placeholder={t("input.enter-title")}
              errors={getFieldError("projekt_name")}
            />
          )}
        </div>
      </div>

      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.producer")}</FormTitle>
            {getFieldDecorator("director", {
              initialValue: initialValues.director,
            })(
              <Input
                name="director"
                errors={getFieldError("director")}
                placeholder={t("input.producer-first-name")}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormTitle>{t("label.presentation")}</FormTitle>
          {getFieldDecorator("document", {
            initialValue: initialValues.document
          })(
            <FormInputFile
              extra={`${t("text.available-formats")}: doc, docx, txt, rtf, pdf`}
              accept='.doc, .docx, .txt, .rtf, .ppt, .pptx, .pdf, application/pdf,  application/mspowerpoint, application/powerpoint, application/vnd.ms-powerpoint, application/x-mspowerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation'
              errors={getFieldError("document")}
            />
          )}
        </div>
      </div>

      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormTitle>{t("label.shooting-in")}</FormTitle>
          {getFieldDecorator("shooting_cities", {
            initialValue: initialValues.shooting_cities
          })(
            <Input
              name="shooting_cities"
              errors={getFieldError("shooting_cities")}
              placeholder={t("input.one-or-more-cities")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default compose(
  withTranslation(),
  inject("dataStore", "navigate"),
  observer
)(General);
