import React, {Component} from "react";
import {FormItem, FormTitle, InputMask} from "../index";
import get from "lodash/get";
import styles from "../../pages/Search/Search.module.scss";
import {withTranslation} from "react-i18next";

class SearchInputsRange extends Component {
  render() {
    const { form, t, queries = {}, title } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    const firstFieldName = this.props.fieldsNames[0]
    const secondFieldName = this.props.fieldsNames[1]

    return (
      <FormItem styles={{ marginBottom: 25 }}>
        <FormTitle size="small">{t(title)}</FormTitle>
        <div style={{ display: 'flex' }}>
          {getFieldDecorator(firstFieldName, { initialValue: get(queries, firstFieldName) })(
            <InputMask
              wrapperClassName={styles.rateFrom}
              size="small"
              mask={"9999999999"}
              name={firstFieldName}
              autocomplete="off"
              placeholder={t("label.from")}
              errors={getFieldError(firstFieldName)}
            />
          )}
          {getFieldDecorator(secondFieldName, { initialValue: get(queries, secondFieldName) })(
            <InputMask
              wrapperClassName={styles.rateTo}
              size="small"
              mask={"9999999999"}
              name={secondFieldName}
              autocomplete="off"
              placeholder={t("label.to")}
              errors={getFieldError(secondFieldName)}
            />
          )}
        </div>
      </FormItem>
    )
  }
}

export default withTranslation()(SearchInputsRange)
