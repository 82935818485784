import React, { Component } from 'react';
import styles from './DataTable.module.scss';

export default class DataTable extends Component {
  handleChange = (e) => {
    this.props.onChange(e.target.checked);
  }

  render() {
    const { header, withoutTitles, data } = this.props;

    return (
      <div className={styles.wrapper}>
        {header && (<div className={styles.dataHeader}>{header}</div>)}
        <div className={styles.dataTable}>
          {!withoutTitles && (
            <div className={styles.dataTitles}>
              {data.map((item, index) => (
                <div key={index} className={styles.dataTitle}>{item.title}</div>
              ))}
            </div>
          )}
          <div className={styles.dataValues}>
            {data.map((item, index) => (
              <div key={index} className={styles.dataValue}>{item.value}</div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
