import React, { Component } from "react";
import { toJS } from 'mobx';
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import get from "lodash/get";
import Loader from '../../components/Loader/Loader';
import CastingInvitationForm from "../Forms/Advertisement/CastingInvitation";

import formStyles from '../Forms/EditForms.module.scss';

@inject("user", "dataStore", "navigate")
@observer
class CreateCastingInvitation extends Component {
  componentDidMount() {
    const boardId = get(this.props.ctx, "params.boardId");
    const id = get(this.props.ctx, "params.id");

    if (id) {
      this.props.dataStore.fetchProject(boardId);
    }
  }

  render() {
    const { ctx, dataStore, type } = this.props;

    const boardId = get(ctx, "params.boardId");
    const id = get(ctx, "params.id");
    const selected = get(ctx, "query.selected", []);
    const casting = get(toJS(dataStore.project), 'advertisements', []).find(a => a.id == id);
    const favoriteTalentInvitation = get(toJS(dataStore.project), 'favorite_talents', []).find(favorite => favorite.advertisement);
    const isEdit = type === 'edit';

    return (
      <div className={formStyles.smallInner}>
        <CastingInvitationForm
          selected={selected}
          isEdit={isEdit}
          data={casting || get(favoriteTalentInvitation, 'advertisement')}
          projectId={boardId}
        />
        <Loader isLoading={dataStore.isBoardUpdating} />
      </div>
    );
  }
}

export default withTranslation()(CreateCastingInvitation);
