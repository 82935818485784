import React, { Component } from 'react';
import styles from './ProgressBar.module.scss';
import { withTranslation } from 'react-i18next';

class ProgressBar extends Component {
  render() {
    const { t, percent, desc } = this.props;

    return (
      <div className={styles.progressWrapper}>
        <div className={styles.progressBlock}>
          <div className={styles.progressText}>{t('text.filled-out')} <span>{percent}%</span></div>
          <div className={styles.progressBar}>
            <div className={styles.progressBarValue} style={{ width: `${percent}%` }} />
          </div>
        </div>
        {desc && (<div className={styles.progressDesc}>{desc}</div>)}
      </div>
    )
  }
}

export default withTranslation()(ProgressBar);
