import React from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import FormItem from "../../../components/FormItem/FormItem";
import FormTitle from "../../../components/FormTitle/FormTitle";
import Input from "../../../components/Input/Input";
import InputPhone from "../../../components/InputPhone/InputPhone";

import styles from "../EditForms.module.scss";

const General = ({ form, initialValues, t, user, type }) => {
  const { getFieldDecorator, getFieldError } = form;

  return (
    <>
      <div className={styles.formGrid20_0}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.sender-first-name")}</FormTitle>
            {getFieldDecorator("sender_first_name", {
              initialValue: initialValues.sender_first_name
            })(
              <Input
                placeholder={t("input.your-first-name")}
                errors={getFieldError("sender_first_name")}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.sender-last-name")}</FormTitle>
            {getFieldDecorator("sender_last_name", {
              initialValue: initialValues.sender_last_name
            })(
              <Input
                name="sender_last_name"
                placeholder={t("input.your-last-name")}
                errors={getFieldError("sender_last_name")}
              />
            )}
          </FormItem>
        </div>
      </div>

      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.sender-phone")}</FormTitle>
            {getFieldDecorator("phone", {
              initialValue: initialValues.phone,
              rules: type === 'extras' || type === 'vacancy' ? [{ required: true, message: t("validation.input.required") }] : []
            })(
              <InputPhone
                required={type === 'extras' || type === 'vacancy' ? true : false}
                locale={user.language}
                placeholder={t("input.your-mobile-phone")}
                errors={getFieldError('phone')}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.sender-email")}</FormTitle>
            {getFieldDecorator("email", {
              initialValue: initialValues.email,
              rules: type === 'extras' || type === 'vacancy' ? [] : [
                { type: 'email', message: t('validation.email.notvalid') },
                { required: true, message: t("validation.input.required") }
              ]
            })(
              <Input
                required={type === 'extras' || type === 'vacancy' ? false : true}
                name="email"
                placeholder={t("input.email")}
                errors={getFieldError("email")}
              />
            )}
          </FormItem>
        </div>
      </div>
    </>
  );
};

export default compose(
  withTranslation(),
  inject("user", "dataStore", "navigate"),
  observer
)(General);
