import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import { NotificationManager } from 'react-notifications';
import ReactTooltip from "react-tooltip";
import AdvCard from '../../components/AdvCard/AdvCard';
import Loader from '../../components/Loader/Loader';
import styles from './TalentCabinetCastings.module.scss';
import inboxIcon from "../../../images/inbox-icon.png";
import get from 'lodash/get';

import cabinetStyles from '../Cabinet/Cabinet.module.scss';

@inject('user', 'talent', 'navigate')
@observer
class TalentCabinetCastings extends Component {
  constructor(props) {
    super(props);

    const subscription = get(props, "user.user.role_object.subscription");
    // ВРЕМЕННО
    // const isNotPaid = get(subscription, 'status') === 'not_paid';
    const isNotPaid = false;

    this.state = {
      defaultTab: isNotPaid ? 'crowdings' : 'castings',
    };
  }

  componentWillMount() {
    this.props.talent.fetchTalentCrowdings()
    this.props.talent.fetchTalentInvitations()

    const { user } = this.props;

    if (user.isUnderModeration) {
      this.props.navigate({ path: `/app/talent` });

      NotificationManager.error(this.props.t("text.wait-for-moderation-menu"));
    }
  }

  componentDidMount() {
    const tab = this.props.tab || this.state.defaultTab;

    this.fetchDataByTab(tab);
  }

  fetchDataByTab(tab) {
    switch (tab) {
      case 'castings': this.props.talent.fetchTalentCastings(); break;
      case 'crowdings': this.props.talent.fetchTalentCrowdings(); break;
      case 'invitations': this.props.talent.fetchTalentInvitations(); break;
      default: break;
    }
  }

  handleTabClick = tab => {
    this.props.navigate({
      path: '/app/talent/castings',
      query: { tab }
    });
  }

  renderContent = () => {
    switch (this.props.tab || this.state.defaultTab) {
      case 'castings': return this.renderCastings();
      case 'crowdings': return this.renderCrowdings();
      case 'invitations': return this.renderInvitations();
      default: break;
    }
  }

  renderCastings = () => {
    const { t, talent } = this.props;

    return (
      talent.isCastingsFetching ? (
        <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
          <Loader isAbsolute={true} isLoading={true}/>
        </div>
      ) : (
        talent.castings.length > 0 ? (
          <div className={cabinetStyles.blocksList}>
            {talent.castings.map(casting => (
              <div key={casting.id} className={cabinetStyles.block}>
                <AdvCard data={casting} />
              </div>
            ))}
          </div>
        ) : (
          <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
            <img src={inboxIcon} alt=""/>
            <p>{t('text.no-projects-yet')}</p>
          </div>
        )
      )
    );
  }

  renderCrowdings = () => {
    const { t, talent } = this.props;

    return (
      talent.isCrowdingsFetching ? (
        <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
          <Loader isAbsolute={true} isLoading={true}/>
        </div>
      ) : (
        talent.crowdings.length > 0 ? (
          <div className={cabinetStyles.blocksList}>
            {talent.crowdings.map(crowding => (
              <div className={cabinetStyles.block}>
                <AdvCard key={crowding.id} data={crowding} />
              </div>
            ))}
          </div>
        ) : (
          <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
            <img src={inboxIcon} alt=""/>
            <p>{t('text.no-extras-yet')}</p>
          </div>
        )
      )
    );
  }

  renderInvitations = () => {
    const { t, talent } = this.props;

    return (
      talent.isInvitationsFetching ? (
        <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
          <Loader isAbsolute={true} isLoading={true}/>
        </div>
      ) : (
        talent.invitations.length > 0 ? (
          <div className={cabinetStyles.blocksList}>
            {talent.invitations.map(invitation => (
              <div className={cabinetStyles.block}>
                <AdvCard key={invitation.id} data={invitation} />
              </div>
            ))}
          </div>
        ) : (
          <div style={{ minHeight: 310 }} className={cabinetStyles.blockBodyEmpty}>
            <img src={inboxIcon} alt=""/>
            <p>{t('text.no-invites-yet')}</p>
          </div>
        )
      )
    );
  }

  render() {
    const { talent, t } = this.props;
    const activeTab = this.props.tab || this.state.defaultTab;
    const subscription = get(this.props, "user.user.role_object.subscription");
    // ВРЕМЕННО
    // const isNotPaid = get(subscription, 'status') === 'not_paid';
    const isNotPaid = false

    return (
      <div className={cabinetStyles.page}>
        <div className={cabinetStyles.inner}>
          <div className={styles.tabs}>
            <div
              data-tip
              data-for="castings"
              onClick={() => isNotPaid ? () => {} : this.handleTabClick('castings')}
              className={cn(styles.tab, { [styles.tabActive]: activeTab === 'castings'})}
            >{t("title.castings")}</div>
            {isNotPaid && <ReactTooltip
              place="bottom"
              className={styles.tooltip}
              id="castings"
              effect="solid"
            >
              <span>{t("text.wait-for-paid")}</span>
            </ReactTooltip>}
            <div
              onClick={() => this.handleTabClick('crowdings')}
              className={cn(styles.tab, { [styles.tabActive]: activeTab === 'crowdings'})}
            >{t("title.crowdings")} ({talent.crowdings.length})</div>
            <div
              onClick={() => this.handleTabClick('invitations')}
              className={cn(styles.tab, { [styles.tabActive]: activeTab === 'invitations'})}
            >{t("title.invitations")} ({talent.invitations.length})</div>
          </div>

          {this.renderContent()}

        </div>
      </div>
    )
  }
}

export default withTranslation()(TalentCabinetCastings);
