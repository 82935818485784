import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import Select from '../../components/Select/Select';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import get from 'lodash/get';

import formStyles from "../Forms/EditForms.module.scss";

@inject('user', 'dataStore', 'navigate')
@observer
class AppearanceEdit extends Component {
  componentDidMount() {
    this.props.dataStore.fetchAppearanciesList();
    this.props.dataStore.fetchPhysiquesList();
    this.props.dataStore.fetchEyesList();
    this.props.dataStore.fetchHairsList();
  }

  handleSubmit = (e, next) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          talent: {
            appearance: get(values, 'appearance.value'),
            physique: get(values, 'physique.value'),
            eye_color: get(values, 'eye_color.value'),
            hair_color: get(values, 'hair_color.value'),
            height: get(values, 'height.value'),
            weight: get(values, 'weight.value'),
            hip_girth: get(values, 'hip_girth.value'),
            head_circumference: get(values, 'head_circumference.value'),
            waist_girth: get(values, 'waist_girth.value'),
            chest_girth: get(values, 'chest_girth.value'),
            clothing_size_up: get(values, 'clothing_size_up.value'),
            clothing_size_bottom: get(values, 'clothing_size_bottom.value'),
            shoe_size: get(values, 'shoe_size.value'),
            lameness: values.lameness,
            scar: values.scar,
            piercing: values.piercing,
            have_twin: values.have_twin,
            large_tattoo: values.large_tattoo,
            veneer: values.veneer,
            gold_crowns: values.gold_crowns,
            small_cosmetic_facial_surgery: values.small_cosmetic_facial_surgery,
            birthmark: values.birthmark,
            vitiligo: values.vitiligo,
            albinism: values.albinism,
            pockmark: values.pockmark,
          }
        };

        this.props.user.update(data, true).then(() => {
          if (next) {
            this.props.navigate({
              path: `/app/${this.props.user.user.role}/edit`,
              query: { tab: 'education' }
            }, true);
          }
        });
      } else {
        console.log('error', error, values);
      }
    });
  }

  handleSaveAndNext = (e) => {
    this.handleSubmit(e, true);
  }

  render() {
    const { user, t, form, dataStore } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const role_object = get(user, 'user.role_object', {});

    const { hipGirthOptions, headCircumferenceOptions,
      waistGirthOptions, chestGirthOptions, shoeSizeOptions,
      heightOptions, weightOptions, clothingSizeOptions } = dataStore;

    const dataAppearance = role_object.appearance ? dataStore.appearanciesOptions.find(i => i.value === role_object.appearance) : undefined;
    const dataPhysique = role_object.physique ? dataStore.physiquesOptions.find(i => i.value === role_object.physique) : undefined;
    const dataEyeColor = role_object.eye_color ? dataStore.eyesOptions.find(i => i.value === role_object.eye_color) : undefined;
    const dataHairColor = role_object.hair_color ? dataStore.hairsOptions.find(i => i.value === role_object.hair_color) : undefined;

    const dataHeight = role_object.height ? heightOptions.find(i => i.value === role_object.height) : undefined;
    const dataWeight = role_object.weight ? weightOptions.find(i => i.value === role_object.weight) : undefined;

    const dataHipGirth = role_object.hip_girth ? hipGirthOptions.find(i => i.value === role_object.hip_girth) : undefined;
    const dataHeadCircumference = role_object.head_circumference ? headCircumferenceOptions.find(i => i.value === role_object.head_circumference) : undefined;
    const dataWaistGirth = role_object.waist_girth ? waistGirthOptions.find(i => i.value === role_object.waist_girth) : undefined;
    const dataChestGirth = role_object.chest_girth ? chestGirthOptions.find(i => i.value === role_object.chest_girth) : undefined;

    const dataClothingSizeUp = role_object.clothing_size_up ? clothingSizeOptions.find(i => i.value === role_object.clothing_size_up) : undefined;
    const dataClothingSizeBottom = role_object.clothing_size_bottom ? clothingSizeOptions.find(i => i.value === role_object.clothing_size_bottom) : undefined;
    const dataShoeSize = role_object.shoe_size ? shoeSizeOptions.find(i => i.value === role_object.shoe_size) : undefined;

    return (
      <div className={formStyles.form}>
        <form onSubmit={this.handleSubmit}>
          <div className={formStyles.formTitle}>{t("title.appearance")}</div>
          <div className={formStyles.formSeparator}/>
          <div className={formStyles.formGrid30_0}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={17} isActive={dataAppearance}>{t('label.type-of-appearance')}</FormTitle>
                {getFieldDecorator('appearance', { initialValue: dataAppearance })(
                  <Select
                    placeholder={t('label.type-of-appearance')}
                    options={dataStore.appearanciesOptions}
                    errors={getFieldError('appearance')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={17} isActive={dataPhysique}>{t('label.physique')}</FormTitle>
                {getFieldDecorator('physique', { initialValue: dataPhysique })(
                  <Select
                    placeholder={t('label.physique')}
                    options={dataStore.physiquesOptions}
                    errors={getFieldError('physique')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataEyeColor}>{t('label.eye-color')}</FormTitle>
                {getFieldDecorator('eye_color', { initialValue: dataEyeColor })(
                  <Select
                    placeholder={t('label.eye-color')}
                    options={dataStore.eyesOptions}
                    errors={getFieldError('eye_color')}
                  />
                )}
              </FormItem>
            </div>
          </div>
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataHairColor}>{t('label.hair-color')}</FormTitle>
                {getFieldDecorator('hair_color', { initialValue: dataHairColor })(
                  <Select
                    placeholder={t('label.hair-color')}
                    options={dataStore.hairsOptions}
                    errors={getFieldError('hair_color')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formTitle}>{t("title.height-and-weight")}</div>
          <div className={formStyles.formSeparator}/>

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={17} isActive={dataHeight}>{t('label.height')}</FormTitle>
                {getFieldDecorator('height', { initialValue: dataHeight })(
                  <Select
                    placeholder={t('label.height')}
                    options={heightOptions}
                    errors={getFieldError('height')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataWeight}>{t('label.weight')}</FormTitle>
                {getFieldDecorator('weight', { initialValue: dataWeight })(
                  <Select
                    placeholder={t('label.weight')}
                    options={weightOptions}
                    errors={getFieldError('weight')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formTitle}>{t("title.girth")}</div>
          <div className={formStyles.formSeparator}/>

          <div className={formStyles.formGrid30_0}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataChestGirth}>{t('label.chest-girth')}</FormTitle>
                {getFieldDecorator('chest_girth', { initialValue: dataChestGirth })(
                  <Select
                    placeholder={t('label.chest-girth')}
                    options={chestGirthOptions}
                    errors={getFieldError('chest_girth')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataWaistGirth}>{t('label.waist-girth')}</FormTitle>
                {getFieldDecorator('waist_girth', { initialValue: dataWaistGirth })(
                  <Select
                    placeholder={t('label.waist-girth')}
                    options={waistGirthOptions}
                    errors={getFieldError('waist_girth')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataHipGirth}>{t('label.hip-girth')}</FormTitle>
                {getFieldDecorator('hip_girth', { initialValue: dataHipGirth })(
                  <Select
                    placeholder={t('label.hip-girth')}
                    options={hipGirthOptions}
                    errors={getFieldError('hip_girth')}
                  />
                )}
              </FormItem>
            </div>
          </div>
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={4} isActive={dataHeadCircumference}>{t('label.head-circumference')}</FormTitle>
                {getFieldDecorator('head_circumference', { initialValue: dataHeadCircumference })(
                  <Select
                    placeholder={t('label.head-circumference')}
                    options={headCircumferenceOptions}
                    errors={getFieldError('head_circumference')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.formTitle}>{t("title.sizes")}</div>
          <div className={formStyles.formSeparator}/>

          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataClothingSizeUp}>{t('label.clothing-size-up')}</FormTitle>
                {getFieldDecorator('clothing_size_up', { initialValue: dataClothingSizeUp })(
                  <Select
                    placeholder={t('label.clothing-size-up')}
                    options={clothingSizeOptions}
                    errors={getFieldError('clothing_size_up')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataClothingSizeBottom}>{t('label.clothing-size-bottom')}</FormTitle>
                {getFieldDecorator('clothing_size_bottom', { initialValue: dataClothingSizeBottom })(
                  <Select
                    placeholder={t('label.clothing-size-bottom')}
                    options={clothingSizeOptions}
                    errors={getFieldError('clothing_size_bottom')}
                  />
                )}
              </FormItem>
            </div>
            <div className={formStyles.formGrid3}>
              <FormItem>
                <FormTitle progress={5} isActive={dataShoeSize}>{t('label.shoe-size')}</FormTitle>
                {getFieldDecorator('shoe_size', { initialValue: dataShoeSize })(
                  <Select
                    placeholder={t('label.shoe-size')}
                    options={shoeSizeOptions}
                    errors={getFieldError('shoe_size')}
                  />
                )}
              </FormItem>
            </div>
          </div>


          <div className={formStyles.formTitle}>{t("title.features")}</div>
          <div className={formStyles.formSeparator}/>


          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid3}>
              <FormItem>
                {getFieldDecorator('lameness', { initialValue: get(role_object, 'lameness') })(
                  <Checkbox name="lameness" label={t("label.lameness")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('scar', { initialValue: get(role_object, 'scar') })(
                  <Checkbox name="scar" label={t("label.scar")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('piercing', { initialValue: get(role_object, 'piercing') })(
                  <Checkbox name="piercing" label={t("label.piercing")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('have_twin', { initialValue: get(role_object, 'have_twin') })(
                  <Checkbox name="have_twin" label={t("label.have-twin")} />
                )}
              </FormItem>
            </div>

            <div className={formStyles.formGrid3}>
              <FormItem>
                {getFieldDecorator('large_tattoo', { initialValue: get(role_object, 'large_tattoo') })(
                  <Checkbox name="large_tattoo" label={t("label.large-tattoo")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('veneer', { initialValue: get(role_object, 'veneer') })(
                  <Checkbox name="veneer" label={t("label.veneer")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('gold_crowns', { initialValue: get(role_object, 'gold_crowns') })(
                  <Checkbox name="gold_crowns" label={t("label.gold-crowns")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('small_cosmetic_facial_surgery', { initialValue: get(role_object, 'small_cosmetic_facial_surgery') })(
                  <Checkbox name="small_cosmetic_facial_surgery" label={t("label.operations")} />
                )}
              </FormItem>
            </div>

            <div className={formStyles.formGrid3}>
              <FormItem>
                {getFieldDecorator('birthmark', { initialValue: get(role_object, 'birthmark') })(
                  <Checkbox name="birthmark" label={t("label.birthmark")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('vitiligo', { initialValue: get(role_object, 'vitiligo') })(
                  <Checkbox name="vitiligo" label={t("label.vitiligo")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('albinism', { initialValue: get(role_object, 'albinism') })(
                  <Checkbox name="albinism" label={t("label.albinism")} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('pockmark', { initialValue: get(role_object, 'pockmark') })(
                  <Checkbox name="pockmark" label={t("label.pockmark")} />
                )}
              </FormItem>
            </div>
          </div>

          <div className={formStyles.buttons}>
            <Button className={formStyles.button} kind="primary" type="submit">{t("button.save")}</Button>
            <Button onClick={this.handleSaveAndNext} className={formStyles.buttonLast} kind="bordered" type="button">{t("button.save-and-next-step")}</Button>
          </div>
        </form>
      </div>
    )
  }
}

export default withTranslation()(createForm()(AppearanceEdit));
