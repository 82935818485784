import React from "react";
import { Collapse } from "react-collapse";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import get from 'lodash/get';

import SearchCheckboxGroup from './components/SearchCheckboxGroup';

import styles from "./Search.module.scss";

@inject("user", "dataStore")
@observer
class FeaturesSearchBlock extends React.Component {
  handleCollapse = () => this.props.toggleCollapse('features');

  render() {
    const { user, form, isOpen, t, queries = {} } = this.props;
    const { searchAggregations } = user;
    const formProps = { form, queries };

    const fieldCount = field => get(
      get(searchAggregations, field, []).find(i => i.key_as_string === "true"), 'doc_count', 0
    );

    return (
      <div className={styles.collapseWrapper}>
        <div
          onClick={this.handleCollapse}
          className={cn(styles.collapseHeader, {
            [styles.collapseHeaderActive]: isOpen
          })}
        >
          {t("title.features")}
        </div>
        <Collapse isOpened={isOpen}>
          <div className={styles.collapseBody}>
            <SearchCheckboxGroup
              {...formProps }
              title="title.features"
              options={[
                { field: 'lameness', label: 'label.lameness' },
                { field: 'scar', label: "label.scar" },
                { field: 'piercing', label: "label.piercing" },
                { field: 'have_twin', label: "label.have-twin" },
                { field: 'large_tattoo', label: "label.large-tattoo" },
                { field: 'veneer', label: "label.veneer" },
                { field: 'gold_crowns', label: "label.gold-crowns" },
                { field: 'small_cosmetic_facial_surgery', label: "label.operations" },
                { field: 'birthmark', label: "label.birthmark" },
                { field: 'vitiligo', label: "label.vitiligo" },
                { field: 'albinism', label: "label.albinism" },
                { field: 'pockmark', label: "label.pockmark" },
              ].map(o => { o.count = fieldCount(o.field); return o; }).filter(o => o.count > 0)}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}
export default withTranslation()(FeaturesSearchBlock);
