import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import styles from "./Pagination.module.scss";

import "./pagination.scss";

export default class Pagination extends Component {
  handlePageClick = data => {
    this.props.handlePageChange(data.selected + 1);
  };

  render() {
    const { page = 1, count, maxCount, initialPage } = this.props;

    const pageCount = Math.ceil(count / maxCount);

    return (
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={3}
        disableInitialCallback={true}
        onPageChange={this.handlePageClick}
        previousLabel={() => (
          <div className={styles.leftButton} onClick={this.props.handleNext} />
        )}
        initialPage={initialPage - 1}
        nextLabel={() => (
          <div className={styles.rightButton} onClick={this.props.handleNext} />
        )}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    );
  }
}
