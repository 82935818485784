import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { createForm } from "rc-form";
import get from "lodash/get";
import isNull from "lodash/isNull";
import pick from "lodash/pick";
import isArray from "lodash/isArray";
import moment from 'moment';
import ModalSuccess from '../../../components/ModalInfo/ModalSuccess';

import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import FormTitle from "../../../components/FormTitle/FormTitle";
import Button from "../../../components/Button/Button";
import InputMask from "../../../components/InputMask/InputMask";
import Select from "../../../components/Select/Select";

import General from "./General";
import Contacts from "./Contacts";
import Role from "./Role";

import styles from "../EditForms.module.scss";

import { Title } from "../Components";

@inject("user", "dataStore", "navigate")
@observer
class CastingInvitation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccessModal: false,
    }

    this.fields = [
      'projekt_name', 'director', 'shooting_cities', 'start_date', 'end_date',
      'place', 'casting_start_date', 'casting_end_date', 'casting_date', 'casting_time', 'rate', 'about', 'attention',
      'sender_first_name', 'sender_last_name', 'phone', 'email'
    ];
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const talents_id = this.props.selected.map(id => Number(id));
        const data = {
          projekt: {
            form: 2,
            talents_id,
            ...pick(values, this.fields),
            currency: get(values, "currency.value"),
            projekt_type: get(values, "projekt_type.value"),
            time_start: get(values, "time_start") ? get(values, "time_start").format("HH:mm") : undefined,
            time_end: get(values, "time_end") ? get(values, "time_end").format("HH:mm") : undefined,
            casting_time: get(values, "casting_time") ? get(values, "casting_time")._d.toLocaleTimeString('en-US', {hour12: false}) : undefined,
            role_type: get(values, "role_type.value"),
            document: isArray(get(values, "document")) ? get(values, "document")[0] : undefined,
          },
          casting_id: (get(values, "document") && !isArray(get(values, "document"))) ? this.props.data.id : undefined,
        };

        this.props.dataStore.createAdvertisement(this.props.projectId, data).then(() => {
          this.setState({ showSuccessModal: true });
        });
      } else {
        console.log("Advertisement error", error, values);
      }
    });
  };

  componentDidMount() {
    this.props.dataStore.fetchMovieTypesList();
    this.props.dataStore.fetchAppearanciesList();
    this.props.dataStore.fetchCurrenciesList();
  }

  handleCloseModal = () => {
    this.setState({ showSuccessModal: false });
    this.props.navigate({ path: `/app/${this.props.user.user.role}/boards/${this.props.projectId}` });
  }

  render() {
    const { dataStore, t, form, data } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const initialValues = {
      ...pick(data, this.fields),
      currency: dataStore.currencyOptions.find(c => c.value === get(data, 'currency')),
      projekt_type: dataStore.movieTypesOptions.find(o => o.value == get(data, "projekt_type")),
      time_start: get(data, "time_start") ? moment.utc(get(data, "time_start")) : undefined,
      time_end: get(data, "time_end") ? moment.utc(get(data, "time_end")) : undefined,
      role_type: dataStore.roleStatusTypes.find(t => t.value == get(data, "role_type")),
      document: isNull(get(data, "document")) ? undefined : get(data, "document"),
    };

    return (
      <form onSubmit={this.handleSubmit}>
        <ModalSuccess
          isOpen={this.state.showSuccessModal}
          onClose={this.handleCloseModal}
          title={t("text.invitation-create-title")}
        >
          <div style={{ marginBottom: 30, lineHeight: 1.4 }}>{t("text.invitation-create-text")}</div>
          <div style={{ marginBottom: 30, lineHeight: 1.4, fontStyle: 'italic' }}>{t("text.invitation-create-text-2")}</div>
          <Button onClick={this.handleCloseModal}>{t('button.close')}</Button>
        </ModalSuccess>
        <Title title="title.invite-to-casting" />

        <SectionTitle>{t("title.common-information")}</SectionTitle>

        <General form={form} initialValues={initialValues} advType={'invitation'}/>

        <div className={styles.formGrid20}>
          <div className={styles.photoGrid2}>
            <FormTitle>{t("input.shift-rate")}</FormTitle>

            <div className={styles.currencyRate}>
              {getFieldDecorator("rate", {
                initialValue: initialValues.rate,
                rules: [{ required: true, message: t("validation.input.required") }]
              })(
                <InputMask
                  wrapperClassName={styles.currencyRateInput}
                  mask={"9999999999"}
                  name="rate"
                  autocomplete="off"
                  placeholder={t("input.shift-rate-placeholder")}
                  errors={getFieldError('rate')}
                />
              )}
              {getFieldDecorator('currency', {
                initialValue: initialValues.currency,
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Select
                  wrapperClassName={styles.currencyRateSelect}
                  placeholder={t('input.currency')}
                  options={dataStore.currencyOptions}
                  errors={getFieldError('currency')}
                />
              )}
            </div>
          </div>
        </div>

        <SectionTitle>{t("title.role")}</SectionTitle>

        <Role form={form} initialValues={initialValues} />

        <SectionTitle>{t("title.contacts")}</SectionTitle>

        <Contacts form={form} initialValues={initialValues} />

        <div className={styles.buttons}>
          <Button className={styles.button} kind="black" type="submit">
            {t("button.done")}
          </Button>
          <Button className={styles.button} onClick={() => window.history.back()} kind="bordered">
            {t("button.cancel")}
          </Button>
        </div>
      </form>
    );
  }
}

export default withTranslation()(createForm()(CastingInvitation));
