import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import InputMask from '../../components/InputMask/InputMask';
import Select from '../../components/Select/Select';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import Checkbox from '../../components/Checkbox/Checkbox';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

import styles from './StaffSignUpStepper.module.scss';
import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class Step2 extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const professions = values.profession.reduce((acc, cur) => ({ ...acc, [cur.value]: true }), {})
        const data = {
          staff: {
            main_profession: values.profession[0].value,
            status: values.status,
            career_attributes: {
              work_status: values.work_status,
              low_budget: values.low_budget,
              trip: values.trip,
              ...professions
            },
            rate_attributes: {
              rate_start: values.rate_from,
              currency: get(values, 'currency.value')
            }
          }
        };

        this.props.user.update(data);
      } else {
        console.log('error', error, values);
      }
    });
  }

  handleSelectProfession = (data) => {
    const currentProfessions = this.props.form.getFieldValue('profession');
    const newData = uniq([...currentProfessions, ...data]);

    this.props.form.setFieldsValue({ profession: newData })
  }

  componentDidMount() {
    this.props.dataStore.fetchCareersList();
    this.props.dataStore.fetchCurrenciesList();
  }

  render() {
    const { t, form, dataStore } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;
    const allProfessions = toJS(dataStore.allProfessions);

    return (
      <form onSubmit={this.handleSubmit}>
        <FormItem>
          <FormTitle size="small">{t("text.career.title")}</FormTitle>
          {getFieldDecorator('profession', {
            initialValue: [],
            rules: [{ required: true, message: t('validation.input.required') }]
          })(
            <MultiSelect
              required
              maxCount={3}
              onChange={this.handleSelectProfession}
              placeholder={t('input.profession')}
              options={allProfessions}
              errors={getFieldError('profession')}
              extra={t("text.profession.extra")}
            />
          )}
        </FormItem>

        <p className={styles.rateTitle}>{t("text.profile-status.title")}</p>

        <div className={styles.formGroup}>
          <FormItem styles={{ display: 'flex' }}>
            {getFieldDecorator('work_status', {
              initialValue: 'company',
            })(
              <RadioGroup isColumn data={dataStore.workStatusOptions} />
            )}
          </FormItem>
        </div>

        <p className={styles.rateTitle}>{t("text.staff-status.title")}</p>

        <div className={styles.formGroup}>
          <FormItem styles={{ display: 'flex' }}>
            {getFieldDecorator('status', {
              initialValue: 'amateur',
            })(
              <RadioGroup isColumn data={dataStore.statusOptions} />
            )}
          </FormItem>
        </div>

        <p className={styles.rateTitle}>{t("text.select-area-of-work")}</p>

        <FormItem>
          {getFieldDecorator('low_budget', { initialValue: false })(
            <Checkbox name="low_budget" label={t('input.low-budget-staff')} />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('trip', { initialValue: false })(
            <Checkbox name="trip" label={t('input.long-trip')} />
          )}
        </FormItem>
        <FormItem styles={{ marginTop: 30 }}>
          <FormTitle size="small">{t("input.shift-rate")}</FormTitle>
          <div className={formStyles.currencyRate}>
            {getFieldDecorator('rate_from', { initialValue: '' })(
              <InputMask
                wrapperClassName={formStyles.currencyRateInput}
                mask={"9999999999"}
                name="rate_from"
                autocomplete="off"
                placeholder={t("input.shift-wage-rate-placeholder")}
                errors={getFieldError('rate_from')}
              />
            )}
            {getFieldDecorator('currency', {
              initialValue: { value: 'KZT', label: 'KZT' },
              rules: [{ required: getFieldValue('rate_from'), message: t('validation.input.required') }]
            })(
              <Select
                wrapperClassName={formStyles.currencyRateSelect}
                placeholder={t('input.currency')}
                options={dataStore.currencyOptions}
                errors={getFieldError('currency')}
              />
            )}
          </div>
        </FormItem>
        <div className={styles.buttons}>
          <Button
            kind="primary"
            type="submit"
            className={styles.button}
          >{t('button.next')}</Button>
        </div>
      </form>
    )
  }
}


export default withTranslation()(createForm()(Step2));
