import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import closeImage from "../../../images/close-modal-icon.png";

import styles from './ModalInfo.module.scss';

Modal.setAppElement('#app');

class ModalSuccess extends Component {
  handleClose = () => {
    this.props.onClose();
  }

  render() {
    const { isOpen, title, children, isUpdating } = this.props;

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 541,
        height: 'auto',
        maxHeight: 'calc(100% - 100px)'
      }
    };

    return (
      <Modal
        isOpen={isOpen}
        style={modalStyle}
        onRequestClose={this.handleClose}
      >
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <p className={styles.titleText}>{title}</p>
          </div>
          {children}
          <img className={styles.modalClose} onClick={this.handleClose} src={closeImage} />
          <Loader isLoading={isUpdating}/>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalSuccess);
