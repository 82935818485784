import React from "react";
import cn from "classnames";
import { PlyrComponent } from "plyr-react";

import Button from "../../../components/Button/Button";

import styles from "./styles/TutorialView.module.scss";

const TutorialView = ({
  user,
  type,
  isLoading,
  title,
  category,
  article,
  announcement,
  videoSources,
  date,
  preview,
  previewMobile,
  sources,
  t,
  goBack,
  read_time
}) => {
  if (isLoading) {
    return null;
  }

  const previewPhoto = window.innerWidth < 720 && previewMobile ? previewMobile : preview

  return (
    <div className={styles.page}>
      <div className={styles.tutorialInner__text}>
        <div className={styles.previewWrapper}>
          <div
            className={styles.preview}
            style={{ backgroundImage: `url(${previewPhoto})` }}
          />
          <div className={styles.previewOverlay} />
          <div className={styles.info}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>{`${category === 'Без категории' ? '' : `${category} /`} ${date}`}</div>
            <div className={styles.subtitle}>{ read_time[user.language] }</div>
          </div>
        </div>
      </div>

      <div className={cn(styles.smallInner, styles.tutorialInner__text)}>
        <Button className={styles.backBtn} kind="bordered" onClick={goBack}>
          {t("button.back")}
        </Button>
      </div>

      <div className={cn(styles.inner, styles.tutorialInner__text, 'prose prose-xl')}>
        <div
          className={styles.article}
          dangerouslySetInnerHTML={{ __html: announcement }}
        />
        <br/>

        <div
          className={styles.article}
          dangerouslySetInnerHTML={{ __html: article }}
        />

        {videoSources.length ? (
          <div className={styles.videoLinksList}>
            {videoSources.map(source => (
              <div className={styles.videoLink}>
                <PlyrComponent sources={source} />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TutorialView;
