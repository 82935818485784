import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import { Input, Select, FormItem, FormTitle, FormItemControls } from '../../components';
import get from 'lodash/get';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class PortfolioProjectEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          company: {
            projects_attributes: [{
              movie_type_dictionary_id: values.movie_type_dictionary_id.value,
              name: values.name,
              year: new Date(values.year.value, 0, 2),
              country_code: get(values, 'country.value'),
              link: values.link,
              id: get(this.props.data, 'id')
            }],
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('PortfolioProjectEdit error', error, values);
      }
    });
  }

  render() {
    const { user, t, form, data = {}, dataStore } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const { countryOptions, movieTypesOptions } = dataStore;

    console.log('countryOptions' ,countryOptions);

    const selectedCountry = data.id
      ? countryOptions.find(c => c.value == data.country_code)
      : null;
    const dataProject = data.id
      ? movieTypesOptions.find(t => t.value == data.movie_type_dictionary_id)
      : null;
    const dataYear = data.year
      ? dataStore.yearOptions.find(t => t.value == data.year)
      : null;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={formStyles.formGrid} style={{ marginBottom: 0 }}>
          <div className={formStyles.formGrid3}>
            <FormItem>
              <FormTitle>{t('label.project-type')}</FormTitle>
              {getFieldDecorator('movie_type_dictionary_id', {
                initialValue: dataProject,
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Select
                  required
                  placeholder={t('label.project-type')}
                  options={movieTypesOptions}
                  errors={getFieldError('movie_type_dictionary_id')}
                />
              )}
            </FormItem>
            <FormItem>
              <FormTitle>{t('label.year')}</FormTitle>
              {getFieldDecorator('year', {
                initialValue: dataYear,
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Select
                  required
                  placeholder={t('label.year')}
                  options={dataStore.yearOptions}
                  errors={getFieldError('year')}
                />
              )}
            </FormItem>
          </div>
          <div className={formStyles.formGrid3}>
            <FormItem>
              <FormTitle>{t('label.project-name')}</FormTitle>
              {getFieldDecorator('name', {
                initialValue: get(data, 'name', ''),
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="project_name"
                  placeholder={t('label.project-name')}
                  errors={getFieldError('name')}
                />
              )}
            </FormItem>
            <FormItem>
              <FormTitle>{t('label.project-link')}</FormTitle>
              {getFieldDecorator('link', {
                initialValue: get(data, 'link', ''),
              })(
                <Input
                  name="link"
                  placeholder={t('label.project-link')}
                  errors={getFieldError('link')}
                />
              )}
            </FormItem>
          </div>
          <div className={formStyles.formGrid3}>
          <FormItem>
              <FormTitle>{t('input.country')}</FormTitle>
              {getFieldDecorator('country', {
                initialValue: selectedCountry,
              })(
                <Select
                  required
                  placeholder={t('input.country')}
                  options={countryOptions}
                  errors={getFieldError('country')}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className={formStyles.formGrid}>
          <FormItemControls data={data} entity="projects"/>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(PortfolioProjectEdit));
