import React, { Component } from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';

export default class Button extends Component {
  render() {
    const {
      children,
      type = 'button',
      className,
      onClick,
      kind,
      size,
      disabled = false,
      wrapperClassName
    } = this.props;

    const classNames = cn(
      styles.button,
      { [`${styles.buttonPrimary}`]: kind === 'primary' },
      { [`${styles.buttonWhite}`]: kind === 'white' },
      { [`${styles.buttonGray}`]: kind === 'gray' },
      { [`${styles.buttonBlack}`]: kind === 'black' },
      { [`${styles.buttonBordered}`]: kind === 'bordered' },
      { [`${styles.buttonSmall}`]: size === 'small' },
      { [`${styles.buttonBig}`]: size === 'big' },
      { [`${styles.buttonLarge}`]: size === 'large' },
      className
    );

    return (
      <div className={cn(styles.wrapper, wrapperClassName)}>
        <button
          disabled={disabled}
          type={type}
          onClick={onClick}
          className={classNames}
        >
          {children}
        </button>
      </div>
    )
  }
}
