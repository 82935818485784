import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import { Input, Button, FormItem, FormTitle, BlockHeader } from '../../components/';
import styles from './CabinetSettings.module.scss';
import get from 'lodash/get';

import cabinetStyles from '../Cabinet/Cabinet.module.scss';

@inject('user', 'navigate')
@observer
class CabinetSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {clearInputs: false}
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          user: {
            current_password: values.current_password,
            password: values.password,
            password_confirmation: values.password_confirmation,
          }
        };

        this.props.user.changePassword(data, this.props.form);
      } else {
        console.log('CabinetSettings error', error, values);
      }
    });

    this.setState({clearInputs: true})
  }

  handleFreezeAccount = () => {
    this.props.user.freezeAccount(true);
  }

  handleUnfreezeAccount = () => {
    this.props.user.freezeAccount(false);
  }

  onChange = () => {
    this.setState({clearInputs: false})
  }

  render() {
    const { user, t, form } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const role_object = get(user, 'user.role_object', {});

    return (
      <div className={cabinetStyles.page}>
        <div className={cabinetStyles.inner}>
          <div className={cabinetStyles.pageGrid}>
            <div className={cabinetStyles.pageGrid5}>
              <BlockHeader>{t("title.change-password")}</BlockHeader>
              <div className={cabinetStyles.blockBody}>
                <form onSubmit={this.handleSubmit}>
                  <FormItem>
                    <FormTitle>{t('input.password')}</FormTitle>
                    {getFieldDecorator('current_password', {
                      initialValue: '',
                      rules: [
                        { required: true, message: t('validation.input.required') },
                      ]
                    })(
                      <Input
                        cleanValue={this.state.clearInputs}
                        onChange={this.onChange}
                        required
                        name="current_password"
                        type="password"
                        placeholder={t('input.password')}
                        errors={getFieldError('current_password')}
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <FormTitle>{t('input.new-password')}</FormTitle>
                    {getFieldDecorator('password', {
                      initialValue: '',
                      rules: [
                        { required: true, message: t('validation.input.required') },
                      ]
                    })(
                      <Input
                        cleanValue={this.state.clearInputs}
                        onChange={this.onChange}
                        required
                        name="password"
                        type="password"
                        placeholder={t('input.new-password')}
                        errors={getFieldError('password')}
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <FormTitle>{t('input.new-password-confirmation')}</FormTitle>
                    {getFieldDecorator('password_confirmation', {
                      initialValue: '',
                      rules: [
                        { required: true, message: t('validation.input.required') },
                      ]
                    })(
                      <Input
                        cleanValue={this.state.clearInputs}
                        onChange={this.onChange}
                        required
                        name="password_confirmation"
                        type="password"
                        placeholder={t('input.new-password-confirmation')}
                        errors={getFieldError('password_confirmation')}
                      />
                    )}
                  </FormItem>
                  <Button kind="primary" type="submit">{t("button.save")}</Button>
                </form>
              </div>
            </div>
            <div className={cabinetStyles.pageGrid5}>
              <BlockHeader>{t("title.freeze-account")}</BlockHeader>
              <div className={cabinetStyles.blockBody}>
                <p className={styles.freezeText}>{t("text.freeze-desc-1")}</p>
                <p className={styles.freezeText}>{t("text.freeze-desc-2")}</p>
                <p className={styles.freezeText}>{t("text.freeze-desc-3")}</p>

                {role_object.frozen_status ? (
                  <Button onClick={this.handleUnfreezeAccount} kind="primary" type="submit">{t("button.unfreeze-account")}</Button>
                ) : (
                  <Button onClick={this.handleFreezeAccount} kind="primary" type="submit">{t("button.freeze-account")}</Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(createForm()(CabinetSettings));
