import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import ReactTooltip from "react-tooltip";
import Button from '../Button/Button';
import get from 'lodash/get';

import styles from "./VacanciesCastingsInfo.module.scss";

@inject("navigate", "user")
@observer
class VacanciesCastingsInfo extends Component {
  state = {
    isMenuOpen: false
  };

  handleAnnounceClick = () => {
    const userType = this.props.user.user.role;

    this.props.navigate({ path: `/app/${userType}/boards/0/create_casting` }, true);
  };

  handlePostVacancyClick = () => {
    const userType = this.props.user.user.role;

    this.props.navigate({ path: `/app/${userType}/boards/0/create_vacancy` }, true);
  }

  render() {
    const { t, user } = this.props;
    const moderationStatus = get(user, "user.role_object.moderation_status", "");
    const isNotApproved = moderationStatus !== 'approved';

    return (
      <div className={styles.vacanciesInfo}>
        <div className={styles.vacanciesInfoBlock}>
          <p>{t("text.vacancies-first")}</p>
          <div data-tip data-for="casting" className={styles.castingBtn}>
            <Button onClick={isNotApproved ? () => {} : this.handleAnnounceClick} disabled={isNotApproved} size="big" kind="primary">
              {t("button.announce-casting")}
            </Button>
          </div>

          {isNotApproved && <ReactTooltip
            className={styles.tooltip}
            id="casting"
            effect="solid"
          >
            <span>{t("text.wait-for-moderation-menu")}</span>
          </ReactTooltip>}
        </div>
        <div className={styles.vacanciesInfoBlock}>
          <p>{t("text.vacancies-second")}</p>
          <div data-tip data-for="vacancy" className={styles.castingBtn2}>
            <Button onClick={isNotApproved ? () => {} : this.handlePostVacancyClick} disabled={isNotApproved} size="big" kind="gray">
              {t("button.post-vacancies")}
            </Button>
          </div>

          {isNotApproved && <ReactTooltip
            className={styles.tooltip}
            id="vacancy"
            effect="solid"
          >
            <span>{t("text.wait-for-moderation-menu")}</span>
          </ReactTooltip>}
        </div>
      </div>
    );
  }
}

export default withTranslation()(VacanciesCastingsInfo);
