import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Input from '../../components/Input/Input';
import InputPhone from '../../components/InputPhone/InputPhone';
import InputMaskFacebook from '../../components/InputMask/InputMaskFacebook';
import InputMaskInstagram from '../../components/InputMask/InputMaskInstagram';
import InputMaskVK from '../../components/InputMask/InputMaskVK';
import Button from '../../components/Button/Button';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import get from 'lodash/get';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'navigate')
@observer
class ContactsEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          staff: {
            phones_attributes: [{
              id: get(this.props.user.user.role_object, 'phones[0].id'),
              number: values.phone_number
            }],
            website: values.website,
            fb: values.fb,
            instagram: values.instagram,
            vk: values.vk,
            imdb: values.imdb,
            kinopoisk: values.kinopoisk,
           }
        };

        this.props.user.update(data, true);
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { user, t, form } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const role_object = get(user, 'user.role_object', {});

    return (
      <div className={formStyles.form}>
        <form onSubmit={this.handleSubmit}>
          <div className={formStyles.formTitle}>{t("title.contacts")}</div>
          <div className={formStyles.formSeparator}/>
          <div className={formStyles.formGrid}>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('label.phone-number')}</FormTitle>
                {getFieldDecorator('phone_number', {
                  initialValue: get(role_object, 'phones[0].number'),
                  rules: [
                    { required: true, message: t('validation.phone.required') },
                  ]
                })(
                  <InputPhone
                    required
                    locale={this.props.user.language}
                    placeholder={t('label.phone-number')}
                    errors={getFieldError('phone_number')}
                  />
                )}
              </FormItem>
              <FormItem>
                <FormTitle>Instagram</FormTitle>
                <InputMaskInstagram form={form} initialValue={get(role_object, 'instagram')} />
              </FormItem>
              <FormItem>
                <FormTitle>Facebook</FormTitle>
                <InputMaskFacebook form={form} initialValue={get(role_object, 'fb')} />
              </FormItem>
              <FormItem>
                <FormTitle>VK</FormTitle>
                <InputMaskVK form={form} initialValue={get(role_object, 'vk')} />
              </FormItem>
            </div>
            <div className={formStyles.formGrid2}>
              <FormItem>
                <FormTitle>{t('label.site')}</FormTitle>
                {getFieldDecorator('website', { initialValue: get(role_object, 'website') })(
                  <Input
                    name="site"
                    placeholder={t('label.site')}
                    errors={getFieldError('website')}
                  />
                )}
              </FormItem>
              <FormItem>
                <FormTitle>Kinopoisk</FormTitle>
                <FormItem>
                  {getFieldDecorator('kinopoisk', { initialValue: get(role_object, 'kinopoisk') })(
                    <Input
                      name="kinopoisk"
                      placeholder={t('input.your-kinopoisk-profile')}
                      errors={getFieldError('kinopoisk')}
                    />
                  )}
                </FormItem>
              </FormItem>
              <FormItem>
                <FormTitle>IMDb</FormTitle>
                {getFieldDecorator('imdb', { initialValue: get(role_object, 'imdb') })(
                  <Input
                    name="imdb"
                    placeholder={t('input.your-imdb-profile')}
                    errors={getFieldError('imdb')}
                  />
                )}
              </FormItem>
            </div>
          </div>

          <Button kind="primary" type="submit">{t("button.save")}</Button>
        </form>
      </div>
    )
  }
}

export default withTranslation()(createForm()(ContactsEdit));
