import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  Button, BlockHeader, Loader, VacancyCard, ProjectsList,
  VacanciesCastingsInfo, Advertising, AdvertisingInfo
} from '../../components'
import styles from "./CompanyCabinet.module.scss";
import cn from "classnames";
import inboxIcon from "../../../images/inbox-icon.png";
import EducationAnnounces from "../EducationAnnounces/EducationAnnounces";
import get from "lodash/get";

import cabinetStyles from "../Cabinet/Cabinet.module.scss";
import GuidePopup from "../../components/GuidePopup/GuidePopup";

@inject("user", "company", "dataStore", "navigate")
@observer
class CompanyCabinet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGuide: false
    }

    this.VacanciesCastingsInfoRef = React.createRef()
  }

  handleLogout = () => {
    this.props.user.logout();
  };

  componentWillMount() {
    const { role } = this.props.user.user;

    if (role !== 'company') {
      this.props.navigate({ path: `/app/${role}`});
    }
  }

  componentDidMount() {
    this.props.dataStore.fetchCareersList();
    this.props.user.fetchTutorialsList();
    this.props.company.fetchVacancies();
    this.props.company.fetchInvitations();

    window.addEventListener("scroll", this.handleScroll);
  }

  handleEditClick = () => {
    this.props.navigate({ path: "/app/company/edit" });
  };

  getModerationStatus = status =>
    ({
      not_yet: this.props.t("status.in-progress"),
      on_moderation: this.props.t("status.in-progress"),
      refused: this.props.t("status.moderation-refused"),
      approved: this.props.t("status.moderation-approved-staff-company"),
      "": ""
    }[status]);

  handleScroll = () => {
    if (this.VacanciesCastingsInfoRef.current) {
      const elementXPosition = this.VacanciesCastingsInfoRef.current.getBoundingClientRect().top
      const windowPageYOffset = window.pageYOffset

      if (!this.state.showGuide && windowPageYOffset > elementXPosition) {
        this.setState({showGuide: true})
      }
    }
  }

  render() {
    const { user, company, dataStore, t } = this.props;
    const { adBannersOptions } = dataStore
    const { role } = this.props.user.user;
    const role_object = get(user, 'user.role_object', {});
    const videoTutorials = toJS(get(user, "tutorialsList.videos", []));
    const textTutorials = toJS(get(user, "tutorialsList.texts", []));

    const moderationStatus = get(role_object, "moderation_status", "");
    const isUnderModeration = user.isUnderModeration;
    const isBannerPresent = adBannersOptions.length !== 0 && adBannersOptions[role][0]

    // Тут из всех объектов user.guides выявляем нужный нам для страницы Поиска с темой "search_guide_popup"
    const guidePopupEvent = get(user, 'user.events').find(event => event.theme === 'vacancy_guide_popup')

    // Формируется ссылка для скачивания pdf
    const pathToFile = role_object.cvs[`cv_${user.language}_contacts_true`]
    const downloadCVUrl =  `https://etalents.pro${pathToFile}`

    return (
      <div className={cn(cabinetStyles.page, cabinetStyles.inner)}>
        <div className={cn(cabinetStyles.pageGrid, styles.firstContentBlock)}>
          <div className={cabinetStyles.pageGrid7}>
            {/* Moderation status */}
            <div
              className={cn(
                styles.detailsHeader,
                styles.detailsHeaderWithStatus
              )}
            >
              <BlockHeader>{t("title.my-profile")}</BlockHeader>
              <div className={cabinetStyles.moderationStatus}>
                {t("text.moderation-status")}:&nbsp;
                <span>{this.getModerationStatus(moderationStatus)}</span>
              </div>
            </div>

            {/*Всплывающее окно*/}
            { role !== 'talent' && this.state.showGuide &&
              <GuidePopup guideState={guidePopupEvent && guidePopupEvent.state} theme={'vacancy_guide_popup'} title={t("text.guide-title-3")} videoLink={'https://www.youtube.com/watch?v=vw8LvVUJqLM'} />
            }

            {/* Profile block */}
            <div className={cn(styles.blockBody, styles.profile)}>
              <div className={styles.profilePhotoWrapper}>
                <img className={styles.profilePhoto} src={user.avatar} alt="" />
                <div className={styles.profileMobileName}>{get(role_object, "name")}</div>
              </div>

              <div className={styles.profileInfo}>
                <div className={styles.profileName}>{get(role_object, "name")}</div>
                <table className={cabinetStyles.profileData}>
                  <tbody>
                    <tr>
                      <td className={cabinetStyles.profileDataCell}>
                        <div className={cabinetStyles.profileDataTitle}>
                          {t("label.primary-activity")}
                        </div>
                        <div className={cabinetStyles.profileDataValue}>
                          {get(role_object, `type_of_activity.name_${user.language}`, "–")}
                        </div>
                      </td>
                      <td className={cabinetStyles.profileDataCell}>
                        <div className={cabinetStyles.profileDataTitle}>
                          {t("label.location")}
                        </div>
                        <div className={cabinetStyles.profileDataValue}>
                          {get(role_object, "actual_address")}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={cabinetStyles.profileDataCell}>
                        <div className={cabinetStyles.profileDataTitle}>
                          {t("title.contacts")}
                        </div>
                        <div className={cabinetStyles.profileDataValue}>
                          {get(role_object, "phones[0].number")}
                        </div>
                      </td>
                      <td className={cabinetStyles.profileDataCell}>
                        <div className={cabinetStyles.profileDataTitle}>
                          {t("label.site")}
                        </div>
                        <div className={cabinetStyles.profileDataValue}>
                          {get(role_object, 'website')}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={styles.profileRight}>
                <div data-tip data-for="progress" className={cabinetStyles.progress}>
                  <div className={cabinetStyles.progressText}>
                    {t("text.filled-out")}{" "}
                    {get(role_object, "progress.percent", 0)}%
                  </div>
                  <div className={cabinetStyles.progressBar}>
                    <div
                      className={cabinetStyles.progressBarValue}
                      style={{
                        width: `${get(role_object, "progress.percent", 0)}%`
                      }}
                    />
                  </div>
                </div>
                <ReactTooltip
                  className={cabinetStyles.tooltip}
                  id="progress"
                  effect="solid"
                >
                  <span>{t("text.progress-desc-staff")}</span>
                </ReactTooltip>
                {get(role_object, "progress.percent") <= 25 ? (
                  <p className={cabinetStyles.statusTooltipMobile}>{t("text.progress-desc-staff-short")}</p>
                ) : (
                  <p className={cabinetStyles.statusTooltipMobile}>{t("text.progress-desc-staff")}</p>
                )}
                <a href={`/${user.language}/users/${get(role_object, "slug")}/information`} target="_blank">
                  <Button className={styles.profileButtonCV} kind="gray">
                    {t("button.view-company")}
                  </Button>
                </a>
                <Button
                  onClick={this.handleEditClick}
                  className={styles.profileButtonEdit}
                  kind="primary"
                >
                  {t("button.edit")}
                </Button>
                <a href={downloadCVUrl} target="_blank">
                  <Button
                    className={styles.profileButtonDownload}
                    kind="bordered"
                  >
                    {t("button.download-cv")}
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Invitation */}
        <div className={cn(cabinetStyles.pageGrid, styles.commercialBlock)}>
          <div className={cabinetStyles.pageGrid7}>
            <BlockHeader className={styles.paymentBlockHeader}>
              {t("title.project-invitations")}
            </BlockHeader>
            <div className={styles.invitationsContainer}>
              {isUnderModeration && <div style={{ borderRadius: 10 }} className={cabinetStyles.overlay}>
                <p>{t("text.wait-for-moderation")}</p>
              </div>}
              {company.isInvitationsFetching ? (
                <div style={{ minHeight: 310 }} className={styles.blockBodyEmpty}>
                  <Loader isAbsolute={true} isLoading={true}/>
                </div>
              ) : (
                company.invitations.length > 0 ? (
                  <div className={cn(styles.blockBody, styles.projectListWrapper)}>
                    <ProjectsList invitations={company.invitations}/>
                  </div>
                ) : (
                  <div style={{ minHeight: 310 }} className={styles.blockBodyEmpty}>
                    <img src={inboxIcon} alt=""/>
                    <p>{t('text.no-projects-yet')}</p>
                  </div>
                )
              )}
            </div>
          </div>
          <div className={cabinetStyles.pageGrid3}>
            <Advertising />
          </div>
        </div>

        { isBannerPresent && <AdvertisingInfo /> }

        <div className={cabinetStyles.pageGrid}>
          <div className={cabinetStyles.pageGrid10}>
            <BlockHeader className={styles.paymentBlockHeader}>
              {t("title.interests-vacancies")}
            </BlockHeader>
            <div className={styles.invitationsContainer}>
              {isUnderModeration && <div style={{ borderRadius: 10 }} className={cabinetStyles.overlay}>
                <p>{t("text.wait-for-moderation")}</p>
              </div>}
              {company.isVacanciesFetching ? (
                <div style={{ minHeight: 310 }} className={styles.blockBodyEmpty}>
                  <Loader isAbsolute={true} isLoading={true}/>
                </div>
              ) : (
                company.vacancies.length > 0 ? (
                  <div className={cabinetStyles.blocksList}>
                    {company.vacancies.map(vacancy => (
                      <div key={vacancy.id} className={cabinetStyles.block}>
                        <VacancyCard data={vacancy} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ minHeight: 310 }} className={styles.blockBodyEmpty}>
                    <img src={inboxIcon} alt=""/>
                    <p>{t('text.no-vacancies-yet')}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div ref={this.VacanciesCastingsInfoRef}></div>
        <VacanciesCastingsInfo />
        <BlockHeader>{t("title.last-tutorials")}</BlockHeader>
        <EducationAnnounces
          isUnderModeration={isUnderModeration}
          videos={videoTutorials}
          texts={textTutorials}
        />
      </div>
    );
  }
}

export default withTranslation()(CompanyCabinet);
