import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import styles from "./EduTextCard.module.scss";

@inject("user", "navigate")
@observer
class EduTextCard extends Component {
  handleClick = () => {
    const { user, data } = this.props;
    this.props.navigate({ path: `/app/${user.user.role}/education/text/${data.id}`})
  }

  render() {
    const { data, user } = this.props;
    const lang = 'ru'; // user.language;

    return (
      <div className={styles.wrapper}>
        <div
          onClick={this.handleClick}
          className={styles.photo}
          style={{ backgroundImage: `url('${data.preview}')` }}
        ></div>
        <div
          onClick={this.handleClick}
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: data[`${lang}_title`] }}
        />
      </div>
    );
  }
}

export default EduTextCard;
