import React, { Component } from "react";
import cn from "classnames";
import { Range } from "rc-slider";
import get from "lodash/get";

import styles from "./RCRange.module.scss";
import "./RCRange.scss";
import "rc-slider/assets/index.css";

export default class RCRange extends Component {
  handleChange = data => {
    this.props.onChange(data);
  };

  handleReset = () => {
    this.props.onChange([]);
  }

  render() {
    const { extra, size, wrapperClassName, value, postfix, errors = [], min, max } = this.props;

    const value0 = get(value, "[0]", "");
    const value1 = get(value, "[1]", "");
    const marks = { [min]: min, [max]: max };

    return (
      <div
        style={{ marginBottom: extra ? -18 : 0 }}
        className={cn(styles.wrapper, wrapperClassName)}
      >
        {
          <div className={cn(styles.value, size === 'small' && styles.valueSmall)}>
            <span>{`${value0} – ${value1} ${postfix ? postfix : ""}`}</span>
            {(value0 !== '' && value1 !== '') && <svg onClick={this.handleReset} fill="#cccccc" height="20" width="20" viewBox="0 0 20 20" ariaHidden="true" focusable="false"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>}
          </div>
        }

        <Range
          defaultValue={value}
          marks={marks}
          onChange={this.handleChange}
          value={value}
          min={min}
          max={max}
        />
        {extra && <p className={styles.extra}>{extra}</p>}
        <p className={styles.errors}>
          {errors.length > 0 ? errors.join(", ") : null}
        </p>
      </div>
    );
  }
}
