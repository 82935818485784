import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Select from '../../components/Select/Select';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import FormItemControls from '../../components/FormItemControls/FormItemControls';
import styles from './EditForms.module.scss';
import get from 'lodash/get';

@inject('user', 'dataStore', 'navigate')
@observer
class SkillsInstrumentEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    const { role } = this.props.user.user;

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          [role]: {
            instruments_attributes: [{
              id: get(this.props.data, 'id'),
              name: get(values, 'instrument_name.value'),
              status: get(values, 'instrument_status.value'),
            }],
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('SkillsInstrumentEdit error', error, values);
      }
    });
  }

  render() {
    const { dataStore, t, form, data = {} } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    const dataInstrumentName = data.id
      ? dataStore.instrumentsOptions.find(i => i.value == data.name)
      : null;

    const dataInstrumentStatus = data.id
      ? dataStore.instrumentsLevelOptions.find(i => i.value == data.status)
      : null;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.formGrid} style={{ marginBottom: 0 }}>
          <div className={styles.photoGrid2}>
            <FormTitle>{t('skill.instrument-name')}</FormTitle>
            {getFieldDecorator('instrument_name', {
              initialValue: dataInstrumentName,
              rules: [{ required: true, message: t('validation.input.required') }]
            })(
              <Select
                required
                placeholder={t('skill.instrument-name')}
                options={dataStore.instrumentsOptions}
                errors={getFieldError('instrument_name')}
              />
            )}
          </div>
          <div className={styles.photoGrid2}>
            <FormTitle>{t('skill.possession')}</FormTitle>
            {getFieldDecorator('instrument_status', {
              initialValue: dataInstrumentStatus,
              rules: [{ required: true, message: t('validation.input.required') }]
            })(
              <Select
                required
                placeholder={t('skill.possession')}
                options={dataStore.instrumentsLevelOptions}
                errors={getFieldError('instrument_status')}
              />
            )}
          </div>
        </div>
        <div className={styles.formGrid}>
          <FormItemControls data={data} entity="instruments"/>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(SkillsInstrumentEdit));
