import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import FormItem from '../../components/FormItem/FormItem';
import Select from '../../components/Select/Select';
import Checkbox from '../../components/Checkbox/Checkbox';
import { withTranslation } from 'react-i18next';


import styles from './TalentSignUpStepper.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class Step2 extends Component {
  handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();

    this.props.form.validateFields({
      // firstFields: false,
    }, (error, values) => {
      if (!error) {
        const data = values.has_education ? {
          talent: {
            has_education: true,
            actor_status: 0,
            educations_attributes: [{
              university_dictionary_id: values.university.value,
              status_type:  values.status_type.value,
              specialty:  values.specialty,
              "year(3i)": "1",
              "year(2i)": "10",
              "year(1i)": values.year.value
            }]
          }
        } : {
          talent: {
            has_education: false,
          }
        };

        console.log('ok', data);

        this.props.user.update(data);
      } else {
        console.log('error', error, values);
      }
    });
  }

  componentDidMount() {
    this.props.dataStore.fetchEducationList();
  }

  render() {
    const { t, i18n, form, user, dataStore } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;
    const { yearOptions, universitiesOptions, universityStatusTypes } = dataStore;

    return (
      <form onSubmit={this.handleSubmit}>
        <FormItem styles={{ paddingTop: 15, fontWeight: 500 }}>
          {getFieldDecorator('has_education', { initialValue: true })(
            <Checkbox name="has_education" label={t('input.has-actor-education')} />
          )}
        </FormItem>
        {getFieldValue('has_education') && (
          <div className={styles.formGroup}>
            <div className={styles.formGroupItem}>
              <FormItem>
                {getFieldDecorator('university', {
                  initialValue: '',
                  rules: [{ required: getFieldValue('has_education'), message: t('validation.input.required') }]
                })(
                  <Select
                    required
                    placeholder={t('input.university')}
                    options={universitiesOptions}
                    errors={getFieldError('university')}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('specialty', {
                  initialValue: '',
                  rules: [{ required: getFieldValue('has_education'), message: t('validation.input.required') }]
                })(
                  <Input
                    required
                    name="specialty"
                    placeholder={t('input.university-specialty')}
                    errors={getFieldError('specialty')}
                  />
                )}
              </FormItem>
            </div>
            <div className={styles.formGroupItem}>
              <FormItem>
                {getFieldDecorator('status_type', {
                  initialValue: '',
                  rules: [{ required: getFieldValue('has_education'), message: t('validation.input.required') }]
                })(
                  <Select
                    required
                    placeholder={t('input.university-status')}
                    options={universityStatusTypes}
                    errors={getFieldError('status_type')}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('year', {
                  initialValue: '',
                  rules: [{ required: getFieldValue('has_education'), message: t('validation.input.required') }]
                })(
                  <Select
                    required
                    placeholder={t('input.university-year')}
                    options={yearOptions}
                    errors={getFieldError('year')}
                  />
                )}
              </FormItem>
            </div>
          </div>
        )}
        <div className={styles.buttons}>
          <Button
            kind="primary"
            type="submit"
            className={styles.button}
          >{t('button.next')}</Button>
        </div>
      </form>
    )
  }
}


export default withTranslation()(createForm()(Step2));
