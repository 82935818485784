import React from "react";
import cn from 'classnames';
import styles from './SectionTitle.module.scss';

export default function SectionTitle(props) {
  return (
    <div
      style={props.styles}
      className={cn(styles.title, props.className)}
    >
      {props.children}
    </div>
  );
}
