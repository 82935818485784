import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import FormItem from "../../../components/FormItem/FormItem";
import FormTitle from "../../../components/FormTitle/FormTitle";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import get from "lodash/get";

class SearchMultiselect extends Component {
  render() {
    const { form, t, queries = {}, title, field, options, placeholder } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const initialValue = get(queries, field, [])
      .map(fv => options.find(o => o.value == fv));

    return (
      <FormItem styles={{ marginBottom: 0 }}>
        <FormTitle size="small">{t(title)}</FormTitle>
        {getFieldDecorator(field, { initialValue })(
          <MultiSelect
            size="small"
            placeholder={t(placeholder || title)}
            options={options}
            errors={getFieldError(field)}
          />
        )}
      </FormItem>
    );
  }
}

export default withTranslation()(SearchMultiselect);
