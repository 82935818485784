import get from "lodash/get";

const findProjectInfo = (projects, id, lvl) => {
  let level = lvl ? lvl : 0;
  let project;

  projects.forEach(p => {
    const subProjects = get(p, "projekts", []);

    if (subProjects.length) {
      const info = findProjectInfo(subProjects, id, level + 1);

      if (info.project) {
        project = info.project;
        level = info.level;
      }
    }

    if (String(p.id) === String(id)) {
      project = p;
    }
  });

  return { project, level };
};

export { findProjectInfo };
