import React, { Component } from 'react';
import cn from 'classnames';
import Input from '../Input/Input';
import InputMask from 'react-input-mask';
import styles from './InputMask.module.scss';

export default class CustomInputMask extends Component {
  render() {
    const { mask, alwaysShowMask, size, name, placeholder, wrapperClassName, className, onChange, value, errors = [] } = this.props;

    return (
      <div className={cn(styles.wrapper, wrapperClassName)}>
        <InputMask
          mask={mask}
          maskChar={''}
          placeholder={placeholder}
          className={className}
          value={value}
          onChange={onChange}
          formatChars={{
            '9': '[0-9]',
            'a': '[A-Za-z]',
            '*': '.+'
          }}
          alwaysShowMask={alwaysShowMask}
        >
          {(inputProps) => <Input name={name} {...inputProps} size={size} errors={errors} />}
        </InputMask>
      </div>
    )
  }
}
