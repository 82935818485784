import React, { Component } from "react";
import Select, { components } from "react-select";
import cn from 'classnames';
import { withTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import Checkbox from "../../components/Checkbox/Checkbox";
import arrowIcon from "../../../images/bordered-arrow-down.png";
import styles from "./MultiSelect.module.scss";

const MultiValue = props => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox onChange={e => null} />
          &nbsp;
          <label>{props.label} </label>
        </div>
      </components.Option>
    </div>
  );
};

class CustomMultiSelect extends Component {
  handleChange = (data, actionedElement) => {
    if (data && this.props.maxCount && data.length > this.props.maxCount) {
      return false;
    }

    this.props.onChange(data || []);
  };

  render() {
    const {
      options,
      placeholder,
      extra,
      required,
      errors = [],
      value,
      isCreatable,
      size,
      t
    } = this.props;
    const SelectComponent = isCreatable ? CreatableSelect : Select;

    return (
      <div className={styles.wrapper}>
        <SelectComponent
          value={value}
          isMulti
          components={{ Option, MultiValue }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          onChange={this.handleChange}
          className={cn("react-select-container", size === 'small' && 'react-select--small')}
          classNamePrefix="react-select"
          placeholder={required ? placeholder + " *" : placeholder}
          options={options}
          formatCreateLabel={(text) => <span>{t("text.select-option-create")} "{text}"</span>}
          noOptionsMessage={() => <span>{t("text.no-options")}</span>}
          components={{
            DropdownIndicator: () => (
              <img style={{ width: 16, height: 16 }} src={arrowIcon} alt="" />
            )
          }}
        />
        <p className={styles.extra}>{extra}</p>
        <p className={styles.errors}>
          {errors.length > 0 ? errors.join(", ") : null}
        </p>
      </div>
    );
  }
}

export default withTranslation()(CustomMultiSelect);
