import React, { Component } from "react";
import { toJS } from 'mobx';
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import get from "lodash/get";
import Loader from '../../components/Loader/Loader';
import CastingVacancyForm from "../Forms/Vacancy/VacancyInvitation";

import formStyles from '../Forms/EditForms.module.scss';

@inject("user", "dataStore", "navigate")
@observer
class CreateVacancyInvitation extends Component {
  componentDidMount() {
    const boardId = get(this.props.ctx, "params.boardId");
    const id = get(this.props.ctx, "params.id");

    if (id) {
      this.props.dataStore.fetchProject(boardId);
    }
  }

  render() {
    const { ctx, dataStore, type } = this.props;

    const boardId = get(ctx, "params.boardId");
    const id = get(ctx, "params.id");
    const selected = get(ctx, "query.selected", []);
    const selectedRole = get(ctx, "query.selected_role", []);
    const vacancy = get(toJS(dataStore.project), 'vacancies', []).find(a => a.id == id);
    const favoriteInvitation = get(toJS(dataStore.project), selectedRole === 'staff' ? 'favorite_staffs' : 'favorite_companies', []).find(favorite => favorite.vacancy);
    const isEdit = type === 'edit';

    return (
      <div className={formStyles.smallInner}>
        <CastingVacancyForm selected={selected} selectedRole={selectedRole} isEdit={isEdit} data={vacancy || get(favoriteInvitation, 'vacancy')} projectId={boardId} />
        <Loader isLoading={dataStore.isBoardUpdating} />
      </div>
    );
  }
}

export default withTranslation()(CreateVacancyInvitation);
