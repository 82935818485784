import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import { Input, Button, Checkbox, FormItem, Title, SocialButtons } from '../../components';
import styles from './SignInPage.module.scss';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import get from 'lodash/get';

@inject('navigate', 'user')
@observer
class SignInPage extends Component {
  componentDidMount() {
    if (get(this.props, 'query.confirmation') === 'success') {
      setTimeout(() => {
        NotificationManager.success(this.props.t("text.email-confirmed"));
      }, 1000)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, user } = this.props;

    form.validateFields({}, (error, values) => {
      if (!error) {
        user.signIn(values, form);
      } else {
        console.log('error', error, values);
      }
    });
  }

  handleSignUpClick = () => {
    this.props.navigate({ path: '/app/signup' });
  }

  handleForgotPasswordClick = () => {
    this.props.navigate({ path: '/app/forgot-password' });
  }

  render() {
    const { t } = this.props;
    const { getFieldDecorator, getFieldError } = this.props.form;

    return (
      <div className={styles.wrapper}>
        <Title>{t('pages.signin.title-social')}</Title>
        <SocialButtons form={this.props.form} query={this.props.query}/>
        {getFieldDecorator('social', { initialValue: '' })(
          <Input
            hidden
            required
            name="social"
            errors={getFieldError('social')}
          />
        )}
        <Title>{t('pages.signin.title')}</Title>
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('email', {
              initialValue: '',
              rules: [
                { type: 'email', message: t('validation.email.notvalid') },
                { required: true, message: t('validation.email.required') },
              ]
            })(
              <Input
                required
                name="email"
                type="email"
                autocomplete="email"
                placeholder={t('input.email')}
                errors={getFieldError('email')}
              />
            )}
          </FormItem>
          {getFieldDecorator('password', {
            initialValue: '',
            rules: [
              { required: true, message: t('validation.password.required') },
            ]
          })(
            <Input
              required
              name="password"
              type="password"
              autocomplete="current-password"
              placeholder={t('input.password')}
              errors={getFieldError('password')}
            />
          )}
          <div className={styles.formInfo}>
            {getFieldDecorator('remember_me', { initialValue: true })(
              <Checkbox
                name="remember_me"
                label={<b style={{ fontWeight: 500 }}>{t('input.remember-me')}</b>}
              />
            )}
            <div onClick={this.handleForgotPasswordClick} className={styles.link}>{t('pages.signin.forgot-password')}</div>
          </div>
          <div className={styles.buttons}>
            <Button kind="primary" type="submit" className={styles.button}>{t('button.signin')}</Button>
            <Button onClick={this.handleSignUpClick} className={styles.button}>{t('pages.signin.signup-button')}</Button>
          </div>
        </form>
      </div>
    )
  }
}

export default withTranslation()(createForm()(SignInPage));
