import React from "react";
import { Collapse } from "react-collapse";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import get from 'lodash/get';
import pick from 'lodash/pick';

import Checkbox from "../../components/Checkbox/Checkbox";
import FormTitle from "../../components/FormTitle/FormTitle";
import FormItem from "../../components/FormItem/FormItem";
import SearchMultiselect from './components/SearchMultiselect';

import styles from "./Search.module.scss";

@inject("user", "dataStore")
@observer
class CareerSearchBlock extends React.Component {
  handleCollapse = () => this.props.toggleCollapse('career');

  render() {
    const { user, form, isOpen, t, dataStore, queries = {} } = this.props;
    const { searchAggregations } = user;
    const { getFieldDecorator, getFieldError, getFieldValue } = form;

    const professionAggrs = pick(searchAggregations, user.professionFields);

    const availableForSearchProfessions = Object.entries(professionAggrs).reduce((arr, val) => {
      if (val[1].length) arr.push(val[0])
      return arr;
    }, []);

    const professions = dataStore.allProfessions
      .filter(p => availableForSearchProfessions.includes(p.value));

    const formProps = { form, queries };

    const workStatusAggr = get(searchAggregations, 'work_status', []);
    const companyCount = get(workStatusAggr.find(i => i.key === 'company'), 'doc_count', 0);
    const independentCount = get(workStatusAggr.find(i => i.key === 'independent'), 'doc_count', 0);

    return (
      <div className={styles.collapseWrapper}>
        <div
          onClick={this.handleCollapse}
          className={cn(styles.collapseHeader, {
            [styles.collapseHeaderActive]: isOpen
          })}
        >
          {t("title.career")}
        </div>
        <Collapse isOpened={isOpen}>
          <div className={styles.collapseBody}>
            <FormItem styles={{ marginBottom: 20 }}>
              <FormTitle size="small">{t("label.work-as")}</FormTitle>

              <div style={{ marginTop: 15 }}>
                <FormItem>
                  {getFieldDecorator("company", { initialValue: get(queries, "company") })(
                    <Checkbox size="small" name="company-status" label={t("input.in-company-short")} count={companyCount}/>)}
                </FormItem>

                <FormItem>
                  {getFieldDecorator("independent", { initialValue: get(queries, "independent")})(
                    <Checkbox size="small" name="independent" label={t("input.independent")} count={independentCount} />)}
                </FormItem>
              </div>
            </FormItem>

            <SearchMultiselect
              {...formProps}
              title="input.profession"
              field="profession"
              options={professions}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}
export default withTranslation()(CareerSearchBlock);
