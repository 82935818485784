import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { withTranslation } from "react-i18next";
import EducationCard from "../../components/EducationCard/EducationCard";
import Pagination from "../../components/Pagination/Pagination";
import Tag from "../../components/Tag/Tag";
import styles from "./CabinetEducation.module.scss";
import moment from "moment";
import brainIcon from "../../../images/brain-icon.png";
import qs from "qs";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import cabinetStyles from '../Cabinet/Cabinet.module.scss';

@inject("user", "navigate")
@observer
class CabinetEducation extends Component {
  constructor(props) {
    super(props);

    this.debounceUpdateTutorial = debounce(this.updateTutorials, 500);
  }
  debounceUpdateTutorial = () => {};
  state = { isSearchFocused: false };

  updateTutorials = () => {
    const { user, queries = {} } = this.props;

    user.fetchTutorialsList(queries);
  };

  handleSearchChange = e => {
    const { queries = {}, navigate, user } = this.props;

    const { value } = e.target;

    navigate({
      path: `/app/${user.user.role}/education`,
      query: { ...queries, q: value }
    });
  };

  componentDidMount() {
    const { queries = {}, user, navigate } = this.props;
    const activePage = get(queries, "page", 1);

    user.fetchTutorialsCategories();

    navigate({
      path: `/app/${user.user.role}/education`,
      query: { ...queries, page: activePage }
    });
    user.fetchTutorialsList({
      ...queries,
      page: activePage
    });
  }

  handlePageChange = page => {
    const { queries = {}, user, navigate } = this.props;

    navigate({
      path: `/app/${user.user.role}/education`,
      query: { ...queries, page }
    });
  };

  componentDidUpdate(prevProps) {
    const { queries: currQuery } = this.props;
    const { queries: prevQuery } = prevProps;

    if (prevQuery && qs.stringify(currQuery) !== qs.stringify(prevQuery) && !isEmpty(prevQuery)) {
      this.debounceUpdateTutorial();
    }
  }

  handleSearchFocus = () => {
    this.setState({ isSearchFocused: true });
  };

  handleOverlayClick = () => {
    this.setState({ isSearchFocused: false });
  };

  handleSelectCategory = categoryId => {
    const { queries, navigate, user } = this.props;
    let categoriesIds = get(queries, "categories_ids", []);

    const isCategorySelected = categoriesIds.includes(String(categoryId));

    if (isCategorySelected) {
      categoriesIds = categoriesIds.filter(cId => cId != categoryId);
    } else {
      categoriesIds = [...categoriesIds, String(categoryId)];
    }

    navigate({
      path: `/app/${user.user.role}/education`,
      query: { ...queries, categories_ids: categoriesIds }
    });
  };

  render() {
    const { user, t, queries = {} } = this.props;
    const { isSearchFocused } = this.state;

    const activeCategoriesIds = get(queries, "categories_ids", []);

    const textTutorials = toJS(get(user, "tutorialsList.texts", []));
    const tutorials = [
      ...textTutorials.map(t => ({ ...t, type: "text" }))
    ];

    const sortedTutorials = sortBy(tutorials, "created_at").reverse();

    const tutorialsCategories = toJS(get(user, "tutorialsCategories"));
    const lang = 'ru'; //user.language;
    const queriesPage = queries.page ? Number(queries.page) : 1;

    return (
      <div className={cabinetStyles.page}>
        <div className={cabinetStyles.inner}>
          <div className={styles.search}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputControl}>
                <input
                  onFocus={this.handleSearchFocus}
                  className={styles.searchInput}
                  type="text"
                  placeholder={t("menu.search")}
                  onChange={this.handleSearchChange}
                  value={queries.q}
                />
              </div>
              {isSearchFocused && (
                <div className={styles.inputContainer}>
                  <p className={styles.inputContainerTitle}>{t("label.categories")}</p>
                  <div className={styles.inputContainerTags}>
                    {tutorialsCategories.map(tag => (
                      <Tag
                        key={tag.id}
                        onClick={() => this.handleSelectCategory(tag.id)}
                        isClosable={activeCategoriesIds.find(
                          cId => cId == tag.id
                        )}
                      >
                        {tag[`${lang}_name`]}
                      </Tag>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.searchCategories}>
              {activeCategoriesIds.map((cId, i) => (
                <Tag
                  key={i}
                  onClose={() => this.handleSelectCategory(cId)}
                  isClosable={true}
                >
                  {tutorialsCategories.find(c => cId == c.id) &&
                    tutorialsCategories.find(c => cId == c.id)[
                      `${lang}_name`
                    ]}
                </Tag>
              ))}
            </div>
          </div>
          {isSearchFocused && (
            <div
              onClick={this.handleOverlayClick}
              className={styles.overlay}
            ></div>
          )}
          {(textTutorials.length > 0 ) ? (
            <div className={styles.list}>
              {sortedTutorials.map(data => (
                <div key={data.id + data.type} className={styles.listItem}>
                  <EducationCard
                    id={data.id}
                    category={data.category[`${lang}_name`]}
                    categoryId={data.category.id}
                    date={moment(data.created_at).format("DD.MM.YYYY")}
                    title={data[`${lang}_title`]}
                    video={data.video}
                    preview={data.preview}
                    previewMobile={data.preview_mobile}
                    type={data.type}
                    read_time={data.read_time}
                    handleSelectCategory={this.handleSelectCategory}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ minHeight: 100 }} className={cabinetStyles.blockBodyEmpty}>
              <img src={brainIcon} alt="" />
              <p>{t("text.no-tutotials")}</p>
            </div>
          )}
          {(textTutorials.length > 0) ? (
            <div className={styles.pagination}>
              <Pagination
                page={queriesPage}
                handlePageChange={this.handlePageChange}
                initialPage={queriesPage}
                count={user.tutorialsCount}
                maxCount={9}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CabinetEducation);
