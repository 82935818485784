import React, { Component } from "react";
import cn from "classnames";
import styles from "./Textarea.module.scss";
import isNull from 'lodash/isNull';
import { withTranslation } from "react-i18next";

class Textarea extends Component {
  state = {
    height: "auto"
  };

  textarea = React.createRef();

  componentDidUpdate() {
    this.textarea.current.style.height =
      this.textarea.current.scrollHeight > this.textarea.current.clientHeight
        ? this.textarea.current.scrollHeight + "px"
        : "60px";
  }

  render() {
    const {
      t,
      extra,
      type = "text",
      symbolsCount,
      name,
      required,
      placeholder,
      autocomplete,
      wrapperClassName,
      className,
      onChange,
      value,
      errors = []
    } = this.props;

    return (
      <div
        style={{ marginBottom: extra ? -18 : 0 }}
        className={cn(styles.wrapper, wrapperClassName)}
      >
        <textarea
          ref={this.textarea}
          name={name}
          type={type}
          value={isNull(value) ? '' : value}
          maxLength={symbolsCount}
          placeholder={required ? placeholder + " *" : placeholder}
          autoComplete={autocomplete}
          className={cn(styles.input, className)}
          onChange={onChange}
        />
        {extra && (<p className={styles.extra}>{extra}</p>)}
        {symbolsCount && value ? (
          <p className={styles.extra}>
            {t("text.characters-left")}: {symbolsCount - value.length}
          </p>
        ) : null}
        <p className={styles.errors}>
          {errors.length > 0 ? errors.join(", ") : null}
        </p>
      </div>
    );
  }
}

export default withTranslation()(Textarea);
