import { observable } from 'mobx';
import User from './user';
import Talent from './talent';
import Staff from './staff';
import Company from './company';
import Data from './data';

class RootStore {
  constructor() {
    this.user = new User(this);
    this.talent = new Talent(this);
    this.staff = new Staff(this);
    this.company = new Company(this);
    this.dataStore = new Data(this);
  }
}

export default new RootStore()

