import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createForm } from 'rc-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputPhone from '../../components/InputPhone/InputPhone';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import FormItem from '../../components/FormItem/FormItem';
import Checkbox from '../../components/Checkbox/Checkbox';
import SocialButtons from '../../components/SocialButtons/SocialButtons';
import Title from '../../components/Title/Title';
import { withTranslation } from 'react-i18next';

import styles from './SignUpPage.module.scss';
import Modal from "react-modal";
import closeImage from "../../../images/close-modal-icon.png";

@inject('user', 'navigate')
@observer
class SignUpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMoveModalOpen: false
    }
  }

  closeMoveModal = () => this.setState({ isMoveModalOpen: false });

  handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();

    this.props.form.validateFields({
      // firstFields: false,
    }, (error, values) => {
      if (!error) {
        values['locale'] = this.props.i18n.language
        console.log('ok', values);

        this.props.user.signUp(values, this.props.form);
        this.setState({ isMoveModalOpen: true })
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator, getFieldError, getFieldValue } = this.props.form;
    const roles = [
      { value: 'talent', label: t('roles.talent'), isDefault: true },
      { value: 'staff', label: t('roles.staff') },
      { value: 'company', label: t('roles.company') },
    ];

    const modalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        height: 'auto',
        minHeight: 300,
        maxHeight: 'calc(100% - 100px)'
      }
    };


    // Всё это для определения наличия ошибки, чтоб модалка о подтверждении письма не всплывала.
    const emailError = getFieldError('email') || true
    const phoneError = getFieldError('email') || true
    const passwordError = getFieldError('email') || true
    const passwordConfirmationError = getFieldError('email') || true
    let anyErrors = emailError || phoneError || passwordError || passwordConfirmationError || false
    if (typeof anyErrors === "object" && anyErrors.length === 0) {
      anyErrors = false
    }

    return (
      <div className={styles.wrapper}>
        <Title>{t('pages.signup.title-social')}</Title>
        <SocialButtons form={this.props.form}/>
        {getFieldDecorator('social', { initialValue: '' })(
          <Input
            hidden
            required
            name="social"
            errors={getFieldError('social')}
          />
        )}
        <Title>{t('pages.signup.title')}</Title>
        <form onSubmit={this.handleSubmit}>
          <div className={styles.formGroup}>
            <div className={styles.formGroupItem}>
              <FormItem>
                {getFieldDecorator('email', {
                  initialValue: '',
                  rules: [
                    { type: 'email', message: t('validation.email.notvalid') },
                    { required: true, message: t('validation.email.required') },
                  ]
                })(
                  <Input
                    required
                    name="email"
                    type="email"
                    autocomplete="email"
                    placeholder={t('input.email')}
                    errors={getFieldError('email')}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('phone', {
                  initialValue: '',
                  rules: [
                    { required: true, message: t('validation.phone.required') },
                  ]
                })(
                  <InputPhone
                    required
                    locale={this.props.user.language}
                    placeholder={t('input.phone')}
                    errors={getFieldError('phone')}
                  />
                )}
              </FormItem>
            </div>
            <div className={styles.formGroupItem} style={{ marginBottom: 15 }}>
              <FormItem>
                {getFieldDecorator('password', {
                  initialValue: '',
                  rules: [
                    { required: true, message: t('validation.password.required') },
                    { min: 6, message: t('validation.password.minlength') }
                  ]
                })(
                  <Input
                    required
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    placeholder={t('input.password')}
                    errors={getFieldError('password')}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password_confirmation', {
                  initialValue: '',
                  rules: [
                    { required: true, message: t('validation.password-confirmation.required') },
                    { min: 6, message: t('validation.password.minlength') }
                  ]
                })(
                  <Input
                    required
                    name="password_confirmation"
                    type="password"
                    autocomplete="new-password"
                    placeholder={t('input.password-confirmation')}
                    errors={getFieldError('password_confirmation')}
                  />
                )}
              </FormItem>
            </div>
          </div>
          {getFieldDecorator('role', {
            initialValue: 'talent',
          })(
            <RadioGroup data={roles} />

          )}
          <div className={styles.formInfo}>
            {getFieldDecorator('condition', { initialValue: true })(
              <Checkbox name="condition" label={(
                <span>
                  <span>{t('pages.signup.agreement-1')}&nbsp;</span>
                  <a href="/home/oferta" target="_blank">{t('pages.signup.agreement-2')}</a>
                  <span>{t('pages.signup.agreement-3')}</span>
                </span>
              )} />
            )}
          </div>
          <div className={styles.buttons} style={{ marginBottom: 20 }}>
            <Button
              kind="primary"
              type="submit"
              disabled={!getFieldValue('condition')}
              className={styles.button}
            >{t('button.signup')}</Button>
          </div>
        </form>

        <Modal
          isOpen={this.state.isMoveModalOpen && !anyErrors }
          style={modalStyle}
        >
          <div className={styles.modalWrapper}>
            <p>{i18n.t("text.sign-up-success-check-email")}</p>
            <p>{i18n.t("text.sign-up-success-we-send-email")}</p>
          </div>
          <img className={styles.modalClose} onClick={this.closeMoveModal} src={closeImage}  alt=''/>
        </Modal>

      </div>
    )
  }
}

export default withTranslation()(createForm()(SignUpPage));
