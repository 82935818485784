import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { NotificationContainer } from 'react-notifications';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import styles from './SearchLayout.module.scss';


@inject('navigate', 'user')
@observer
class SearchLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: props.user.isAuthenticated,
      isMenuOpen: false,
    }
  }

  componentDidMount() {
    if (!this.state.isAuthenticated) {
      this.props.user.fetchProfile(false);
    }
  }

  handleLoginClick = () => {
    this.props.navigate({ path: '/app/signin' });
  }

  handleLogoutClick = () => {
    this.props.user.logout();
  }

  handleSwitchLanguage = () => {
    this.props.user.changeLanguage(this.props.i18n);
  }

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true });
  }

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  }

  render() {
    const { user, t } = this.props;

    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <div className={styles.inner}>
            <Header isFull isBlack isLogoWhite isSearch />
            {(user.isLoaded || user.isLoadedFailure) && this.props.children}
          </div>
        </div>
        <NotificationContainer/>
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(SearchLayout);
