import React, { Component } from "react";
import Input from '../Input/Input';
import styles from "./BoardCard.module.scss";
import get from 'lodash/get';
import moment from "moment";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

class BoardCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      boardName: ''
    }
  }

  handleEdit = e => {
    e.preventDefault();
    e.stopPropagation();
    this.handleNameClick();
  };

   notClicked = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDelete = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleDelete(this.props.data.id);
  };

  handleNameClick = () => {
    this.setState({ editMode: true, boardName: this.props.data.name });

    this.listener = document.addEventListener('keydown', this.handleDocumentKeyDown, false);
  }

  handleNameChange = (e) => {
    this.setState({ boardName: e.target.value });
  }

  handleNameBlur = () => {
    this.setState({ editMode: false, boardName: '' });
    document.removeEventListener('keydown', this.handleDocumentKeyDown, false);
  }

  handleDocumentKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.handleNameBlur();
    }
  }

  handleNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onUpdate(this.props.data.id, this.state.boardName).then(() => {
        this.setState({ editMode: false });
      });
    }
  }

  currentProject = (castings, crowdings, vacancies) => {
    return (castings.length && castings[0]) || (crowdings.length && crowdings[0]) || (vacancies.length && vacancies[0]) || null
  }

  isProjectExpired = (project) => {
    // Если это не проект, а пустая папка:
    if (!project) return null

    const endDate = project.vacancy_end_date || project.casting_end_date || project.end_date
    return endDate < moment(new Date().toLocaleString("en-US", {timeZone: "Asia/Almaty"})).format('YYYY-MM-DD HH:mm')
  }

  projectFolderColor = (currentProject) => {

    if (!currentProject) {
      return '#909090'
    }

    return this.isProjectExpired(currentProject) ? '#ff0000' : '#369636'
  }

  coloredCircleTitle = (currentProject) => {
    const isProjectExpired = this.isProjectExpired(currentProject)

    if (isProjectExpired === true) return this.props.t(`label.${currentProject.form_string}-is-not-active`)
    if (isProjectExpired === false) return this.props.t(`label.${currentProject.form_string}-is-active`)
  }

  render() {
    const { t, data } = this.props;
    const castings = get(data, 'advertisements', []).filter(a => a.form === 0);
    const crowdings = get(data, 'advertisements', []).filter(a => a.form === 1);
    const vacancies = get(data, 'vacancies', []);
    const currentProject = this.currentProject(castings, crowdings, vacancies)
    const talentFavoritesPhotos = get(data, 'favorite_talents', []).map(f => f.avatar.url);
    const staffFavoritesPhotos = get(data, 'favorite_staffs', []).map(f => f.avatar.url);
    const companyFavoritesPhotos = get(data, 'favorite_companies', []).map(f => f.avatar.url);
    const favoritePhotos = [...talentFavoritesPhotos, ...staffFavoritesPhotos, ...companyFavoritesPhotos];
    const folderExpiredColor = this.projectFolderColor(currentProject)
    const coloredCircleTitle = this.coloredCircleTitle(currentProject)

    return (
      <a
        className={styles.wrapper}
      >
        <div onClick={() => this.props.onClick(data.id)} className={styles.board}>
          <div style={{ backgroundImage: `url(${favoritePhotos[0]})`}} className={styles.boardTile}></div>
          <div style={{ backgroundImage: `url(${favoritePhotos[1]})`}} className={styles.boardTile}></div>
          <div style={{ backgroundImage: `url(${favoritePhotos[2]})`}} className={styles.boardTile}></div>
          <div style={{ backgroundImage: `url(${favoritePhotos[3]})`}} className={styles.boardTile}></div>
          <div style={{ backgroundImage: `url(${favoritePhotos[4]})`}} className={styles.boardTile}></div>
          <div style={{ backgroundImage: `url(${favoritePhotos[5]})`}} className={styles.boardTile}></div>
          {castings.length > 0 && (
            <div className={styles.type}>{t("label.type")}: <b>{t("label.casting")}</b></div>
          )}
          {crowdings.length > 0 && (
            <div className={styles.type}>{t("label.type")}: <b>{t("label.crowding")}</b></div>
          )}
          {vacancies.length > 0 && (
            <div className={styles.type}>{t("label.type")}: <b>{t("label.vacancy")}</b></div>
          )}
          <div className={styles.editIcon} onClick={this.handleEdit} />
          <div className={styles.usersCountIcon}
               style={{ backgroundColor: folderExpiredColor }}
               data-tip={ currentProject && true }
               data-for={`board_card_${data.id}`}
               onClick={this.notClicked}>
            <span>{favoritePhotos.length}</span>
          </div>

          <ReactTooltip
              place="top"
              id={`board_card_${data.id}`}
              effect="solid"
            >
            <span>{ coloredCircleTitle }</span>
          </ReactTooltip>

          <div className={styles.removeIcon} onClick={this.handleDelete} />
        </div>
        {this.state.editMode ? (
          <Input
            autoFocus
            className={styles.boardNameInput}
            wrapperClassName={styles.boardNameControl}
            value={this.state.boardName}
            onChange={this.handleNameChange}
            onBlur={this.handleNameBlur}
            onKeyPress={this.handleNameKeyPress}
            extra={t("text.enter-save-esc-reset")}
          />
        ) : (
          <div onClick={this.handleNameClick} className={styles.boardName}>{data.name}</div>
        )}
      </a>
    );
  }
}

export default withTranslation()(BoardCard);
