import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import FormTitle from '../../components/FormTitle/FormTitle';
import get from 'lodash/get';

import PortfolioExperienceEdit from './PortfolioExperienceEdit';
import PortfolioAwardEdit from '../Forms/PortfolioAwardEdit';
import PortfolioPhotoEdit from '../Forms/PortfolioPhotoEdit';
import PortfolioVideoEdit from '../Forms/PortfolioVideoEdit';
import PortfolioAudioEdit from '../Forms/PortfolioAudioEdit';
import PortfolioFilesEdit from '../Forms/PortfolioFilesEdit';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class PortfolioEdit extends Component {
  componentDidMount() {
    this.props.dataStore.fetchMovieTypesList();
  }

  handleNextStep = () => {
    this.props.navigate({
      path: `/app/${this.props.user.user.role}/edit`,
      query: { tab: 'contacts' }
    }, true);
  }

  render() {
    const { user, t } = this.props;
    const role_object = get(user, 'user.role_object', {});

    const drafts = get(role_object, 'drafts');
    const awards = get(role_object, 'awards');
    const videoLinks = get(role_object, 'video_links');
    const audios = get(role_object, 'media.audios');
    const photos = get(role_object, 'media.photos');
    const documents = get(role_object, 'media.documents');

    return (
      <div className={formStyles.form}>
        <div className={formStyles.formTitle}>
          <span>{t("title.creative-experience")}</span>
          <FormTitle progress={50} isActive={drafts.length}></FormTitle>
        </div>
        <div className={formStyles.formSeparator}/>

        {drafts.map(data => (
          <PortfolioExperienceEdit key={data.id} data={data}/>
        ))}

        <PortfolioExperienceEdit/>

        <div className={formStyles.formTitle}>{t("title.awards")}</div>
        <div className={formStyles.formSeparator}/>

        {awards.map(data => (
          <PortfolioAwardEdit key={data.id} data={data}/>
        ))}

        <PortfolioAwardEdit/>

        <div className={formStyles.formTitle}>
          <span>{t("title.images")}</span>
        </div>
        <div className={formStyles.formSeparator}/>

        <PortfolioPhotoEdit photos={photos}/>

        <div className={formStyles.formTitle}>{t("title.video")}</div>
        <div className={formStyles.formSeparator}/>

        {videoLinks.map(data => (
          <PortfolioVideoEdit key={data.id} data={data}/>
        ))}

        <PortfolioVideoEdit/>

        <div className={formStyles.formTitle}>{t("title.audio")}</div>
        <div className={formStyles.formSeparator}/>
        <p className={formStyles.formDesc}>{t("text.audio-desc")}</p>

        {audios.map(data => (
          <PortfolioAudioEdit key={data.id} data={data}/>
        ))}

        <PortfolioAudioEdit audios={audios}/>

        <div className={formStyles.formTitle}>
          <span>{t("title.files")}</span>
        </div>
        <div className={formStyles.formSeparator}/>

        <PortfolioFilesEdit files={documents} field={'documents'}/>

        <Button onClick={this.handleNextStep} kind="bordered" type="button">{t("button.next-step")}</Button>
      </div>
    )
  }
}

export default withTranslation()(PortfolioEdit);
