import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "page-title.signin": "Sign in to the application",
      "page-title.signup": "Sign up",
      "page-title.signup-social": "Sign up via social network",
      "page-title.signup-steps": "Sign up in the app",
      "page-title.talent-agreement": "User Agreement with Talent",
      "page-title.staff-agreement": "User Agreement with Filmmaker",
      "page-title.company-agreement": "User Agreement with Company",
      "page-title.forgot-password": "Password recovery",
      "page-title.new-password": "Create a new password",
      "page-title.search": "Search",
      "page-title.talent-workspace": "Talent workspace",
      "page-title.staff-workspace": "Filmmaker workspace",
      "page-title.company-workspace": "Company workspace",
      "page-title.profile-edit": "Profile editing",
      "page-title.education": "Education",
      "page-title.boards": "Boards",
      "page-title.vacancies": "Vacancies",
      "page-title.castings": "Castings",
      "page-title.settings": "Settings",
      "page-title.payment": "Payment",

      "pages.signin.title": "Sign in to the account",
      "pages.signin.title-social": "Sign in with social networks",
      "pages.signin.signup-button": "Create a profile",
      "pages.signin.forgot-password": "Forgot your password?",

      "pages.signup.title": "Sign up",
      "pages.signup.title-social": "Sign up with social networks",
      "pages.signup.agreement-1": "By registering you accept all",
      "pages.signup.agreement-2": "terms of use of the service",
      "pages.signup.agreement-3":
        ", privacy policy and agree to the processing of your personal data.",

      "pages.signup-stepper.title": "Registration on the website",

      "pages.forgot-password.title": "Forgot you password?",
      "pages.new-password.title": "Create a new password",

      "validation.input.required": "The field cannot be empty",
      "validation.input.tooSmall": "Too small",
      "validation.input.tooBig": "Too big",
      "validation.input-city.required":
        "Enter a city",
      "validation.email.required": "Enter your e-mail",
      "validation.email.notvalid": "E-mail is not valid",
      "validation.phone.required": "Enter your mobile number",
      "validation.password.required": "Enter your password",
      "validation.password.minlength": "Password must contain at least 6 characters",
      "validation.password.notmatch": "Passwords must match",
      "validation.password-confirmation.required":
        "Enter your password confirmation",
      "validation.input.one-or-both": "Choose one or both",
      "validation.input.instagram": "Enter a valid profile link (https://instagram.com/yourprofile)",
      "validation.input.facebook": "Enter a valid profile link (https://facebook.com/yourprofile)",
      "validation.input.vk": "Enter a valid profile link (https://vk.com/yourprofile)",
      "validation.input.user-age": "Minimum age for registration is 5 years",

      "input.email": "E-mail",
      "input.phone": "Mobile number",
      "input.password": "Password",
      "input.password-confirmation": "Password confirmation",
      "input.new-password": "New password",
      "input.new-password-confirmation": "Confirm new password",
      "input.remember-me": "Remember me",
      "input.first-name": "First name",
      "input.first-name-en": "First name (ENG)",
      "input.your-first-name": "Your first name",
      "input.last-name": "Last name",
      "input.last-name-en": "Last name (ENG)",
      "input.your-last-name": "Your last name",
      "input.birthday": "Date of Birth",
      "input.country": "Country",
      "input.currency": "Currency",
      "input.city": "City",
      "input.shooting-cities": "Shooting in",
      "input.select-country": "Select country",
      "input.select-city": "Select city",
      "input.select-status": "Select status",
      "input.sex": "Gender",
      "input.male": "Male",
      "input.female": "Female",
      "input.university": "University",
      "input.has-actor-education": "I have an acting degree",
      "input.has-high-education": "I have a university degree",
      "input.university-status": "Degree",
      "input.university-specialty": "Major",
      "input.university-year": "Graduation year",
      "input.university-status-higher": "Bachelor",
      "input.university-status-master": "Master",
      "input.university-status-doctor": "Doctor",
      "input.university-status-incomplete": "Incomplete bachelor of education",
      "input.one-or-more-cities": "One or more cities",
      "input.choose-city": "One or more cities",

      "input.course": "Additional classes",
      "input.name": "Name",
      "input.border-name": "Board name",
      "input.course-year": "Graduation year",

      "input.have-agent": "I have an agent",
      "input.agent-phone": "Phone number",
      "input.agent-email": "E-mail",
      "input.agent-fio": "Full name",
      "input.agency-name": "Agency",
      "input.producer-first-name": "Enter name",
      "input.your-mobile-phone": "Your phone number",
      "input.your-site": "Website",
      "input.your-facebook-profile": "Your Facebook profile",
      "input.your-vk-profile": "Your VK profile",
      "input.your-instagram-profile": "Your Instagram profile",
      "input.your-kinopoisk-profile": "Your Kinopoisk profile",
      "input.your-imdb-profile": "Your IMDb profile",
      "input.your-kinolift-profile": "Your Kinolift profile",
      "input.website-company": "Company website",
      "input.agent-first-name": "Enter name",
      "input.agent-second-name": "Enter second name",
      "input.agent-middle-name": "Enter middle name",
      "input.agent-position": "Position in the company",
      "input.amount": "Amount",

      "input.crowd-scene": "I agree to be an extra",
      "input.crowd-scene-short": "to be an extra",
      "input.low-budget": "Ready to work in low budget (student) projects",
      "input.low-budget-staff": "Ready to work in low budget (student) projects",
      "input.low-budget-short": "to work in low-budget (student) projects",
      "input.low-budget-short-staff": "to work in low-budget (student) projects",
      "input.long-trip": "Ready for long business trips",
      "input.long-trip-short": "for long business trips",
      "input.model-work": "Ready to work as model",
      "input.model-work-short": "to work as model",
      "input.shooting-shift-rate": "Wage per shift",
      "input.shooting-shift-rate-to": "Rate for rent. shift (to)",
      "input.shift-rate": "Wage per shift",
      "input.shift-rate-placeholder": "Enter a rate",
      "input.shift-wage-rate-placeholder": "Enter a rate",
      "input.from": "from",
      "input.to": "to",
      "input.select-type": "Select type",
      "input.select-project-type": "Select project type",
      "input.select-appearance-type": "Select appearance",
      "input.select-appearance": "Select appearance",
      "input.select-physique": "Select build",
      "input.select-eyes": "Select eyes",
      "input.select-hairs": "Select hair",

      "input.enter-title": "Enter title",
      "input.enter-address": "Enter address",

      "input.in-company": "Company / Production",
      "input.amateur": "Amateur",
      "input.professional": "Professional",
      "input.in-company-short": "Company / Production",
      "input.independent": "Independent",
      "input.department": "Department",
      "input.profession": "Profession",

      "input.company-type": "Type of company",
      "input.company-name": "Company name",
      "input.company-activity": "Services and products provided",
      "input.company-reg-date": "Establishment Date",
      "input.legal-address": "Legal address",
      "input.actual-address": "Address",

      "input.company-type-too": "LLP",
      "input.company-type-ip": "Sole proprietor/ trader",
      "input.company-type-ao": "JSC",

      "extra.profession": "*The first profession chosen will be taken as the main one for the profile. Maximum of 3 professions.",

      "placeholder.birthday": "DD.MM.YYYY",

      "error.birthday": "Enter the date in DD.MM.YYYY format or select from the calendar",

      "button.reset-password": "Reset password",
      "button.signin": "Sign in",
      "button.signup": "Create a profile",
      "button.logout": "Log out",
      "button.next": "Next",
      "button.reset": "Reset",
      "button.details": "Details",
      "button.create": "Create",
      "button.next-user": "Next",
      "button.prev-user": "Prev",
      "button.yes": "Yes",
      "button.no": "No",
      "button.show-no-more": "Don't show it again",

      "roles.talent": "Talent",
      "roles.staff": "Filmmaker",
      "roles.company": "Company",

      "menu.home": "Home",
      "menu.search": "Search",
      "menu.for-talents": "For talent",
      "menu.for-staff": "For filmmakers",
      "menu.for-company": "For companies",
      "menu.locations": "Locations",
      "menu.about-us": "About us",
      "menu.workspace": "My Account",
      "menu.vacancies": "Vacancies",
      "menu.boards": "Boards",
      "menu.castings": "Castings",
      "menu.education": "Education",
      "menu.payment": "Plans",
      "menu.settings": "Settings",
      "menu.articles": "Blog",

      "menu.contract-offer": "Formal offer",
      "menu.talent-agreement": "Talent agreement",
      "menu.staff-agreement": "Filmmaker agreement",
      "menu.company-agreement": "Production agreement",
      "menu.all-rights-reserved": "All rights reserved",

      "label.appearance": "Appearance",
      "label.instrument": "Instrument",
      "label.sport": "Sport",
      "label.lang": "Language",
      "label.eyes": "Eyes",
      "label.hairs": "Hair",
      "label.about-myself": "About me",
      "label.health": "Health",
      "label.hobbies-and-pastime": "Your hobbies",
      "label.hobbies": "Enter hobbies",
      "label.city": "City",
      "label.country": "Country",
      "label.citizenship": "Citizenship",
      "label.gender": "Gender",
      "label.location": "Location",
      "label.geolocation": "Location",
      "label.actor-status": "Status",
      "label.main-profession": "Main profession",
      "label.staff-status": "Status",
      "label.primary-activity": "Primary activity",
      "label.place-of-residence": "Place of residence",
      "label.status": "Status",
      "label.age": "Age",
      "label.game-age": "Playing age",
      "label.type-of-appearance": "Ethnicity",
      "label.height": "Height",
      "label.weight": "Weight",
      "label.physique": "Build",
      "label.hair-color": "Hair color",
      "label.hair-length": "Hair length",
      "label.eye-color": "Eye color",
      "label.chest-girth": "Chest line",
      "label.waist-girth": "Waist line",
      "label.hip-girth": "Hip line",
      "label.head-circumference": "Head circumference",
      "label.clothing-size-up": "Upper body size",
      "label.clothing-size-bottom": "Lower body size",
      "label.shoe-size": "Shoe size (EUR)",
      "label.company-presentation": "Company presentation",

      "label.favorite-directors": "Favorite directors",
      "label.favorite-films": "Favorite films",
      "label.favorite-music": "Favorite music",
      "label.favorite-artists": "Favorite artists",

      "label.lameness": "Lameness",
      "label.scar": "Scars",
      "label.piercing": "Piercing",
      "label.have-twin": "Have a twin",
      "label.large-tattoo": "Large tattoo",
      "label.veneer": "Veneers",
      "label.gold-crowns": "Gold crowns",
      "label.operations": "Minor cosmetic surgery",
      "label.birthmark": "Birthmark",
      "label.vitiligo": "Vitiligo",
      "label.albinism": "Albinism",
      "label.pockmark": "Pockmark",

      "skill.dubbing": "Dubbing",
      "skill.scoring": "Voice acting",
      "skill.model": "Modelling",
      "skill.model-school": "Model school",
      "skill.opera-singing": "Opera singing",
      "skill.pop-singing": "Pop singing",
      "skill.folk-singing": "Folk singing",
      "skill.jazz-singing": "Jazz singing",
      "skill.soprano": "Soprano",
      "skill.baritone": "Baritone",
      "skill.tenor": "Tenor",
      "skill.contralto": "Contralto",
      "skill.mezzo-soprano": "Mezzo soprano",
      "skill.bass": "Bass",
      "skill.instrument-name": "Instrument Name",
      "skill.possession": "Level of proficiency",
      "skill.ballroom": "Ballroom dancing",
      "skill.folk": "Folk",
      "skill.modern": "Modern",
      "skill.hip-hop": "Hip-hop",
      "skill.ballet": "Ballet",
      "skill.step": "Step",
      "skill.kind-of-sport": "Kind of sport",
      "skill.stuntman": "Stuntman",
      "skill.stunt-school": "Stunt school",
      "skill.horse-stunts": "Horse Tricks",
      "skill.auto-tricks": "Car Tricks",
      "skill.acrobatics": "Acrobatics",
      "skill.battle-scenes": "Battle scenes",
      "skill.falling-from-height": "Stunt falling from height",
      "skill.cable-work": "Wire work",
      "skill.burning-man": "Body burn stunt ",
      "skill.parkour": "Parkour",
      "skill.stage-battle": "Fight scenes",
      "skill.fencing": "Fencing",
      "skill.clownery": "Clownery",
      "skill.juggler": "Juggler",
      "skill.magician": "Magician",
      "skill.equilibrist": "Equilibrist",
      "skill.trainer": "Trainer",
      "skill.language": "Language",
      "skill.level": "Level of proficiency",
      "skill.car": "Car",
      "skill.motorcycle": "Motorcycle",
      "skill.oversized-transport": "Oversized vehicles",
      "skill.extreme-driving": "Extreme driving",
      "skill.horseback-riding": "Horse riding",

      "label.step": "Step",
      "label.type": "Type",
      "label.project-type": "Project type",
      "label.year": "Year",
      "label.project-name": "Project name",
      "label.project-director": "Director",
      "label.project-position": "Position in the project ",
      "label.project-role": "Role",
      "label.project-role-staff": "Position in the project",
      "label.role-status": "Role type",
      "label.dates": "Shooting dates",

      "label.performance-title": "Performance title",
      "label.theater-name": "Theater name",
      "label.role-type": "Role type",
      "label.role-name": "Role",
      "label.name": "Name",
      "label.title": "Title",
      "label.project-link": "Link to the project",
      "label.category": "Category",
      "label.categories": "Categories",

      "label.sort-by": "Sort by",
      "label.select-type": "Select type",
      "label.select-role-type": "Select from the list",
      "label.select-appearance-type": "Select appearance type",
      "label.sort-by-name": "Name",
      "label.sort-by-date": "Registration date",
      "label.from": "From",
      "label.to": "To",
      "label.attach-scene-text": "Attach scene text",
      "label.attach": "Attach",

      "label.agent-full-name": "Full name",
      "label.agent-phone-number": "Phone number",
      "label.agent-email": "E-mail",
      "label.agency": "Agency",
      "label.phone-number": "Phone number",
      "label.site": "Website",

      "label.video-business": "Self introduction video",
      "label.video": "Video",
      "label.video-link": "Link to YouTube or Vimeo",

      "label.professional": "Professional",
      "label.amateur": "Amateur",
      "label.actor-professional": "Professional actor",
      "label.actor-amateur": "Amateur actor",

      "label.main-role": "Lead role",
      "label.second-role": "Supporting role",
      "label.episode-role": "Principal role",
      "label.crowd-role": "Background/extra",

      "plan.pro": "PRO year",
      "plan.pro_month": "PRO month",
      "plan.premium": "PREMIUM",
      "plan.status.paid": "Paid",
      "plan.status.subscribed": "Subscribed",
      "plan.status.unsubscribed": "Unsubscribed",
      "plan.status.not_paid": "Not paid",

      "button.update": "Update",
      "button.unsubscribe": "Unsubscribe",
      "button.add": "Add",
      "button.remove": "Remove",
      "button.save": "Save",
      "button.edit": "Edit",
      "button.crop": "Crop",
      "button.view": "My portfolio",
      "button.view-company": "Portfolio",
      "button.share": "Share",
      "button.more": "Show more",
      "button.resume": "Resume",
      "button.submit-application": "Submit your application",
      "button.leave-request": "Leave a request",
      "button.download-cv": "Download CV",
      "button.join": "Join",
      "button.pay": "Pay",
      "button.subscribe": "Subscribe",
      "button.done": "Done",
      "button.cancel": "Cancel",
      "button.freeze-account": "Freeze account",
      "button.unfreeze-account": "Unfreeze account",
      "button.quick-view": "Quick view",
      "button.go-to-profile": "Go to profile",
      "button.clear-all-filters": "Reset all filters",
      "button.announce-casting": "Announce a casting call",
      "button.post-vacancies": "Post a vacancy",
      "button.collect-extras": "Gather extras",
      "button.accept-agreement": "Accept",
      "button.decline": "Decline",
      "button.to-favorites": "To favorites",
      "button.in-favorites": "Added to favorites",
      "button.back": "Back",
      "button.upload-file": "Upload file",
      "button.download": "Download",
      "button.create-board": "Create a new board",
      "button.finish-signup": "Finish registration",
      "button.participate": "Participate",
      "button.apply-casting": "Apply",
      "button.accept": "Accept",
      "button.delete": "Remove",
      "button.refuse": "Refuse",
      "button.close": "Close",
      "button.apply": "Apply",
      "button.move": "Move",
      "button.send-invitation": "Send invitation",
      "button.select-all": "Select all",
      "button.selection-pdf": "Selection.PDF",
      "button.download-pdf-with-contacts": "With contacts",
      "button.download-pdf-without-contacts": "Without contacts",
      "button.save-and-next-step": "Save and go to the next step",
      "button.next-step": "Go to the next step",

      "title.want-adv": "Do you want to advertise on Etalents.pro?",
      "title.adv-sent": "Application sent!",
      "title.adv-sent-approved": "Your response has been sent.",
      "title.adv-sent-refused": "You refused the application!",
      "title.block-coming-soon": "Coming soon",
      "title.common-data": "General information",
      "title.appearance": "Appearance",
      "title.height-and-weight": "Height and weight",
      "title.girth": "Girth measurements",
      "title.sizes": "Sizes",
      "title.features": "Features",
      "title.vocal": "Vocal",
      "title.voice-type": "Voice type",
      "title.skills": "Skills",
      "title.education": "Education",
      "title.career": "Career",
      "title.high-education": "Higher education",
      "title.high-actor-education": "Professional acting degree",
      "title.portfolio": "Portfolio",
      "title.contacts": "Contacts",
      "title.your-contacts": "Your contacts",
      "title.agent-contacts": "Agent",
      "title.talent-contacts": "Talent contacts",
      "title.company-contacts": "Company contacts",
      "title.representative-contacts": "Representative contacts",
      "title.area-of-work": "Area of work",
      "title.cabinet": "My account",
      "title.tariffs": "Plans",
      "title.details": "Details",
      "title.change-password": "Change password",
      "title.freeze-account": "Freeze account",
      "title.payment": "Payment",
      "title.my-profile": "My profile",
      "title.project-invitations": "Project invitations",
      "title.interests-vacancies": "Jobs that may be of interest to you",
      "title.interests-castings": "Casting calls that may be of interest to you",
      "title.last-tutorials": "Latest educational materials",
      "title.main-information": "General information",
      "title.common-information": "Common information",
      "title.vacancy-description": "Vacancy description",
      "title.about-yourself": "About me",
      "title.about-company": "About us",
      "title.who-are-we": "Who we are",
      "title.who-are-looking-for": "Who are you looking for?",
      "title.who-we-invite": "Who are we inviting?",
      "title.profile-photo": "Profile photo",
      "title.your-logo": "Your logotype",
      "title.photo": "Photo",
      "title.working": "I work",
      "title.courses": "Additional classes",
      "title.languages-skills": "Language skills",
      "title.special-skills": "Special Skills",
      "title.music-skills": "Music skills",
      "title.sport-skills": "Sports skills",
      "title.cirque-skills": "Circus skills",
      "title.dance-skills": "Dance skills",
      "title.stunt-skills": "Stunt skills",
      "title.board-remove": "Are you sure you want to remove the board",

      "title.driving": "Driving",
      "title.creative-experience": "Experience",
      "title.awards": "Awards and nominations",
      "title.projects-do": "What we have done",
      "title.role": "Role",
      "title.theater-role": "Theater role",
      "title.audio": "Audio",
      "title.video": "Video",
      "title.photogallery": "Photo gallery",
      "title.images": "Images",
      "title.files": "Files",
      "title.upload-photo": "Upload a photo",
      "title.upload-audio": "Upload an audio",
      "title.our-clients": "Our clients",
      "title.create-board": "Create a board",
      "title.edit-board": "Edit board",
      "title.new-board": "Name the board",
      "title.move-to-board": "Move to board",

      "title.castings": "Casting calls",
      "title.vacancies": "Vacancies",
      "title.crowdings": "Gathering extras",
      "title.invitations": "Invitations",

      "title.announce-casting": "Casting call",
      "title.edit-casting": "Casting edit",
      "title.announce-crowding": "Gathering extras",
      "title.edit-crowding": "Gathering extras edit",
      "title.publish-vacancy": "Vacancy publishing",
      "title.edit-vacancy": "Vacancy edit",
      "title.invite-to-casting": "Casting invitation",
      "title.invite-to-vacancy": "Vacancy invitation",

      "text.leave-adv-req": "Leave a request and we will contact you",
      "text.adv-req-on-review": "Your application will be processed",
      "text.adv-req-on-review-author-notified": "Please, save the casting-director’s number so you can contact him/her in case of emergency or unexpected situations.",

      "text.photogallery-desc":
        "Photos should be fresh (preferably a week ago today), and the appearance is natural. Do not use photo editors: they do not make sense, because you will still be seen live.",
      "text.video-desc": "Upload your video-presentation, showreel, or other videos.",
      "text.audio-desc": "Download MP3 audio recording",
      "text.upload-photo-desc-1": "Drag your photo to this area or",
      "text.upload-photo-desc-2": "select a file",
      "text.upload-photo-desc-3": "from your computer",
      "text.upload-photo-item-1": "Size: photo aspect ratio",
      "text.upload-photo-item-2":
        "close-up of your face without sunglasses and shadows.",
      "text.upload-photo-item-3":
        "The background should be plain, monotonous without any distracting objects.",
      "text.upload-photo-item-4": "JPG or PNG photo format",

      "text.subscription-term": "Valid until",
      "text.next-withdrawal": "Next withdrawal",
      "text.next-withdrawal-plan": "Plan switched to PRO year",
      "text.your-plan": "Your plan",
      "text.your-plan.detail":
        "Open access to the best casting calls and educational materials",
      "text.your-plan.link": "Go to plans",
      "text.referal.title": "Invite friends and get bonuses!",
      "text.filled-out": "Filled out",
      "text.payment-status": "Payment status",
      "text.moderation-status": "Moderation status",
      "text.freeze-desc-1":
        "If you want to suspend your activities on the platform, you can temporarily freeze your account. Please note: your profile will disappear from the database, but the subscription period will not be extended.",
      "text.freeze-desc-2":
        "The system will automatically delete your account after 6 months if you do not defrost it.",
      "text.freeze-desc-3":
        "To permanently delete your account, write to the website technical support.",
      "text.coming-soon": "Coming soon...",
      "text.vacancies-first":
        "No time to look for people in the database? Announce a casting call, and get responses from available actors.",
      "text.vacancies-second":
        "Looking for professionals? Open a vacancy among filmmakers and select candidates from the list of respondents.",
      "text.reklama-here": "Your ad may be here",
      "text.reklama-there": "You may place your own ad",
      "text.reklama-there-staff": "I want to place a banner ad on ETalents.pro",
      "text.progress-desc-talent":
        "The more you fill out the profile, the higher the chances of getting an invitation to shoot. Go to 'Edit' to complete your profile.",
      "text.progress-desc-talent-short":
        "The more you fill out the profile, the higher the chances of getting an invitation to shoot",
      "text.progress-desc-staff":
        "The more you fill out the profile, the higher your profile will be the search rating. Go to 'Edit' to complete your profile.",
      "text.progress-desc-staff-short":
        "Tap 'Edit' to fill out your profile",
      "text.progress-full-desc":
        "The more you fill out all the points on your profile, the higher you will rise in the internal rating of the platform. Your position depends on the amount of data about yourself that you have indicated.",
      "text.no-tutotials": "There are no educational materials yet",
      "text.no-options": "No options",
      "text.select-option-create": "Create",
      "text.subscription-expires-soon": "Subscription Expires",
      "payment.unsubscribe": "Unsubscribe",
      "text.subscription-renew": "Switch to PRO year",
      "text.unsubscribed-success": "You have successfully unsubscribed",
      "text.change-plan-to-pro-success": "Plan was successfully changed to PRO year",

      "text.invitations-develop":
        'The "Invitations" section is under development and will soon appear in the application.',
      "text.vacancies-develop":
        'The "Vacancies" section is under development and will soon appear in the application.',
      "text.castings-develop":
        'The "Castings" section is under development and will soon appear in the application.',

      "text.date-format": "DD.MM.YYYY format",
      "text.select-city-from-list": "Start entering the name",
      "text.select-area-of-work":
        "Indicate areas you are interested to work in",
      "text.career.title": "Career",
      "text.profile-status.title": "Occupation",
      "text.staff-status.title": "My skills level",
      "text.profession.extra":
        "*The first profession chosen will be taken as the main one for the profile. You can point the maximum of 3 professions.",
      "text.service.extra": "*The first activity selected will be taken as the main one for the profile.",
      "text.guide-title-1": "How to add a talent to your «Favorites»",
      "text.guide-title-2": "How to work with the tool «Boards»",
      "text.guide-title-3": "How to create castings on the platform",

      "text.photo-drop-title-talent-1": "AN EXAMPLE OF",
      "text.photo-drop-title-talent-2": "SUCCESSFUL HEAD SHOT",
      "text.photo-drop-title-staff-1": "AN EXAMPLE OF",
      "text.photo-drop-title-staff-2": "SUCCESSFUL HEAD SHOT",
      "text.photo-drop-checklist": "Checklist for a successful photo:",
      "text.photo-drop-checklist-1": "Close-up of your face (no bright makeup)",
      "text.photo-drop-checklist-2": "Little smile",
      "text.photo-drop-checklist-3": "Plain background",
      "search.no-talent-found":
        "Talents not found. Try changing your search options.",
      "search.no-staff-found":
        "Staffs not found. Try changing your search options.",
      "search.no-company-found":
        "Companies not found. Try changing your search options.",
      "search.talent-found": "Talents found",
      "search.staff-found": "Filmmakers found",
      "search.company-found": "Companies found",

      "gallery.height": "FULL-LENGTH PHOTO",
      "gallery.smile": "LITTLE SMILE",
      "gallery.frowning": "GLOOMY FACE",
      "gallery.mouth": "TOOTHY SMILE/GRIN",
      "gallery.positive": "SURPRISED FACE",
      "gallery.three-quarter": "3/4 VIEW SHOT (the body is turned about 45º from the camera)",
      "gallery.back": "LIFT YOUR HANDS IN FRONT OF YOUR FACE, THE BACK SIDE TO THE CAMERA",
      "gallery.profile": "PROFILE VIEW",
      "gallery.text": "add a JPG or PNG photo",

      "status.in-progress": "In progress",
      "status.moderation-refused": "Denied! Make some changes",
      "status.moderation-approved": "Approved! You are now in the database",
      "status.moderation-approved-staff-company": "Approved! All platform tools are available to you",

      "payment.subscription-expires": "Subscription expires.",
      "payment.update-subscription": "Update your payment.",
      "payment.free-is-infinite": "Free subscription has no time limit.",
      "payment.openAccess":
        "Open access to the best casting calls and educational materials",
      "payment.invite-friends": "Пригласите друзей",
      "payment.and-get-bonuses": "И получите бонусы!",

      "payment.free.title": "FREE",
      "payment.free.subtitle": "Free",
      "payment.free.casting": "Casting calls ",
      "payment.free.casting-detail": "for background roles",
      "payment.free.profile": "Detailed personal profile",
      "payment.free.cv": "CV in both Russian and English",
      "payment.free.photos": "Photo – 8",
      "payment.free.video": "Video – 1",
      "payment.free.audio": "Audio – 1",
      "payment.free.image": "Photo (1)",
      "payment.free.tutorial": "Education materials (3)",
      "payment.free.email": "Notifications about casting calls",
      "payment.free.email-detail": "only for background roles",

      "payment.pro-month.title": "PRO month – 1 999 ₸/month",
      "payment.pro-month.subtitle": "Subscription for a month",
      "payment.pro-month.casting": "Castings calls",
      "payment.pro-month.casting-detail":
        "for lead, supporting, principal, background roles",
      "payment.pro-month.casting-world": "Castings all over the world",
      "payment.pro-month.profile": "Detailed personal profile",
      "payment.pro-month.photos": "Photo – 18",
      "payment.pro-month.videos": "Video – 5",
      "payment.pro-month.audio": "Audio – 5",
      "payment.pro-month.shooting": "1 headshot shooting at ETalents.pro studio",
      "payment.pro-month.cv": "CV in both Russian and English",
      "payment.pro-month.image": "Photos (up to 20)",
      "payment.pro-month.video": "Videos (up to 5)",
      "payment.pro-month.tutorial": "Education materials (all)",
      "payment.pro-month.email": "Notifications about casting calls",
      "payment.pro-month.email-detail":
        "for lead, supporting, principal, background roles",

      "payment.pro-year.title": "PRO year – 19 999 ₸/year",
      "payment.pro-year.subtitle": "Subscription for a year",
      "payment.pro-year.photos": "Photo – 18",
      "payment.pro-year.videos": "Video – 5",
      "payment.pro-year.audio": "Audio - 5",
      "payment.pro-year.shooting": "Photo and video shooting for your acting portfolio at ETalents.pro studio",
      "payment.pro-year.casting": "Casting calls",
      "payment.pro-year.casting-detail":
        "for lead, supporting, principal, background roles",
      "payment.pro-year.casting-world": "Castings all over the world",
      "payment.pro-year.profile": "Detailed personal profile",
      "payment.pro-year.cv": "CV in both Russian and English",
      "payment.pro-year.image": "Photos ( up to 20)",
      "payment.pro-year.image-detail":
        "you can make them for free at ETalents.pro studio or at our partners",
      "payment.pro-year.video": "Videos (up to 5)",
      "payment.pro-year.video-detail":
        "you can make your video-presentation for free at ETalents.pro studio or at our partners",
      "payment.pro-year.tutorial": "Education materials (all)",
      "payment.pro-year.email": "Notifications about casting calls",
      "payment.pro-year.email-detail":
        "for lead, supporting, principal, background roles",
      "payment.switch-to-annual-rate": "Switch to annual subscription",
      "payment.switch-to-annual-rate-next-time": "Switch to annual subscription from the next billing date",

      "text.no-projects-yet": "There are no projects yet",
      "text.no-invites-yet": "There are no invitations yet",
      "text.no-castings-yet": "There are no casting calls yet",
      "text.no-extras-yet": "There are no casting calls for extras yet",
      "text.no-vacancies-yet": "There are no vacancies yet",
      "text.start-your-way-in-movies": "START YOUR JOURNEY WITH",

      "label.project": "Project",
      "label.about-project": "About project",
      "label.casting": "Casting",
      "label.producer": "Director",
      "label.shooting-in": "Shooting in",
      "label.shooting-dates": "Shooting dates",
      "label.shooting-time": "Shooting time",
      "label.address": "Address",
      "label.payment": "Rate per shift",
      "label.description": "Description",
      "label.scene-text": "Scene text",
      "label.presentation": "Presentation",
      "label.pay-attention": "Be sure to pay attention",
      "label.author": "Posted by",
      "label.looking-for": "Looking for",
      "label.looking-for-in": "Looking for in",
      "label.date": "Shooting dates",
      "label.time": "Shooting time",
      "label.time-casting": "Audition time",
      "label.position": "Position",
      "label.invite-position": "Inviting you to the position of",
      "label.gathering-place": "Venue",
      "label.crowding": "Gathering extras",
      "label.invitation": "Invitation",
      "label.casting-active": "Casting is active",
      "label.casting-date": "Casting date",
      "label.casting-time": "Casting time",
      "label.vacancy-active": "Vacancy is active",
      "label.extra-active": "Extras collection is active",
      "label.vacancy-invitation": "Job invitation",
      "label.role-description": "Role description",
      "label.sender-first-name": "Sender's first name",
      "label.sender-last-name": "Sender's last name",
      "label.sender-phone": "Sender's phone",
      "label.sender-email": "Sender's e-mail",
      "label.who-invited": "Who we are inviting",
      "label.accepted": "Accepted",
      "label.refused": "Refused",
      "label.not-answered": "Not answered",
      "label.vacancy": "Vacancy",
      "label.show-who-is-ready": "Show those who are ready:",
      "label.work-as": "Work as:",
      "label.what-we-doing": "What are we doing?",
      "label.casting-is-active": "Casting call is active",
      "label.casting-is-not-active": "Casting call is over",
      "label.crowding-is-active": "Crowding call is active",
      "label.crowding-is-not-active": "Crowding call is over",
      "label.vacancy-is-active": "Vacancy call is active",
      "label.vacancy-is-not-active": "Vacancy call is over",

      "text.you-participating": "You are already participating",
      "text.you-applied-to-vacancy": "You have already applied for a vacancy",

      "title.talents": "Talents",
      "title.staffs": "Filmmakers",
      "title.companies": "Companies",

      "text.have-no-favorite-talent": "You have no favorite talents yet",
      "text.have-no-favorite-staff": "You have no favorite filmmakers yet",
      "text.have-no-favorite-company": "You have no favorite companies yet",

      "text.sign-up-success-check-email": "Please, check your email.",
      "text.sign-up-success-we-send-email": "An email has been sent to your address to confirm that your registration has completed successfully.",
      "text.forgot-password-check-email": "A link to reset your password has been sent to your email address, click on it.",
      "text.forgot-password-check-email-error": "This e-mail is not registered in our system. Please ensure that you enter your e-mail correctly.",
      "text.wait-for-moderation": "Your profile is being verified. It may take up to 2 business days. Thanks for waiting.",
      "text.wait-for-moderation-menu": "The section is temporarily unavailable. Your profile is being verified.",
      "text.refused-menu": "Your profile is not verified. Please, edit your profile. *The rejection reason is indicated in the letter that was sent to your e-mail.",
      "text.wait-for-paid": "The section is temporarily unavailable. Your tariff is not paid.",
      "text.available-formats": "Formats",
      "text.files-examples": "(synopsis, logline, script, project presentation, etc.)",
      "text.characters-left": "Characters left",
      "text.talent-paid-materials": "To add more photos, video, and audio materials, please switch to PRO plans.",
      "text.crop-photo-help": "Use the scroll bar to edit the image",
      "text.health-help": "If you have/had serious injuries, chronic illnesses or allergies, please indicate this here",
      "text.enter-save-esc-reset": "Press Enter to save or Esc to cancel",
      "text.board-cannot-do-talent": "These actions are not available because one (or more) of the selected talents already has an invitation.",
      "text.board-cannot-do-staff": "These actions are not available because one (or more) of the selected filmmakers already has an invitation.",
      "text.board-cannot-do-company": "These actions are not available because one (or more) of the selected companies already has an invitation.",
      "text.full-vacancy-info": "Vacancy details",
      "text.full-crowding-info": "Here you can define the basic requirements for extras actors. For example: Everyone come in clothes of the 90s.",
      "text.full-casting-info": "Define the basic requirements from the director or casting director. For example: Be sure to learn the text.",

      "notification.user-update-title": "User updating",
      "notification.update-success-text": "Data saved successfully",
      "notification.password-change-success-text": "Password changed successfully",
      "notification.password-change-not-success-text": "Password confirmation does not match",
      "notification.freeze-success": "Your account is frozen",
      "notification.unfreeze-success": "Your account is unfrozen",
      "notification.update-failure-text": "Error saving data",
      "notification.update-video-text": "YouTube or Vimeo only",

      "label.cm": "cm",
      "label.kg": "kg",

      "text.status-waiting": "не просмотрено",
      "text.status-refused": "refused",
      "text.status-approved": "approved",

      "board.type.casting": "casting",
      "board.type.crowding": "crowding",
      "board.type.vacancy": "vacancy",
      "board.favorites": "Favorites",

      "text.email-confirmed": "Your email address has been successfully confirmed.",
      "text.casting-accept-title": "Your application has been accepted, the sender will see it",
      "text.casting-approved-title": "Your application has been accepted, the sender will see it!",
      "text.casting-refused-title": "You refused the offer, the sender will be notified",
      "text.casting-approved-text": "He will contact you. Thanks!",
      "text.casting-refused-text": "Perhaps he will send you an offer on better terms. Thanks!",
      "text.casting-accept-text": "If you come up, he will send you an invitation or contact you.",
      "text.casting-create-title": "Your casting ad is active!",
      "text.casting-create-text": "Check the applications on your Boards.",
      "text.casting-create-text-2": "You will receive an application summary via your e-mail once a day.",
      "text.crowding-create-title": "Your ad on extras is active!",
      "text.crowding-create-text": "See the responding applicants in the Boards. Thank!",
      "text.invitation-create-title": "The invitation have been sent out!",
      "text.invitation-create-text": "Check the response status on your Boards.",
      "text.invitation-create-text-2": "*If talent is not responding for too long, we recommend you contact them directly via the phone number listed in the talent’s profile. ",
    }
  },
  ru: {
    translation: {
      "page-title.signin": "Вход в приложение",
      "page-title.signup": "Регистрация",
      "page-title.signup-social": "Регистрация через соц.сеть",
      "page-title.signup-steps": "Регистрация на сайте",
      "page-title.talent-agreement": "Пользовательское соглашение с Талантом",
      "page-title.staff-agreement": "Пользовательское соглашение с Кинодеятелем",
      "page-title.company-agreement": "Пользовательское соглашение с Компанией",
      "page-title.forgot-password": "Восстановление пароля",
      "page-title.new-password": "Создание нового пароля",
      "page-title.search": "Поиск",
      "page-title.talent-workspace": "Кабинет таланта",
      "page-title.staff-workspace": "Кабинет кинодеятеля",
      "page-title.company-workspace": "Кабинет компании",
      "page-title.profile-edit": "Редактирование профиля",
      "page-title.education": "Образование",
      "page-title.boards": "Доски",
      "page-title.vacancies": "Вакансии",
      "page-title.castings": "Кастинги",
      "page-title.settings": "Настройки",
      "page-title.payment": "Оплата",

      "pages.signin.title": "Войти в аккаунт",
      "pages.signin.title-social": "Войти через соц. сети",
      "pages.signin.signup-button": "Создать профайл",
      "pages.signin.forgot-password": "Забыли пароль?",

      "pages.signup.title": "Регистрация",
      "pages.signup.title-social": "Регистрация через соц. сети",
      "pages.signup.agreement-1": "Регистрируясь, вы принимаете все",
      "pages.signup.agreement-2": "условия пользования сервисом",
      "pages.signup.agreement-3":
        ", политику конфиденциальности и соглашаетесь на обработку персональных данных.",

      "pages.signup-stepper.title": "Регистрация на сайте",

      "pages.forgot-password.title": "Забыли пароль?",
      "pages.new-password.title": "Создание нового пароля",

      "validation.input.required": "Поле не может быть пустым",
      "validation.input.tooSmall": "Слишком маленькое",
      "validation.input.tooBig": "Слишком большое",
      "validation.input-city.required":
        "Укажите город",
      "validation.email.required": "Введите почту",
      "validation.email.notvalid": "Неправильный формат почты",
      "validation.phone.required": "Введите номер телефона",
      "validation.password.required": "Введите пароль",
      "validation.password.minlength": "Пароль должен состоять не менее чем из 6 знаков",
      "validation.password.notmatch": "Пароли должны совпадать",
      "validation.password-confirmation.required":
        "Подтвердите пароль",
      "validation.input.one-or-both": "Выберите один из вариантов или оба",
      "validation.input.instagram": "Укажите корректную ссылку на профиль (https://instagram.com/yourprofile)",
      "validation.input.facebook": "Укажите корректную ссылку на профиль (https://facebook.com/yourprofile)",
      "validation.input.vk": "Укажите корректную ссылку на профиль (https://vk.com/yourprofile)",
      "validation.input.user-age": "Минимальный возраст для регистрации 5 лет",

      "input.email": "E-mail",
      "input.phone": "Телефон",
      "input.password": "Пароль",
      "input.password-confirmation": "Подтверждение пароля",
      "input.new-password": "Новый пароль",
      "input.new-password-confirmation": "Подтвердите новый пароль",
      "input.remember-me": "Запомнить меня",
      "input.first-name": "Имя",
      "input.first-name-en": "Имя (англ.)",
      "input.your-first-name": "Ваше имя",
      "input.last-name": "Фамилия",
      "input.last-name-en": "Фамилия (англ.)",
      "input.your-last-name": "Ваша фамилия",
      "input.birthday": "Дата рождения",
      "input.country": "Страна",
      "input.currency": "Валюта",
      "input.city": "Город",
      "input.shooting-cities": "Снимаем в",
      "input.select-country": "Выберите страну",
      "input.select-city": "Выберите город",
      "input.select-status": "Выберите статус",
      "input.sex": "Пол",
      "input.male": "Мужской",
      "input.female": "Женский",
      "input.university": "Университет",
      "input.has-actor-education": "У меня есть актерское образование",
      "input.has-high-education": "У меня есть высшее образование",
      "input.university-status": "Степень",
      "input.university-specialty": "Специальность",
      "input.university-year": "Год окончания",
      "input.university-status-higher": "Бакалавр",
      "input.university-status-master": "Магистр",
      "input.university-status-doctor": "Доктор",
      "input.university-status-incomplete": "Неоконченное высшее",
      "input.one-or-more-cities": "Один или несколько городов",
      "input.choose-city": "Один или несколько городов",

      "input.course": "Курс",
      "input.name": "Имя",
      "input.border-name": "Название доски",
      "input.course-year": "Год окончания",

      "input.crowd-scene": "Согласен(-сна) сниматься в массовых сценах",
      "input.crowd-scene-short": "сниматься в массовых сценах",
      "input.low-budget": "Согласен(-сна) сниматься в малобюджетных (студенческих) проектах",
      "input.low-budget-staff": "Согласен(-сна) работать в малобюджетных (студенческих) проектах",
      "input.low-budget-short": "сниматься в малобюджетных (студенческих) проектах",
      "input.low-budget-short-staff": "работать в малобюджетных (студенческих) проектах",
      "input.long-trip": "Готов(-а) к длительным командировкам",
      "input.long-trip-short": "к длительным командировкам",
      "input.model-work": "Готов(-а) к работе моделью",
      "input.model-work-short": "к работе моделью",
      "input.shooting-shift-rate": "Ставка за съемочную смену",
      "input.shooting-shift-rate-to": "Ставка за съем. смену (до)",
      "input.shift-rate": "Ставка за смену",
      "input.shift-rate-placeholder": "Укажите сумму",
      "input.shift-wage-rate-placeholder": "Укажите ставку",
      "input.from": "c",
      "input.to": "до",
      "input.select-type": "Выберите тип",
      "input.select-project-type": "Выберите тип проекта",
      "input.select-appearance-type": "Выберите тип внешности",
      "input.select-appearance": "Выберите внешность",
      "input.select-physique": "Выберите телосложение",
      "input.select-eyes": "Выберите цвет глаз",
      "input.select-hairs": "Выберите цвет волос",

      "input.enter-title": "Введите название",
      "input.enter-address": "Введите адрес",

      "input.have-agent": "У меня есть агент",
      "input.agent-phone": "Телефон",
      "input.agent-email": "E-mail",
      "input.agent-fio": "ФИО агента",
      "input.agent-first-name": "Имя",
      "input.agent-second-name": "Фамилия",
      "input.agent-position": "Должность",
      "input.agent-middle-name": "Отчество",
      "input.agency-name": "Агентство",
      "input.producer-first-name": "Имя режиссера",
      "input.your-mobile-phone": "Ваш номер телефона",
      "input.your-site": "Сайт",
      "input.your-facebook-profile": "Профиль Facebook",
      "input.your-vk-profile": "Профиль VK",
      "input.your-instagram-profile": "Профиль Instagram",
      "input.your-kinopoisk-profile": "Профиль на Кинопоиске",
      "input.your-imdb-profile": "Профиль на IMDb",
      "input.your-kinolift-profile": "Профиль на Кинолифт",
      "input.website-company": "Сайт компании",
      "input.amount": "Сумма",

      "input.in-company": "Работаю в компании / Продакшне",
      "input.amateur": "Любитель",
      "input.professional": "Профессионал",
      "input.in-company-short": "В компании / Продакшне",
      "input.independent": "Независимый кинодеятель",
      "input.department": "Департамент",
      "input.profession": "Профессия",

      "input.company-type": "Тип компании",
      "input.company-name": "Название компании",
      "input.company-activity": "Услуги",
      "input.company-reg-date": "Дата регистрации компании",
      "input.legal-address": "Юридический адрес",
      "input.actual-address": "Адрес",

      "input.company-type-too": "ТОО",
      "input.company-type-ip": "ИП",
      "input.company-type-ao": "АО",

      "extra.profession": "*Первая выбранная профессия будет указана как основная. Вы можете выбрать до 3 профессий.",

      "placeholder.birthday": "ДД.ММ.ГГГГ",

      "error.birthday": "Введите дату в формате ДД.ММ.ГГГГ или выберите в календаре",

      "button.reset-password": "Сбросить пароль",
      "button.signin": "Войти",
      "button.signup": "Создать профайл",
      "button.logout": "Выйти",
      "button.next": "Далее",
      "button.reset": "Сбросить",
      "button.details": "Детали",
      "button.create": "Создать",
      "button.next-user": "Следующий",
      "button.prev-user": "Предыдущий",
      "button.yes": "Да",
      "button.no": "Нет",
      "button.show-no-more": "Больше не показывать",

      "roles.talent": "Талант",
      "roles.staff": "Кинодеятель",
      "roles.company": "Компания",

      "menu.home": "Главная",
      "menu.search": "Поиск",
      "menu.for-talents": "Для талантов",
      "menu.for-staff": "Для кинодеятелей",
      "menu.for-company": "Для компаний",
      "menu.locations": "Локации",
      "menu.about-us": "О нас",
      "menu.workspace": "Личный кабинет",
      "menu.vacancies": "Вакансии",
      "menu.boards": "Доски",
      "menu.castings": "Кастинги",
      "menu.education": "Обучение",
      "menu.payment": "Тарифы",
      "menu.settings": "Настройки",
      "menu.articles": "Блог",

      "menu.contract-offer": "Договор оферты",
      "menu.talent-agreement": "Соглашение с Талантом",
      "menu.staff-agreement": "Соглашение с Кинодеятелем",
      "menu.company-agreement": "Соглашение с Компанией",
      "menu.all-rights-reserved": "Все права защищены",

      "label.appearance": "Внешность",
      "label.instrument": "Инструмент",
      "label.sport": "Спорт",
      "label.lang": "Язык",
      "label.eyes": "Глаза",
      "label.hairs": "Волосы",
      "label.about-myself": "О себе",
      "label.health": "Здоровье",
      "label.hobbies-and-pastime": "Ваши хобби и увлечения",
      "label.hobbies": "Ваши хобби",
      "label.city": "Город",
      "label.country": "Страна",
      "label.citizenship": "Гражданство",
      "label.gender": "Пол",
      "label.location": "Локация",
      "label.geolocation": "Местоположение",
      "label.actor-status": "Статус",
      "label.main-profession": "Основная профессия",
      "label.staff-status": "Статус",
      "label.primary-activity": "Основная деятельность",
      "label.place-of-residence": "Место проживания",
      "label.status": "Статус",
      "label.age": "Возраст",
      "label.game-age": "Игровой возраст",
      "label.type-of-appearance": "Тип внешности",
      "label.height": "Рост",
      "label.weight": "Вес",
      "label.physique": "Телосложение",
      "label.hair-color": "Цвет волос",
      "label.hair-length": "Длина волос",
      "label.eye-color": "Цвет глаз",
      "label.chest-girth": "Обхват груди",
      "label.waist-girth": "Обхват талии",
      "label.hip-girth": "Обхват бёдер",
      "label.head-circumference": "Обхват головы",
      "label.clothing-size-up": "Размер одежды: верх",
      "label.clothing-size-bottom": "Размер одежды: низ",
      "label.shoe-size": "Размер обуви (EUR)",
      "label.company-presentation": "Презентация компании",

      "label.favorite-directors": "Любимые режиссеры",
      "label.favorite-films": "Любимые фильмы",
      "label.favorite-music": "Любимая музыка",
      "label.favorite-artists": "Любимые художники",

      "label.lameness": "Хромота",
      "label.scar": "Шрамы",
      "label.piercing": "Пирсинг",
      "label.have-twin": "Есть близнец",
      "label.large-tattoo": "Крупные татуировки",
      "label.veneer": "Виниры",
      "label.gold-crowns": "Золотые коронки на зубах",
      "label.operations": "Мелкие косметические операции",
      "label.birthmark": "Родимое пятно",
      "label.vitiligo": "Витилиго",
      "label.albinism": "Альбинизм",
      "label.pockmark": "Рябое лицо",

      "skill.dubbing": "Дубляж",
      "skill.scoring": "Озвучивание",
      "skill.model": "Модель",
      "skill.model-school": "Модельная школа",
      "skill.opera-singing": "Оперное пение",
      "skill.pop-singing": "Эстрадное пение",
      "skill.folk-singing": "Народное пение",
      "skill.jazz-singing": "Джазовое пение",
      "skill.soprano": "Сопрано",
      "skill.baritone": "Баритон",
      "skill.tenor": "Тенор",
      "skill.contralto": "Контральто",
      "skill.mezzo-soprano": "Меццо-сопрано",
      "skill.bass": "Бас",
      "skill.instrument-name": "Инструмент",
      "skill.possession": "Владение",
      "skill.ballroom": "Бальные",
      "skill.folk": "Народные",
      "skill.modern": "Модерн",
      "skill.hip-hop": "Хип-хоп",
      "skill.ballet": "Балет",
      "skill.step": "Стэп",
      "skill.kind-of-sport": "Вид спорта",
      "skill.stuntman": "Каскадер",
      "skill.stunt-school": "Каскадерская школа",
      "skill.horse-stunts": "Конные трюки",
      "skill.auto-tricks": "Автотрюки",
      "skill.battle-scenes": "Исполнение боевых сцен",
      "skill.falling-from-height": "Падение с высоты",
      "skill.cable-work": "Работа на тросах",
      "skill.burning-man": "Горение человека",
      "skill.parkour": "Паркур",
      "skill.stage-battle": "Сценический бой",
      "skill.fencing": "Фехтование",
      "skill.clownery": "Клоунада",
      "skill.juggler": "Жонглирование",
      "skill.acrobatics": "Акробатика",
      "skill.magician": "Фокусник",
      "skill.equilibrist": "Эквилибристика",
      "skill.trainer": "Дрессировщик",
      "skill.language": "Язык",
      "skill.level": "Уровень владения",
      "skill.car": "Автомобиль",
      "skill.motorcycle": "Мотоцикл",
      "skill.oversized-transport": "Крупногабаритный транспорт",
      "skill.extreme-driving": "Экстремальное вождение",
      "skill.horseback-riding": "Верховая езда",

      "label.step": "Шаг",
      "label.type": "Тип",
      "label.project-type": "Тип проекта",
      "label.year": "Год",
      "label.project-name": "Название проекта",
      "label.project-director": "Режиссер",
      "label.project-position": "Должность в проекте",
      "label.project-role": "Роль",
      "label.project-role-staff": "Должность в проекте",
      "label.role-status": "Статус роли",
      "label.dates": "Даты съемок",
      "label.casting_date": "Даты съемок",

      "label.performance-title": "Название спектакля",
      "label.theater-name": "Название театра",
      "label.role-type": "Тип роли",
      "label.role-name": "Название роли",
      "label.name": "Название",
      "label.project-link": "Ссылка на проект",
      "label.category": "Категория",
      "label.categories": "Категории",

      "label.select-type": "Выберите тип",
      "label.select-role-type": "Выберите из списка",
      "label.select-appearance-type": "Выберите тип внешности",
      "label.sort-by": "Сортировать по",
      "label.sort-by-name": "Имени",
      "label.sort-by-date": "Дате регистрации",
      "label.from": "От",
      "label.to": "До",
      "label.attach-scene-text": "Прикрепить текст сцены",
      "label.attach": "Прикрепить",

      "label.agent-full-name": "ФИО агента",
      "label.agent-phone-number": "Номер агента",
      "label.agent-email": "E-mail агента",
      "label.agency": "Агентство",
      "label.phone-number": "Номер",
      "label.site": "Сайт",

      "label.video-business": "Видео-визитка",
      "label.video": "Видео",
      "label.video-link": "Ссылка на YouTube или Vimeo",

      "label.professional": "Профессионал",
      "label.amateur": "Любитель",
      "label.actor-professional": "Актер-профессионал",
      "label.actor-amateur": "Актер-любитель",

      "label.main-role": "Главная роль",
      "label.second-role": "Роль второго плана",
      "label.episode-role": "Эпизодическая роль",
      "label.crowd-role": "Актер массовой сцены",

      "plan.pro": "PRO year",
      "plan.pro_month": "PRO month",
      "plan.premium": "PREMIUM",
      "plan.status.paid": "Оплачено",
      "plan.status.subscribed": "Подписка оформлена",
      "plan.status.unsubscribed": "Подписка не оформлена",
      "plan.status.not_paid": "Не оплачено",

      "button.update": "Обновить",
      "button.unsubscribe": "Отписаться",
      "button.add": "Добавить",
      "button.remove": "Удалить",
      "button.save": "Сохранить",
      "button.edit": "Редактировать",
      "button.crop": "Обрезать",
      "button.view": "Моё портфолио",
      "button.view-company": "Портфолио",
      "button.share": "Поделиться",
      "button.more": "Подробнее",
      "button.resume": "Возобновить",
      "button.submit-application": "Оставить заявку",
      "button.leave-request": "Оставить заявку",
      "button.download-cv": "Скачать CV",
      "button.join": "Присоединиться",
      "button.pay": "Оплатить",
      "button.subscribe": "Оформить подписку",
      "button.done": "Готово",
      "button.freeze-account": "Заморозить аккаунт",
      "button.unfreeze-account": "Разморозить аккаунт",
      "button.quick-view": "Быстрый просмотр",
      "button.go-to-profile": "Перейти в профайл",
      "button.clear-all-filters": "Сбросить все фильтры",
      "button.announce-casting": "Объявить кастинг",
      "button.post-vacancies": "Опубликовать вакансию",
      "button.collect-extras": "Объявить сбор массовки",
      "button.accept-agreement": "Принять соглашение",
      "button.decline": "Отклонить",
      "button.to-favorites": "В избранное",
      "button.in-favorites": "Добавлен в избранное",
      "button.back": "Назад",
      "button.upload-file": "Загрузить файл",
      "button.download": "Скачать",
      "button.create-board": "Создать новую доску",
      "button.finish-signup": "Закончить регистрацию",
      "button.participate": "Участвую",
      "button.apply-casting": "Подать заявку",
      "button.accept": "Принимаю",
      "button.delete": "Удалить",
      "button.refuse": "Отклоняю",
      "button.close": "Закрыть",
      "button.apply": "Откликнуться",
      "button.move": "Перенести",
      "button.cancel": "Отмена",
      "button.send-invitation": "Выслать приглашение",
      "button.select-all": "Выбрать всех",
      "button.selection-pdf": "Подборка.PDF",
      "button.download-pdf-with-contacts": "С контактами",
      "button.download-pdf-without-contacts": "Без контактов",
      "button.save-and-next-step": "Сохранить и перейти на следующий шаг",
      "button.next-step": "Перейти на следующий шаг",

      "title.want-adv": "Хотите рекламироваться на Etalents.pro?",
      "title.adv-sent": "Заявка отправлена!",
      "title.adv-sent-approved": "Спасибо, ваш ответ отправлен кинодеятелю.",
      "title.adv-sent-refused": "Вы отклонили заявку!",
      "title.block-coming-soon": "Этот блок скоро заработает.",
      "title.common-data": "Общие данные",
      "title.appearance": "Внешность",
      "title.height-and-weight": "Рост и вес",
      "title.girth": "Обхват",
      "title.sizes": "Размеры",
      "title.features": "Особенности",
      "title.vocal": "Вокал",
      "title.voice-type": "Тип голоса",
      "title.skills": "Навыки",
      "title.education": "Образование",
      "title.career": "Карьера",
      "title.high-education": "Высшее образование",
      "title.high-actor-education": "Высшее актерское образование",
      "title.portfolio": "Портфолио",
      "title.contacts": "Контакты",
      "title.your-contacts": "Ваши контакты",
      "title.agent-contacts": "Контакты агента",
      "title.talent-contacts": "Контакты таланта",
      "title.company-contacts": "Контакты компании",
      "title.representative-contacts": "Контакты представителя",
      "title.area-of-work": "Область работы",
      "title.cabinet": "Кабинет",
      "title.tariffs": "Тарифы",
      "title.details": "Детали",
      "title.change-password": "Сменить пароль",
      "title.freeze-account": "Заморозка аккаунта",
      "title.payment": "Оплата",
      "title.my-profile": "Мой профайл",
      "title.project-invitations": "Приглашения в проекты",
      "title.interests-vacancies": "Вакансии, которые могут быть вам интересны",
      "title.interests-castings": "Кастинги, которые могут быть вам интересны",
      "title.last-tutorials": "Последние обучающие материалы",
      "title.main-information": "Основная информация",
      "title.common-information": "Общая информация",
      "title.vacancy-description": "Описание вакансии",
      "title.about-yourself": "О себе",
      "title.about-company": "О компании",
      "title.who-are-we": "Кто мы?",
      "title.who-are-looking-for": "Кого ищем?",
      "title.who-we-invite": "Кого приглашаем?",
      "title.profile-photo": "Фото профайла",
      "title.your-logo": "Ваш логотип",
      "title.photo": "Фото",
      "title.working": "Работаю",
      "title.courses": "Дополнительные курсы",
      "title.languages-skills": "Языковые навыки",
      "title.special-skills": "Специальные навыки",
      "title.music-skills": "Музыкальные навыки",
      "title.sport-skills": "Спортивные навыки",
      "title.cirque-skills": "Цирковые навыки",
      "title.dance-skills": "Танцевальные навыки",
      "title.stunt-skills": "Каскадерские навыки",
      "title.board-remove": "Вы уверены, что хотите удалить доску",

      "title.driving": "Вождение",
      "title.creative-experience": "Творческий опыт",
      "title.awards": "Награды и номинации",
      "title.projects-do": "Что сделали?",
      "title.role": "Роль",
      "title.theater-role": "Роль в театре",
      "title.audio": "Аудио",
      "title.video": "Видео",
      "title.photogallery": "Фотогалерея",
      "title.images": "Изображения",
      "title.files": "Файлы",
      "title.upload-photo": "Загрузить фото",
      "title.upload-audio": "Загрузить аудио",
      "title.our-clients": "Наши клиенты",
      "title.create-board": "Создать доску",
      "title.edit-board": "Редактировать доску",
      "title.new-board": "Название",
      "title.move-to-board": "Перенести на доску",

      "title.castings": "Кастинги",
      "title.vacancies": "Вакансии",
      "title.crowdings": "Сбор массовки",
      "title.invitations": "Приглашения",

      "title.announce-casting": "Объявить кастинг",
      "title.edit-casting": "Редактировать кастинг",
      "title.announce-crowding": "Объявить сбор массовки",
      "title.edit-crowding": "Редактировать сбор массовки",
      "title.publish-vacancy": "Объявить вакансию",
      "title.edit-vacancy": "Редактировать вакансию",
      "title.invite-to-casting": "Приглашение на кастинг",
      "title.invite-to-vacancy": "Приглашение на вакансию",

      "text.leave-adv-req": "Оставьте заявку и мы свяжемся с вами",
      "text.adv-req-on-review": "Ваша заявка будет рассмотрена",
      "text.adv-req-on-review-author-notified": "Если вы, по каким-то причинам, не сможете прийти на кастинг вовремя, предупредите кастинг-директора по номеру, который он оставил в приглашении.",
      "text.photogallery-desc":
        "Фотографии должны быть свежими (желательно недельной давности на момент регистрации), а внешность естественной. Не используйте фоторедакторы: в них нет смысла, ведь вас все равно увидят вживую.",
      "text.video-desc": "Загрузите видео-визитку, шоурил или другие видео.",
      "text.audio-desc": "Загрузите аудиозапись в формате MP3",
      "text.upload-photo-desc-1": "Перетащите ваше фото в эту область или",
      "text.upload-photo-desc-2": "выберите файл",
      "text.upload-photo-desc-3": "на вашем компьютере",
      "text.upload-photo-item-1": "Размер: соотношение сторон фото",
      "text.upload-photo-item-2":
        "Лицо крупным планом без солнцезащитных очков и теней",
      "text.upload-photo-item-3":
        "Фон в кадре должен быть простым, монотонным без отвлекающих предметов",
      "text.upload-photo-item-4": "Формат фото JPG или PNG",

      "text.subscription-term": "Действителен до",
      "text.next-withdrawal": "Следующее списание",
      "text.next-withdrawal-plan": "Тариф будет автоматически переключён на PRO year с",
      "text.your-plan": "Ваш тариф",
      "text.your-plan.detail":
        "Откройте доступ к лучшим кастингам и обучающим материалам",
      "text.your-plan.link": "Выбрать тариф",
      "text.referal.title": "Пригласите друзей и получите бонусы!",
      "text.filled-out": "Заполнено",
      "text.payment-status": "Cтатус оплаты",
      "text.moderation-status": "Cтатус модерации",
      "text.freeze-desc-1":
        "Если вы хотите приостановить свою деятельность на платформе, вы можете временно заморозить свой аккаунт. Обратите внимание: ваш профайл исчезнет из общей базы, но срок подписки не будет продлен.",
      "text.freeze-desc-2":
        "Система автоматически удалит ваш аккаунт через 6 месяцев, если вы не разморозите его.",
      "text.freeze-desc-3":
        "Чтобы удалить свой аккаунт навсегда, напишите в тех поддержку сайта.",
      "text.coming-soon": "Скоро...",
      "text.vacancies-first":
        "Нет времени искать людей в базе? Опубликуйте объявление о кастинге или сборе массовки, и свободные актеры откликнутся сами.",
      "text.vacancies-second":
        "Ищете профессионалов кино? Откройте вакансию среди кинодеятелей и выбирайте кандидатов из списка откликнувшихся.",
      "text.reklama-here": "Здесь может быть ваша реклама",
      "text.reklama-there": "Вы можете разместить свою рекламу",
      "text.reklama-there-staff": "Хочу разместить рекламный баннер на сайте",
      "text.progress-desc-talent":
        "Чем подробнее вы заполните профайл, тем выше шансы получить приглашение на съемку. Заполните анкету через кнопку 'Редактировать'",
      "text.progress-desc-talent-short":
        "Чем подробнее вы заполните профайл, тем выше шансы получить приглашение на съемку",
      "text.progress-desc-staff":
        "Чем подробнее вы заполните профайл, тем выше вы поднимитесь во внутреннем рейтинге платформы. Заполните анкету через кнопку 'Редактировать'",
      "text.progress-desc-staff-short":
        "Заполните анкету через кнопку 'Редактировать'",
      "text.progress-full-desc":
        "Чем подробнее вы заполните все пункты своего профайла, тем выше вы поднимитесь во внутреннем рейтинге платформы. Ваша позиция зависит от количества данных, которые вы указали.",
      "text.no-tutotials": "У вас пока нет обучающих материалов",
      "text.no-options": "Не найдено",
      "text.select-option-create": "Создать",
      "text.subscription-expires-soon": "Срок подписки начинает истекать",
      "text.subscription-renew": "Продлить подписку на год",
      "text.unsubscribed-success": "Вы успешно отписались",
      "text.change-plan-to-pro-success": "Тариф успешно изменён на PRO year",

      "text.invitations-develop":
        'Раздел "Приглашения" находится в разработке и уже скоро появится в приложении.',
      "text.vacancies-develop":
        'Раздел "Вакансии" находится в разработке и уже скоро появится в приложении.',
      "text.castings-develop":
        'Раздел "Кастинги" находится в разработке и уже скоро появится в приложении.',

      "text.date-format": "Дата в формате ДД.ММ.ГГГГ",
      "text.select-city-from-list": "Выберите город из списка",
      "text.select-area-of-work":
        "Укажите области, в которых вы готовы работать",
      "text.career.title": "Карьера",
      "text.profile-status.title": "Занятость",
      "text.staff-status.title": "Я позиционирую себя как",
      "text.profession.extra":
        "*Первая выбранная профессия будет указана как основная. Вы можете выбрать до 3 профессий.",
      "text.service.extra": "*Первая выбранная деятельность будет указана как основная.",
      "text.guide-title-1": "Как добавить таланта в «Избранное»",
      "text.guide-title-2": "Как работать с инструментом «Доски»",
      "text.guide-title-3": "Как проводить кастинги на платформе",

      "text.photo-drop-title-talent-1": "ФОТО, С КОТОРЫМ",
      "text.photo-drop-title-talent-2": "ТЫ ПРОЙДЁШЬ ЛЮБОЙ КАСТИНГ",
      "text.photo-drop-title-staff-1": "ФОТО, С КОТОРЫМ",
      "text.photo-drop-title-staff-2": "ТЫ ПРОЙДЁШЬ МОДЕРАЦИЮ",
      "text.photo-drop-checklist": "Чек-лист удачной фотографии:",
      "text.photo-drop-checklist-1": "Лицо крупным планом (без яркого макияжа)",
      "text.photo-drop-checklist-2": "Лёгкая улыбка",
      "text.photo-drop-checklist-3": "Однотонный фон",
      "search.no-talent-found":
        "Таланты не найдены. Попробуйте изменить параметры поиска.",
      "search.no-staff-found":
        "Кинодеятели не найдены. Попробуйте изменить параметры поиска.",
      "search.no-company-found":
        "Компании не найдены. Попробуйте изменить параметры поиска.",
      "search.talent-found": "Найдено талантов",
      "search.staff-found": "Найдено кинодеятелей",
      "search.company-found": "Найдено компаний",

      "gallery.height": "ФОТО В РОСТ",
      "gallery.smile": "ЛЕГКАЯ УЛЫБКА",
      "gallery.frowning": "ХМУРОЕ ВЫРАЖЕНИЕ ЛИЦА",
      "gallery.mouth": "УЛЫБКА В ЗУБЫ",
      "gallery.positive": "ПОЗИТИВНОЕ УДИВЛЕНИЕ",
      "gallery.three-quarter": "ФОТО В ТРИ ЧЕТВЕРТИ, СРЕДНЕ-ОБЩИЙ ПЛАН",
      "gallery.back": "ПРИПОДНИМИТЕ РУКИ ПЕРЕД СОБОЙ ТЫЛЬНОЙ СТОРОНОЙ К КАМЕРЕ",
      "gallery.profile": "ФОТО В ПРОФИЛЬ ИЛИ В ТРИ ЧЕТВЕРТИ",
      "gallery.text": "добавьте фотографию в формате JPG или PNG",

      "status.in-progress": "В процессе",
      "status.moderation-refused": "Отклонено! Внесите изменения",
      "status.moderation-approved": "Одобрено! Вы в общей базе",
      "status.moderation-approved-staff-company": "Одобрено, вам доступны все инструменты платформы",

      "payment.subscription-expires": "Срок подписки начинает истекать.",
      "payment.unsubscribe": "Отписаться от подписки",
      "payment.update-subscription": "Обновите ваш платёж.",
      "payment.free-is-infinite": "Срок бесплатной подписки не ограничен.",
      "payment.openAccess":
        "Откройте доступ к лучшим кастингам и обучающим материалам",
      "payment.invite-friends": "Пригласите друзей",
      "payment.and-get-bonuses": "и получите бонусы!",

      "payment.free.title": "FREE",
      "payment.free.subtitle": "Бесплатно",
      "payment.free.casting": "Кастинги",
      "payment.free.casting-detail": "на массовые и групповые роли",
      "payment.free.profile": "Подробная анкета актера",
      "payment.free.cv": "CV (резюме) на русском и английском языках",
      "payment.free.photos": "Фотографии – 8",
      "payment.free.video": "Видео – 1",
      "payment.free.audio": "Аудио – 1",
      "payment.free.image": "Фото в профайле (1)",
      "payment.free.tutorial": "Обучающие материалы (3)",
      "payment.free.email": "Оповещения о кастингах на почту",
      "payment.free.email-detail": "только на массовые и групповые роли",

      "payment.pro-month.title": "PRO month – 1 999 ₸/мес.",
      "payment.pro-month.subtitle": "Подписка на месяц",
      "payment.pro-month.casting": "Кастинги",
      "payment.pro-month.casting-detail":
        "на главные, второстепенные, эпизодические, массовые и групповые роли",
      "payment.pro-month.casting-world": "Кастинги по миру",
      "payment.pro-month.profile": "Подробная анкета актера",
      "payment.pro-month.cv": "CV (резюме) на русском и английском языках",
      "payment.pro-month.photos": "Фотографии – 18",
      "payment.pro-month.videos": "Видео – 5",
      "payment.pro-month.audio": "Аудио – 5",
      "payment.pro-month.shooting": "Съемка 1 хэдшота (портретное фото) в студии Etalents.pro",
      "payment.pro-month.image": "Фото в профайле (до 20)",
      "payment.pro-month.video": "Видео-визитка (3)",
      "payment.pro-month.tutorial": "Обучающие материалы (все)",
      "payment.pro-month.email": "Оповещения о кастингах на почту",
      "payment.pro-month.email-detail":
        "на главные, второстепенные, эпизодические, массовые и групповые роли",

      "payment.pro-year.title": "PRO year – 19 999 ₸/год.",
      "payment.pro-year.subtitle": "Подписка на год",
      "payment.pro-year.casting": "Кастинги",
      "payment.pro-year.casting-detail":
        "на главные, второстепенные, эпизодические, массовые и групповые роли",
      "payment.pro-year.casting-world": "Кастинги по миру",
      "payment.pro-year.profile": "Подробная анкета актера",
      "payment.pro-year.cv": "CV (резюме) на русском и английском языках",
      "payment.pro-year.photos": "Фотографии – 18",
      "payment.pro-year.videos": "Видео – 5",
      "payment.pro-year.audio": "Аудио – 5",
      "payment.pro-year.shooting": "Съемка полного актерского портфолио в студии ETalents.pro",
      "payment.pro-year.image": "Фото в профайле (до 20)",
      "payment.pro-year.image-detail":
        "можете сделать бесплатно в студии ETalents.pro или у партнеров платформы",
      "payment.pro-year.video": "Видео-визитка (на трех языках)",
      "payment.pro-year.video-detail":
        "можете сделать бесплатно в студии ETalents.pro или у партнеров платформы",
      "payment.pro-year.tutorial": "Обучающие материалы (все)",
      "payment.pro-year.email": "Оповещения о кастингах на почту",
      "payment.pro-year.email-detail":
        "на главные, второстепенные, эпизодические, массовые и групповые роли",
      "payment.switch-to-annual-rate": "Перейти на годовой тариф",
      "payment.switch-to-annual-rate-next-time": "Перейти на годовой тариф со следующей расчётной даты",

      "text.no-projects-yet": "У вас пока нет проектов",
      "text.no-invites-yet": "У вас пока нет приглашений",
      "text.no-castings-yet": "У вас пока нет кастингов",
      "text.no-extras-yet": "У вас пока нет сбора массовок",
      "text.no-vacancies-yet": "У вас пока нет вакансий",
      "text.start-your-way-in-movies": "НАЧНИТЕ СВОЙ ПУТЬ В КИНО С",

      "label.project": "Проект",
      "label.about-project": "О проекте",
      "label.casting": "Кастинг",
      "label.producer": "Режиссер",
      "label.shooting-in": "Снимаем в",
      "label.shooting-dates": "Даты съёмок",
      "label.shooting-time": "Время съёмок",
      "label.address": "Адрес",
      "label.payment": "Оплата за съёмочную смену",
      "label.description": "Описание",
      "label.scene-text": "Текст сцены",
      "label.presentation": "Презентация",
      "label.pay-attention": "Обязательно ко вниманию",
      "label.author": "Автор",
      "label.looking-for": "Ищем",
      "label.looking-for-in": "Ищем в",
      "label.date": "Даты съемок",
      "label.time": "Время съемок",
      "label.time-casting": "Время кастинга",
      "label.position": "Должность",
      "label.invite-position": "Приглашаем на должность",
      "label.gathering-place": "Место сбора",
      "label.crowding": "Сбор массовки",
      "label.invitation": "Приглашение",
      "label.vacancy-invitation": "Приглашение на вакансию",
      "label.casting-active": "Кастинг активен",
      "label.casting-date": "Дата кастинга",
      "label.casting-time": "Время кастинга",
      "label.vacancy-active": "Вакансия активна",
      "label.extra-active": "Сбор массовки активен",
      "label.role-description": "Описание роли",
      "label.sender-first-name": "Имя отправителя",
      "label.sender-last-name": "Фамилия отправителя",
      "label.sender-phone": "Телефон отправителя",
      "label.sender-email": "E-mail отправителя",
      "label.who-invited": "Кого приглашаем участвовать",
      "label.accepted": "Принято",
      "label.refused": "Отклонено",
      "label.not-answered": "Не отвечено",
      "label.vacancy": "Вакансия",
      "label.show-who-is-ready": "Показать тех, кто готов:",
      "label.work-as": "Работает как:",
      "label.what-we-doing": "Что делаем?",
      "label.casting-is-active": "Кастинг активен",
      "label.casting-is-not-active": "Кастинг закрыт",
      "label.crowding-is-active": "Массовка активна",
      "label.crowding-is-not-active": "Массовка закрыта",
      "label.vacancy-is-active": "Вакансия активна",
      "label.vacancy-is-not-active": "Вакансия закрыта",

      "text.you-participating": "Вы уже участвуете",
      "text.you-applied-to-vacancy": "Вы уже откликнулись на вакансию",

      "title.talents": "Таланты",
      "title.staffs": "Кинодеятели",
      "title.companies": "Компании",

      "text.have-no-favorite-talent": "У вас еще нет избранных талантов",
      "text.have-no-favorite-staff": "У вас еще нет избранных кинодеятелей",
      "text.have-no-favorite-company": "У вас еще нет избранных компаний",

      "text.sign-up-success-check-email": "Проверьте свой почтовый ящик.",
      "text.sign-up-success-we-send-email": "Мы выслали вам письмо для подтверждения учётной записи.",
      "text.forgot-password-check-email": "На указанный вами почтовый адрес отправлена ссылка для восстановления пароля, перейдите по ней.",
      "text.forgot-password-check-email-error": "Этот почтовый адрес не зарегистрирован в нашей системе. Проверьте правильность ввода данных.",
      "text.wait-for-moderation": "Ваш профайл проходит проверку. Это может занять до 2 рабочих дней. Спасибо за ожидание.",
      "text.wait-for-moderation-menu": "Раздел временно недоступен. Ваш профайл проходит проверку.",
      "text.refused-menu": "Раздел недоступен, так как ваш профайл не прошел модерацию. Отредактируйте свой профайл. *Причина отказа указана в письме, которое пришло вам на почту.",
      "text.wait-for-paid": "Раздел временно недоступен. Ваш тариф не оплачен.",
      "text.available-formats": "Форматы",
      "text.files-examples": "(синопсис, логлайн, сценарий, презентация проекта и прочее)",
      "text.characters-left": "Осталось символов",
      "text.talent-paid-materials": "Чтобы добавить больше своих фотографий, видео и аудио, перейдите на тариф PRO.",
      "text.crop-photo-help": "Используйте скролл для редактирования изображения",
      "text.health-help": "Если у вас были/имеются серьезные травмы, хронические заболевания или аллергии, укажите это здесь",
      "text.enter-save-esc-reset": "Нажмите Enter чтобы сохранить или Esc чтобы отменить",
      "text.board-cannot-do-talent": "Эти действия недоступны, т.к. один (или более) из выбранных талантов уже имеет приглашение.",
      "text.board-cannot-do-staff": "Эти действия недоступны, т.к. один (или более) из выбранных кинодеятелей уже имеет приглашение.",
      "text.board-cannot-do-company": "Эти действия недоступны, т.к. одна (или более) из выбранных компаний уже имеет приглашение.",
      "text.full-vacancy-info": "Подробная информация о вакансии",
      "text.full-crowding-info": "Здесь вы можете расписать основные требования к актерам массовки. Например: Всем прийти в одежде 90-х годов.",
      "text.full-casting-info": "Укажите основные требования от режиссера или кастинг-директора. Например: Обязательно выучить текст.",

      "notification.user-update-title": "Обновление пользователя",
      "notification.update-success-text": "Данные успешно сохранены",
      "notification.password-change-success-text": "Пароль успешно изменён",
      "notification.password-change-not-success-text": "Подтверждение пароля не совпадает",
      "notification.freeze-success": "Ваш аккаунт заморожен",
      "notification.unfreeze-success": "Ваш аккаунт разморожен",
      "notification.update-failure-text": "Ошибка сохранения данных",
      "notification.update-video-text": "Только YouTube или Vimeo",

      "label.cm": "см",
      "label.kg": "кг",

      "text.status-waiting": "не просмотрено",
      "text.status-refused": "отказал",
      "text.status-approved": "принял",

      "board.type.casting": "кастинг",
      "board.type.crowding": "массовка",
      "board.type.vacancy": "вакансия",
      "board.favorites": "Избранное",

      "text.email-confirmed": "Ваша учётная запись успешно подтверждена.",
      "text.casting-accept-title": "Ваша заявка принята, ее увидит отправитель",
      "text.casting-approved-title": "Ваша заявка на участие принята, ее увидит отправитель!",
      "text.casting-refused-title": "Вы отклонили предложение, отправитель будет уведомлён",
      "text.casting-approved-text": "Он свяжется с вами. Спасибо!",
      "text.casting-refused-text": "Возможно он вышлет вам предложение на лучших условиях. Спасибо!",
      "text.casting-accept-text": "Если вы подойдете, он вышлет вам приглашение или свяжется с вами.",
      "text.casting-create-title": "Ваше объявление о кастинге активно!",
      "text.casting-create-text": "Откликнувшихся претендентов смотрите в Досках. Спасибо!",
      "text.casting-create-text-2": "1 раз в сутки вы будете получать на e-mail сводку о том, сколько человек откликнулось на кастинг.",
      "text.crowding-create-title": "Ваше объявление о массовке активно!",
      "text.crowding-create-text": "Откликнувшихся претендентов смотрите в Досках. Спасибо!",
      "text.invitation-create-title": "Приглашение отправлено",
      "text.invitation-create-text": "Следите за статусом ответов в вашей доске.",
      "text.invitation-create-text-2": "*Если талант долго не реагирует на приглашение, рекомендуем связаться с ним лично, по контактам в его профайле. ",
    }
  }
};

let language = localStorage.getItem("lang"); // localStorage.getItem('lang');

if (!language) {
  language = navigator.language !== "ru-RU" ? "en" : "ru";
  localStorage.setItem("lang", language);
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    allLanguages: ["en", "ru"],

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      bindI18n: "languageChanged"
    }
  });

export default i18n;
