import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { createForm } from 'rc-form';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import FormItem from '../../components/FormItem/FormItem';
import FormTitle from '../../components/FormTitle/FormTitle';
import FormItemControls from '../../components/FormItemControls/FormItemControls';
import get from 'lodash/get';

import formStyles from '../Forms/EditForms.module.scss';

@inject('user', 'dataStore', 'navigate')
@observer
class PortfolioExperienceEdit extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields({}, (error, values) => {
      if (!error) {
        const data = {
          staff: {
            drafts_attributes: [{
              movie_type_dictionary_id: values.movie_type_dictionary_id.value,
              name: values.name,
              position: values.position,
              director: values.director,
              year: new Date(values.year.value, 0, 2),
              id: get(this.props.data, 'id')
            }],
          }
        };

        this.props.user.update(data, true).then(() => {
          this.props.form.resetFields();
        });
      } else {
        console.log('error', error, values);
      }
    });
  }

  render() {
    const { t, form, data = {}, dataStore } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const { movieTypesOptions } = dataStore;

    const dataProject = data.id
      ? movieTypesOptions.find(t => t.value == data.movie_type_dictionary_id)
      : null;
    const dataYear = data.year
      ? dataStore.yearOptions.find(t => t.value == data.year)
      : null;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={formStyles.formGrid} style={{ marginBottom: 0 }}>
          <div className={formStyles.formGrid3}>
            <FormItem>
              <FormTitle>{t('label.project-type')}</FormTitle>
              {getFieldDecorator('movie_type_dictionary_id', {
                initialValue: dataProject,
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Select
                  required
                  placeholder={t('label.project-type')}
                  options={movieTypesOptions}
                  errors={getFieldError('movie_type_dictionary_id')}
                />
              )}
            </FormItem>
            <FormItem>
              <FormTitle>{t('label.year')}</FormTitle>
              {getFieldDecorator('year', {
                initialValue: dataYear,
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Select
                  required
                  placeholder={t('label.year')}
                  options={dataStore.yearOptions}
                  errors={getFieldError('year')}
                />
              )}
            </FormItem>
          </div>
          <div className={formStyles.formGrid3}>
            <FormItem>
              <FormTitle>{t('label.project-name')}</FormTitle>
              {getFieldDecorator('name', {
                initialValue: get(data, 'name', ''),
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="project_name"
                  placeholder={t('label.project-name')}
                  errors={getFieldError('name')}
                />
              )}
            </FormItem>
            <FormItem>
              <FormTitle>{t('label.project-director')}</FormTitle>
              {getFieldDecorator('director', {
                initialValue: get(data, 'director', ''),
              })(
                <Input
                  name="director"
                  placeholder={t('label.project-director')}
                  errors={getFieldError('director')}
                />
              )}
            </FormItem>
          </div>
          <div className={formStyles.formGrid3}>
            <FormItem>
              <FormTitle>{t('label.project-role-staff')}</FormTitle>
              {getFieldDecorator('position', {
                initialValue: get(data, 'position', ''),
                rules: [{ required: true, message: t('validation.input.required') }]
              })(
                <Input
                  required
                  name="position"
                  placeholder={t('label.project-role-staff')}
                  errors={getFieldError('position')}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className={formStyles.formGrid}>
          <FormItemControls data={data} entity="drafts"/>
        </div>
      </form>
    )
  }
}

export default withTranslation()(createForm()(PortfolioExperienceEdit));
