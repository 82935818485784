import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Loader from '../../components/Loader/Loader';

@inject('navigate', 'user')
@observer
export default class VkSigninPage extends Component {
  componentDidMount() {
    const code = this.props.code;

    console.log(code, this.props);

    if (code) {
      this.props.user.signUpWithSocial({
        token: code,
        platform: 'vk',
      }).then(res => {
        console.log('=====', res)

      })

    }
  }

  render() {
    return (
      <div>
        <Loader isLoading={true}/>
      </div>
    )
  }
}
