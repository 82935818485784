import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import InputFiles from 'react-input-files';
import Button from '../Button/Button';
import styles from './InputFile.module.scss';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';

@inject("user")
@observer
class InputFile extends Component {
  handleChange = (files) => {
    const promises = [];

    for (let file of files) {
      const filePromise = new Promise(resolve => {
        const reader = new FileReader();
        const filename = decodeURI(file.name);
        reader.readAsDataURL(file);
        reader.onabort = () => console.log('InputFile file reading was aborted');
        reader.onerror = () => console.log('InputFile file reading has failed');
        reader.onload = () => resolve({ data: reader.result, filename });
      });

      promises.push(filePromise);
    }

    Promise.all(promises).then(fileContents => {
      this.props.onSelect(fileContents);
    });
  }

  handleDownload = () => {
    const { name, path } = this.props.value;

    this.props.user.downloadFile(name, path);
  }

  handleRemove = () => {
    this.props.onRemove(this.props.value.id);
  }

  render() {
    const { t, accept, multiple, errors = [], extra, value = {}, title } = this.props;

    return (
      <div className={styles.wrapper}>
        {value.id ? (
          <div className={styles.fileBlock}>
            <span className={styles.fileName}>{value.name}</span>
            <Button onClick={this.handleDownload} className={styles.fileButton} size="small" kind="primary">
              {t("button.download")}
            </Button>
            <Button onClick={this.handleRemove} className={styles.fileButton} size="small" kind="bordered">
              {t("button.remove")}
            </Button>
          </div>
        ) : (
          <div style={{ marginBottom: 10 }}>
            <InputFiles style={{ display: 'block' }} multiple={multiple} accept={accept} onChange={this.handleChange}>
              <div className={styles.fileAttach}>
                <Button kind="primary">
                  {title ? t(title) : t("button.upload-file")}
                </Button>
                {!multiple && get(value, '[0].filename]') && (<span className={styles.attachedFileName}>{get(value, '[0].filename]')}</span>)}
              </div>
              {extra && (<p className={styles.extra}>{extra}</p>)}
              {errors.length > 0 && (<p className={styles.errors}>{errors.length > 0 ? errors.join(', ') : null}</p>)}
            </InputFiles>
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(InputFile);
