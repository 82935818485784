import React from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import Select from "../../../components/Select/Select";
import FormItem from "../../../components/FormItem/FormItem";
import FormInputFile from "../../../components/FormInputFile/InputFile";
import FormTitle from "../../../components/FormTitle/FormTitle";
import Textarea from "../../../components/Textarea/Textarea";

import styles from "../EditForms.module.scss";

const Role = ({ form, initialValues, t, dataStore }) => {
  const { getFieldDecorator, getFieldError } = form;
  const { roleStatusTypes } = dataStore;

  return (
    <>
      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem>
            <FormTitle>{t("label.role-type")}</FormTitle>
            {getFieldDecorator("role_type", {
              initialValue: initialValues.role_type,
              rules: [
                { required: true, message: t("validation.input.required") }
              ]
            })(
              <Select
                required
                placeholder={t("label.select-role-type")}
                options={roleStatusTypes}
                errors={getFieldError("role_type")}
              />
            )}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormTitle>{t("label.attach-scene-text")}</FormTitle>
          {getFieldDecorator("document", {
            initialValue: initialValues.document
          })(
            <FormInputFile
              extra={`${t("text.available-formats")}: doc, docx, txt, rtf, pdf`}
              accept=".doc, .docx, .txt, .rtf, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain, application/rtf, application/pdf"
              errors={getFieldError("document")}
            />
          )}
        </div>
      </div>

      <div className={styles.formGrid20}>
        <div className={styles.photoGrid2}>
          <FormItem styles={{ marginBottom: 10 }}>
            <FormTitle>{t("label.role-description")}</FormTitle>
            {getFieldDecorator("about", {
              initialValue: initialValues.about,
              rules: [
                { required: true, message: t("validation.input.required") }
              ]
            })(<Textarea name="about" errors={getFieldError("about")} />)}
          </FormItem>
        </div>
        <div className={styles.photoGrid2}>
          <FormTitle>{t("label.pay-attention")}</FormTitle>
          <FormItem>
            {getFieldDecorator("attention", {
              initialValue: initialValues.attention
            })(
              <Textarea
                name="attention"
                errors={getFieldError("attention")}
                extra={t("text.full-casting-info")}
              />
            )}
          </FormItem>
        </div>
      </div>
    </>
  );
};

export default compose(
  withTranslation(),
  inject("dataStore", "navigate"),
  observer
)(Role);
