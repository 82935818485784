import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Modal from 'react-modal';
import styles from './Dropzone.module.scss';
import uploadImage from '../../../images/upload-image.png';
import Cropper from 'react-easy-crop';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import Slider from '../RCRange/RCSlider';
import getCroppedImg from './cropImage'

Modal.setAppElement('#app');

class CustomDropzone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isModalOpen: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      minZoom: this.props.format === 'image/png' ? 0.4 : 1,
      aspect: props.cropAspect ? props.cropAspect : 3 / 4,
    }
  }

  handleFileDrop = (acceptedFiles, files) => {
    console.log(acceptedFiles, files)
    if (files.length > 1) {
      this.props.form.setFields({ photo: {
        value: this.props.form.getFieldValue('photo'),
        errors: [new Error('Выберите один файл')]
      }});
    }

    if (files[0] && !['image/png', 'image/jpg', 'image/jpeg'].includes(files[0].type)) {
      this.props.form.setFields({ photo: {
        value: this.props.form.getFieldValue('photo'),
        errors: [new Error('Выберите файл с расширением .png или .jpg/.jpeg')]
      }});
    }

    this.setState({ isLoading: true });

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => {
        this.setState({ isLoading: false });
        console.log('file reading was aborted')
      }
      reader.onerror = () => {
        this.setState({ isLoading: false });
        console.log('file reading has failed')
      }
      reader.onload = () => {
      // Do whatever you want with the file contents
        let base64String = reader.result;

        this.props.onChange(base64String);
        this.props.onCrop(base64String);

        this.setState({ isModalOpen: true, isLoading: false });
      }
      reader.readAsDataURL(file);
    })
  }

  handlePhotoRemove = () => {
    this.props.onChange();
    this.props.onCrop();
    this.setState({ crop: { x: 0, y: 0 } });
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedArea, croppedAreaPixels });
  }

  handleCropSave = () => {
    this.setState({ isLoading: true, isModalOpen: false });

    setTimeout(() => {
      getCroppedImg(this.props.value, this.state.croppedAreaPixels, 0, this.props.format)
      .then((base64) => {
        let base64String = base64;
        while (base64String.replace(`data:${this.props.format || 'image/jpeg'};base64,`, '').length % 4 > 0) {
          base64String += '=';
        }

        this.props.onCrop(base64String);
        this.setState({ isLoading: false });

      })
      .catch(() => {
        this.setState({ isLoading: false });
      })
    }, 200);
  }

  handleCropOpen = () => this.setState({ isModalOpen: true });

  handleCropClose = () => this.setState({ isModalOpen: false });

  handleResetClick = () => this.setState({ crop: { x: 0, y: 0 }, zoom: 1 });

  render() {
    const { t, value, cropedImage, errors = [] } = this.props;
    const { isModalOpen } = this.state;

    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };

    return (
      <div>
        <Loader isLoading={this.state.isLoading} />
        {!value && (
          <Dropzone
            onDrop={this.handleFileDrop}
            multiple={false}
            accept={['image/png', 'image/jpg', 'image/jpeg']}
          >
            {({getRootProps, getInputProps}) => (
              <section className={styles.wrapper}>
                <div className={styles.inner} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <img className={styles.uploadImage} src={uploadImage} alt=""/>
                  <p className={styles.title}>{t("title.upload-photo")}</p>
                  <p className={styles.text}>{t("text.upload-photo-desc-1")}&nbsp;<span>{t("text.upload-photo-desc-2")}</span>&nbsp;{t("text.upload-photo-desc-3")}</p>
                </div>
              </section>
            )}
          </Dropzone>
        )}
        {errors.length > 0 && (
          <p className={styles.errors}>{errors.join(', ')}</p>
        )}
        {(cropedImage || value) && (
          <div className={styles.imageContainer}>
            <img className={styles.imagePreview} src={(cropedImage || value)} />
            <div className={styles.imageInfo}>
              <div onClick={this.handleCropOpen} className={styles.imageButton}>{t("button.crop")}</div>
              <div onClick={this.handlePhotoRemove} className={styles.imageButtonPrimary}>{t("button.remove")}</div>
            </div>
          </div>
        )}
        <Modal
          isOpen={isModalOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className={styles.cropWrapper}>
            <div className={styles.cropInner}>
              <Cropper
                minZoom={this.state.minZoom}
                image={value}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                restrictPosition={this.props.format === 'image/png' ? false: true}
              />
            </div>
            <div className={styles.sliderWrapper}>
              <Slider wrapperClassName={styles.slider} onChange={this.onZoomChange} value={this.state.zoom} min={this.state.minZoom} max={3} step={0.1}/>
              <Button onClick={this.handleResetClick} kind="bordered" size="small">{t("button.reset")}</Button>
            </div>
            <p className={styles.help}>*{t("text.crop-photo-help")}</p>
            <div className={styles.imageInfo}>
              <Button onClick={this.handleCropSave}>{t("button.save")}</Button>
              {/* <div onClick={this.handleCropClose} className={styles.imageButtonPrimary}>Закрыть</div> */}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(CustomDropzone);
