import React from "react";
import { Collapse } from "react-collapse";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import get from 'lodash/get';

import SearchCheckboxGroup from './components/SearchCheckboxGroup';
import SearchMultiselect from './components/SearchMultiselect';

import styles from "./Search.module.scss";

@inject("user", "dataStore")
@observer
class SkillSearchBlock extends React.Component {
  handleCollapse = () => this.props.toggleCollapse('skills');

  render() {
    const { user, form, isOpen, t, dataStore, queries = {} } = this.props;
    const { searchAggregations } = user;
    const { instrumentsOptions, sportsOptions, languagesOptions } = dataStore;
    const role = queries.role || 'talent';

    const fieldCount = field => get(
      get(searchAggregations, field, []).find(i => i.key_as_string === "true"), 'doc_count', 0
    );

    const aggrFilter = (field, options) => options
      .filter(o => get(searchAggregations, field, []).map(c => c.key.toString()).includes(o.value.toString())
    );

    const filteredInstruments = aggrFilter('instrument', instrumentsOptions);
    const filteredSports = aggrFilter('sport', sportsOptions);
    const filteredLangs = aggrFilter('lang', languagesOptions);

    const formProps = { form, queries };

    return (
      <div className={styles.collapseWrapper}>
        <div
          onClick={this.handleCollapse}
          className={cn(styles.collapseHeader, {
            [styles.collapseHeaderActive]: isOpen
          })}
        >
          {t("title.skills")}
        </div>
        <Collapse isOpened={isOpen}>
          <div className={styles.collapseBody}>
            {role === 'talent' && (
              <SearchCheckboxGroup
                {...formProps }
                title="title.special-skills"
                options={[
                  { field: 'i_model', label: 'skill.model' },
                  { field: 'dubbing', label: "skill.dubbing" },
                  { field: 'voice_acting', label: "skill.scoring" },
                ].map(o => { o.count = fieldCount(o.field); return o; }).filter(o => o.count > 0)}
              />
            )}

            {role === 'talent' && (
              <SearchCheckboxGroup
                {...formProps }
                title="title.vocal"
                options={[
                  { field: 'opera', label: 'skill.opera-singing' },
                  { field: 'pop', label: "skill.pop-singing" },
                  { field: 'folk', label: "skill.folk-singing" },
                  { field: 'jazz', label: "skill.jazz-singing" },
                ].map(o => { o.count = fieldCount(o.field); return o; }).filter(o => o.count > 0)}
              />
            )}

            {role === 'talent' && (
              <SearchCheckboxGroup
                {...formProps }
                title="title.voice-type"
                options={[
                  { field: 'soprano', label: 'skill.soprano' },
                  { field: 'baritone', label: "skill.baritone" },
                  { field: 'tenor', label: "skill.tenor" },
                  { field: 'contralto', label: "skill.contralto" },
                  { field: 'mezzo_soprano', label: "skill.mezzo-soprano" },
                  { field: 'bass', label: "skill.bass" },
                ].map(o => { o.count = fieldCount(o.field); return o; }).filter(o => o.count > 0)}
              />
            )}

            {role === 'talent' && (
              <SearchMultiselect
                {...formProps}
                title="label.instrument"
                field="instrument"
                options={filteredInstruments}
              />
            )}

            {role === 'talent' && (
              <SearchCheckboxGroup
                {...formProps }
                title="title.dance-skills"
                options={[
                  { field: 'dance_ballroom', label: 'skill.ballroom' },
                  { field: 'dance_folk', label: "skill.folk" },
                  { field: 'dance_modern', label: "skill.modern" },
                  { field: 'dance_hip_hop', label: "skill.hip-hop" },
                  { field: 'dance_ballet', label: "skill.ballet" },
                  { field: 'dance_step', label: "skill.step" },
                ].map(o => { o.count = fieldCount(o.field); return o; }).filter(o => o.count > 0)}
              />
            )}

            {role === 'talent' && (
              <SearchMultiselect
                {...formProps}
                title="label.sport"
                field="sport"
                options={filteredSports}
              />
            )}

            {role === 'talent' && (
              <SearchCheckboxGroup
                {...formProps }
                title="title.stunt-skills"
                options={[
                  { field: 'stunt', label: 'skill.stuntman' },
                  { field: 'horse', label: 'skill.horse-stunts' },
                  { field: 'car', label: 'skill.auto-tricks' },
                  { field: 'acrobatic', label: 'skill.acrobatics' },
                  { field: 'fight', label: 'skill.battle-scenes' },
                  { field: 'fall_from_height', label: 'skill.falling-from-height' },
                  { field: 'working_on_cables', label: 'skill.cable-work' },
                  { field: 'burning_man', label: 'skill.burning-man' },
                  { field: 'parkour', label: 'skill.parkour' },
                  { field: 'stage_combat', label: 'skill.stage-battle' },
                  { field: 'fencing', label: 'skill.fencing' },
                ].map(o => { o.count = fieldCount(o.field); return o; }).filter(o => o.count > 0)}
              />
            )}

            {role === 'talent' && (
              <SearchCheckboxGroup
                {...formProps }
                title="title.cirque-skills"
                options={[
                  { field: 'clown', label: 'skill.clownery' },
                  { field: 'juggler', label: "skill.juggler" },
                  { field: 'acrobat', label: "skill.acrobatics" },
                  { field: 'magician', label: "skill.magician" },
                  { field: 'equilibrist', label: "skill.equilibrist" },
                  { field: 'trainer', label: "skill.trainer" },
                ].map(o => { o.count = fieldCount(o.field); return o; }).filter(o => o.count > 0)}
              />
            )}

            <SearchMultiselect
              {...formProps}
              title="label.lang"
              field="lang"
              options={filteredLangs}
            />

            <SearchCheckboxGroup
              {...formProps }
              title="title.driving"
              options={[
                { field: 'vehicle_car', label: 'skill.car' },
                { field: 'vehicle_motorcycle', label: "skill.motorcycle" },
                { field: 'vehicle_bus', label: "skill.oversized-transport" },
                { field: 'vehicle_extreme_driving', label: "skill.extreme-driving" },
                { field: 'vehicle_riding', label: "skill.horseback-riding" },
              ].map(o => { o.count = fieldCount(o.field); return o; }).filter(o => o.count > 0)}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}
export default withTranslation()(SkillSearchBlock);
