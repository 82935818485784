import React from "react";
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import router from './router';
import history from './router/history';
import navigate from './router/navigate';
import api from './api';
import qs from 'qs'
import stores from './stores';

import "trix/dist/trix";
import "trix/dist/trix.css";

import './i18n';

import 'react-notifications/lib/notifications.css';
import 'rc-datepicker/lib/style.css';
import './react-select.css'
import "react-phone-input-2/lib/style.css"
import './../src/entry.scss';

/**
* Renders app with state corresponding to given location
* @param {Object} location
*/

async function authoriseUser(location) {
  let profileStatus = 401

  await api.fetchProfile()
    .then(result => profileStatus = result.status)
    .catch(reason => profileStatus = reason.response.status);

  const allowedLocations = ['/app/search', '/app/signup', '/app/forgot-password', '/app/new-password', '/app/vk-signin']

  let newPathname = location

  if (!allowedLocations.includes(location)) {
    newPathname = profileStatus === 401 ? '/app/signin' : location
  }

  return newPathname
}

async function renderByPath({ pathname, search }) {
  const { component, title } = await router.resolve({ pathname: await authoriseUser(pathname), query: qs.parse(search, { ignoreQueryPrefix: true }) });

  document.title = title;

  ReactDOM.render(
    [
      <Provider {...stores} navigate={navigate} api={api} key="app-root">
        {component}
      </Provider>,
    ],
    document.getElementById('app')
  );
}

history.listen(renderByPath);

export default () => renderByPath(history.location);

