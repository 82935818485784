import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import TabsHeader from '../../components/TabsHeader/TabsHeader';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import get from 'lodash/get';

import CommonEdit from './CommonEdit';
import CareerEdit from './CareerEdit';
import EducationEdit from './EducationEdit';
import SkillsEdit from './SkillsEdit';
import PortfolioEdit from './PortfolioEdit';
import ContactsEdit from './ContactsEdit';

import cabinetStyles from '../Cabinet/Cabinet.module.scss';

@inject('user', 'navigate')
@observer
class StaffCabinetEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'common'
    }
  }

  renderForm = () => {
    switch (this.props.tab || 'common') {
      case 'common': return (<CommonEdit />);
      case 'career': return (<CareerEdit />);
      case 'skills': return (<SkillsEdit />);
      case 'education': return (<EducationEdit />);
      case 'portfolio': return (<PortfolioEdit />);
      case 'contacts': return (<ContactsEdit />);
      default: break;
    }
  }

  render() {
    const { user, t, tab = 'common' } = this.props;
    const profile = user.user;
    const progress = get(profile, 'role_object.progress', {});

    const tabs = [
      { title: t("title.common-data"), tab: 'common', filled: get(progress, 'main', 0) >= 100 },
      { title: t("title.career"), tab: 'career', filled: true },
      { title: t("title.education"), tab: 'education', filled: get(progress, 'education', 0) >= 100 },
      { title: t("title.skills"), tab: 'skills', filled: get(progress, 'skills', 0) >= 100 },
      { title: t("title.portfolio"), tab: 'portfolio', filled: get(progress, 'portfolio', 0) >= 100 },
      { title: t("title.contacts"), tab: 'contacts', filled: get(progress, 'contacts', 0) >= 100 },
    ];

    return (
      <div className={cabinetStyles.page}>
        <div className={cabinetStyles.inner}>
          <TabsHeader tabs={tabs} activeTab={tab}/>
          <ProgressBar percent={get(profile, 'role_object.progress.percent', 0)}/>
          {this.renderForm()}
        </div>
      </div>
    )
  }
}

export default withTranslation()(StaffCabinetEdit);
